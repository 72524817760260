import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

//style
import "../../static/styles/components/magicLinkSignin.css";

//components
import GoogleAuth from "../design/authWithGoogle";
import Loader from "../design/loader";
import Left from "../landingContent";
import SignIn from "./signin/signIn";

// apis
import { sendMagicLink } from "../../apis/magicLinkApis";

// constants
import { SIGN_IN } from "../../constants/magicLink";

// utils

// hooks
import classNames from "classnames";
import RightSlider from "components/design/RightSlider";
import { Button } from "components/DesignSystem/Button/Button";
import Help from "components/help/help";
import ProfileHelpIcon from "components/icons/ProfileHelpIcon";
import Logo from "components/inkleLogo";
import OnBoardingOption from "components/onboarding/onboardingOption/onBoardingOption";
import {
  NEW_SIGNUP_FAILURE,
  NEW_SIGNUP_SUCCESS,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useToast } from "hooks/useToast";
import { useTokenValidation } from "hooks/useTokenValidation";
import { useDispatch } from "react-redux";
import { setOnboardingStep } from "store/slices/onboarding";
import { postConversionToReditus } from "utils/analytics";
import { useQuery, useUpdateQuery } from "../../hooks/useQuery";
import { InvitationContext } from "contexts/InvitationContext";
import { useUpdateNameMutation } from "store/apis/settings";
import { FOREIGN_CA } from "constants/userTypes";

function MagicLink({ recaptchaLoaded }) {
  const { alertToast } = useToast();
  const tokenValid = useTokenValidation();
  const { pathname, hash } = useLocation();
  let query = useQuery();
  const couponCode = query.get("couponCode");
  const isInvited = useContext(InvitationContext);
  const showSignup = isInvited || query.get("signupform");
  const referralCode = query.get("ref");
  const { setItem } = useLocalStorage("referralCode", null);
  const history = useHistory();
  const googleAccessToken = isInvited ? "" : hash?.split(/[=&]+/)[1];
  const [loading, setLoading] = useState(false);
  const { trackEvent } = useAnalytics();
  const [authLoading, setAuthLoading] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const dispatch = useDispatch();
  const isPracticeUser = query.get("practice");
  const [updateName] = useUpdateNameMutation();
  const { update } = useUpdateQuery();

  useEffect(() => {
    if (isPracticeUser) {
      localStorage.setItem("user_type", FOREIGN_CA);
      update({ query: "practice", value: null });
    }
  }, [isPracticeUser, update]);

  useEffect(() => {
    if (couponCode) localStorage.setItem("couponCode", couponCode);
  }, [couponCode]);

  useEffect(() => {
    if (referralCode) setItem(referralCode);
  }, [referralCode]);

  useEffect(() => {
    dispatch(setOnboardingStep(1));
    if (googleAccessToken) setLoading(true);
    if (tokenValid) {
      history.replace("/");
    }
  }, []);

  const authorize = async ({ token, payload, origin }) => {
    const session_id = localStorage.getItem("TASK_SESSION_ID");
    const updatePayload = {
      ...payload,
      captcha_value: token,
      session_id,
      is_public_user: session_id ? true : false,
    };

    try {
      let response;

      if (isInvited) {
        const updateResponse = await updateName({
          payload: {
            first_name: payload?.first_name,
            last_name: payload?.last_name,
            profile_id: payload?.uuid,
          },
        }).unwrap();

        response = {
          data: { data: updateResponse },
        };
      } else {
        response = await sendMagicLink(updatePayload);
      }

      if (!response.data.data.mobile) {
        trackEvent(NEW_SIGNUP_SUCCESS, {
          screen: "Sign Up",
          first_name: response.data.data.first_name,
          last_name: response.data.data.last_name,
          email: response.data.data.email,
        });
        postConversionToReditus({ email: response.data.data.email });
      }

      localStorage.setItem("magicLinkData", JSON.stringify(payload));

      const couponCode = localStorage.getItem("couponCode");
      if (couponCode)
        history.push({
          pathname: isInvited ? "/invitation/phoneverify" : "otpverify",
          search: `?couponCode=${couponCode}&origin=${origin}`,
        });
      else
        history.push({
          pathname: isInvited ? "/invitation/phoneverify" : "otpverify",
          search: `?origin=${origin}`,
        });
      setAuthLoading(false);
      return true;
    } catch (e) {
      if (payload?.first_name) {
        trackEvent(NEW_SIGNUP_FAILURE, {
          screen: "Sign Up",
          error_message: e?.response?.data?.error?.message,
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
        });
      }
      alertToast({ message: e?.response?.data?.error?.message });
      setAuthLoading(false);
      return false;
    }
  };

  const updatePage = async (payload, origin) => {
    setAuthLoading(true);
    if (isInvited) {
      return authorize({ token: "", payload, origin });
    }

    grecaptcha.ready(function () {
      grecaptcha
        .execute(import.meta.env.VITE_APP_RECAPTCHA_KEY, {
          action: "submit",
        })
        .then(async function (token) {
          authorize({ token, payload, origin });
        });
    });
  };

  const openModal = () => setShowHelp(true);
  const closeModal = () => setShowHelp(false);
  const signinPath = pathname === SIGN_IN;

  const signinUrl = couponCode ? `/signin?couponCode=${couponCode}` : "/signin";
  const signupUrl = couponCode ? `/signup?couponCode=${couponCode}` : "/signup";

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="t-relative t-z-10 t-flex t-h-20 t-items-center t-justify-between t-bg-surface">
        <Logo className="t-mb-0 t-ml-16" />
        <div className="t-z-50 t-mr-8 t-flex t-gap-6">
          <span className="t-z-50  t-flex t-items-center t-text-text-100 hover:t-text-purple">
            <Button onClick={openModal} size="small" customType="transparent">
              <ProfileHelpIcon color="currentColor" />
              <span className="helpText t-ml-1 !t-text-body !t-font-medium">
                Help
              </span>
            </Button>
          </span>
          {!signinPath ? (
            <Button
              customType="primary"
              onClick={() => history.push(signinUrl)}
            >
              Sign in
            </Button>
          ) : (
            <Button
              customType="primary"
              onClick={() => history.push(signupUrl)}
            >
              Sign up
            </Button>
          )}
        </div>
      </div>
      <div
        className={classNames(
          "parentDiv t-mt-[4%] !t-h-4/5 sm:!t-justify-end ",
          {
            "!t-justify-center": signinPath,
            "sm:-t-mt-12": !signinPath,
          }
        )}
      >
        {!signinPath && showSignup && (
          <Left invitation={isInvited} className="sm:t-hidden" />
        )}
        {!googleAccessToken ? (
          signinPath ? (
            <SignIn
              updatePage={async (value) => {
                const res = await updatePage(value, "signin");
                return res;
              }}
              isLoading={authLoading}
            />
          ) : (
            <OnBoardingOption
              updatePage={(value) => updatePage(value, "signup")}
              authLoading={authLoading || !recaptchaLoaded}
              showSignup={showSignup}
            />
          )
        ) : (
          <GoogleAuth
            googleAccessToken={googleAccessToken}
            setLoading={setLoading}
            onGoogleAuthFail={() => history.push(pathname)}
            invitation={isInvited}
          />
        )}
      </div>
      {showHelp && (
        <RightSlider width="400px" show={showHelp} closeModal={closeModal}>
          <Help closeModal={closeModal} loggedOut />
        </RightSlider>
      )}
    </>
  );
}

export default MagicLink;
