import { Note } from "store/apis/notes";
import { formatDate } from "utils/formatDate";
import React, { useEffect, useRef } from "react";
import QuillEditor from "components/QuillEditor/QuillEditor";

const NoteBody = ({
  note,
}: {
  note: {
    uuid: string;
    description: string;
    created_at: string;
    created_by?: { name: string };
  };
}) => {
  const { description, created_at, created_by } = note;
  let formattedNotes = "";
  try {
    formattedNotes = JSON.parse(description);
  } catch (error) {
    formattedNotes = description;
  }

  const quillRefItem = useRef();

  if (typeof formattedNotes === "object") {
    return (
      <span className="noteDescription">
        <QuillEditor
          initReadOnly
          theme="bubble"
          defaultValue={formattedNotes}
          parentRef={quillRefItem}
          onTextChange={() => {}}
        />
        <div className="t-text-body-sm t-text-text-30 t-flex t-gap-1.5 t-items-center">
          <span>{created_by?.name || ""}</span>
          <span>•</span>
          <span>{formatDate(created_at)}</span>
        </div>
      </span>
    );
  }

  return (
    <>
      <div className="t-text-body t-text-text-100">{description}</div>
      <div className="t-text-body-sm t-text-text-30 t-flex t-gap-1.5 t-items-center">
        <span>{created_by?.name || ""}</span>
        <span>•</span>
        <span>{formatDate(created_at)}</span>
      </div>
    </>
  );
};

export const NotesContent = ({ notes }: { notes: Note[] }) => {
  const lastNoteRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (lastNoteRef.current) {
      lastNoteRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [notes, lastNoteRef]);

  return (
    <div className="t-space-y-4">
      {notes.map((note, index) => (
        <div
          key={note.uuid}
          ref={index === notes.length - 1 ? lastNoteRef : null}
          className="t-py-4 t-px-6 t-rounded-lg t-border t-border-solid t-border-neutral-0 t-bg-surface-lighter-grey t-space-y-5"
        >
          <NoteBody note={note} />
        </div>
      ))}
    </div>
  );
};
