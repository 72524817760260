import { ConditionalLink } from "components/conditionalLink";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { Cart } from "components/icons/Cart";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { Product } from "pages/Billing/Product/Product";
import { useState } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import CreditCoin from "static/images/CreditCoin.svg";
import { useGetGroupCouponQuery } from "store/apis/billing";
import { useGetCartQuery } from "store/apis/products";
import { useGetCreditsQuery } from "store/apis/refrral";
import { pluralize } from "utils/pluralize";
import ApplyCouponModal from "./ApplyCouponModal";
import { BillingApp } from "./BillingApp";
import CreditHistory from "./CreditHistory";
import InvoiceeDetails from "./InvoiceeDetails";
import PastQuotes from "./PastQuotes";
import PaymentInfo from "./PaymentInfo";
import { DashboardLayout } from "components/DashboardLayout";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import ConditionalToolTip from "components/design/conditionalToolTip";
import WarningCircle from "static/images/WarningCircle.svg";
import { formatNumber } from "utils/formatNumber";

const BillingTabBar = ({ crmId = "" }: { crmId?: string }) => {
  usePageTitle("Billing");
  const { isCpa, isUserTypeForeignCA, isForeignCA } = useRoleBasedView();
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const isFCA = isUserTypeForeignCA || isForeignCA;

  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: cart, isFetching: isCartFetching } = useGetCartQuery(
    { groupId: groupId!, entityId },
    { skip: !groupId || !entityId }
  );
  const { data: couponData } = useGetGroupCouponQuery(
    { groupId },
    { skip: !groupId }
  );

  const { data } = useGetCreditsQuery({ groupId }, { skip: !groupId });
  const { total_credits } = data || {};
  const isLessCredits = total_credits?.toString() && total_credits < 100;
  const currentEntity = entities.find(({ uuid }) => entityId === uuid);
  const [openApplyCoupon, setOpenApplyCoupon] = useState(false);

  const LinkComponent = isCartFetching ? "div" : ConditionalLink;
  const { link } = useConstructInternalLink();

  return (
    <>
      <DashboardLayout
        header={
          <Header
            className="t-py-1"
            v2
            title="Billing"
            right={
              <div className="t-flex t-gap-2">
                {!isFCA && (
                  <div className="t-flex t-flex-wrap t-gap-2">
                    {Boolean(couponData?.coupon_code) ? (
                      <ToolTip text={couponData?.coupon_code}>
                        <div className="t-rounded t-border t-border-solid t-border-green-40 t-bg-dark_green-10 t-px-4 t-py-1 t-text-subtitle-sm t-font-medium t-text-dark_green-50 t-h-8">
                          Coupon Applied
                        </div>
                      </ToolTip>
                    ) : (
                      <Button
                        onClick={() => setOpenApplyCoupon(true)}
                        size="small"
                      >
                        Apply Coupon
                      </Button>
                    )}
                  </div>
                )}
                <EntitySelector showOnlyUSEntities size="small" />
                {!isFCA && (
                  <ConditionalLink
                    className="t-flex t-h-8 t-cursor-pointer t-items-center t-justify-center t-rounded t-bg-credit-gradient t-px-2 t-py-2 t-text-subtitle-sm t-text-purple-10 t-no-underline hover:t-text-purple-10"
                    to={
                      isCpa
                        ? `${url}/credits?entity=${entityId}&add_credits=true`
                        : `/settings/billing/credits?entity=${entityId}&add_credits=true`
                    }
                  >
                    <ConditionalToolTip
                      condition={
                        isLessCredits &&
                        "Your credit balance is below $100. Please add more."
                      }
                    >
                      <span>
                        <img
                          className="t-h-4"
                          src={CreditCoin}
                          alt="CreditCoin"
                        />
                        <span className="t-mx-1">
                          {formatNumber(total_credits || 0)}
                        </span>
                        <span>Credits</span>
                      </span>
                    </ConditionalToolTip>
                    {isLessCredits && currentEntity?.autopay_credits && (
                      <img
                        src={WarningCircle}
                        alt="Warning circle"
                        className="t-h-5 t-absolute t-top-0 -t-right-2"
                      />
                    )}
                  </ConditionalLink>
                )}
              </div>
            }
            bottom={
              <Tab.Root defaultValue={pathname} value={link(pathname)}>
                <Tab.List>
                  <Tab.NavTrigger exact value={`${url}${link("")}`}>
                    Products
                  </Tab.NavTrigger>
                  <Tab.NavTrigger exact value={`${url}/${link("invoices")}`}>
                    Invoices
                  </Tab.NavTrigger>
                  {!isFCA && (
                    <>
                      <Tab.NavTrigger exact value={`${url}/${link("credits")}`}>
                        Credits & Rewards
                      </Tab.NavTrigger>
                      <Tab.NavTrigger value={`${url}/${link("pastquotes")}`}>
                        Past Quotes
                      </Tab.NavTrigger>
                    </>
                  )}
                  <Tab.NavTrigger exact value={`${url}/${link("billinginfo")}`}>
                    Billing Address
                  </Tab.NavTrigger>
                  <Tab.NavTrigger
                    exact
                    value={`${url}/${link("paymentmethod")}`}
                  >
                    Payment Methods
                  </Tab.NavTrigger>
                </Tab.List>
              </Tab.Root>
            }
          />
        }
      >
        <Switch>
          <Route path={`${path}/invoices`}>
            <BillingApp crmId={crmId} />
          </Route>
          <Route path={`${path}/credits`}>
            <CreditHistory />
          </Route>
          <Route path={`${path}/pastquotes`}>
            <PastQuotes />
          </Route>
          <Route exact path={`${path}/billinginfo`}>
            <InvoiceeDetails />
          </Route>
          <Route exact path={`${path}/paymentmethod`}>
            <PaymentInfo />
          </Route>
          <Route path={path}>
            <Product />
          </Route>
        </Switch>
        <ApplyCouponModal
          show={openApplyCoupon}
          closeModal={() => setOpenApplyCoupon(false)}
        />
      </DashboardLayout>
      {cart && cart.order_summary.items_count > 0 && (
        <LinkComponent to={isCartFetching ? "" : link(`${url}/cart`)}>
          <div className=" t-px-4 t-absolute t-bottom-5 t-left-1/2 t-transform -t-translate-x-1/2 t-flex t-justify-between t-w-4/5 t-p-2 t-pl-4 t-bg-purple-50 t-text-surface t-items-center t-rounded t-text-subtext t-shadow-hover-card-shadow t-z-10 ">
            <div className="t-flex t-gap-2">
              {pluralize(cart.order_summary.items_count, "item", "items")}{" "}
              <span className="t-text-purple-40">|</span>{" "}
              <AmountSuperScript amount={cart.order_summary.subtotal} />
            </div>
            <Button
              customType="transparent"
              size="small"
              disabled={isCartFetching}
              isLoading={isCartFetching}
            >
              {!isCartFetching && (
                <div className="t-flex t-gap-1.5">
                  <Cart /> View Cart
                </div>
              )}
            </Button>
          </div>
        </LinkComponent>
      )}
    </>
  );
};

export default BillingTabBar;
