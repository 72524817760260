// Not a compound component as the logic is interlinked
//
// Entry points for payments:
// - Task (Invoice gets created when you pay)
// - Subscription (Supported)
// - Revive Subscription (No support)
// - Add credit (No support)
//  - Create invoice internally and pays the invoice
// - Pay single/multiple invoice (Partial support added, has to be tested)
//   - Ticket
//   - Invoice pay from billing
// - Cart (Supported)
//  - Cart Subscriptions and one time invoice

// Features
// - Add cards
// - use saved cards

import * as Accordion from "@radix-ui/react-accordion";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { profileData } from "apis/profileData";
import classNames from "classnames";
import { AddCardModal } from "components/billing/AddCardModal";
import DefaultCardModal from "components/billing/DefaultCardModal";
import { PaymentBillingAddress } from "components/billing/PaymentBillingAddress";
import PracticePaymentBillingAddress from "components/billing/PracticePaymentBillingAddress";
import { SavedCardItem } from "components/billing/SavedCardItem";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { BareInput, Label } from "components/DesignSystem/TextInput/TextInput";
import { BrexPointLogo } from "components/icons/BrexPointLogo";
import { CaretDown } from "components/icons/CaretDown";
import { Info } from "components/icons/Info";
import { InkleCreditCoin } from "components/icons/InkleCreditCoin";
import { AddPaymentMethod } from "components/PracticeBilling/MyBilling/AddPaymentMethod";
import { US } from "constants/countryCodes";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { BILLING_CYCLE } from "dictionaries";
import { useAppSelector } from "hooks/useAppSelector";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useModal } from "hooks/useModal";
import { useServiceTeamId } from "hooks/useServiceTeamId";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { MouseEvent, ReactNode, useContext, useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { NumericFormat } from "react-number-format";
import { useDispatch } from "react-redux";
import { MultiValue, SingleValue } from "react-select";
import NoSavedCard from "static/images/NoSavedCard.svg";
import PoweredByStripe from "static/images/PoweredByStripe.svg";
import {
  billingApis,
  useGetAllSavedCardsQuery,
  useGetBrexPointsQuery,
} from "store/apis/billing";
import { useGetPracticeAutofillQuery } from "store/apis/practiceAutofill";
import { useGetPaymentMethodsQuery } from "store/apis/practiceBilling";
import { Cart } from "store/apis/products";
import {
  useGetCreditsQuery,
  useUpdateAutopayCreditsMutation,
} from "store/apis/refrral";
import {
  useCreateSubscriptionMutation,
  useSavePaymentMethodMutation,
} from "store/apis/subscriptions";
import { BillingInvoice } from "types/Models/billing";
import { Subscription } from "types/Models/subscription";
import { Task } from "types/Models/task";
import { ArrayElement } from "types/utils/ArrayElement";
import { BackendError } from "types/utils/error";
import { formatNumber } from "utils/formatNumber";
import {
  CheckoutModalPropsContext,
  CheckoutModalPropsContextType,
} from "./CheckoutModalPropsContext";
import {
  getCreditUsageForBulkInvoices,
  getCreditUsageForCart,
  getCreditUsageForTask,
} from "./creditUtils";
import { PaymentButton } from "./PaymentButton";
import { useBillingAddress } from "./useBillingAddress";

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY!);

export const useSavedCards = ({
  serviceTeamId,
  groupId,
  entityId,
}: {
  serviceTeamId?: string;
  groupId?: string;
  entityId?: string;
}) => {
  const { data: practiceSavedCards = [], ...practiceStates } =
    useGetPaymentMethodsQuery(
      { serviceTeamId: serviceTeamId! },
      { skip: !serviceTeamId }
    );

  const { data: customersavedCards = [], ...customerStates } =
    useGetAllSavedCardsQuery(
      { groupId: groupId!, entityId: entityId! },
      { skip: !groupId || !entityId || Boolean(serviceTeamId) }
    );

  const isLoading = practiceStates.isLoading || customerStates.isLoading;
  const isSuccess = practiceStates.isSuccess && customerStates.isSuccess;
  const data = serviceTeamId ? practiceSavedCards : customersavedCards;

  return { isLoading, isSuccess, data };
};

const Step = ({
  setStep,
  title,
  stepNumber,
  active,
}: {
  setStep: (step: "DETAILS" | "PAYMENT") => void;
  title: ReactNode;
  stepNumber: number;
  active: boolean;
}) => {
  return (
    <button
      onClick={() => setStep("DETAILS")}
      className={classNames("all:unset p-0 t-flex t-items-center", {
        "t-text-text-black": active,
        "t-text-neutral": !active,
      })}
    >
      <div
        className={classNames(
          "t-mr-2 t-flex t-max-h-[22px] !t-max-w-[22px] t-justify-center t-items-center t-rounded-lg t-p-2 t-text-body-sm ",
          {
            "t-bg-purple t-text-surface": active,
            "t-bg-neutral-10 t-text-text-black": !active,
          }
        )}
      >
        {stepNumber}
      </div>
      <span>{title}</span>
    </button>
  );
};

const SelectBillingEntity = () => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const group = useCurrentGroupContext();

  const entityOptions = group.entities
    .filter((g) => g.country_code === US)
    .map(({ name, uuid, country, country_code }) => ({
      label: (
        <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
          <ReactCountryFlag countryCode={country_code} svg title={country} />
          <span className="t-truncate t-max-w-36">{name}</span>
        </div>
      ),
      value: uuid,
    }));

  if (
    (propsContext?.type === "invoices" ||
      propsContext?.type === "credits" ||
      propsContext?.type === "subscription") &&
    !propsContext.isEntityProvided
  ) {
    const currentEntity = entityOptions.find(
      (entity) => entity.value === propsContext.entityId
    );
    return (
      <Combobox
        label="Billing entity"
        menuPortalTarget={document.body}
        options={entityOptions}
        // @ts-ignore
        onChange={(value) => {
          if (value) {
            if (value instanceof Array) {
              return null;
            }

            propsContext.setEntityId(value.value);
          }
        }}
        value={currentEntity || null}
      />
    );
  }

  return null;
};

const AddCredits = () => {
  const parentPropsContext = useContext(CheckoutModalPropsContext);
  // @ts-ignore
  const { data: { total_credits } = {} } = useGetCreditsQuery(
    {
      groupId: parentPropsContext?.groupId!,
    },
    { skip: !parentPropsContext?.groupId }
  );

  if (parentPropsContext?.type === "credits") {
    const { setCreditsToBeAdded } = parentPropsContext;

    const onChange = (value: number) => {
      setCreditsToBeAdded?.(value);
    };

    return (
      <div className="t-space-y-3">
        <div className="t-text-subtext">Inkle Credits</div>
        <div>
          <div className="t-flex t-items-center t-gap-1 t-mb-1">
            <InkleCreditCoin />
            <div className="t-text-body t-text-text-100">
              {total_credits} Inkle Credits
            </div>
            <span className="t-block t-text-body-sm t-text-neutral">
              (Worth <AmountSuperScript amount={total_credits!} />)
            </span>
          </div>
          <Label>Add credits</Label>
          <NumericFormat
            onClick={(event: MouseEvent<HTMLInputElement>) => {
              // @ts-ignore
              if (event.target.value?.includes?.("0.00")) {
                // @ts-ignore
                event.target?.select();
              }
            }}
            customInput={BareInput}
            onValueChange={({ floatValue }) => {
              if (floatValue) {
                onChange(floatValue);
              }
            }}
            onChange={() => {}}
            placeholder="Enter"
          />
        </div>
      </div>
    );
  }

  return null;
};

const UseCredits = () => {
  const parentPropsContext = useContext(CheckoutModalPropsContext);
  const dispatch = useDispatch();

  // @ts-ignore
  const { data: { total_credits } = {} } = useGetCreditsQuery(
    {
      groupId: parentPropsContext?.groupId!,
    },
    { skip: !parentPropsContext?.groupId }
  );

  const { data: brexPoints, isFetching } = useGetBrexPointsQuery(
    {
      entityId: parentPropsContext?.entityId!,
    },
    {
      skip: !parentPropsContext?.entityId,
    }
  );

  const group = useCurrentGroupContext();
  const entityId = parentPropsContext?.entityId;

  const { connections } = useGetAllConnections({
    groupId: group.uuid!,
    entityId: entityId!,
  });

  const { onConnectPopup, isLoading: isConnecting } = useBankConnect({
    onConnectSuccess: async () => {
      dispatch(billingApis.util.invalidateTags(["CONNECTION"]));
    },
  });

  const onBrexConnect = async () => {
    const brexConnection = connections?.find(
      (c) => c.connection_provider === "BREX"
    );

    if (parentPropsContext?.entityId && brexConnection) {
      onConnectPopup({
        connectionId: brexConnection?.uuid,
        entityId: parentPropsContext?.entityId,
      });
    }
  };

  const showCredits = Boolean(total_credits) && total_credits! > 1;
  const showBrexPoints =
    brexPoints?.connection && brexPoints.connection.connection_available;

  if (parentPropsContext?.type === "subscription") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-flex-col t-gap-1">
          <p className="t-text-subtext t-font-semibold t-text-text-100 t-m-0 t-leading-none">
            Credits and Points
          </p>
          <div className="t-flex t-gap-1 t-items-center">
            <span className="t-text-text-30 t-text-body-sm">
              You cannot use credits and points for subscription payments.
            </span>
          </div>
        </div>
        <div className="t-flex t-flex-col t-gap-3 t-border-solid t-border-neutral-0 t-rounded t-p-3 t-bg-surface-lighter-grey t-border t-relative">
          <div className="t-flex t-items-start t-gap-2">
            <div className="t-mt-0.5">
              <Checkbox name="Credit" />
            </div>

            <label htmlFor="Credit" className="t-cursor-pointer">
              <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                <span className="t-flex t-gap-1 t-text-text-60">
                  <InkleCreditCoin />
                  {formatNumber(Number(total_credits))} Inkle Credits
                </span>
              </div>
              <span className="t-block t-text-body-sm t-text-neutral t-ml-5">
                Worth <AmountSuperScript amount={total_credits!} />
              </span>
            </label>
          </div>

          {brexPoints?.connection &&
            brexPoints.connection.connection_available && (
              <div className="t-flex t-items-start t-gap-2">
                <div className="t-mt-0.5">
                  <Checkbox name="BrexPoint" />
                </div>

                <label htmlFor="BrexPoint" className="t-cursor-pointer">
                  <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                    <span className="t-flex t-gap-1 t-text-text-60">
                      <BrexPointLogo />
                      {formatNumber(Number(brexPoints.connection.balance))} Brex
                      Points
                    </span>
                  </div>
                  <span className="t-block t-text-body-sm t-text-neutral t-ml-5">
                    Worth{" "}
                    <AmountSuperScript
                      amount={brexPoints.connection.available_usd}
                    />
                  </span>
                </label>
              </div>
            )}

          <div className="t-absolute t-inset-0 t-bg-white t-opacity-70 t-z-10 t-rounded t-cursor-not-allowed" />
        </div>
      </div>
    );
  }

  if (
    parentPropsContext?.type === "cart" ||
    parentPropsContext?.type === "invoices" ||
    parentPropsContext?.type === "task"
  ) {
    const { useCredits, setUseCredits, useBrexPoints, setUseBrexPoints } =
      parentPropsContext;

    return (
      <div className="t-flex t-flex-col t-gap-2">
        <p className="t-text-subtext t-text-text-60 t-m-0 t-leading-none t-mb-2">
          Credits and Points
        </p>
        {(showBrexPoints || showCredits) && (
          <div className="t-space-y-3">
            {showCredits && (
              <div className="t-flex t-items-start t-gap-2">
                <div className="t-mt-0.5">
                  <Checkbox
                    name="Credit"
                    checked={useCredits}
                    onChange={(e) => setUseCredits(e.target.checked)}
                  />
                </div>

                <label htmlFor="Credit" className="t-cursor-pointer">
                  <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                    <span className="t-flex t-gap-1 t-text-text-60">
                      <InkleCreditCoin />
                      {formatNumber(Number(total_credits))} Inkle Credits
                    </span>
                  </div>
                  <span className="t-block t-text-body-sm t-text-neutral t-ml-5">
                    Worth <AmountSuperScript amount={total_credits!} />
                  </span>
                </label>
              </div>
            )}

            {brexPoints?.connection &&
              brexPoints.connection.connection_available &&
              brexPoints.connection.available_usd > 0 && (
                <div className="t-flex t-items-start t-gap-2">
                  <div className="t-mt-0.5">
                    <Checkbox
                      name="BrexPoint"
                      checked={useBrexPoints}
                      onChange={(e) => setUseBrexPoints(e.target.checked)}
                    />
                  </div>

                  <label htmlFor="BrexPoint" className="t-cursor-pointer">
                    <div className="t-text-body t-flex t-font-normal t-text-text-30 t-gap-1 t-items-center">
                      <span className="t-flex t-gap-1 t-text-text-60">
                        <BrexPointLogo />
                        {formatNumber(
                          Number(brexPoints.connection.balance)
                        )}{" "}
                        Brex Points
                      </span>
                    </div>
                    <span className="t-block t-text-body-sm t-text-neutral t-ml-5">
                      Worth{" "}
                      <AmountSuperScript
                        amount={brexPoints.connection.available_usd}
                      />
                    </span>
                  </label>
                </div>
              )}
          </div>
        )}

        {brexPoints && !brexPoints?.connection && (
          <div className="t-flex t-gap-1.5 t-text-body-sm t-text-text-30 t-items-center">
            <BrexPointLogo />
            <p className="t-m-0">Pay using Brex points.</p>
            <Button
              isLoading={isConnecting || isFetching}
              disabled={isConnecting || isFetching}
              onClick={onBrexConnect}
              customType="link"
            >
              <span className="t-text-body-sm">Connect Brex account</span>
            </Button>
          </div>
        )}

        {brexPoints &&
          brexPoints?.connection &&
          !brexPoints?.connection?.connection_available && (
            <div className="t-flex t-gap-1.5 t-text-body-sm t-text-text-30 t-items-center">
              <BrexPointLogo />
              <p className="t-m-0">Pay using Brex points.</p>
              <Button
                isLoading={isConnecting || isFetching}
                disabled={isConnecting || isFetching}
                onClick={onBrexConnect}
                customType="link"
              >
                <span className="t-text-body-sm">Reconnect Brex account</span>
              </Button>
            </div>
          )}
      </div>
    );
  }

  return null;
};

const PaymentMethods = () => {
  const [showAddCard, setShowAddCard] = useState(false);
  const propsContext = useContext(CheckoutModalPropsContext);
  const serviceTeamId = useServiceTeamId();
  const [savePaymentMethod, { isLoading: isMakingDefault }] =
    useSavePaymentMethodMutation();
  const { successToast, alertToast } = useToast();
  const defaultCardModal = useModal();

  const isPayerFCA = propsContext?.payerUserType === "FCA";

  const { data: savedCards } = useSavedCards({
    serviceTeamId,
    groupId: propsContext?.groupId,
    entityId: propsContext?.entityId,
  });

  if (isPayerFCA && showAddCard) {
    return (
      <AddPaymentMethod
        isOpen={showAddCard}
        close={() => setShowAddCard(false)}
      />
    );
  }

  if (
    propsContext?.type === "cart" ||
    propsContext?.type === "invoices" ||
    propsContext?.type === "credits" ||
    propsContext?.type === "task" ||
    propsContext?.type === "subscription"
  ) {
    const { paymentMethodId, setPaymentMethodId } = propsContext;
    const disabled = isPayerFCA ? false : !propsContext?.entityId;
    const options = savedCards.map((card) => ({
      value: card.payment_method_id,
      label: <SavedCardItem card={card} isDefault={card.is_default_card} />,
    }));

    const selectedCard =
      savedCards.find((card) => card.payment_method_id === paymentMethodId) ||
      null;

    const selectedOption = selectedCard
      ? {
          value: selectedCard.payment_method_id,
          label: <SavedCardItem card={selectedCard} />,
        }
      : null;

    const makeCardDefault = async () => {
      try {
        if (selectedCard?.payment_method_id && propsContext) {
          await savePaymentMethod({
            groupId: propsContext.groupId,
            paymentMethodId: selectedCard?.payment_method_id,
            isDefaultCard: true,
            entityId: propsContext.entityId,
          }).unwrap();
          successToast({ message: "Card marked as default successfully" });
        }
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }
      defaultCardModal.close();
    };

    return (
      <div className="t-space-y-3">
        <Combobox
          components={{
            ClearIndicator: () => null,
            NoOptionsMessage: () => (
              <div className="t-flex t-flex-col t-gap-3 t-items-center t-p-8">
                <img src={NoSavedCard} alt="NoSavedCard" />
                <div className="t-text-text-100 t-text-subtext">
                  No cards saved yet
                </div>
                <Button
                  onClick={() => setShowAddCard(true)}
                  disabled={disabled}
                  size="small"
                >
                  Add card
                </Button>
              </div>
            ),
          }}
          actions={
            options.length > 0 ? (
              <Button
                customType="link"
                disabled={disabled}
                onClick={() => setShowAddCard(true)}
              >
                Add new card
              </Button>
            ) : (
              <></>
            )
          }
          menuPortalTarget={document.body}
          name="cards"
          label="Cards"
          value={selectedOption}
          options={options}
          onChange={(
            selectedOption: MultiValue<OptionData> | SingleValue<OptionData>
          ) =>
            setPaymentMethodId(
              (selectedOption as SingleValue<OptionData>)?.value || ""
            )
          }
        />
        {selectedCard && (
          <div className="t-mt-1">
            <ConditionalToolTip
              condition={
                selectedCard?.is_default_card &&
                "One card must always be selected as the default for future payments."
              }
              disableHoverableContent
              side="bottom"
            >
              <div className="t-w-fit">
                <Checkbox
                  name="makeDefault"
                  onChange={defaultCardModal.open}
                  label={
                    <div className="t-text-body-sm t-text-neutral">
                      Mark as default for all future payments
                    </div>
                  }
                  checked={selectedCard?.is_default_card}
                  disabled={isMakingDefault || selectedCard?.is_default_card}
                  fitWidth
                />
              </div>
            </ConditionalToolTip>
          </div>
        )}
        {showAddCard && (
          <AddCardModal
            entityId={propsContext?.entityId!}
            show
            closeModal={() => setShowAddCard(false)}
            cardsAdded={savedCards.length > 0}
            ispaymentFlow
          />
        )}
        {defaultCardModal.isOpen && (
          <DefaultCardModal
            show={defaultCardModal.isOpen}
            closeModal={defaultCardModal.close}
            entityId={propsContext?.entityId}
            addCard={makeCardDefault}
            cardAddLoading={isMakingDefault}
          />
        )}
      </div>
    );
  }
  return null;
};

const EditPaymentDetails = ({
  isScheduledPayment,
}: {
  isScheduledPayment?: boolean;
}) => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const isPayerFCA = propsContext?.payerUserType === "FCA";

  if (isPayerFCA) {
    return (
      <div className="t-flex t-flex-col t-gap-4">
        <PaymentMethods />
      </div>
    );
  }

  return (
    <div className="t-flex t-flex-col t-gap-4">
      <SelectBillingEntity />
      {!isScheduledPayment && (
        <div className="-t-mb-4">
          <PaymentMethods />
          <hr className="t-text-neutral-0 t-mt-4" />
        </div>
      )}
      <AddCredits />
      <UseCredits />
      {isScheduledPayment && (
        <div className="-t-mt-4">
          <hr className="t-text-neutral-0" />
          <PaymentMethods />
        </div>
      )}
    </div>
  );
};

const CartSummaryItem = ({
  cartItem: {
    product_details: { company_entity, product_name, season },
    quantity,
    subscription,
    unit_price,
  },
}: {
  cartItem: ArrayElement<Cart["cart_items"]>;
}) => (
  <div className="t-flex t-text-body-sm t-items-baseline">
    <p className="t-m-0 t-text-text-60">
      <div>
        <p className="t-m-0">
          {product_name} {quantity > 1 && `(x ${quantity})`}
        </p>
        <p className="t-m-0 t-text-body-sm t-text-text-30">{season}</p>
      </div>
    </p>
    <div className="t-text-body-sm t-text-text-60 t-ml-auto">
      <AmountSuperScript amount={unit_price} />
      {subscription && (
        <span className="t-text-text-30">
          /{BILLING_CYCLE[subscription.billing_cycle]}
        </span>
      )}
    </div>
  </div>
);

const PriceSummary = () => {
  const propsContext = useContext(CheckoutModalPropsContext);

  if (propsContext?.type === "cart") {
    return (
      <div className="t-space-y-3">
        {propsContext.cart.cart_items.map((item) => (
          <CartSummaryItem cartItem={item} key={item.uuid} />
        ))}
      </div>
    );
  }

  if (propsContext?.type === "invoices") {
    return (
      <div className="t-flex t-gap-1 t-flex-col">
        {propsContext.invoices.map((invoice) => (
          <div
            className="t-flex t-items-center t-text-body-sm"
            key={invoice.uuid}
          >
            <p className="t-m-0 t-text-text-60 t-w-10/12">
              {invoice.short_name}
            </p>
            <div className="t-text-body-sm t-text-text-60 t-ml-auto t-text-right t-w-2/12">
              <AmountSuperScript amount={Number(invoice.amount)} />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (propsContext?.type === "subscription") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-items-center">
          <p className="t-m-0 t-text-body-sm t-text-text-60">
            {propsContext.subscription.subscription_name}
          </p>
          <div className="t-text-body-sm t-text-text-60 t-ml-auto">
            <AmountSuperScript amount={propsContext.subscription.amount} />/
            {BILLING_CYCLE[propsContext.subscription.billing_cycle]}
          </div>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "task") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-items-center t-text-body-sm t-text-text-60">
          <p className="t-m-0">{propsContext.task.title}</p>
          <div className="t-ml-auto">
            <AmountSuperScript
              amount={Number(propsContext.task.payment_amount)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "credits") {
    return (
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-flex t-items-center t-text-body-sm t-text-text-60">
          <p className="t-m-0">Credits</p>
          <div className="t-ml-auto">
            <AmountSuperScript amount={propsContext.creditsToBeAdded} />
          </div>
        </div>
      </div>
    );
  }

  return null;
};

const TotalSummary = () => {
  const propsContext = useContext(CheckoutModalPropsContext);
  const { data: { total_credits } = {} } = useGetCreditsQuery(
    { groupId: propsContext?.groupId! },
    { skip: !propsContext?.groupId }
  );

  const { data: brexPoints } = useGetBrexPointsQuery(
    {
      entityId: propsContext?.entityId!,
    },
    {
      skip: !propsContext?.entityId,
    }
  );

  const getBrexCreditFromUSD = (usd: number) => {
    if (brexPoints?.connection?.connection_available) {
      return usd * brexPoints.connection.rate;
    }

    return 0;
  };

  if (propsContext?.type === "cart") {
    const {
      useCredits,
      useBrexPoints,
      cartInvoice,
      cart: {
        order_summary: { total, discount },
      },
    } = propsContext;

    // Cart invoice won't be present if the cart only has subscriptions
    const usableCredits = cartInvoice
      ? getCreditUsageForCart(cartInvoice, total_credits || 0).usableCredits
      : 0;

    let usableBrexPoints = cartInvoice
      ? getCreditUsageForCart(
          cartInvoice,
          total_credits || 0,
          brexPoints?.connection?.available_usd
        )[useCredits ? "usableBrexPointsAfterCredits" : "usableBrexPoints"]
      : 0;

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body-sm t-text-text-60">
        {useCredits && (
          <div className="t-flex t-justify-between">
            <p className="t-m-0">{formatNumber(usableCredits)} Credits used</p>
            <p className="t-m-0">
              <AmountSuperScript amount={-usableCredits} />
            </p>
          </div>
        )}

        {useBrexPoints && (
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-flex t-justify-between t-text-body-sm t-text-text-60">
              <p className="t-m-0">
                {formatNumber(getBrexCreditFromUSD(usableBrexPoints))} Brex
                points used
              </p>
              <p className="t-m-0">
                - <AmountSuperScript amount={usableBrexPoints} />
              </p>
            </div>
          </div>
        )}

        {discount && discount.discount_value > 0 && (
          <div className="t-flex t-items-center t-text-body-sm t-text-text-60">
            <p className="t-m-0">
              Coupon applied ({discount?.coupon?.coupon_code})
            </p>
            <div className="t-ml-auto">
              - <AmountSuperScript amount={discount.discount_value} />
            </div>
          </div>
        )}

        {(useCredits ||
          useBrexPoints ||
          (discount && discount.discount_value > 0)) && (
          <hr className="t-text-neutral-10 t-m-0" />
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0 t-text-subtext t-text-text-60">Total</p>
          <p className="t-m-0 t-text-subtext t-text-text-60">
            <AmountSuperScript
              amount={
                total -
                (useCredits ? usableCredits : 0) -
                (useBrexPoints ? usableBrexPoints : 0)
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "task") {
    const {
      useCredits,
      useBrexPoints,
      task: { coupon: discount, payment_amount },
    } = propsContext;

    const finalPrice = discount?.discounted_price || payment_amount;

    const usablePoints = getCreditUsageForTask(
      propsContext.task,
      total_credits || 0,
      brexPoints?.connection?.available_usd
    );

    const { usableCredits } = usablePoints;

    let usableBrexPoints =
      usablePoints[
        useCredits ? "usableBrexPointsAfterCredits" : "usableBrexPoints"
      ];

    const discounted =
      Number(payment_amount) - Number(discount?.discounted_price || 0);

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body-sm t-text-text-60">
        {useCredits && (
          <div className="t-flex t-justify-between">
            <p className="t-m-0">{formatNumber(usableCredits)} Credits used</p>
            <p className="t-m-0">
              {<AmountSuperScript amount={-usableCredits} />}
            </p>
          </div>
        )}

        {useBrexPoints && (
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-flex t-justify-between t-text-body-sm t-text-text-60">
              <p className="t-m-0">
                {formatNumber(getBrexCreditFromUSD(usableBrexPoints))} Brex
                points used
              </p>
              <p className="t-m-0">
                - {<AmountSuperScript amount={usableBrexPoints} />}
              </p>
            </div>
          </div>
        )}

        {discount && Number(discounted) > 0 && (
          <div className="t-flex t-items-center t-text-body-sm t-text-text-60">
            <p className="t-m-0">Coupon applied ({discount?.coupon_code})</p>
            <div className="t-ml-auto">
              - <AmountSuperScript amount={Number(discounted)} />
            </div>
          </div>
        )}

        {(useCredits ||
          useBrexPoints ||
          (discount && Number(discounted) > 0)) && (
          <hr className="t-text-neutral-10 t-m-0" />
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0 t-text-subtext t-text-text-60">Total</p>
          <p className="t-m-0 t-text-subtext t-text-text-60">
            <AmountSuperScript
              amount={
                Number(finalPrice) -
                (useCredits ? usableCredits : 0) -
                (useBrexPoints ? usableBrexPoints : 0)
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "invoices") {
    const { useCredits, invoices, totalDue, useBrexPoints } = propsContext;

    const finalTotal =
      totalDue || invoices.reduce((acc, i) => acc + Number(i.amount), 0);

    const usablePoints = getCreditUsageForBulkInvoices(
      invoices,
      total_credits || 0,
      brexPoints?.connection?.available_usd,
      totalDue
    );

    const { usableCredits } = usablePoints;

    const usableBrexPoints =
      usablePoints[
        useCredits ? "usableBrexPointsAfterCredits" : "usableBrexPoints"
      ];

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-body-sm t-text-text-60">
        {useCredits && (
          <div className="t-flex t-justify-between">
            <p className="t-m-0">{formatNumber(usableCredits)} Credits used</p>
            <p className="t-m-0">
              - {<AmountSuperScript amount={usableCredits} />}
            </p>
          </div>
        )}

        {useBrexPoints && (
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-flex t-justify-between t-text-body-sm t-text-text-60">
              <p className="t-m-0">
                {formatNumber(getBrexCreditFromUSD(usableBrexPoints))} Brex
                points used
              </p>
              <p className="t-m-0">
                - {<AmountSuperScript amount={usableBrexPoints} />}
              </p>
            </div>
          </div>
        )}
        {(useCredits || useBrexPoints) && (
          <hr className="t-text-neutral-10  t-m-0" />
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0 t-text-subtext t-text-text-60">Total</p>
          <p className="t-m-0 t-text-subtext t-text-text-60">
            <AmountSuperScript
              amount={
                Number(finalTotal) -
                (useCredits ? usableCredits : 0) -
                (useBrexPoints ? usableBrexPoints : 0)
              }
            />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "credits") {
    const { creditsToBeAdded } = propsContext;

    return (
      <div className="t-flex t-flex-col t-gap-3 t-text-subtitle t-text-text-60">
        <div className="t-flex t-justify-between">
          <p className="t-m-0">Total</p>
          <p className="t-m-0">
            <AmountSuperScript amount={creditsToBeAdded} />
          </p>
        </div>
      </div>
    );
  }

  if (propsContext?.type === "subscription") {
    const discount = propsContext.subscription.stripe_coupon;
    const subtotal = propsContext.subscription.amount;

    const total = Boolean(
      propsContext.subscription.stripe_coupon?.discounted_amount?.toString()
    )
      ? propsContext.subscription.stripe_coupon?.discounted_amount
      : subtotal;
    const discountValue = subtotal - total!;

    return (
      <>
        {discount && discountValue > 0 && (
          <div className="t-flex t-items-center t-text-body-sm t-text-text-60">
            <p className="t-m-0 t-text-dark_green">
              Coupon applied ({discount.coupon_code})
            </p>
            <div className="t-ml-auto">
              - <AmountSuperScript amount={discountValue} />
            </div>
          </div>
        )}
        {discount && discountValue > 0 && (
          <hr className="t-text-neutral-10  t-m-0" />
        )}

        <div className="t-flex t-justify-between">
          <p className="t-m-0 t-text-subtext t-text-text-60">Total</p>
          <p className="t-m-0 t-text-subtext t-text-text-60">
            <AmountSuperScript amount={total!} />/
            {BILLING_CYCLE[propsContext.subscription.billing_cycle]}
          </p>
        </div>
      </>
    );
  }

  return null;
};

const BillingAddress = () => {
  const serviceTeamId = useServiceTeamId();
  const propsContext = useContext(CheckoutModalPropsContext);
  const isPayerFCA = propsContext?.payerUserType === "FCA";
  const { groupId, entityId } = propsContext || {};

  const { billingAddresses, isLoading, billingTagId } = useBillingAddress({
    groupId,
    entityId,
  });

  const { data: autoFill = [], ...autofillState } = useGetPracticeAutofillQuery(
    {
      serviceTeamId,
      autofillKey: "addresses",
      tagId: billingTagId,
    },
    {
      skip: !serviceTeamId || !billingTagId,
    }
  );

  const loading = autofillState.isLoading || isLoading;

  if (loading || !billingTagId) {
    return (
      <div className="t-flex t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  if (isPayerFCA) {
    return <PracticePaymentBillingAddress defaultAddress={autoFill[0]} />;
  }

  if (entityId && !isPayerFCA) {
    return (
      <PaymentBillingAddress
        entityId={entityId}
        defaultAddress={billingAddresses[0]}
      />
    );
  }

  return null;
};

const PaymentSummary = () => {
  return (
    <div className="t-space-y-5 t-border t-p-4 t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-rounded">
      <div className="t-text-subtext t-text-text-60">
        Summary
        <hr className="t-text-neutral-10 t-mt-3" />
      </div>
      <div className="t-max-h-80 t-overflow-scroll">
        <PriceSummary />
      </div>

      <div />
      <hr className="t-text-neutral-10 t-mb-3" />
      <TotalSummary />
    </div>
  );
};

const Footer = ({
  onSubscribed,
  isScheduledPayment,
}: {
  onSubscribed: () => void;
  isScheduledPayment?: boolean;
}) => {
  const { successToast, alertToast } = useToast();
  const propsContext = useContext(CheckoutModalPropsContext);
  const groupId = propsContext?.groupId;
  const { updateUser } = useContext(authContext);

  const { billingAddresses, billingTagId } = useBillingAddress({
    groupId,
    entityId: propsContext?.entityId,
  });

  const stripe = useStripe();
  const serviceTeamId = useServiceTeamId();

  const isPayerFCA = propsContext?.payerUserType === "FCA";

  const { data: savedCards, isLoading } = useSavedCards({
    serviceTeamId,
    groupId,
    entityId: propsContext?.entityId,
  });

  const [createSubscription, { isLoading: isLoadingCreateSubscription }] =
    useCreateSubscriptionMutation();

  let enableButton = false;

  if (!stripe) {
    return null;
  }

  if (
    propsContext?.type === "cart" ||
    propsContext?.type === "invoices" ||
    propsContext?.type === "credits" ||
    propsContext?.type === "task"
  ) {
    const { paymentMethodId } = propsContext;
    if (isPayerFCA) {
      enableButton = Boolean(paymentMethodId);
    } else {
      enableButton = Boolean(paymentMethodId) && Boolean(propsContext.entityId);
    }
  }

  if (propsContext?.type === "subscription") {
    const defaultCard = savedCards.find((c) => c.is_default_card);
    enableButton = Boolean(defaultCard) && Boolean(propsContext.entityId);
  }

  if (propsContext?.type === "cart") {
    enableButton = propsContext.agreementAccepted;
  }

  const onCreateSubscription = async () => {
    try {
      const payload = {
        subscription_uuid: (propsContext as CheckoutSubscription)?.subscription
          ?.uuid,
        scheduled_date: dayjs()
          .add(1, "year")
          .startOf("year")
          .format(YYYY_MM_DD),
      };
      await createSubscription({
        payload,
        groupId: groupId!,
        entityId: propsContext?.entityId!,
      }).unwrap();
      onSubscribed();
      const profile = await profileData();
      updateUser(profile);
      successToast({ message: "Payment scheduled successfully." });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  if (isScheduledPayment) {
    return (
      <Button
        customType="primary"
        disabled={!enableButton || !billingAddresses?.[0]}
        onClick={onCreateSubscription}
        isLoading={isLoadingCreateSubscription}
      >
        Schedule Payment
      </Button>
    );
  }

  return <PaymentButton disabled={!enableButton} />;
};

const Agreement = () => {
  const propsContext = useContext(CheckoutModalPropsContext);

  if (
    propsContext?.type === "cart" &&
    propsContext.cart.cart_items.some(
      (c) => c.product_details.base_task_key === "PRO_MONTHLY_BOOKKEEPING"
    )
  ) {
    return (
      <div className="t-flex t-gap-2">
        <div className="t-mt-0.5">
          <Checkbox
            alignTop
            name="agreement"
            checked={propsContext.agreementAccepted}
            onChange={(e) =>
              propsContext.setAgreementAccepted(e.target.checked)
            }
            label={
              <span className="t-text-body-sm t-text-text-30">
                I understand that Monthly pro payments will be auto-deducted 14
                days after books are completed. (We give a 14-day review period
                and send reminder emails)
              </span>
            }
          />
        </div>
      </div>
    );
  }

  return null;
};

type CheckoutCartItem = Omit<
  ArrayElement<Cart["cart_items"]>,
  "subscription"
> & {
  subscription?: Subscription & {
    selectedTierAmount?: string;
    raStateName?: string;
    cartItemId?: string;
  };
};

export type CheckoutCart = Omit<Cart, "cart_items"> & {
  cart_items: CheckoutCartItem[];
};

type CheckoutCartModal = {
  type: "cart";
  cart: CheckoutCart;
  cartInvoice?: BillingInvoice | null;
  onCartPaid: () => void;
  lineItems?: {
    title: ReactNode;
    quantity: number;
    price: number;
    subscription?: Subscription;
  }[];
};

type CheckoutSubscription = {
  entityId?: string;
  type: "subscription";
  subscription: Subscription;
  onSubscribed: () => void;
};

type CheckoutInvoices = {
  type: "invoices";
  totalDue?: number;
  entityId?: string;
  invoices: BillingInvoice[];
  messageId?: string;
  onInvoicePaid: () => void;
};

type CheckoutTask = {
  type: "task";
  task: Task;
  messageId?: string;
  onInvoicePaid: () => void;
};

type CheckoutCredits = {
  type: "credits";
  entityId?: string;
  creditsToBeAdded?: number;
  onCreditsAdded: () => void;
};

export type CheckoutDataProps =
  | CheckoutCartModal
  | CheckoutSubscription
  | CheckoutInvoices
  | CheckoutCredits
  | CheckoutTask;

type CheckoutModalOptionProps = {
  title?: string;
  onClose: () => void;
  open: boolean;
  payerUserType?: "FCA" | "CUSTOMER";
  isScheduledPayment?: boolean;
  invoiceRequestId?: string;
  onlyCTA?: boolean;
  useCredits?: boolean;
};

type CheckoutModalProps = CheckoutDataProps & CheckoutModalOptionProps;

export const CheckoutModal = (props: CheckoutModalProps) => {
  const title = props?.title || "Make Payment";
  const { uuid } = useCurrentGroupContext();
  const [step, setStep] = useState<"DETAILS" | "PAYMENT">("DETAILS");
  const [creditsToBeAdded, setCreditsToBeAdded] = useState<number | null>(
    (props.type === "credits" ? props.creditsToBeAdded : null) || null
  );
  const [paymentMethodId, setPaymentMethodId] = useState<string | null>(null);
  const [useCredits, setUseCredits] = useState(props.useCredits);
  const [useBrexPoints, setUseBrexPoints] = useState(false);
  const shouldAgree =
    props.type === "cart" &&
    props.cart?.cart_items.some(
      (c) => c.product_details.base_task_key === "PRO_MONTHLY_BOOKKEEPING"
    );
  const [agreementAccepted, setAgreementAccepted] = useState(
    shouldAgree ? false : true
  );

  let defaultEntityId: string | null | undefined;

  switch (props.type) {
    case "cart":
      defaultEntityId = props.cart.entity_id;
      break;

    case "task":
      defaultEntityId = props.task.entity.uuid;
      break;

    case "invoices":
    case "credits":
    case "subscription":
      defaultEntityId = props.entityId;
      break;

    default:
      defaultEntityId = null;
      break;
  }

  const isEntityProvided = Boolean(defaultEntityId);
  const [entityId, setEntityId] = useState(defaultEntityId);
  const serviceTeamId = useServiceTeamId();

  useEffect(() => setEntityId(defaultEntityId), [defaultEntityId]);

  const { activeChannelGroupId } = useAppSelector(
    (state) => state.reviewAndBalancePayment
  );

  const groupId = uuid || activeChannelGroupId;

  const { data: savedCards } = useSavedCards({
    serviceTeamId,
    groupId,
    entityId: entityId || undefined,
  });

  const defaultCard = savedCards.find((c) => c.is_default_card);

  useEffect(() => {
    setPaymentMethodId(defaultCard?.payment_method_id || null);
  }, [defaultCard?.payment_method_id]);

  let contextValue = null;

  const usingCredits = {
    useCredits,
    setUseCredits,
    useBrexPoints,
    setUseBrexPoints,
  };

  const canChangePaymentMethod = {
    paymentMethodId,
    setPaymentMethodId,
  };

  const stepProps = {
    step,
    setStep,
    groupId,
  };

  const canChangeEntity = {
    setEntityId,
    isEntityProvided,
  };

  if (props.type === "cart") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      agreementAccepted,
      setAgreementAccepted,
      ...usingCredits,
      ...canChangePaymentMethod,
      ...stepProps,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "task") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      ...usingCredits,
      ...canChangePaymentMethod,
      ...stepProps,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "subscription") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      ...stepProps,
      ...canChangeEntity,
      ...canChangePaymentMethod,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "invoices") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      invoiceRequestId: props.invoiceRequestId,
      ...usingCredits,
      ...canChangePaymentMethod,
      ...stepProps,
      ...canChangeEntity,
    } as CheckoutModalPropsContextType;
  }

  if (props.type === "credits") {
    contextValue = {
      ...props,
      type: props.type,
      entityId,
      creditsToBeAdded: creditsToBeAdded || 0,
      setCreditsToBeAdded,
      ...canChangePaymentMethod,
      ...stepProps,
      ...canChangeEntity,
    } as CheckoutModalPropsContextType;
  }

  return (
    <CheckoutModalPropsContext.Provider value={{ ...contextValue! }}>
      <Elements stripe={stripePromise}>
        {props.onlyCTA ? (
          <PaymentButton disabled={false} />
        ) : (
          <Modal.Root open={props.open} onOpenChange={props.onClose}>
            <Modal.Content size={props.isScheduledPayment ? "regular" : "xl"}>
              <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <div className="t-flex t-gap-4">
                  <div
                    className={classNames({
                      "t-w-[55%]": !props.isScheduledPayment,
                      "t-w-full": props.isScheduledPayment,
                    })}
                  >
                    <Accordion.Root
                      type="multiple"
                      className="t-relative t-flex t-w-full t-flex-col t-gap-4"
                      defaultValue={entityId ? ["CARDS", "ADDRESS"] : ["CARDS"]}
                    >
                      <Accordion.Item
                        value="CARDS"
                        key="CARDS"
                        className="t-border t-border-solid t-border-neutral-10 t-rounded"
                      >
                        <Accordion.Trigger className="all:unset t-text-subtext t-text-text-60 t-flex t-gap-2 t-group t-w-full t-py-3 t-px-4 t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-l-0 t-border-r-0">
                          <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                            <CaretDown />
                          </span>
                          Payment Method
                        </Accordion.Trigger>
                        <Accordion.Content className="t-p-4">
                          <EditPaymentDetails
                            isScheduledPayment={props.isScheduledPayment}
                          />
                        </Accordion.Content>
                      </Accordion.Item>
                      <Accordion.Item
                        value="ADDRESS"
                        key="ADDRESS"
                        className="t-border t-border-solid t-border-neutral-10 t-rounded"
                      >
                        <Accordion.Trigger className="all:unset t-text-subtext t-text-text-60 t-flex t-gap-2 t-group t-w-full t-py-3 t-px-4 t-border t-border-solid t-border-neutral-10 t-border-t-0 t-border-l-0 t-border-r-0">
                          <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                            <CaretDown />
                          </span>
                          Billing Address
                        </Accordion.Trigger>
                        <Accordion.Content className="t-p-4">
                          <BillingAddress />
                        </Accordion.Content>
                      </Accordion.Item>
                    </Accordion.Root>
                  </div>
                  {!props.isScheduledPayment && (
                    <div className="t-w-[45%] t-flex t-gap-4 t-flex-col">
                      <PaymentSummary />
                      <Agreement />
                    </div>
                  )}
                </div>
              </Modal.Body>

              <Modal.Footer>
                <div className="t-flex t-justify-between">
                  <img src={PoweredByStripe} alt="Powered by stripe" />
                  <div className="t-flex t-gap-2">
                    <Button type="button" onClick={props.onClose}>
                      Cancel
                    </Button>
                    <Footer
                      onSubscribed={
                        (props as CheckoutSubscription)?.onSubscribed
                      }
                      isScheduledPayment={props.isScheduledPayment}
                    />
                  </div>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Root>
        )}
      </Elements>
    </CheckoutModalPropsContext.Provider>
  );
};
