import axios from "axios";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import "../../static/styles/components/emailAndPhone.css";
import { checkValidEmail, checkValidPhone } from "../../utils/checkValidEmail";
import { AuthFooter } from "../design/authFooter";

const ResetPassword_url =
  import.meta.env.VITE_APP_BASE_URL + "api/inkle/users/reset-password/";

const EmailAndPhone = ({ next, handleChange, values }) => {
  const history = useHistory();
  const { alertToast } = useToast();
  const [error, setError] = useState({
    email_id: false,
    phone_no: false,
  });
  const [loader, setLoader] = useState(false);

  const Continue = () => {
    if (values.email_id) {
      if (!checkValidEmail(values.email_id)) {
        setError((prevState) => ({
          ...prevState,
          email_id: "Email is not valid",
        }));
      } else {
        setLoader(true);
        send();
      }
    } else if (values.phone_no) {
      if (!checkValidPhone(values.phone_no)) {
        setError((prevState) => ({
          ...prevState,
          phone_no: "Phone number is not valid",
        }));
      } else {
        setLoader(true);
        send();
      }
    } else {
      alertToast({ message: "Please enter either email or phone number!" });
    }
  };

  const send = () => {
    axios
      .post(ResetPassword_url, {
        email: values.email_id,
        mobile: values.email_id ? null : values.phone_no,
      })
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          next();
        } else {
          history.push("/forgotPassword");
        }
      })
      .catch(function (error) {
        setLoader(false);
        if (error.response) {
          alertToast({ message: error?.response?.data?.error?.message });
        } else {
          console.log(error.request);
        }
        history.push("/");
      });
  };

  const handChange = (p, event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }
    handleChange(p, value);
    setError((prevState) => ({
      ...prevState,
      [p]: false,
    }));
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      Continue();
    }
  };

  return (
    <div
      className={loader ? "rightBox landingForm cr" : "rightBox landingForm"}
    >
      <h1 className="accountHead">Reset Password</h1>
      <form className="homeForm">
        <input
          type="email"
          className={error.email_id !== false ? "errorbox" : ""}
          name="email_id"
          placeholder="Email address"
          onChange={(e) => handChange("email_id", e)}
          onKeyPress={handleEnter}
          value={values.email_id}
        />{" "}
        <br />
        {error.email_id !== false ? (
          <span className="errorMessage">
            {error.email_id}
            <br />
          </span>
        ) : (
          ""
        )}
        <p className="orField">or</p>
        <PhoneInput
          country="in"
          className={error.phone_no !== false ? "errorbox" : ""}
          placeholder="Phone number"
          onChange={(phone_no) => handChange("phone_no", phone_no)}
          value={values.phone_no}
          onKeyPress={handleEnter}
        />
        <AuthFooter />
        {error.phone_no !== false ? (
          <span className="errorMessage">
            {error.phone_no}
            <br />
          </span>
        ) : (
          ""
        )}
      </form>
      <button className="bueBtn" onClick={Continue}>
        Continue
      </button>
      <span
        aria-hidden="true"
        onClick={() => {
          history.push("/signin");
        }}
        className="signup"
      >
        <u>Sign in</u>
      </span>
    </div>
  );
};

export default EmailAndPhone;
