import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import HorizontalScroll from "components/DesignSystem/HorizontalScroll/HorizontalScroll";
import { BILLING_INFO_CARD_ADD_CLICKED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import authContext from "jwt_context&axios/authContext";
import { useContext, useState } from "react";
import AddCardPlusIcon from "static/images/AddCardPlusIcon.svg";
import { useGetAllSavedCardsQuery } from "store/apis/billing";
import { AddCardModal } from "./AddCardModal";
import AddedCard from "./AddedCard";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

//@ts-ignore
const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY);

const PaymentInfo = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const {
    authtoken: { email },
  } = useContext(authContext);
  const { trackEvent } = useAnalytics();
  const [showAddCard, setShowAddCard] = useState(false);
  const entityId = useCurrentEntityId();

  const {
    data: savedCards = [],
    isSuccess: cardApiSuccess,
    isLoading,
  } = useGetAllSavedCardsQuery(
    { groupId, entityId },
    { skip: !groupId || !entityId }
  );

  const defaultCard = savedCards.find(({ is_default_card }) => is_default_card);
  const cardsAdded = savedCards.length > 0;

  const openCardAdd = () => {
    setShowAddCard(true);
    trackEvent(BILLING_INFO_CARD_ADD_CLICKED, {
      group_id: groupId,
      email: email,
    });
  };

  if (isLoading || !cardApiSuccess) {
    return <Loader />;
  }
  return (
    <div className="t-flex t-flex-col t-gap-6 t-pb-16">
      <div className="t-w-full">
        <div className="t-flex t-justify-between t-flex-col t-gap-4 ">
          <div>
            <div className="t-text-subtitle t-text-text-100">Default card</div>
            <div className="t-text-body t-text-text-30 t-mt-1">
              The default card will be used for all future auto-payments.
            </div>
          </div>

          {Boolean(defaultCard) ? (
            <AddedCard
              entityId={entityId}
              // @ts-ignore
              cardDetail={defaultCard}
            />
          ) : (
            <div
              onClick={openCardAdd}
              role="button"
              className={classNames(
                "t-flex t-h-[147px] t-w-[231px] t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-surface-lighter-grey t-px-4 t-py-3 sm:t-hidden",
                {
                  "t-ml-2": savedCards.length > 0,
                  "t-mt-4": savedCards.length === 0,
                }
              )}
            >
              <img src={AddCardPlusIcon} alt="AddCardPlusIcon" />
              <div className="t-text-subtext">Add default card</div>
            </div>
          )}
        </div>
      </div>
      <div className="t-w-full">
        {Boolean(defaultCard) && (
          <>
            <div className="t-flex t-items-center t-justify-between">
              <div className="t-text-subtitle t-text-text-100">
                Other saved cards
              </div>
              <div className="t-hidden sm:t-block">
                <Button customType="primary" size="small" onClick={openCardAdd}>
                  Add card
                </Button>
              </div>
            </div>
            <div className="t-flex t-w-full t-items-end t-gap-2">
              <HorizontalScroll maxWidth="t-max-w-[calc(100%-231px)] sm:t-max-w-full">
                <div className="t-flex t-w-max t-items-center t-gap-5 t-overflow-auto">
                  {savedCards
                    .filter(({ is_default_card }) => !is_default_card)
                    .map((cardDetail) => (
                      <AddedCard
                        entityId={entityId}
                        cardDetail={cardDetail}
                        key={cardDetail.payment_method_id}
                      />
                    ))}
                </div>
              </HorizontalScroll>
              <div
                onClick={openCardAdd}
                role="button"
                className={classNames(
                  "t-flex t-h-[147px] t-w-[231px] t-flex-col t-items-center t-justify-center t-rounded-lg t-bg-surface-lighter-grey t-px-4 t-py-3 sm:t-hidden t-mt-3",
                  {
                    "t-ml-3": savedCards.length > 1,
                    "-t-ml-2 t-mt-6": savedCards.length === 0,
                  }
                )}
              >
                <img src={AddCardPlusIcon} alt="AddCardPlusIcon" />
                <div className="t-text-subtext">Add card</div>
              </div>
            </div>
          </>
        )}
        <Elements stripe={stripePromise}>
          <AddCardModal
            entityId={entityId}
            show={showAddCard}
            closeModal={() => setShowAddCard(false)}
            setShowCardAddedModal={() => {}}
            setShowSavedCards={() => {}}
            setSavedCards={() => {}}
            cardsAdded={cardsAdded}
          />
        </Elements>
      </div>
    </div>
  );
};

export default PaymentInfo;
