import { useChatFilterContext } from "contexts/ChatFilterContext";
import { ChannelFilters } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react";

export const useStreamFilterQuery =
  (): ChannelFilters<DefaultStreamChatGenerics> => {
    const { filters: values } = useChatFilterContext();

    return {
      ...(values.CHANNEL.length > 0 && {
        channel_type: {
          $in: values.CHANNEL,
        },
      }),

      ...(values.COMPANY.length > 0 && {
        group_uuid: {
          $in: values.COMPANY,
        },
      }),

      ...(values.AGENT.length > 0 && {
        assigned_agent: {
          $in: values.AGENT,
        },
      }),

      ...(values.STATUS.length > 0 && {
        task_status_id: {
          $in: values.STATUS,
        },
      }),

      ...(values.START_DATE &&
        values.END_DATE && {
          $and: [
            {
              last_message_at: {
                $gte: new Date(values.START_DATE).toISOString(),
              },
            },

            {
              last_message_at: {
                $lte: new Date(values.END_DATE).toISOString(),
              },
            },
          ],
        }),
    };
  };
