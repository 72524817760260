import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getGroupUsers } from "../../apis/groups";
import { Chat } from "components/chat/Chat";
import { ChannelFilters } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useChatContext } from "stream-chat-react";
import {
  useChatFilterContext,
  withChatFilterContext,
} from "contexts/ChatFilterContext";
import { useStreamFilterQuery } from "hooks/useStreamFilterQuery";

export const GroupChat = ({ path }: { path: string }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { channelId } = useParams<{ channelId: string }>();
  const { messageId } = useParams<{ messageId: string }>();
  const [groupUserIds, setGroupUserIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const { client } = useChatContext();
  const filtersQuery = useStreamFilterQuery();

  useEffect(() => {
    const getAndsetGroupIds = async () => {
      setLoading(true);
      const { data: groupUsers } = await getGroupUsers({ groupId });
      const ids = groupUsers.map((g: any) => g.member_id);
      setGroupUserIds(ids);
      setLoading(false);
    };

    getAndsetGroupIds();
  }, []);

  const filters: ChannelFilters<DefaultStreamChatGenerics> = useMemo(() => {
    delete filtersQuery.group_uuid;
    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
      type: "messaging",
      $and: [
        { members: { $in: [client.userID!] } },
        {
          group_uuid: { $eq: groupId },
        },
      ],
    };

    return filters;
  }, [client.userID, groupUserIds]);

  return (
    <>
      {loading && <strong>Loading...</strong>}
      {!loading && groupUserIds.length === 0 && (
        <strong>No member exists in the group!</strong>
      )}
      {!loading && groupUserIds.length > 0 && (
        <Chat
          filters={{ ...filters, ...filtersQuery }}
          channelId={channelId}
          messageId={messageId}
          hideFilters={["COMPANY"]}
        />
      )}
    </>
  );
};

export const Messages = withChatFilterContext(GroupChat);
