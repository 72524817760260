import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { DeleteOpenItem } from "components/Transaction/RequestInfo";
import { Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useToast } from "hooks/useToast";
import { ChangeEvent, FC } from "react";
import { useSelector } from "react-redux";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useAddClarificationMutation,
  useDeleteOpenItemInvoiceMutation,
  useGetOpenItemsQuery,
  useUploadOpenItemInvoiceMutation,
} from "store/apis/openItem";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { RootState } from "store/store";
import { useChannelStateContext, useMessageContext } from "stream-chat-react";
import { OpenItem } from "types/Models/openItem";
import { debounce } from "utils/debouncing";
import { openLink } from "utils/openLink";

const ClarificationColumn = (info: CellContext<OpenItem, string>) => {
  const [addClarification, { isLoading: isUpdating }] =
    useAddClarificationMutation();

  const { activeChannelGroupId: groupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );

  const clarification = info.getValue();
  const {
    row: {
      original: { uuid, csv_id: csvId },
    },
  } = info;

  const { data: uploadedCSV } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { status } = uploadedCSV || {};

  const handleCommentChange = debounce(
    async (e: ChangeEvent<HTMLFormElement>, openItemId: string) => {
      const { value } = e.target;
      await addClarification({
        groupId,
        openItemId,
        clarification: value,
      }).unwrap();
    },
    1000
  );

  if (status === "SUBMITTED") {
    return <>{info.getValue() || "-"}</>;
  }

  return (
    <Formik
      initialValues={{
        clarification: clarification || "",
      }}
      onSubmit={() => {}}
    >
      <Form className="all:unset">
        <div className="t-flex t-items-center t-gap-1.5">
          <TextArea
            name="clarification"
            // @ts-ignore // Ignoring for compatability
            onChange={(e) => handleCommentChange(e, uuid)}
            cols={100}
            rows={1}
          />
          <span
            className={classNames("t-flex t-animate-spin", {
              "t-invisible": !Boolean(isUpdating),
              "t-block": Boolean(isUpdating),
            })}
          >
            <LoadingIcon />
          </span>
        </div>
      </Form>
    </Formik>
  );
};

const defaultArray: any[] = [];

const InvoiceColumn = (info: CellContext<OpenItem, OpenItem["invoice"]>) => {
  const { alertToast, successToast } = useToast();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const [uploadOpenItemInvoice, { isLoading: isUploading }] =
    useUploadOpenItemInvoiceMutation();

  const [deleteOpenItemInvoice, { isLoading: isDeleting }] =
    useDeleteOpenItemInvoiceMutation();

  const { activeChannelGroupId: groupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );

  const invoice = info.getValue();

  const {
    row: {
      original: { uuid, csv_id: csvId },
    },
  } = info;

  const { data: uploadedCSV } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { status } = uploadedCSV || {};

  const uploadInvoice = async ({
    openItemId,
    file,
  }: {
    openItemId: string;
    file: FileType;
  }) => {
    try {
      await uploadOpenItemInvoice({
        groupId,
        openItemId,
        file: file as File,
      }).unwrap();
      successToast({ message: "File Uploaded" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onInvoiceDelete = async ({ openItemId }: { openItemId: string }) => {
    try {
      await deleteOpenItemInvoice({ openItemId, groupId }).unwrap();
      successToast({ message: "File Deleted" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onPreview = async ({ fileId }: { fileId: string }) => {
    try {
      const { preview_url } = await getPreviewUrl({ groupId, fileId }).unwrap();
      openLink(preview_url);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  if (status === "SUBMITTED" && !Boolean(invoice)) {
    return "-";
  }

  return (
    <FileInput
      file={invoice}
      onDrop={(files) => uploadInvoice({ openItemId: uuid, file: files[0] })}
      isUploading={isUploading}
      variant="icon"
      onFileClick={({ uuid: fileId }) => onPreview({ fileId })}
      isDeleting={isDeleting}
      onDelete={
        status === "REQUESTED"
          ? (e) => onInvoiceDelete({ openItemId: uuid })
          : undefined
      }
    />
  );
};

export const OpenItemTable: FC<{ csvId: string; addOpenItem?: boolean }> = ({
  csvId,
  addOpenItem,
}) => {
  const { channel } = useChannelStateContext();
  const groupId = channel?.data?.group_uuid as string;

  const { data: uploadedCSV, isLoading } = useGetOpenItemsQuery(
    { csvId, groupId },
    { skip: !groupId || !csvId, refetchOnMountOrArgChange: true }
  );

  const { open_items } = uploadedCSV || {};

  const columnHelper = createColumnHelper<OpenItem>();

  const clarify = addOpenItem
    ? [
        columnHelper.accessor("uuid", {
          id: "Delete",
          cell: DeleteOpenItem,
          header: "",
          size: 5,
        }),
      ]
    : [
        columnHelper.accessor("invoice", {
          id: "invoice",
          header: "Invoice",
          size: 10,
          cell: InvoiceColumn,
        }),
        columnHelper.accessor("clarification", {
          id: "clarification",
          header: "Clarification",
          size: 20,
          cell: ClarificationColumn,
        }),
      ];

  const columns = [
    columnHelper.accessor("date", {
      id: "date",
      header: "Date",
      size: addOpenItem ? 12 : 10,
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor("bank_name", {
      id: "bank_name",
      header: "Bank Name",
      size: addOpenItem ? 15 : 10,
      cell: (info) => <div className="t-break-words">{info.getValue()}</div>,
    }),

    columnHelper.accessor("description", {
      id: "description",
      header: "Description",
      size: addOpenItem ? 20 : 10,
      cell: (info) => <div className="t-break-words">{info.getValue()}</div>,
    }),

    columnHelper.accessor("amount", {
      id: "amount",
      header: "Amount",
      size: addOpenItem ? 15 : 10,
      cell: (info) => {
        const { amount } = info.row.original;

        if (!Boolean(info.getValue())) {
          return "-";
        }

        if (isNaN(Number(amount))) {
          return amount;
        }

        return <AmountSuperScript amount={Number(amount)} />;
      },
    }),

    columnHelper.accessor("information_request", {
      id: "information_request",
      header: "Information Request",
      size: addOpenItem ? 35 : 15,
      cell: (info) => <div className="t-break-words">{info.getValue()}</div>,
    }),

    ...clarify,
  ];

  const table = useReactTable({
    data: open_items || defaultArray,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Table.Container>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          <AnimatePresence>
            {table.getRowModel().rows.map((row) => (
              <motion.tr
                layout
                key={row.original.uuid}
                transition={{ duration: 0.1, ease: "easeOut" }}
                animate={{ x: 0 }}
                exit={{ x: -1000 }}
                className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
              >
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                    className="t-py-3 t-px-2"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </motion.tr>
            ))}
          </AnimatePresence>
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};
