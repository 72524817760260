import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Form, Formik } from "formik";
import { merchantEditSchema } from "formValidations/merchantSchema";
import { useToast } from "hooks/useToast";
import { useEditVendorsMutation } from "store/apis/vendors";

export const EditMerchant = ({
  show,
  closeModal,
  groupId,
  editMerchantData = {},
}) => {
  const { alertToast, successToast } = useToast();
  const [editVendor, { isLoading: isEditing }] = useEditVendorsMutation();
  const { uuid, name, email, w_form_season, transaction_amount } =
    editMerchantData || {};

  const editMerchant = async (payload) => {
    try {
      await editVendor({
        groupId,
        uuid,
        payload: { ...payload, season: w_form_season },
      }).unwrap();
      successToast({ message: "Vendor has been updated" });
      closeModal();
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content size="regular">
        <Modal.Header>
          Edit Vendor
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              name: name || "",
              email: email || "",
              amount_paid_in_season: w_form_season || "",
              total_amount: transaction_amount || 0,
            }}
            onSubmit={editMerchant}
            validationSchema={merchantEditSchema}
            validateOnMount={false}
            validateOnChange={false}
          >
            <Form className="t-m-0">
              <div className="t-flex t-w-full t-flex-col t-gap-5">
                <TextInput
                  block
                  name="name"
                  label="Name"
                  placeholder="Vendor name"
                  required
                />
                <TextInput
                  block
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Vendor email"
                />
                <PriceInput
                  disabled={uuid}
                  name="total_amount"
                  label="Amount Paid"
                  required
                />
              </div>
              <div className="t-flex t-gap-3 t-justify-end t-mt-6">
                <Button
                  customType="secondary"
                  size="regular"
                  type="reset"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  size="regular"
                  type="submit"
                  disabled={isEditing}
                  isLoading={isEditing}
                >
                  Save
                </Button>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
