import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { LargeCard } from "components/home/Home";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import React from "react";
import ECLHomeCardIcon from "static/images/ECLHomeCardIcon.svg";
import { useGetAllDealsQuery } from "store/apis/rewards";

const eclPerkId = import.meta.env.VITE_APP_ECL_PERK_ID;

export const ECLHomeCard = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { data } = useGetAllDealsQuery({ groupId });
  const eclPerkSlug = data?.perks.find(
    (perk) => perk?.perk_id === eclPerkId
  )?.slug;

  return (
    <LargeCard>
      <ConditionalLink to={`/community/perks/${eclPerkSlug}`}>
        <div
          className={classNames(
            "t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-10 t-h-full t-w-full t-text-text-100 t-bg-inkle-gradient t-flex t-flex-col t-justify-between t-relative"
          )}
        >
          <img
            src={ECLHomeCardIcon}
            alt="ECLHomeCardIcon"
            className="t-absolute t-right-0 t-bottom-0"
          />
          <div className="t-flex t-flex-col t-w-full md:t-w-3/4 t-gap-3">
            <div className="t-text-h5 t-text-white t-leading-6">
              Unlock up to 65% of Your Revenue in Non-Dilutive Financing!
            </div>
            <div className="t-space-y-1 t-text-white">
              <div className="t-text-body-sm">
                ✅ <span className="t-font-bold">Get up to $2M</span> with
                Efficient Capital Labs
              </div>
              <div className="t-text-body-sm">
                ✅ <span className="t-font-bold">Exclusive Offer:</span> 25 bps
                fee discount through Inkle
              </div>
            </div>
          </div>
          <div>
            <Button size="small">Reveal Instructions</Button>
          </div>
        </div>
      </ConditionalLink>
    </LargeCard>
  );
};
