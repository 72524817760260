import * as RDialog from "@radix-ui/react-dialog";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { InvoiceActions } from "components/InvoiceAction/InvoiceAction";
import { invoiceInitialValues } from "components/InvoiceForm/InvoiceForm";
import { InvoicePreivew } from "components/InvoicePreivew/InvoicePreivew";
import {
  CLICKED_PREVIEW_AND_SEND_IN_DRAFT_INVOICE_VIEW,
  CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL,
} from "constants/analyticsEvents";
import { Formik, FormikErrors } from "formik";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { ComponentProps, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Invoice,
  useGenrateShortLinkMutation,
  useGetEntityInvoiceQuery,
  useGetInvoiceItemsQuery,
  useGetInvoiceSettingsQuery,
  useLazyDownloadInvoiceQuery,
  useSendInvoiceMutation,
  useUpdateInvoiceMutation,
} from "store/apis/invoices";
import { debounce } from "utils/debouncing";
import { AddInvoice } from "./AddInvoice";

import * as Popover from "@radix-ui/react-popover";
import { ConditionalLink } from "components/conditionalLink";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowRight } from "components/icons/ArrowRight";
import { CopyIcon } from "components/icons/CopyIcon";
import { DownloadIcon } from "components/icons/Download";
import { LinkInvoiceToTransactionModal } from "components/InvoiceAction/LinkInvoiceToTransactionModal";
import { SendInvoice } from "components/SendInvoiceModal/SendInvoice";
import {
  invoiceBody,
  invoiceSubject,
} from "components/SendInvoiceModal/SendInvoiceModal";
import { invoiceValidation } from "formValidations/invoiceValidation";
import { AnimatePresence } from "framer-motion";
import isEqual from "lodash/isEqual";
import omitBy from "lodash/omitBy";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { openLink } from "utils/openLink";
import { DownloadInvoice } from "./DownloadInvoice";
import { InvoiceHeader } from "./InvoiceHeader";
import { Form } from "formik";
import { InvoiceConfiguration } from "components/InvoiceConfiguration/InvoiceConfiguration";

const InvoiceShareDropDown = ({
  invoice,
  from,
}: {
  invoice: Invoice;
  from: "mail_draft_screen" | "invoice_edit_screen";
}) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();

  const [getInvoiceDownloadUrl] = useLazyDownloadInvoiceQuery();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();
  const [generateLinkToken] = useGenrateShortLinkMutation();

  const downloadInvoice = async () => {
    try {
      const { doc_id } = await getInvoiceDownloadUrl({
        groupId: groupId,
        entityId,
        invoiceId: invoice.uuid,
      }).unwrap();

      const { download_url } = await getPreviewUrl({
        groupId: groupId!,
        fileId: doc_id,
      }).unwrap();

      openLink(download_url, "_blank");
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const copyInvoiceLink = async () => {
    try {
      const { token } = await generateLinkToken({
        entityId,
        invoiceId: invoice.uuid,
        from,
      }).unwrap();

      const origin = window.location.origin;
      await navigator.clipboard.writeText(`${origin}/invoice/${token}`);
      successToast({ message: "Invoice link has been copied" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <div className="t-group">
          <Button size="small">
            Share
            <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
              <ArrowRight color="currentColor" />
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content>
          <Dropdown.Item onSelect={downloadInvoice}>
            <DownloadIcon />
            Download invoice
          </Dropdown.Item>
          <Dropdown.Item onSelect={copyInvoiceLink}>
            <CopyIcon />
            Copy invoice link
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
const Content = ({
  breadcrumbs,
}: {
  breadcrumbs?: ComponentProps<typeof Header>["breadcrumbs"];
}) => {
  const { invoiceId } = useParams<{
    invoiceId: string;
  }>();
  const entityId = useCurrentEntityId();
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const query = useQuery();
  const isSendInvoiceOpen = Boolean(query.get("is_send_invoice_open"));
  const { alertToast, successToast } = useToast();
  const sendInvoiceStep = useModal(isSendInvoiceOpen);
  const { trackEvent } = useAnalytics();
  const editFlow = query.get("editflow");
  const previewFromEdit = query.get("from_edit");
  const confirmChangesModal = useModal();
  const { update, updateMultiple } = useUpdateQuery();
  const history = useHistory();

  const linkExistingTransactionModal = useModal();
  const [sendInvoice, { isLoading: sendingInvoice }] = useSendInvoiceMutation();
  const entity = entities.find((entity) => entity.uuid === entityId);

  const {
    data: invoice,
    isLoading: invoiceLoading,
    isFetching,
    error,
  } = useGetEntityInvoiceQuery(
    {
      groupId,
      entityId,
      invoiceId,
    },
    { skip: !groupId || !entityId || !invoiceId }
  );

  const { data: invoiceSetting } = useGetInvoiceSettingsQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const isInvoiceSent = invoice && invoice.status === "SENT";
  const isSentInvoiceEditFlow = invoice?.status === "SENT" && Boolean(editFlow);
  const [updateInvoice, { isLoading: isUpdating, isSuccess }] =
    useUpdateInvoiceMutation();
  const [prevInvoiceValues, setPrevInvoiceValues] = useState<Partial<Invoice>>(
    invoiceInitialValues as Partial<Invoice>
  );

  const diffValues = (obj1: Partial<Invoice>, obj2: Partial<Invoice>) => {
    return omitBy(obj2, (value, key) =>
      isEqual(value, obj1[key as keyof Invoice])
    );
  };

  const onChange = debounce(
    async (
      values: Partial<Invoice>,
      setErrors: (errors: FormikErrors<Invoice>) => void,
      errors: FormikErrors<Invoice>
    ) => {
      if (isSentInvoiceEditFlow) return;

      if (groupId && entityId) {
        try {
          const response = await updateInvoice({
            payload: diffValues(prevInvoiceValues, values),
            groupId: groupId,
            entityId: entityId,
            invoiceId,
          }).unwrap();
          const { field_errors } = response || {};

          if (field_errors) {
            setErrors(field_errors);
          }
          setPrevInvoiceValues(values);
        } catch (error) {
          alertToast(
            { message: (error as BackendError).data?.error?.message },
            error as Error
          );
        }
      }
    }
  );

  const closeSendInvoiceModal = () => {
    sendInvoiceStep.close();
    updateMultiple([
      {
        query: "is_send_invoice_open",
        value: null,
      },
      ...(previewFromEdit
        ? [
            {
              query: "from_edit",
              value: null,
            },
            { query: "editflow", value: true },
          ]
        : []),
    ]);
  };

  const cancelChanges = () => {
    confirmChangesModal.close();
    updateMultiple([
      {
        query: "editflow",
        value: null,
      },
      {
        query: "from_edit",
        value: true,
      },
    ]);
  };

  const onPreviewOrSend = async () => {
    trackEvent(CLICKED_PREVIEW_AND_SEND_IN_DRAFT_INVOICE_VIEW);
    const isValid = invoiceValidation.isValidSync(invoice);

    if (!isValid) {
      return alertToast({
        message: "Please fill all the required fields.",
      });
    }
    if (isSentInvoiceEditFlow) {
      confirmChangesModal.open();
      return;
    }
    sendInvoiceStep.open();
    cancelChanges();
  };

  const { data: invoiceItems, isLoading } = useGetInvoiceItemsQuery(
    {
      groupId: groupId,
      entityId: invoiceSetting?.entity_id!,
      invoiceId: invoice?.uuid!,
    },
    { skip: !groupId || !invoiceSetting?.entity_id || !invoice?.uuid }
  );

  if (invoiceLoading) {
    return (
      <div className="t-flex t-h-full t-w-full t-justify-center t-items-center">
        <Loader />
      </div>
    );
  }

  if (!invoice || !entity || invoiceLoading || !invoiceSetting) {
    return null;
  }

  const onBackClick = () => {
    if (sendInvoiceStep.isOpen) {
      closeSendInvoiceModal();
      return;
    }

    if (breadcrumbs?.at(-1)?.link) {
      history.push(breadcrumbs?.at(-1)?.link!);
    } else {
      history.goBack();
    }
  };

  const onSendInvoice = async (values: {
    attachments: File[];
    body: string;
    subject: string;
  }) => {
    trackEvent(CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL);
    try {
      await sendInvoice({
        groupId: groupId,
        entityId: entityId,
        invoiceId: invoiceId,
        payload: {
          subject: values.subject,
          body: values.body,
          attachments: values.attachments,
        },
      }).unwrap();
      successToast({ message: "Invoice has been sent." });
      closeSendInvoiceModal();
      cancelChanges();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const updateChanges = async (values: Invoice) => {
    try {
      await updateInvoice({
        payload: values as Partial<Invoice>,
        groupId: groupId,
        entityId: entityId,
        invoiceId,
      });
      successToast({ title: "Successfully updated" });
      sendInvoiceStep.open();
      cancelChanges();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const isReminder = !isSentInvoiceEditFlow && invoice.status === "SENT";

  const defaultBody = invoiceBody({
    entity,
    invoice,
    invoiceSetting,
    isSentInvoiceEditFlow,
  });

  const subject = invoiceSubject({
    entity,
    invoice,
    isSentInvoiceEditFlow,
    isReminder,
  });

  const invoiceHeader = (
    <InvoiceHeader
      invoice={invoice}
      onBackClick={onBackClick}
      linkedTransaction={
        <>
          {invoice.associated_txns && invoice.associated_txns.length > 0 && (
            <ToolTip
              text={
                <div
                  className="t-min-w-64 t-text-left t-flex t-flex-col t-gap-0.5"
                  onClick={(e) => e.stopPropagation()}
                >
                  {invoice.associated_txns.map((txn) => (
                    <div className="t-flex t-justify-between" key={txn.uuid}>
                      <div className="t-flex t-justify-between t-items-center">
                        <div>{formatDate(txn.date)}</div>
                      </div>
                      <ConditionalLink
                        target="_blank"
                        to={`/books/transactions/?entity=${invoiceSetting?.entity_id}&company=${groupId}&transaction_id=${txn.uuid}`}
                      >
                        <Button customType="link" size="small" type="button">
                          <span className="t-text-white t-underline">
                            <AmountSuperScript amount={Number(txn.amount)} />
                          </span>
                        </Button>
                      </ConditionalLink>
                    </div>
                  ))}
                </div>
              }
            >
              <span>
                <ConditionalLink
                  target="_blank"
                  to={`/books/transactions/?entity=${invoiceSetting?.entity_id}&company=${groupId}&transaction_id=${invoice.associated_txns?.[0].uuid}`}
                >
                  <Button customType="link" size="small" type="button">
                    View linked
                    {invoice.associated_txns.length === 1
                      ? " transaction"
                      : " transactions"}{" "}
                    ({invoice.associated_txns.length})
                  </Button>
                </ConditionalLink>
              </span>
            </ToolTip>
          )}
        </>
      }
    />
  );

  if (sendInvoiceStep.isOpen) {
    if (isFetching) {
      return <Loader />;
    }

    return (
      <>
        <Formik
          key="SendInvoiceForm"
          onSubmit={onSendInvoice}
          initialValues={{
            attachments: [] as File[],
            subject: subject,
            body: defaultBody,
            attachWform: invoice?.should_send_w_form,
            wform: invoice?.w_form || null,
          }}
        >
          {({ submitForm }) => (
            <>
              <div className="t-overflow-auto t-pt-6 t-shadow-light-100">
                <DashboardLayout header={invoiceHeader}>
                  <div className="t-mt-5">
                    <AnimatePresence>
                      <SendInvoice invoice={invoice} />
                    </AnimatePresence>
                  </div>
                </DashboardLayout>
              </div>
              <div className="t-overflow-auto t-pt-6 t-bg-surface-lighter-grey">
                <DashboardLayout
                  header={
                    <div className="t-flex t-gap-2 t-justify-end t-bg-surface-lighter-grey t-items-center">
                      <span className="t-text-body-sm t-text-text-30">
                        <Async.Root
                          isLoading={isUpdating || isFetching}
                          customLoader={<Loader customType="secondary" />}
                          isEmpty={!invoice}
                          isSuccess={isSuccess}
                        >
                          <Async.Empty>
                            <></>
                          </Async.Empty>
                          <Async.Success>
                            <span className="t-flex t-items-center t-gap-2 t-transition-all">
                              <img src={DoubleGreenTick} alt="Saved" />
                              Changes saved
                            </span>
                          </Async.Success>
                        </Async.Root>
                      </span>
                      {invoice &&
                        (invoice.status === "DRAFT" ||
                          isSentInvoiceEditFlow) && (
                          <DownloadInvoice invoice={invoice} />
                        )}

                      {invoice.status !== "DRAFT" && (
                        <InvoiceShareDropDown
                          invoice={invoice}
                          from="mail_draft_screen"
                        />
                      )}

                      <Button
                        size="small"
                        customType="primary"
                        type="button"
                        onClick={submitForm}
                        isLoading={sendingInvoice}
                        disabled={sendingInvoice}
                      >
                        Send Invoice
                      </Button>

                      {invoice &&
                        invoice.status === "SENT" &&
                        !isSentInvoiceEditFlow && (
                          <InvoiceActions
                            invoice={invoice}
                            isFromInvoiceDetails
                          />
                        )}
                    </div>
                  }
                >
                  <InvoicePreivew invoice={invoice} />
                </DashboardLayout>
              </div>
            </>
          )}
        </Formik>

        <LinkInvoiceToTransactionModal
          invoiceId={invoiceId}
          invoiceDueAmount={Number(invoice?.due_balance)}
          invoiceNumber={invoice?.invoice_number}
          isOpen={linkExistingTransactionModal.isOpen}
          close={linkExistingTransactionModal.close}
        />
      </>
    );
  }

  return (
    <>
      <Formik
        key="EditInvoiceForm"
        onSubmit={updateChanges}
        validateOnBlur
        validateOnChange={true}
        validationSchema={invoiceValidation}
        initialValues={
          invoice ||
          (invoiceInitialValues as Omit<Invoice, "uuid" | "document">)
        }
      >
        {({ submitForm: updateInvoiceChanges, setErrors, errors, values }) => {
          const subtotal =
            invoiceItems?.reduce((acc, item) => acc + Number(item.amount), 0) ||
            0;

          let tax = 0;

          let discount = 0;

          if (values.discount_type === "PERCENT") {
            discount = subtotal * ((Number(values.discount) || 0) / 100);
          }

          if (values.discount_type === "ABSOLUTE") {
            discount = Number(values.discount);
          }

          if (values.tax_type === "PERCENT") {
            tax = (subtotal - discount) * ((Number(values.tax) || 0) / 100);
          }

          if (values.tax_type === "ABSOLUTE") {
            tax = Number(values.tax);
          }

          const total = subtotal - discount + tax;
          const due = total - Number(values.paid_amount || 0);

          return (
            <>
              <Form className="t-overflow-auto t-pt-6 t-shadow-light-100">
                <DashboardLayout header={invoiceHeader}>
                  <div className="t-mt-5">
                    <AnimatePresence>
                      <AddInvoice
                        breadcrumbs={breadcrumbs}
                        onChange={(values) =>
                          onChange(values, setErrors, errors)
                        }
                      />
                    </AnimatePresence>
                  </div>
                </DashboardLayout>
              </Form>
              <Form className="t-overflow-auto t-pt-6 t-bg-surface-lighter-grey">
                <DashboardLayout
                  header={
                    <div className="t-flex t-gap-2 t-justify-end t-bg-surface-lighter-grey t-items-center">
                      <span className="t-text-body-sm t-text-text-30">
                        {!isSentInvoiceEditFlow && (
                          <Async.Root
                            isLoading={isUpdating || isFetching}
                            customLoader={<Loader customType="secondary" />}
                            isEmpty={!invoice}
                            isSuccess={isSuccess}
                          >
                            <Async.Empty>
                              <></>
                            </Async.Empty>
                            <Async.Success>
                              <span className="t-flex t-items-center t-gap-2 t-transition-all">
                                <img src={DoubleGreenTick} alt="Saved" />
                                Changes saved
                              </span>
                            </Async.Success>
                          </Async.Root>
                        )}
                      </span>
                      {invoice &&
                        (invoice.status === "DRAFT" ||
                          isSentInvoiceEditFlow) && (
                          <DownloadInvoice invoice={invoice} />
                        )}

                      {invoice.status !== "DRAFT" &&
                        invoice.status !== "SENT" && (
                          <InvoiceShareDropDown
                            invoice={invoice}
                            from="invoice_edit_screen"
                          />
                        )}

                      <Popover.Root>
                        <Popover.Trigger asChild>
                          <Button
                            type="button"
                            size="small"
                            customType="primary"
                            onClick={
                              isSentInvoiceEditFlow ? () => {} : onPreviewOrSend
                            }
                          >
                            {isSentInvoiceEditFlow
                              ? "Save & Preview"
                              : isInvoiceSent
                              ? "Remind"
                              : "Preview & Send"}
                          </Button>
                        </Popover.Trigger>
                        <Popover.Content className="t-bg-neutral-100 t-text-surface t-p-3 t-rounded t-text-body-sm t-z-tooltip t-text-center t-break-all t-hyphen-auto t-shadow-dark-30">
                          <Popover.Arrow />
                          <div className="t-flex t-flex-col t-gap-4 t-text-left t-w-[264px]">
                            <div className="t-flex t-flex-col t-gap-2">
                              <p className="t-m-0 t-text-subtitle-sm">
                                Confirm Changes
                              </p>
                              <p className="t-m-0 t-text-body-sm t-break-keep">
                                Invoice will be updated. This can't be reversed.
                              </p>
                            </div>
                            <div className="t-flex t-gap-2 t-w-full t-justify-between">
                              <Button
                                type="button"
                                size="small"
                                block
                                onClick={cancelChanges}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="button"
                                size="small"
                                block
                                onClick={updateInvoiceChanges}
                              >
                                Confirm
                              </Button>
                            </div>
                          </div>
                        </Popover.Content>
                      </Popover.Root>
                      {invoice &&
                        invoice.status === "SENT" &&
                        !isSentInvoiceEditFlow && (
                          <InvoiceActions
                            invoice={invoice}
                            isFromInvoiceDetails
                          />
                        )}
                    </div>
                  }
                >
                  <div className="t-flex t-flex-col t-min-h-full">
                    <InvoiceConfiguration />
                    <InvoicePreivew
                      invoice={
                        isSentInvoiceEditFlow
                          ? {
                              ...values,
                              sub_total: String(subtotal),
                              total: String(total),
                              tax_amount: String(tax),
                              discount_amount: String(discount),
                              due_balance: String(due),
                            }
                          : invoice
                      }
                    />
                  </div>
                </DashboardLayout>
              </Form>
            </>
          );
        }}
      </Formik>

      <LinkInvoiceToTransactionModal
        invoiceId={invoiceId}
        invoiceDueAmount={Number(invoice?.due_balance)}
        invoiceNumber={invoice?.invoice_number}
        isOpen={linkExistingTransactionModal.isOpen}
        close={linkExistingTransactionModal.close}
      />
    </>
  );
};

export const InvoiceFullPageView = ({
  breadcrumbs,
}: {
  breadcrumbs?: ComponentProps<typeof Header>["breadcrumbs"];
}) => {
  return (
    <RDialog.Root open={true} modal={false}>
      <RDialog.Portal>
        <RDialog.Content className="t-fixed t-z-modal t-top-[50%] t-left-[50%] t-translate-x-[-50%] t-translate-y-[-50%] t-w-full t-h-full t-bg-surface t-grid t-grid-cols-2">
          <Content breadcrumbs={breadcrumbs} />
        </RDialog.Content>
      </RDialog.Portal>
    </RDialog.Root>
  );
};
