import { array, object, string } from "yup";

export const upcomingReminderValidation = () =>
  object({
    message_text: string().required("Message is required"),
    subscribers: array().when("notification_channels", {
      is: (val: string[] | undefined) => val?.includes("WHATSAPP"),
      then: array().length(1, "Send to is required").required(),
      otherwise: array().notRequired(),
    }),
  });
