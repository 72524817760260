import { AddressFormFields } from "components/AddressFormFields/AddressFormFields";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {
  useCreateCustomerAddressMutation,
  useGetAllCustomerAddressesQuery,
  useUpdateCustomerAddressMutation,
} from "store/apis/invoices";
import { BackendError } from "types/utils/error";
import { initialCustomerValues } from "./InvoiceCustomerModal";
import Pencil from "components/icons/pencil";
import { MultiValue, SingleValue } from "react-select";

export const InvoiceCustomerAddress = ({
  isShipping,
}: {
  isShipping?: boolean;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const addressModal = useModal();
  const { alertToast, successToast } = useToast();
  const { values, setFieldValue } = useFormikContext<
    typeof initialCustomerValues & {
      created_customer_id: string;
      shipping_same_as_billing: boolean;
      to_edit_address_id: string;
    }
  >();
  const addressType = isShipping ? "SHIPPING" : "BILLING";
  const addressId = isShipping
    ? values?.shipping_address?.uuid
    : values?.billing_address?.uuid;

  const { data: allAddresses, isLoading: isLoadingAllAddresses } =
    useGetAllCustomerAddressesQuery(
      {
        entityId,
        customerId: values?.created_customer_id,
        addressType,
      },
      { skip: !entityId || !groupId || !values?.created_customer_id }
    );

  const defaultAddress = allAddresses?.find(({ uuid }) => uuid === addressId);

  const [createCustomerAddress, { isLoading: isCreatingCustomerAddress }] =
    useCreateCustomerAddressMutation();

  const [updateCustomerAddress, { isLoading: isUpdatingCustomerAddress }] =
    useUpdateCustomerAddressMutation();

  const saveAddress = async () => {
    try {
      const address =
        addressType === "BILLING"
          ? values?.billing_address
          : values?.shipping_address;
      const payload = {
        address: {
          ...address,
          address_type: addressType as "BILLING" | "SHIPPING",
        },
      };

      if (!values?.to_edit_address_id) {
        await createCustomerAddress({
          entityId,
          customerId: values?.created_customer_id,
          payload,
        }).unwrap();
      } else {
        await updateCustomerAddress({
          entityId,
          customerId: values?.created_customer_id,
          addressId: values?.to_edit_address_id,
          payload,
        }).unwrap();
      }
      addressModal.close();
      successToast({ message: "Address saved successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <p className="t-m-0 t-text-subtitle">{isShipping ? "Ship" : "Bill"} to</p>
      <Modal.Root open={addressModal.isOpen} onOpenChange={addressModal.close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              {isShipping ? "Shipping" : "Billing"} Address
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-flex-col t-gap-4">
              <AddressFormFields
                required={false}
                formPrefix={
                  isShipping ? "shipping_address." : "billing_address."
                }
              />
            </div>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>

            <Button
              onClick={saveAddress}
              customType="primary"
              disabled={isCreatingCustomerAddress || isUpdatingCustomerAddress}
              isLoading={isCreatingCustomerAddress || isUpdatingCustomerAddress}
            >
              Save
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      <Combobox
        name={isShipping ? "shipping_address_id" : "billing_address_id"}
        withForm
        menuPortalTarget={document.body}
        // @ts-ignore
        options={allAddresses?.map((address) => ({
          label: (
            <div className="t-flex t-justify-between t-w-full t-items-center">
              <div>{address.address_string}</div>
              <Button
                customType="icon"
                size="extra-small"
                onClick={() => {
                  setFieldValue("to_edit_address_id", address.uuid);
                  setFieldValue(
                    isShipping ? "shipping_address" : "billing_address",
                    address || {}
                  );
                  addressModal.open();
                }}
              >
                <Pencil color="currentColor" />
              </Button>
            </div>
          ),
          value: address.uuid,
          data: address,
        }))}
        onChange={(
          selectedOption: MultiValue<OptionData> | SingleValue<OptionData>
        ) => {
          setFieldValue(
            isShipping ? "shipping_address" : "billing_address",
            (selectedOption as OptionData)?.data || {}
          );
        }}
        value={
          defaultAddress
            ? {
                label: defaultAddress.address_string,
                value: defaultAddress.uuid,
              }
            : undefined
        }
        isLoading={isLoadingAllAddresses}
        actions={
          <Button
            customType="text"
            onClick={() => {
              setFieldValue(
                isShipping ? "shipping_address" : "billing_address",
                {}
              );
              addressModal.open();
            }}
          >
            Add address
          </Button>
        }
      />
    </div>
  );
};
