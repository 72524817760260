import { Button } from "components/DesignSystem/Button/Button";
import { DownloadIcon } from "components/icons/Download";
import { invoiceValidation } from "formValidations/invoiceValidation";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { Invoice, useLazyDownloadInvoiceQuery } from "store/apis/invoices";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { openLink } from "utils/openLink";

export const DownloadInvoice = ({ invoice }: { invoice: Invoice }) => {
  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [getInvoiceDownloadUrl, { isLoading }] = useLazyDownloadInvoiceQuery();
  const [getPreviewUrl] = useLazyGetPreviewUrlQuery();

  const downloadInvoice = async () => {
    const isValid = invoiceValidation.isValidSync(invoice);
    if (!isValid) {
      return alertToast({
        message: "Please fill all the required fields.",
      });
    }

    try {
      const { doc_id } = await getInvoiceDownloadUrl({
        groupId: groupId,
        entityId,
        invoiceId: invoice.uuid,
      }).unwrap();

      const { download_url } = await getPreviewUrl({
        groupId: groupId!,
        fileId: doc_id,
      }).unwrap();
      openLink(download_url, "_blank");
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Button
      size="small"
      type="button"
      onClick={downloadInvoice}
      isLoading={isLoading}
      disabled={isLoading}
    >
      <span className="t-flex t-items-center t-gap-1">
        <DownloadIcon strokeWidth="1.5" color="currentColor" />
        <span>Download</span>
      </span>
    </Button>
  );
};
