import Async from "components/DesignSystem/AsyncComponents/Async";
import { YYYY_MM_DD } from "constants/date";
import { MetricType, RevenueBasis } from "constants/revenueMetrics";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePageTitle } from "hooks/usePageTitle";
import { useGetAllRevenueMetricsQuery } from "store/apis/revenueMetrics";
import { formatRevenueData } from "utils/formatRevenueData";
import { getDateRange } from "utils/getDateRange";
import { FinalDataTable } from "./FinalDataTable";
import { RevenueChart } from "./RevenueChart";
import { RecurringRevenueFilter } from "./RevenueFilter";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";

export const AnnualisedRecurringRevenue = () => {
  usePageTitle("Annualised Recurring Revenue");
  const { startDate, endDate } = getDateRange("last12months");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const query = useQuery();
  const page_num = query.get("page_num") || 1;
  const { update } = useUpdateQuery();

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      START_DATE: dayjs(startDate).format(YYYY_MM_DD),
      END_DATE: dayjs(endDate).format(YYYY_MM_DD),
      SELECT_PERIOD: "last12months",
      REVENUE_BASIS: RevenueBasis.CUSTOMER,
    },
  });

  const onRevenueBasisChange = (revenueBasis: RevenueBasis) => {
    updateFilter("REVENUE_BASIS", revenueBasis);
  };

  const { data, isLoading, isSuccess } = useGetAllRevenueMetricsQuery(
    {
      groupId,
      entityId,
      revenue_basis: filterValues.REVENUE_BASIS,
      start_date: filterValues.START_DATE,
      end_date: filterValues.END_DATE,
      metric_type: MetricType.ARR,
      page_num: Number(page_num),
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const {
    customers = [],
    periodic_total_amounts = [],
    current_page = 1,
    total_pages = 1,
    per_page = 1,
    total_count = 1,
  } = data || {};

  const { goToFirstPage, goToLastPage, goToNextPage, goToPrevPage } =
    usePagination({
      totalPage: total_pages,
      onPageNumChange: (page) => {
        update({
          query: "page_num",
          value: page,
        });
      },
      pageNumber: current_page,
    });

  const { chartData, finalTableData } = formatRevenueData({
    data: { periodic_total_amounts, customers },
  });

  return (
    <div className="t-flex t-gap-6 t-flex-col t-pb-10 t-relative t-top-0">
      <RecurringRevenueFilter
        filterValues={filterValues}
        updateFilter={updateFilter}
      />
      <Async.Root isLoading={isLoading} isSuccess={isSuccess} isEmpty={false}>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <RevenueChart
            title="Annualized Recurring Revenue"
            chartData={chartData}
          />
          <FinalDataTable
            finalTableData={finalTableData}
            onRevenueBasisChange={onRevenueBasisChange}
            revenueBasis={filterValues.REVENUE_BASIS}
            pagination={
              <Pagination
                goToFirstPage={goToFirstPage}
                goToLastPage={goToLastPage}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                itemsPerPage={per_page}
                totalItemCount={total_count}
                totalPage={total_pages}
                currentPage={current_page}
              />
            }
          />
        </Async.Success>
      </Async.Root>
    </div>
  );
};
