import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import {
  BROWSER_NOTIFICATION_ALLOWED,
  BROWSER_NOTIFICATION_BLOCKED,
  BROWSER_NOTIFICATION_NUDGE_ALLOWED,
  BROWSER_NOTIFICATION_NUDGE_CLOSED,
} from "constants/analyticsEvents";
import { getToken } from "firebase/messaging";
import { useAnalytics } from "hooks/useAnalytics";
import { useDeviceInfo } from "hooks/useDeviceInfo";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAddNotificationNudgeEventMutation } from "store/apis/notifiaction";
import {
  closeNotificationBlockModal,
  closeNotificationNudgeModal,
  openNotificationBlockModal,
} from "store/slices/notification";
import { RootState } from "store/store";
import { useFirebase } from "./firebase";

export const NotificationNudge = () => {
  const { successToast } = useToast();
  const { messaging, isSupportedBrowser } = useFirebase();
  const { isCustomer } = useRoleBasedView();
  const { trackEvent } = useAnalytics();
  const {
    authtoken: { email },
  } = useContext(authContext);
  const dispatch = useDispatch();
  const { device, browser, isIosDevice } = useDeviceInfo();
  const { showNudge, browserBlocked } = useSelector(
    (state: RootState) => state.notification
  );
  const [addNotificationNudgeEvent] = useAddNotificationNudgeEventMutation();

  const onNudgeClose = () => {
    dispatch(closeNotificationNudgeModal());
    trackEvent(BROWSER_NOTIFICATION_NUDGE_CLOSED, {
      browser,
      device,
      email,
    });
    addNotificationNudgeEvent({
      accepted: false,
      deviceType: device,
    });
  };

  const onBlockModal = () => {
    dispatch(closeNotificationBlockModal());
    addNotificationNudgeEvent({
      accepted: false,
      deviceType: device,
      browserBlocked: true,
    });
  };

  const onAllow = async () => {
    trackEvent(BROWSER_NOTIFICATION_NUDGE_ALLOWED, {
      browser,
      device,
      email,
    });
    dispatch(closeNotificationNudgeModal());
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getToken(messaging, {
          vapidKey: import.meta.env.VITE_APP_FIREBASE_VAPID_KEY,
        })
          .then(async (deviceToken: string) => {
            // Notification allowed
            if (deviceToken) {
              try {
                const { accepted } = await addNotificationNudgeEvent({
                  accepted: true,
                  deviceToken,
                  deviceType: device,
                }).unwrap();
                if (accepted) {
                  successToast({
                    message: "Notifications enabled successfully!",
                  });
                }
                trackEvent(BROWSER_NOTIFICATION_ALLOWED, {
                  browser,
                  device,
                  email,
                });
              } catch (error) {}
            }
          })
          .catch((error) => {
            console.log("error generatin token", error);
          });
      } else {
        // Notification block
        dispatch(openNotificationBlockModal());
        addNotificationNudgeEvent({
          accepted: false,
          deviceType: device,
          browserBlocked: true,
        });
        trackEvent(BROWSER_NOTIFICATION_BLOCKED, {
          browser,
          device,
          email,
        });
      }
    });
  };

  if (!isCustomer || isIosDevice || !isSupportedBrowser) {
    return null;
  }

  return (
    <div>
      <Modal.Root open={showNudge} onOpenChange={onNudgeClose}>
        <Modal.Content onPointerDownOutside={(e) => e.preventDefault()}>
          <Modal.Header>
            <Modal.Title>Enable browser notifications</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-leading-5 t-text-text-60">
              Stay updated by receiving timely updates on your Filings, Chats
              and more on Inkle.
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button customType="primary" onClick={onAllow} block>
              Allow
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root open={browserBlocked} onOpenChange={onBlockModal}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Permission required</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-leading-5 t-text-text-60">
              You need to allow notifications for app.inkle.io
              <br />
              within your Browser or Device Settings.
            </div>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button customType="primary" onClick={onBlockModal} block>
              Got it
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
