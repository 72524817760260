import {
  IMessage,
  NovuProvider,
  PopoverNotificationCenter,
  useNotifications,
  useSocket,
} from "@novu/notification-center";
import classNames from "classnames";
import { ChatText } from "components/icons/Navbar/ChatText";
import { CheckSquare } from "components/icons/Navbar/CheckSquare";
import { Reward } from "components/icons/Navbar/Reward";
import {
  MARK_NOTIFICATION_AS_READ,
  NOTIFICATION_BELL_CLICKED,
  NOTIFICATION_CLICKED,
} from "constants/analyticsEvents";
import * as NOTIFICATION_TYPES from "constants/notificationsTypes";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAnalytics } from "hooks/useAnalytics";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import Lottie from "lottie-react";
import { forwardRef, useContext, useEffect, useRef } from "react";
import EmptyNotification from "static/images/EmptyNotification.svg";
import { openLink } from "utils/openLink";
import processingAnimation from "./bell.json";
import { ConditionalLink } from "./conditionalLink";
import ToolTip from "./design/toolTip";
import { Button } from "./DesignSystem/Button/Button";
import { BellIcon } from "./icons/BellIcon";
import { Cross } from "./icons/Cross";
import { Hourglass } from "./icons/Hourglass";
import { OtherNotificationIcon } from "./icons/OtherNotificationIcon";
import { WhatsNewIcon } from "./icons/WhatsNewIcon";
import notify from "./notify.mp3";

dayjs.extend(relativeTime);

const notificationIconMap = {
  [NOTIFICATION_TYPES.PENDING_TASK_REMINDER]: <Hourglass />,
  [NOTIFICATION_TYPES.BI_WEEKLY_PRODUCT_UPDATE]: <WhatsNewIcon />,
  [NOTIFICATION_TYPES.NEW_PERK_LAUNCHED]: <Reward stroke="1.5" />,
  [NOTIFICATION_TYPES.NEW_TASK_LAUNCHED]: <CheckSquare stroke="1.5" />,
  [NOTIFICATION_TYPES.NEW_CHAT_NOTIFICATION]: <ChatText stroke="1.5" />,
};

const notificationColorMap = {
  /* @tw */
  [NOTIFICATION_TYPES.PENDING_TASK_REMINDER]: "t-bg-red-10 t-text-red",
  /* @tw */
  [NOTIFICATION_TYPES.BI_WEEKLY_PRODUCT_UPDATE]: "t-bg-purple-10 t-text-purple",
  /* @tw */
  [NOTIFICATION_TYPES.NEW_PERK_LAUNCHED]: "t-bg-orange-10 t-text-orange",
  /* @tw */
  [NOTIFICATION_TYPES.NEW_TASK_LAUNCHED]: "t-bg-purple-10 t-text-purple",
  /* @tw */
  [NOTIFICATION_TYPES.NEW_CHAT_NOTIFICATION]: "t-bg-purple-10 t-text-purple",
};

export const EmptyScreen = () => {
  return (
    <div className="t-flex t-min-h-96 t-flex-col t-items-center t-justify-center">
      <img src={EmptyNotification} alt="No Notification" />
      <span className="t-text-center t-text-subtext t-text-text-30">
        It looks like you don't have any notifications
        <br /> at the moment.
      </span>
    </div>
  );
};

const CustomBell = forwardRef<HTMLSpanElement, { unseenCount?: number }>(
  ({ unseenCount }, ref) => {
    const { socket } = useSocket();
    const { trackEvent } = useAnalytics();
    const {
      authtoken: { email, uuid },
    } = useContext(authContext);

    useEffect(() => {
      if (socket) {
        const audio = new Audio(notify);
        socket.on("notification_received", (data: any) => {
          if (document.visibilityState === "visible") {
            audio.play().catch((error) => {});
          } else {
            // TODO: handle notification when tab is not visible then play sound on any one of the tab
          }
        });
      }
    }, [socket]);

    const onBellClick = () => {
      trackEvent(NOTIFICATION_BELL_CLICKED, {
        email,
        userId: uuid,
      });
    };

    return (
      <span ref={ref} onClick={onBellClick}>
        <Button customType="icon" size="small">
          <span className="t-text-neutral-80">
            {Boolean(unseenCount) ? (
              <>
                <span className="t-border-solid t-border t-border-surface t-absolute t-right-1 t-top-1 t-h-2 t-w-2 t-rounded-full t-z-10 t-overflow-hidden">
                  <span className="t-bg-red-50 t-h-2 t-w-2 t-block"></span>
                </span>
                <Lottie className="t-w-9" animationData={processingAnimation} />
              </>
            ) : (
              <span className="t-text-text-60">
                <BellIcon />
              </span>
            )}
          </span>
        </Button>
      </span>
    );
  }
);

const Header = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className="bottom-border t-flex t-items-center t-justify-between t-border-neutral-0 t-px-4 t-py-3 t-text-h6">
      Notifications
      <Button customType="transparent" onClick={onClose}>
        <Cross />
      </Button>
    </div>
  );
};

export const NotificationItem = ({
  message,
  noBorder,
}: {
  message: IMessage;
  noBorder?: boolean;
}) => {
  const { trackEvent } = useAnalytics();
  const { content, createdAt, templateIdentifier, _id, read } = message;
  const { markNotificationAsRead, isFetching } = useNotifications();
  const {
    authtoken: { email, uuid },
  } = useContext(authContext);

  const handleHtmlEntityOutputInUrl = (url: string) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(url, "text/html").body
      .textContent;
    return decodedString;
  };
  const onNotificationClick = () => {
    markNotificationAsRead(_id);
    if (message?.cta?.data?.url) {
      const cleanedUrl = handleHtmlEntityOutputInUrl(message?.cta?.data?.url);
      openLink(cleanedUrl, "_self");
    }
    trackEvent(NOTIFICATION_CLICKED, {
      email,
      content: content?.toString(),
      typeOfNotification: templateIdentifier!,
      notificationId: _id,
      userId: uuid,
    });
  };

  const onMarkAsRead = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    markNotificationAsRead(_id);
    trackEvent(MARK_NOTIFICATION_AS_READ, {
      email,
      content: content?.toString(),
      typeOfNotification: templateIdentifier!,
      notificationId: _id,
      userId: uuid,
    });
  };

  return (
    <div
      className={classNames(
        "t-flex t-cursor-pointer t-items-center t-justify-between t-gap-4 t-px-5 t-py-3 hover:t-bg-purple-0",
        {
          "t-border-0": noBorder,
          "t-border-neutral-0 bottom-border": !noBorder,
        }
      )}
      onClick={onNotificationClick}
    >
      <div className="t-flex t-items-center t-gap-4">
        <span
          className={classNames(
            "t-flex t-h-10 t-w-10 t-items-center t-justify-center t-rounded-full t-p-2",
            // @ts-ignore
            notificationColorMap[templateIdentifier] || "t-bg-purple-10"
          )}
        >
          {/* @ts-ignore */}
          {notificationIconMap[templateIdentifier] || (
            <span className="t-text-purple">
              <OtherNotificationIcon />
            </span>
          )}
        </span>
        <div className="t-flex t-w-72 t-flex-col t-gap-1 t-text-subtext t-text-text-60">
          <>
            {typeof content === "string"
              ? content
              : content.map((m) => m.content).join(" ")}
          </>
          <span className="t-text-body-sm t-text-neutral-50">
            {dayjs().to(dayjs(createdAt))}
          </span>
        </div>
      </div>

      {!read && (
        <ToolTip text={!read && "Mark as read"}>
          <button
            className="all:unset t-flex t-rounded-full t-p-2 hover:t-bg-neutral-10"
            onClick={onMarkAsRead}
            disabled={isFetching}
          >
            <div className="t-h-2 t-w-2 t-self-center t-rounded-full t-bg-red-50" />
          </button>
        </ToolTip>
      )}
    </div>
  );
};
export const Notification = () => {
  const ref = useRef<HTMLSpanElement>(null);
  const { isAdmin } = useRoleBasedView();

  const {
    authtoken: { uuid },
  } = useContext(authContext);

  const onClose = () => {
    ref?.current?.click();
  };

  const applicationIdentifier =
    import.meta.env.VITE_APP_NOVU_NOTIFICATION_APP_IDENTIFIER || "";

  return (
    <NovuProvider
      styles={{
        popover: {
          dropdown: {
            zIndex: "700 !important",
          },
        },
        layout: {
          root: {
            padding: "0",
          },
        },
      }}
      subscriberId={uuid}
      applicationIdentifier={applicationIdentifier}
      initialFetchingStrategy={{
        fetchNotifications: true,
        fetchUserPreferences: true,
      }}
    >
      <div className="t-z-sidebar">
        <PopoverNotificationCenter
          header={() => <Header onClose={onClose} />}
          footer={() =>
            isAdmin && (
              <div className="t-h-14 t-flex t-justify-center t-items-center">
                <ConditionalLink to="/settings/notifications">
                  <Button customType="link" block onClick={onClose}>
                    View all notifications
                  </Button>
                </ConditionalLink>
              </div>
            )
          }
          listItem={(message: IMessage) => (
            <NotificationItem message={message} />
          )}
          emptyState={<EmptyScreen />}
          colorScheme="light"
        >
          {({ unseenCount }) => (
            <CustomBell unseenCount={unseenCount} ref={ref} />
          )}
        </PopoverNotificationCenter>
      </div>
    </NovuProvider>
  );
};
