import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ParsedQs } from "qs";
import dayjs from "dayjs";

const currentYear = dayjs().year();
export const vendorsSeasonOptions = Array.from(
  { length: currentYear - 2022 + 1 },
  (_, i) => (currentYear - i).toString()
);

const getDefaultSeason = () => {
  const currentDate = dayjs();
  const febEndThisYear = dayjs().year(currentYear).month(1).endOf("month");

  if (currentDate.isAfter(febEndThisYear)) {
    return currentYear.toString();
  } else {
    return (currentYear - 1).toString();
  }
};

export const defaultSeason = getDefaultSeason();
export const vendorsWFormOptions = [
  { label: "Required", value: "wFormRequired" },
  { label: "Not required", value: "wFormNotRequired" },
];
export const vendorsInitialState = {
  filters: {
    wForm: {
      name: "W-Form",
      value: null,
      type: "wForm",
      fixed: true,
      removable: true,
    },
    season: {
      name: "season",
      value: defaultSeason,
      type: "season",
      fixed: true,
      removable: false,
    },
  },
};

export type VendorsFilterName = "season" | "wForm";

export type VendorsFilterPayload = {
  [key: string]: string | boolean | undefined;
};

export const vendorsFilter = createSlice({
  name: "vendorsFilter",
  initialState: vendorsInitialState,
  reducers: {
    setVendorsFilter: (
      state,
      action: PayloadAction<VendorsFilterPayload | ParsedQs>
    ) => {
      const payload = action.payload;

      const updatedValues = Object.keys(payload).reduce((acc, el) => {
        const value = payload[el];
        const newState = {
          ...vendorsInitialState.filters?.[el as VendorsFilterName],
          value: value as
            | null
            | string
            | ParsedQs
            | string[]
            | ParsedQs[]
            | boolean,
        };

        return { ...acc, [el]: { ...newState } };
      }, {});

      state.filters = {
        ...state.filters,
        ...updatedValues,
      };
    },
    resetVendorFilters: (state) => {
      state.filters = vendorsInitialState.filters;
    },
  },
});

export const { setVendorsFilter, resetVendorFilters } = vendorsFilter.actions;

export default vendorsFilter.reducer;
