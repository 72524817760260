import { InvoiceCustomerModal } from "components/InvoiceCustomerModal/InvoiceCustomerModal";
import { Form } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  InvoiceItem,
  useGetEntityInvoiceQuery,
  useGetInvoiceItemsQuery,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import { InvoiceItemGroup } from "components/InvoiceForm/InvoiceForm";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { Address } from "components/Address/Address";
import { formatDate } from "utils/formatDate";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import { ConditionalLink } from "components/conditionalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const InvoiceInfo = () => {
  const [customerId, setCustomerId] = useState<string | null | "NEW">("");
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const group = useCurrentGroupContext();
  const { search } = useLocation();
  const entityId = useCurrentEntityId();

  const { data: invoiceSettings, isLoading: invoiceSettingLoading } =
    useGetInvoiceSettingsQuery(
      {
        groupId: group?.uuid!,
        entityId,
      },
      { skip: !group?.uuid || !entityId }
    );

  const { data: invoice, isLoading: invoiceLoading } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const { data: invoiceItems, isLoading } = useGetInvoiceItemsQuery(
    {
      groupId: group.uuid,
      invoiceId: invoiceId,
      entityId: entityId!,
    },
    {
      skip: !group.uuid || !entityId || !invoiceId,
    }
  );

  const entity = group.entities.find((entity) => entity.uuid === entityId);

  const createColumn = createColumnHelper<InvoiceItem>();

  const columns = [
    createColumn.accessor("description", {
      size: 33.3333,
      header: "ITEM",
    }),
    createColumn.accessor("quantity", {
      size: 33.3333,
      header: "QUANTITY",
    }),
    createColumn.accessor("amount", {
      size: 33.3333,
      header: "AMOUNT",
      cell: (info) => (
        <AmountSuperScript amount={Number(info.getValue() || 0)} />
      ),
    }),
  ];

  const invoiceItemsTableData = useMemo(() => invoiceItems, [invoiceItems]);

  const table = useReactTable({
    columns,
    data: invoiceItemsTableData || [],
    getCoreRowModel: getCoreRowModel(),
  });

  if (!invoice) {
    return null;
  }

  return (
    <Form>
      <div className="t-pb-10 t-gap-16 t-flex t-flex-col ">
        <div className="t-flex t-flex-col t-gap-12">
          <InvoiceItemGroup title="Customer Details" required>
            <div className="t-grid t-grid-cols-2 t-gap-y-6 t-gap-x-20">
              <InfoItem label="Name">
                <ConditionalLink
                  className="t-text-purple"
                  to={`/books/invoicing/customers/${invoice?.customer?.uuid}${search}`}
                >
                  {invoice?.customer?.company_name}
                </ConditionalLink>
              </InfoItem>
              <InfoItem label="Email">{invoice?.customer?.email}</InfoItem>
              <InfoItem label="Phone">{invoice?.customer?.phone}</InfoItem>
              {invoice?.customer?.billing_address && (
                <InfoItem label="Address">
                  <Address address={invoice?.customer?.billing_address} />
                </InfoItem>
              )}
            </div>
          </InvoiceItemGroup>

          <InvoiceItemGroup title="Invoice Details">
            <div className="t-grid t-grid-cols-2 t-gap-y-6 t-gap-x-20">
              <InfoItem label="Email">{invoiceSettings?.email}</InfoItem>
              <InfoItem label="Due Date">
                {invoice?.due_date ? formatDate(invoice?.due_date) : "-"}
              </InfoItem>
            </div>
          </InvoiceItemGroup>
        </div>

        <InvoiceItemGroup title="Item Details">
          <TableUI size="regular" table={table} />
        </InvoiceItemGroup>

        <div className="t-flex t-flex-col t-gap-12">
          <InvoiceItemGroup title="Tax & Discount">
            <div className="t-grid t-grid-cols-3 t-gap-y-6 t-gap-x-20">
              <InfoItem label="Tax">
                {invoice?.tax_type === "PERCENT" ? (
                  `${invoice?.tax || 0}%`
                ) : (
                  <AmountSuperScript amount={Number(invoice.tax)} />
                )}
              </InfoItem>
              <InfoItem label="Discount">
                {invoice?.discount_type === "PERCENT" ? (
                  `${invoice?.discount || 0}%`
                ) : (
                  <AmountSuperScript amount={Number(invoice.discount)} />
                )}
              </InfoItem>
              <InfoItem label="Advance Paid">
                <AmountSuperScript amount={Number(invoice?.paid_amount) || 0} />
              </InfoItem>
            </div>
          </InvoiceItemGroup>

          <InvoiceItemGroup>
            <InfoItem label="Title">{invoice?.title || "-"}</InfoItem>
            <InfoItem label="Notes">
              <span className="t-whitespace-pre-wrap">
                {invoice?.notes || "-"}
              </span>
            </InfoItem>
            <InfoItem label="Terms">{invoice?.terms || "-"}</InfoItem>
          </InvoiceItemGroup>
        </div>
      </div>
      <InvoiceCustomerModal
        customerId={customerId}
        setCustomerId={setCustomerId}
      />
    </Form>
  );
};
