import { PeriodicTotalAmount, TableData } from "types/Models/revenueMetrics";

type Args = {
  data:
    | {
        periodic_total_amounts: PeriodicTotalAmount[];
        customers: TableData[];
      }
    | undefined;
};

export const formatRevenueData = ({ data }: Args) => {
  const { customers = [], periodic_total_amounts = [] } = data || {};

  const chartData = periodic_total_amounts.map((period) => {
    return {
      x: period.period.range,
      y: period.total_txns_sum_in_period,
    };
  });

  return { data, finalTableData: customers, chartData };
};
