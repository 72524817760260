import {
  ChatTicket,
  ChatTicketCreateResult,
  ChatUpdatePayload,
  TicketETDDetails,
  TicketNotes,
  TicketNotesPayload,
  TicketNotesResponse,
} from "types/Models/chatTicket";
import qs, { stringify } from "qs";
import { Pagination } from "types/Models/pagination";
import { emptyApi } from "./emptyApi";

export const ticketApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    createChatTicket: build.mutation<
      ChatTicketCreateResult,
      {
        ticket_title: string;
        group_entity_id?: string;
        company_group_id: string;
        is_priced_ticket?: boolean;
        is_price_finalized?: boolean;
        ticket_price?: number;
        tag_ids?: string;
        assignee_profile_ids: string;
        is_non_chat_ticket: boolean;
        channel_url: string;
        message_id: string;
        create_ticket_channel?: boolean;
        ticket_channel_name?: string;
      }
    >({
      query: ({
        channel_url,
        assignee_profile_ids,
        message_id,
        ticket_title,
        tag_ids,
        company_group_id,
        is_non_chat_ticket,
        ticket_price,
        is_price_finalized,
        is_priced_ticket,
        group_entity_id,
        create_ticket_channel,
        ticket_channel_name,
      }) => {
        return {
          url: "/api/inkle/tickets/",
          method: "POST",
          body: {
            channel_url,
            assignee_profile_ids,
            message_id,
            ticket_title,
            tag_ids,
            company_group_id,
            is_non_chat_ticket,
            ticket_price,
            is_price_finalized,
            is_priced_ticket,
            group_entity_id,
            create_ticket_channel,
            ticket_channel_name,
          },
        };
      },
      invalidatesTags: ["ChatTickets"],
    }),

    getChatTicketReports: build.query<
      string,
      {
        assignee_profile_ids: string | null;
        resolved_status: boolean | null;
        page_num: number;
        search_term?: string | null;
        tag_filter?: string | null;
        type_filters?: string | null;
        sort_by?: string | null;
        company_group_uuids?: string | null;
        action?: "EXPORT_TICKETS";
      }
    >({
      query: (queryParams) => {
        return {
          url: `/api/inkle/tickets/${stringify(queryParams, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`,
        };
      },
      providesTags: ["ChatTickets"],
    }),

    getChatTickets: build.query<
      {
        current_page: number;
        tickets: ChatTicket[];
        total_count: number;
        total_pages: number;
        unresolved_tickets: number;
      },
      {
        assignee_profile_ids: string | null;
        resolved_status: boolean | null;
        page_num: number;
        search_term?: string | null;
        tag_filter?: string | null;
        type_filters?: string | null;
        etd_filters?: string | null;
        sort_by?: string | null;
        company_group_uuids?: string | null;
      }
    >({
      query: ({
        assignee_profile_ids,
        resolved_status,
        page_num,
        search_term,
        tag_filter,
        type_filters,
        etd_filters,
        sort_by,
        company_group_uuids,
      }) => {
        let queryUrl = qs.stringify(
          {
            page_num,
            assignee_profile_ids,
            resolved_status,
            search_term,
            tag_filter,
            type_filters,
            etd_filters,
            sort_by,
            company_group_uuids,
          },
          {
            skipNulls: true,
            addQueryPrefix: true,
          }
        );
        return {
          url: `/api/inkle/tickets/${queryUrl}`,
        };
      },
      providesTags: ["ChatTickets"],
    }),

    updateChatTicket: build.mutation<ChatTicket, ChatUpdatePayload>({
      query: ({
        assignee_profile_ids,
        resolved_status,
        ticket_id,
        ticket_title,
        ticket_price,
        is_priced_ticket,
        tag_ids,
        remove_tag_id,
        batch_tag_ids,
        is_price_finalized,
        create_ticket_channel,
        ticket_channel_name,
      }) => {
        return {
          url: `/api/inkle/tickets/${ticket_id}/`,
          method: "put",
          body: {
            assignee_profile_ids,
            resolved_status,
            ticket_title,
            tag_ids,
            remove_tag_id,
            batch_tag_ids,
            ticket_price,
            is_priced_ticket,
            is_price_finalized,
            create_ticket_channel,
            ticket_channel_name,
          },
        };
      },
      invalidatesTags: ["ChatTickets"],
    }),

    getCustomerChatTickets: build.query<
      {
        tickets: ChatTicket[];
        tickets_count: number;
      } & Pagination,
      {
        resolved_status: boolean | null;
        page_num: number;
        group_id: string;
        search_term: string;
      }
    >({
      query: ({ resolved_status, page_num, group_id, search_term }) => {
        let queryUrl = qs.stringify(
          { page_num, resolved_status, search_term },
          {
            skipNulls: true,
            addQueryPrefix: true,
          }
        );
        return {
          url: `/api/inkle/tickets/group/${group_id}/${queryUrl}`,
        };
      },
      providesTags: ["CustomerChatTickets"],
    }),

    getSingleChatTickets: build.query<ChatTicket, { ticketUuid: string }>({
      query: ({ ticketUuid }) => `/api/inkle/tickets/${ticketUuid}/`,
      providesTags: ["ChatTickets"],
    }),

    createTicketNotes: build.mutation<TicketNotes, TicketNotesPayload>({
      query: ({ groupId, ticketId, description }) => {
        return {
          url: `/api/inkle/notes/group/${groupId}/tickets/${ticketId}/`,
          method: "post",
          body: {
            description,
          },
        };
      },
      invalidatesTags: ["TicketNotes"],
    }),

    getTicketNotes: build.query<
      TicketNotesResponse,
      { groupId: string; ticketId: string; page_num: number }
    >({
      query: ({ groupId, ticketId, page_num }) =>
        `/api/inkle/notes/group/${groupId}/tickets/${ticketId}?page_num=${page_num}`,
      providesTags: ["TicketNotes"],
    }),

    updateTicketNotes: build.mutation<
      TicketNotes,
      { noteId: string; description: string }
    >({
      query: ({ noteId, description }) => {
        return {
          url: `/api/inkle/notes/${noteId}/`,
          method: "put",
          body: {
            description,
          },
        };
      },
      invalidatesTags: ["TicketNotes"],
    }),

    deleteTicketNotes: build.mutation<any, { noteId: string }>({
      query: ({ noteId }) => {
        return {
          url: `/api/inkle/notes/${noteId}/`,
          method: "delete",
        };
      },
      invalidatesTags: ["TicketNotes"],
    }),

    getTicketById: build.query<
      ChatTicket,
      { groupId: string; ticketId: string }
    >({
      query: ({ groupId, ticketId }) =>
        `/api/inkle/tickets/${ticketId}/group/${groupId}`,
      providesTags: ["CustomerChatTickets"],
    }),
    getTicketSLAMetric: build.query<
      {
        average_resolution_time: {
          days: number;
          hours: number;
        };
        tickets_created_count: number;
        resolved_tickets_count: number;
      },
      { duration: number }
    >({
      query: ({ duration }) =>
        `/api/inkle/tickets/sla_metrics/?duration=${duration}`,
    }),

    escalateTicket: build.mutation<ChatTicket, { ticketId: string }>({
      query: ({ ticketId }) => {
        return {
          url: `/api/inkle/tickets/ticket/${ticketId}/escalate/`,
          method: "PUT",
        };
      },
      invalidatesTags: ["ChatTickets"],
    }),
    addTicketETD: build.mutation<
      ChatTicket,
      { ticketId: string; etd_date: string; reason?: string }
    >({
      query: ({ ticketId, etd_date, reason }) => {
        return {
          url: `/api/inkle/tickets/ticket/${ticketId}/create_etd_history/ `,
          method: "POST",
          body: { etd_date, reason },
        };
      },
      invalidatesTags: ["ChatTickets", "TICKET_ETD_HISTORY"],
    }),
    getTicketETDHistory: build.query<TicketETDDetails[], { ticketId: string }>({
      query: ({ ticketId }) =>
        `/api/inkle/tickets/ticket/${ticketId}/get_etd_history/`,
      providesTags: ["TICKET_ETD_HISTORY"],
    }),
  }),
});

export const {
  useCreateChatTicketMutation,
  useGetChatTicketsQuery,
  useUpdateChatTicketMutation,
  useGetCustomerChatTicketsQuery,
  useGetSingleChatTicketsQuery,
  useCreateTicketNotesMutation,
  useGetTicketNotesQuery,
  useUpdateTicketNotesMutation,
  useDeleteTicketNotesMutation,
  useGetTicketByIdQuery,
  useLazyGetTicketByIdQuery,
  useLazyGetTicketNotesQuery,
  useLazyGetChatTicketReportsQuery,
  useGetTicketSLAMetricQuery,
  useEscalateTicketMutation,
  useAddTicketETDMutation,
  useGetTicketETDHistoryQuery,
} = ticketApis;
