import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { merchantAddSchema } from "formValidations/merchantSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import {
  useBulkMerchantUploadMutation,
  useGetParsedCSVQuery,
} from "store/apis/vendors";
import { MerchantCSVResponse } from "types/Models/vendors";
import { BackendError } from "types/utils/error";
import { AddMerchantsTable } from "./AddMerchantsTable";

type AddMerchantProps = {
  show: boolean;
  closeModal: () => void;
  season?: string;
  useCSV: boolean;
  csvId?: string;
};

export const AddManualVendors = ({
  show,
  closeModal,
  season,
  useCSV,
  csvId,
}: AddMerchantProps) => {
  const { alertToast, successToast } = useToast();
  let selectedEntityId = useCurrentEntityId();

  const [bulkUploadMerchant, { isLoading }] = useBulkMerchantUploadMutation();

  const { data: parsedCSV = [], isLoading: isCSVParsing } =
    useGetParsedCSVQuery(
      {
        entityId: selectedEntityId,
        csvId: csvId || "",
      },
      { skip: !useCSV || !csvId }
    );

  const handleSubmit = async (values: { merchants: MerchantCSVResponse[] }) => {
    const merchants = values.merchants.map((data) => {
      return {
        ...data,
        amount_paid_in_season: dayjs(data.amount_paid_in_season).year(),
      };
    });
    try {
      await bulkUploadMerchant({
        entityId: selectedEntityId,
        merchants: merchants,
      }).unwrap();

      successToast({ message: "New vendor has been added" });
      closeModal();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const parsedInitialValue = parsedCSV.map((data, index: number) => {
    return {
      ...data,
      amount_paid_in_season: dayjs().year(data.amount_paid_in_season as number),
      id: index,
    };
  });

  const initialValue = useCSV
    ? parsedInitialValue
    : [
        {
          name: "",
          email: "",
          total_amount: 0,
          amount_paid_in_season: season
            ? dayjs().year(parseInt(season))
            : dayjs().year(),
          id: 1,
        },
        {
          name: "",
          email: "",
          total_amount: 0,
          amount_paid_in_season: season
            ? dayjs().year(parseInt(season))
            : dayjs().year(),
          id: 2,
        },
      ];

  return (
    <Modal.Root open={show} onOpenChange={closeModal} modal={false}>
      <Formik
        initialValues={{ merchants: initialValue }}
        onSubmit={handleSubmit}
        validationSchema={merchantAddSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form className="t-m-0">
            <Modal.Content size="xl" useCustomOverlay>
              <Modal.Header>
                <Modal.Title>Add Vendors Manually</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                {isCSVParsing ? (
                  <div className="t-w-full t-h-full t-flex t-justify-center">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <AddMerchantsTable />
                    <div className="t-mt-6">
                      <Button
                        size="small"
                        onClick={() =>
                          setFieldValue("merchants", [
                            ...values?.merchants,
                            {
                              name: "",
                              email: "",
                              total_amount: "",
                              amount_paid_in_season: season,
                              id: values.merchants.length + 1,
                            },
                          ])
                        }
                        type="button"
                      >
                        Add more
                      </Button>
                    </div>
                  </>
                )}
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button type="button">Cancel</Button>
                </Modal.RawClose>
                <Button
                  customType="primary"
                  onClick={submitForm}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Confirm
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
