import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { NumericStepper } from "components/NumericStepper/NumericStepper";
import { PriceInput } from "components/PriceInput/PriceInput";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Cart } from "components/icons/Cart";
import { US } from "constants/countryCodes";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { addProductToCartValidation } from "formValidations/addProductToCartValidation";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import ReactCountryFlag from "react-country-flag";
import { useParams } from "react-router-dom";
import {
  useAddProductToCartMutation,
  useGetAllProductsQuery,
  Product,
} from "store/apis/products";
import { BackendError } from "types/utils/error";

export const EntityLabel = ({
  name,
  countryCode,
}: {
  name: string;
  countryCode: string;
}) => (
  <div className="t-flex t-gap-1.5 t-items-center">
    <span className="t-rounded-sm">
      <ReactCountryFlag svg countryCode={countryCode} />
    </span>{" "}
    <span>{name}</span>
  </div>
);

export const AddProductToCart = ({ onClose }: { onClose: () => void }) => {
  const { successToast, alertToast } = useToast();
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const entityId = useCurrentEntityId();
  const { productId } = useParams<{ productId?: string }>();
  const { isCustomer } = useRoleBasedView();

  const { data: products, isLoading } = useGetAllProductsQuery(
    {
      groupId: groupId!,
      entityId: entityId,
    },
    { skip: !groupId || !entityId }
  );
  const [addProductToCart, { isLoading: addingProductToCart }] =
    useAddProductToCartMutation();

  if (isLoading) {
    return (
      <Modal.Root open>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Add to cart</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-flex t-h-full t-w-full t-items-center t-justify-center">
              <Loader />
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }

  if (!products) {
    return null;
  }

  const allProducts = Object.values(products).flatMap((productGroup) => {
    return Object.values(productGroup).flatMap((value) => {
      return Array.isArray(value) &&
        value.length > 0 &&
        !("subscription_name" in value[0])
        ? (value as Product[])
        : [];
    });
  });

  const selectedProduct = allProducts.find(
    (p) => p.uuid === productId
  ) as Product;

  const {
    title,
    product_content_type_id,
    uuid,
    seasons,
    quantity_type,
    approx_deadline,
    approx_price,
    description,
    states,
    has_from_date,
    has_to_date,
  } = selectedProduct || {};

  const currentYearSeason = seasons?.find((s) => s === dayjs().year());
  const selectedYear = dayjs().year().toString();

  const onSubmit = async ({
    entity_id,
    season,
    quantity,
    state_id,
    payment_amount,
    from_date,
    to_date,
  }: {
    entity_id: string;
    season?: number;
    quantity: number;
    state_id?: string;
    payment_amount?: number;
    from_date?: string | null;
    to_date?: string | null;
  }) => {
    if (groupId && uuid && product_content_type_id) {
      try {
        const payload = {
          entity_id,
          season: season ? String(season) : null,
          product_content_type_id,
          product_id: uuid,
          quantity: quantity,
          payment_amount,
          state_id,
          from_date: from_date ? dayjs(from_date).format(YYYY_MM_DD) : null,
          to_date: to_date ? dayjs(to_date).format(YYYY_MM_DD) : null,
        };

        await addProductToCart({
          groupId: groupId,
          entityId,
          payload: payload,
        }).unwrap();

        successToast({ title: "Item added!", size: "small" });
        onClose();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  return (
    <Modal.Root open onOpenChange={onClose}>
      <Formik
        initialValues={{
          entity_id: entityId,
          product_id: uuid,
          product_content_type_id: product_content_type_id,
          season: currentYearSeason,
          has_season: Boolean(seasons && seasons.length > 0),
          has_states: Boolean(states && states.length > 0),
          quantity: 1,
          state_id: "",
          payment_amount: 0,
          from_date: null,
          to_date: null,
        }}
        validationSchema={addProductToCartValidation}
        onSubmit={onSubmit}
      >
        {({ submitForm, values }) => {
          const selectedEntity = group?.entities?.find(
            (e) => e.uuid === values.entity_id
          );

          return (
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-m-0">
                  <div className="t-grid t-grid-cols-2 t-gap-4">
                    <ToolTip text="Change entity from top right of the page">
                      <div>
                        <Combobox
                          isDisabled
                          components={{
                            DropdownIndicator: () => null,
                          }}
                          menuPortalTarget={document.body}
                          name="entity_id"
                          withForm
                          label="Entity"
                          value={
                            selectedEntity && {
                              label: (
                                <EntityLabel
                                  name={selectedEntity.name}
                                  countryCode={selectedEntity.country_code}
                                />
                              ),
                              value: selectedEntity.uuid,
                            }
                          }
                          options={group.entities?.map((entity) => ({
                            label: (
                              <EntityLabel
                                name={entity.name}
                                countryCode={entity.country_code}
                              />
                            ),
                            value: entity.uuid,
                          }))}
                        />
                      </div>
                    </ToolTip>

                    {seasons && seasons.length > 0 && (
                      <Combobox
                        menuPortalTarget={document.body}
                        name="season"
                        withForm
                        label="Season"
                        value={
                          Boolean(values.season)
                            ? {
                                value: String(values.season),
                                label: values.season,
                              }
                            : null
                        }
                        options={seasons?.map((season: number) => ({
                          value: String(season),
                          label: season,
                        }))}
                      />
                    )}
                    {has_from_date && (
                      <>
                        <br />
                        <Field name="from_date">
                          {({ field }: FieldProps) => {
                            return (
                              <DateInput
                                {...field}
                                label="Start from"
                                placeholder="Select month and year"
                                dateFormat="MMMM-yyyy"
                                showMonthYearPicker
                                required
                                portalId="startDate"
                                openToDate={new Date(selectedYear || "")}
                                maxDate={dayjs(selectedYear)
                                  .endOf("year")
                                  .toDate()}
                              />
                            );
                          }}
                        </Field>
                      </>
                    )}

                    {has_to_date && (
                      <Field name="to_date">
                        {({ field }: FieldProps) => {
                          return (
                            <DateInput
                              {...field}
                              label="To"
                              placeholder="Select month and year"
                              dateFormat="MMMM-yyyy"
                              showMonthYearPicker
                              required
                              portalId="startDate"
                              openToDate={new Date(selectedYear || "")}
                              maxDate={dayjs(selectedYear)
                                .endOf("year")
                                .toDate()}
                            />
                          );
                        }}
                      </Field>
                    )}

                    {states && !isCustomer && states.length > 0 && (
                      <div className="t-col-span-2">
                        <Combobox
                          menuPortalTarget={document.body}
                          name="state_id"
                          withForm
                          label="State"
                          options={states?.map(({ state_id, name }) => ({
                            value: state_id,
                            label: name,
                          }))}
                        />
                      </div>
                    )}

                    {quantity_type && (
                      <>
                        <div className="t-self-center">
                          <span className="t-font-sans t-text-caption t-text-text-30 t-mb-1.5">
                            Number of {quantity_type}
                          </span>
                        </div>

                        <div className="t-justify-self-end">
                          <NumericStepper name="quantity" />
                        </div>
                      </>
                    )}

                    {description && (
                      <div className="t-col-span-2">
                        <span className="t-font-sans t-text-caption t-text-text-30 t-mb-1.5">
                          About
                        </span>
                        <div
                          className="t-text-body"
                          dangerouslySetInnerHTML={{
                            __html: description,
                          }}
                        />
                      </div>
                    )}

                    {approx_deadline && (
                      <div>
                        <p className="t-font-sans t-text-caption t-text-text-30 t-mb-1.5 t-m-0">
                          Typical Deadline
                        </p>
                        <p className="t-text-subtitle">
                          {dayjs(approx_deadline).format("Do MMMM")}
                        </p>
                      </div>
                    )}

                    {approx_price && !isNaN(Number(approx_price)) ? (
                      <div>
                        <p className="t-font-sans t-text-caption t-text-text-30 t-mb-1.5 t-m-0">
                          Price
                        </p>
                        <p className="t-text-subtitle">
                          <AmountSuperScript
                            amount={Number(approx_price || "0")}
                          />
                        </p>
                      </div>
                    ) : (
                      <div className="t-col-span-2">
                        <PriceInput
                          name="payment_amount"
                          label="Price"
                          type="number"
                          required
                        />
                      </div>
                    )}
                  </div>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <div className="t-flex t-justify-end">
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={addingProductToCart}
                    disabled={addingProductToCart}
                  >
                    <div className="t-flex t-items-center t-gap-1.5">
                      <Cart />
                      <span>Add to Cart</span>
                    </div>
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
