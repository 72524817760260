import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { BareInput, Label } from "components/DesignSystem/TextInput/TextInput";
import { NumericInput } from "components/NumericInput/NumericInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { AddProductModal } from "components/MyProducts/AddProductModal";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { EnterIcon } from "components/icons/EnterIcon";
import { Sparkle } from "components/icons/Navbar/Sparkle";
import { SparkleSolid } from "components/icons/Navbar/SparkleSolid";
import { DeleteIcon } from "components/icons/delete";
import { DRAFT } from "constants/invoiceStatuses";
import { PRODUCTS_AND_SERVICES } from "constants/subscriptionPermissionFeatures";
import { useFormikContext } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useHasSubscriptionPermission } from "hooks/useHasSubscriptionPermission";
import { useModal } from "hooks/useModal";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Invoice,
  InvoiceItem,
  invoiceApis,
  useCreateInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
  useGetEntityInvoiceQuery,
  useGetInvoiceItemsQuery,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceItemMutation,
} from "store/apis/invoices";
import { useGetGroupByProductsServicesQuery } from "store/apis/productAndServices";
import { ProductAndServices } from "types/Models/productAndServices";
import { debounce } from "utils/debouncing";
import { onEnterKey } from "utils/onEnterKey";

const ItemWrapper = ({
  children,
  invoiceItem,
  onNewProductCreateSuccess,
}: {
  children: ({
    isCustomInput,
    onChange,
  }: {
    isCustomInput: boolean;
    onChange: ({ checked }: { checked: boolean }) => void;
    createNewProduct: (inputValue: string) => void;
    addNewProductAndServicesModal: () => void;
  }) => React.ReactNode;
  invoiceItem: InvoiceItem;
  onNewProductCreateSuccess: ({ item }: { item: ProductAndServices }) => void;
}) => {
  const { description, product_data_id } = invoiceItem;
  const isCustomItem = Boolean(!product_data_id && description);
  const [isCustomInput, setIsCustomInput] = useState(isCustomItem);
  const [newProductName, setNewProductName] = useState<string>("");
  const addProductAndServicesModal = useModal();

  const onChange = ({ checked }: { checked: boolean }) => {
    setIsCustomInput(checked);
  };

  const createNewProduct = (inputValue: string) => {
    setNewProductName(inputValue);
    addProductAndServicesModal.open();
  };

  return (
    <>
      {children({
        isCustomInput,
        onChange,
        createNewProduct,
        addNewProductAndServicesModal: addProductAndServicesModal.open,
      })}
      <Modal.Root
        open={addProductAndServicesModal.isOpen}
        onOpenChange={addProductAndServicesModal.close}
        modal={false}
      >
        <Modal.Content useCustomOverlay>
          <AddProductModal
            name={newProductName}
            close={() => {
              addProductAndServicesModal.close();
              setNewProductName("");
            }}
            isOpen={addProductAndServicesModal.isOpen}
            onSuccess={({ item }) => {
              onNewProductCreateSuccess({ item });
            }}
          />
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export const InvoiceItems = () => {
  const { values } = useFormikContext<Partial<Invoice>>();
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const entityId = useCurrentEntityId();
  const { data, isLoading: isProductsLoading } =
    useGetGroupByProductsServicesQuery(
      {
        groupId: groupId!,
        entityId,
      },
      {
        skip: !groupId || !entityId,
      }
    );

  const { products = [], services = [] } = data || {};

  const productoptions = products.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const serviceoptions = services.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const options = [
    { label: "Products", options: productoptions },
    {
      label: "Services",
      options: serviceoptions,
    },
  ];

  const { data: invoiceSettings, isLoading: invoiceSettingLoading } =
    useGetInvoiceSettingsQuery(
      {
        groupId: groupId!,
        entityId,
      },
      { skip: !groupId || !entityId }
    );

  const { data: items, isFetching: isFetchingInvoiceItems } =
    useGetInvoiceItemsQuery(
      {
        groupId: group?.uuid!,
        entityId: entityId!,
        invoiceId,
      },
      { skip: !group?.uuid || !invoiceSettings?.entity_id }
    );

  const { data: invoice } = useGetEntityInvoiceQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      invoiceId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [createInvoiceItem, { isLoading: creatingInvoiceItem }] =
    useCreateInvoiceItemMutation();
  const [updateInvoiceItem] = useUpdateInvoiceItemMutation();
  const [deleteInvoiceItem, { isLoading: deleting, originalArgs }] =
    useDeleteInvoiceItemMutation();

  const dispatch = useAppDispatch();

  const onCreateInvoice = () => {
    if (groupId && invoiceId && entityId) {
      createInvoiceItem({
        groupId,
        entityId,
        invoiceId,
      });
    }
    if (invoice?.customer) {
      dispatch(
        invoiceApis.util.invalidateTags([
          { type: "INVOICE_CUSTOMERS", id: invoice?.customer?.uuid },
        ])
      );
    }
  };

  const onUpdateInvoice = (
    invoiceItemId: string,
    values: InvoiceItem,
    update: Partial<InvoiceItem>
  ) => {
    if (groupId && invoiceId && entityId) {
      updateInvoiceItem({
        groupId,
        entityId,
        invoiceId,
        invoiceItemId,
        payload: { ...values, ...update },
      });

      if (invoice?.customer) {
        dispatch(
          invoiceApis.util.invalidateTags([
            { type: "INVOICE_CUSTOMERS", id: invoice?.customer?.uuid },
          ])
        );
      }
    }
  };

  const onDeleteInvoice = (invoiceItemId: string) => {
    if (groupId && invoiceId && entityId) {
      deleteInvoiceItem({
        groupId,
        entityId,
        invoiceId,
        invoiceItemId,
      });

      if (invoice?.customer) {
        dispatch(
          invoiceApis.util.invalidateTags([
            { type: "INVOICE_CUSTOMERS", id: invoice?.customer?.uuid },
          ])
        );
      }
    }
  };

  const { upgrade } = useUpgradePlan();

  const permission = useHasSubscriptionPermission({
    feature: PRODUCTS_AND_SERVICES,
  });

  if (!values?.invoice_items) {
    return null;
  }
  const handleInvoiceItemAddition = () => {
    if (items) {
      const completeItems = items.filter(
        ({ quantity, description }) => quantity && description
      );
      if (items.length === completeItems.length) {
        onCreateInvoice();
      }
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-6">
      <>
        <div>
          <div
            className="t-flex t-flex-col t-gap-5"
            onKeyDown={onEnterKey(handleInvoiceItemAddition)}
          >
            {items &&
              items.map((invoiceItem, index) => (
                <ItemWrapper
                  key={invoiceItem.uuid}
                  invoiceItem={invoiceItem}
                  onNewProductCreateSuccess={debounce(
                    ({ item: { uuid, name } }) => {
                      onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                        product_data_id: uuid,
                        description: name,
                      });
                    }
                  )}
                >
                  {({
                    isCustomInput,
                    onChange,
                    createNewProduct,
                    addNewProductAndServicesModal,
                  }) => (
                    <div className="t-flex t-flex-col t-gap-1">
                      <div className="t-grid t-grid-cols-[2fr_1fr_1fr_0.5fr] t-gap-3 t-items-end">
                        <div>
                          <Label required htmlFor={`item_description.${index}`}>
                            Item
                          </Label>
                          {permission?.blocked || isCustomInput ? (
                            <BareInput
                              customSize="small"
                              name={`item_description.${index}`}
                              onChange={debounce((e) =>
                                onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                                  description: e.target.value,
                                  product_data_id: null,
                                })
                              )}
                              defaultValue={invoiceItem.description}
                              required
                            />
                          ) : (
                            <Combobox
                              size="small"
                              name={`item_description.${index}`}
                              required
                              creatable
                              onCreateOption={(inputValue) => {
                                createNewProduct(inputValue);
                              }}
                              isLoading={isProductsLoading}
                              isDisabled={isProductsLoading}
                              options={options}
                              isClearable={false}
                              defaultValue={
                                invoiceItem.product_data_id
                                  ? {
                                      label: invoiceItem.description,
                                      value: invoiceItem.product_data_id,
                                    }
                                  : undefined
                              }
                              value={
                                invoiceItem.product_data_id
                                  ? {
                                      label: invoiceItem.description,
                                      value: invoiceItem.product_data_id,
                                    }
                                  : undefined
                              }
                              onChange={debounce((selectedOption) => {
                                onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                                  product_data_id: selectedOption?.value,
                                });
                              })}
                              actions={
                                <Button
                                  size="small"
                                  customType="link"
                                  type="button"
                                  onClick={addNewProductAndServicesModal}
                                >
                                  Add Product
                                </Button>
                              }
                            />
                          )}
                        </div>
                        <div>
                          <NumericInput
                            fieldProps={{ name: `item_quantity.${index}` }}
                            numericProps={{
                              onValueChange: ({ floatValue }) =>
                                onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                                  quantity: floatValue || 0,
                                }),
                              defaultValue: invoiceItem.quantity,
                              customSize: "small",
                            }}
                            label="Quantity"
                            required
                          />
                        </div>
                        <div>
                          <PriceInput
                            customSize="small"
                            name={`item_price.${index}`}
                            onValueChange={({ floatValue }) =>
                              onUpdateInvoice(invoiceItem.uuid, invoiceItem, {
                                rate: floatValue || 0,
                              })
                            }
                            value={invoiceItem.rate}
                            defaultValue={invoiceItem.rate}
                            label="Rate"
                            required
                          />
                        </div>
                        <div>
                          <Button
                            onClick={() => onDeleteInvoice(invoiceItem.uuid)}
                            type="button"
                            customType="icon"
                            disabled={items.length === 1}
                            isLoading={
                              deleting &&
                              originalArgs?.invoiceItemId === invoiceItem.uuid
                            }
                            size="small"
                          >
                            <DeleteIcon color="currentColor" />
                          </Button>
                        </div>
                      </div>
                      {!permission?.blocked && (
                        <Checkbox
                          name={`item_description_type.${index}`}
                          checked={isCustomInput}
                          onChange={({ target }) =>
                            onChange({ checked: target.checked })
                          }
                          label={
                            <ConditionalToolTip
                              side="right"
                              condition={
                                <>
                                  Use this if you want to input custom text
                                  instead of
                                  <br />
                                  selecting/creating a product as a line item.
                                </>
                              }
                            >
                              <span className="t-mt-1.5 t-text-body-sm t-text-text-30 t-leading-none">
                                Add custom input instead
                              </span>
                            </ConditionalToolTip>
                          }
                        />
                      )}
                    </div>
                  )}
                </ItemWrapper>
              ))}
          </div>

          {permission?.blocked &&
            permission?.subscription &&
            invoice?.status === DRAFT && (
              <div className="t-flex t-gap-1 t-text-body-sm t-text-text-30 t-mt-1.5">
                <span className="t-flex t-text-neutral-20">
                  <SparkleSolid />
                </span>
                <span>To link your products effortlessly, </span>
                <Button
                  onClick={() => upgrade({ addon: permission?.subscription! })}
                  size="small"
                  type="button"
                  customType="link"
                >
                  <span className="t-text-body-sm t-font-normal">
                    upgrade to {permission?.subscription?.subscription_name}
                  </span>
                </Button>
              </div>
            )}
        </div>

        <div className="t-flex t-items-center t-gap-2">
          <Button
            type="button"
            customType="secondary"
            onClick={handleInvoiceItemAddition}
            isLoading={creatingInvoiceItem}
            disabled={creatingInvoiceItem}
            size="small"
          >
            Add line item
          </Button>
          <span className=" t-text-button t-text-neutral-30">or</span>
          <div className="t-bg-neutral-0 t-justify-center t-items-center  t-rounded-full t-text-neutral-70 t-px-3 t-py-2 t-text-body-sm t-gap-2 t-flex">
            <EnterIcon />
            <span>Return</span>
          </div>
        </div>
      </>
    </div>
  );
};
