import { Chat } from "components/chat/Chat";
import { withChatFilterContext } from "contexts/ChatFilterContext";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAllFspClientsQuery } from "store/apis/practiceCrm";
import { ChannelFilters } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

const PracticeGroupChat = () => {
  const { client } = useChatContext();
  const { practicecrmId } = useParams<{ practicecrmId: string }>();
  const [fspGroupIds, setFspGroupIds] = useState<string[]>([]);
  const {
    data = [],
    isFetching,
    isLoading,
    isSuccess,
  } = useGetAllFspClientsQuery({
    practicecrmId: practicecrmId,
  });

  useEffect(() => {
    if (isSuccess) {
      const clientIds = data.map((client: any) => client.uuid);
      setFspGroupIds(clientIds);
    }
  }, [isFetching, isSuccess]);

  const filters: ChannelFilters<DefaultStreamChatGenerics> = useMemo(() => {
    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
      type: "messaging",
      $and: [
        {
          group_uuid: { $in: fspGroupIds },
        },
        { members: { $in: [client.userID!] } },
      ],
    };

    return filters;
  }, [client.userID, fspGroupIds]);

  return (
    <>
      {isLoading && (
        <div className="t-pl-6">
          <strong>Loading...</strong>
        </div>
      )}
      {!isLoading && fspGroupIds.length === 0 && (
        <div className="t-pl-6">
          <strong>This practice has no clients.</strong>
        </div>
      )}
      {!isLoading && fspGroupIds.length > 0 && (
        <Chat filters={filters} hideTabBar />
      )}
    </>
  );
};

export const PracticeCrmGroupChat = withChatFilterContext(PracticeGroupChat);
