import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

// context
import authContext from "../../jwt_context&axios/authContext";

export const ShareDocuments = () => {
  const { authtoken } = useContext(authContext);
  const { docId, groupId } = useParams();

  useEffect(() => {
    if (authtoken.is_any_service_user) {
      window.location.href = `${
        import.meta.env.VITE_APP_URL
      }admin/crm/${groupId}/documents/${docId}`;
    } else {
      window.location.href = `${
        import.meta.env.VITE_APP_URL
      }tax/documents/${docId}`;
    }
  }, []);

  return <></>;
};
