import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Table from "components/DesignSystem/Table/V2/Table";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { PriceInput } from "components/PriceInput/PriceInput";
import dayjs from "dayjs";
import { Field, FieldProps, useFormikContext } from "formik";
import { useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { MerchantCSVResponse } from "types/Models/vendors";

export const AddMerchantsTable = () => {
  const { values, setFieldValue } = useFormikContext<{
    merchants: MerchantCSVResponse[];
  }>();

  const merchants = values.merchants || [];

  const createColumn = createColumnHelper<MerchantCSVResponse>();

  const deleteMerchantRow = (id: number) => {
    const newValues = merchants.filter((merchants) => merchants.id !== id);
    setFieldValue("merchants", newValues);
  };

  const columns = useMemo(
    () => [
      createColumn.accessor("name", {
        size: 24,
        header: () => (
          <div className="after:t-content-['*'] after:t-text-red">Name</div>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <Field name={`merchants[${rowIndex}].name`}>
              {({ field }: FieldProps) => (
                <div>
                  <TextInput
                    block
                    {...field}
                    placeholder="John Doe"
                    required
                    customSize="small"
                  />
                </div>
              )}
            </Field>
          );
        },
      }),

      createColumn.accessor("email", {
        size: 24,
        header: "Email",
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <Field name={`merchants[${rowIndex}].email`}>
              {({ field }: FieldProps) => (
                <div>
                  <TextInput
                    block
                    {...field}
                    placeholder="vendor@inkle.io"
                    type="email"
                    customSize="small"
                  />
                </div>
              )}
            </Field>
          );
        },
      }),

      createColumn.accessor("amount_paid_in_season", {
        size: 24,
        header: () => (
          <div className="t-flex after:t-content-['*'] after:t-text-red">
            Year of Payment
          </div>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <Field name={`merchants[${rowIndex}].amount_paid_in_season`}>
              {({ field }: FieldProps) => (
                <DateInput
                  {...field}
                  name={field.name}
                  showYearPicker
                  showYearDropdown
                  required
                  dateFormat="yyyy"
                  onDateChange={(date: Date) => {
                    if (date)
                      setFieldValue(
                        `merchants[${rowIndex}].amount_paid_in_season`,
                        dayjs(date).year().toString()
                      );
                  }}
                  value={field.value ? dayjs(field.value).format("YYYY") : ""}
                />
              )}
            </Field>
          );
        },
      }),

      createColumn.accessor("total_amount", {
        size: 24,
        header: () => (
          <div className="t-flex t-justify-end after:t-content-['*'] after:t-text-red">
            Amount Paid
          </div>
        ),
        cell: (info) => {
          const rowIndex = info.row.index;
          return (
            <Field name={`merchants[${rowIndex}].total_amount`}>
              {({ field }: FieldProps) => (
                <div>
                  <PriceInput
                    {...field}
                    label=""
                    required
                    rightAlign
                    placeholder="$ 0.00"
                    customSize="small"
                  />
                </div>
              )}
            </Field>
          );
        },
      }),

      createColumn.display({
        size: 4,
        header: "",
        id: "ACTIONS",
        cell: (info) => {
          const disableDelete = merchants?.length <= 1;
          return (
            <ConditionalToolTip
              condition={disableDelete && "One merchant is required"}
            >
              <div
                className={classNames("t-flex t-items-center", {
                  "t-opacity-30": disableDelete,
                })}
              >
                <Button
                  customType="transparent"
                  onClick={() => deleteMerchantRow(Number(info.row.index))}
                  disabled={disableDelete}
                >
                  <DeleteIcon color="#706A85" />
                </Button>
              </div>
            </ConditionalToolTip>
          );
        },
      }),
    ],
    [merchants?.length]
  );

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  return (
    <Table.Container className="t-h-full t-overflow-x-auto" size="small">
      <Table.Content className="md:t-table-fixed">
        <Table.Head className="!t-static">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.Row>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <Table.Row key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Table.Cell
                  key={cell.id}
                  style={{ width: `${cell.column.getSize()}%` }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};
