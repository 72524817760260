import { useEffect, useState } from "react";

export const usePagination = ({
  pageNumber,
  onPageNumChange,
  totalPage: totalPageFromProps,
}: {
  pageNumber?: number;
  onPageNumChange?: (currentPageNumber: number) => void;
  totalPage?: number;
} = {}) => {
  const [pageNum, setPageNum] = useState<number>(pageNumber || 1);
  const [totalPage, setTotalPage] = useState(totalPageFromProps || 1);

  useEffect(() => {
    if (pageNumber) {
      setPageNum(pageNumber);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (totalPageFromProps) {
      setTotalPage(totalPageFromProps);
    }
  }, [totalPageFromProps]);

  useEffect(() => {
    if (onPageNumChange) {
      onPageNumChange(pageNum);
    }
  }, [pageNum]);

  const goToFirstPage = () => {
    setPageNum(1);
  };

  const goToPrevPage = () => {
    setPageNum((prev) => (prev < totalPage ? prev - 1 : totalPage - 1));
  };

  const goToNextPage = () => {
    if (pageNum < totalPage) {
      setPageNum((prev) => prev + 1);
    }
  };
  const goToLastPage = () => {
    setPageNum(totalPage);
  };

  return {
    pageNum,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    setTotalPage,
  };
};
