import { Header } from "components/DesignSystem/Header/Header";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { CaretRight } from "components/icons/CaretRight";
import * as INVOICE_STATUSES from "constants/invoiceStatuses";
import { Invoice } from "store/apis/invoices";

export const InvoiceHeader = ({
  invoice,
  onBackClick,
  linkedTransaction,
}: {
  invoice: Invoice;
  onBackClick: () => void;
  linkedTransaction: React.ReactNode;
}) => {
  return (
    <div className="t-flex t-gap-2 t-items-center t-w-full">
      <button
        className="all:unset t-rounded-full t-border t-border-solid t-border-neutral-10 t-bg-white t-rotate-180 t-h-8 t-w-8 t-items-center t-flex t-justify-center hover:t-bg-neutral-0 t-text-text-black"
        onClick={onBackClick}
      >
        <CaretRight />
      </button>

      <div className="t-w-full">
        <Header
          v2
          title={
            <div className="t-flex t-items-center t-justify-center t-gap-3">
              <h5 className="t-text-h5 t-font-bold t-m-0">
                {invoice.invoice_number}
              </h5>
              <Tag
                rounded
                size="small"
                icon={false}
                tagType={
                  {
                    [INVOICE_STATUSES.DRAFT]: "gray",
                    [INVOICE_STATUSES.SENT]: "purple",
                    [INVOICE_STATUSES.PAID]: "green",
                    [INVOICE_STATUSES.PARTIALLY_PAID]: "purple",
                  }[invoice.status] as "gray" | "purple" | "green"
                }
              >
                {invoice.status}
              </Tag>
            </div>
          }
          right={linkedTransaction}
        />
      </div>
    </div>
  );
};
