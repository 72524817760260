import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import AddSlack from "static/images/AddSlack.svg";
import { UnorderedList } from "./design/unorderdList";
import cx from "classnames";
import ToolTip from "./design/toolTip";
import classNames from "classnames";
import FloatingSlack from "static/images/FloatingSlack.svg";
import SubscribeSlack from "static/images/SubscribeSlack.svg";
import GreyInfo from "static/images/InfoGray.svg";

const ToolTipContainer = () => {
  return (
    <ToolTip
      side="bottom"
      text={
        <>
          <h6>How it works?</h6>
          <UnorderedList className="t-m-0">
            <li className="t-mb-2">
              Receive chat notifications of all your unread messages on Slack
            </li>
            <li className="t-mb-2">
              Send a quick reply to the latest unread message by replying to the
              alert on Slack
            </li>
            <li className="t-mb-2">
              Never miss important messages on your tax deadlines and filings
            </li>
          </UnorderedList>
        </>
      }
    >
      <img
        className="t-cursor-pointer"
        src={GreyInfo}
        alt="How slack connection works?"
      />
    </ToolTip>
  );
};

export const SlackIntegration = ({ reverse, hideOnceAdded, floating }) => {
  const { authtoken } = useContext(authContext);
  const slackUrl = `${import.meta.env.VITE_APP_SLACK_AUTH_URL}`;
  const isSlackConnected = authtoken.is_slack_added;

  if (isSlackConnected && hideOnceAdded) {
    return null;
  }

  return (
    <div
      className={classNames("t-items-center", {
        floatingSlack: floating,
      })}
    >
      {!floating && (
        <div
          className={cx("t-w-full t-flex", {
            "t-hidden": isSlackConnected,
            "t-order-1": !reverse,
            "t-order-2 t-justify-end": reverse,
          })}
        >
          <ToolTipContainer />
        </div>
      )}

      <span
        className={cx("t-relative t-w-full", {
          "t-order-2": !reverse,
          "t-order-1": reverse,
        })}
      >
        <a href={slackUrl} alt="slack icon" className="all:unset t-w-full">
          {!floating ? (
            <>
              {isSlackConnected ? (
                <div className="t-flex t-justify-between t-items-center t-border t-border-solid t-border-neutral-0 t-p-3 t-gap-2 t-w-full t-rounded t-cursor-pointer">
                  <div className="t-flex t-gap-2">
                    <img
                      alt="Add to Slack"
                      className="t-w-5 t-h-5"
                      src={SubscribeSlack}
                    />
                    <div className="t-text-body t-text-text-100">
                      Update Slack
                    </div>
                  </div>
                  <ToolTipContainer />
                </div>
              ) : (
                <img
                  alt="Add to Slack"
                  style={{ height: "40px" }}
                  className="t-cursor-pointer"
                  src={AddSlack}
                />
              )}
            </>
          ) : (
            !isSlackConnected && (
              <img
                alt="Add to Slack"
                className="t-h-[32px] t-cursor-pointer"
                src={FloatingSlack}
              />
            )
          )}
        </a>
      </span>
    </div>
  );
};
