import axios from "axios";

const baseURL = import.meta.env.VITE_APP_BASE_URL;

export const getGoogleUser = async ({
  access_token,
  session_id,
  accept_tos,
}) => {
  return await axios.post(baseURL + "api/inkle/users/auth/google/", {
    access_token,
    session_id: session_id,
    is_public_user: session_id ? true : false,
    accept_tos,
  });
};

export const addPhoneNumberToGoogleUser = async ({
  mobile,
  whatsapp_consent,
  email,
  accept_tos,
}) => {
  return await axios.post(baseURL + "api/inkle/users/change-mobile/", {
    mobile,
    whatsapp_consent,
    email,
    accept_tos,
  });
};

export const verifyPhoneNumber = async (payload) => {
  return await axios.post(
    baseURL + "api/inkle/users/verifymobileotp/",
    payload
  );
};
