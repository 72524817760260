import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { DateFilter } from "components/Filters/DateFilter";
import { NO_ASSGINEE_KEY, NO_ASSIGNEE } from "constants/agent";
import { NOT_STARTED, NOT_STARTED_KEY } from "constants/taskStatus";
import { FilterValues } from "contexts/ChatFilterContext";
import { usePaginatedQuery } from "hooks/usePaginatedQuery";
import React from "react";
import FunnelIcon from "static/images/Funnel.svg";
import { useLazyGetAllGroupsQuery } from "store/apis/crm";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { useGetAllBaseTaskTemplatesQuery } from "store/apis/task";
import { useGetAllTaskStatesQuery } from "store/apis/taskTags";
import { CompanyGroup } from "types/Models/groups";
import { debounce } from "utils/debouncing";

type ChannelListFilterProps = {
  values: FilterValues;
  updateFilter: <S extends keyof FilterValues>(
    name: S,
    newValue: FilterValues[S]
  ) => void;
};

export type FilterTypes =
  | "CHANNEL"
  | "STATUS"
  | "COMPANY"
  | "AGENT"
  | "LAST_MESSAGE_DATE"
  | "OTHERS";

const LoaderState = () => {
  return (
    <div className="t-flex t-items-center t-justify-center t-h-full">
      <Loader customType="secondary" />
    </div>
  );
};

const StatusList = ({ updateFilter, values }: ChannelListFilterProps) => {
  const {
    data: taskStatesData = [],
    isLoading,
    isSuccess,
  } = useGetAllTaskStatesQuery();

  const taskStates = [
    ...taskStatesData,
    { name: NOT_STARTED, uuid: NOT_STARTED_KEY },
  ];

  return (
    <Async.Root
      isLoading={isLoading}
      isEmpty={taskStates.length === 0}
      isSuccess={isSuccess}
      customLoader={<LoaderState />}
    >
      <Async.Empty>No results found</Async.Empty>
      <Async.Success>
        <MultiSelectFilter
          onChange={(value) => {
            updateFilter("STATUS", value);
          }}
          options={taskStates.map(({ name, uuid }) => ({
            value: uuid,
            label: name,
          }))}
          selected={values.STATUS}
        />
      </Async.Success>
    </Async.Root>
  );
};

const ChannelList = ({ updateFilter, values }: ChannelListFilterProps) => {
  const [searchTerm, setSearchTerm] = React.useState<null | string>(null);
  const { data: baseTaskTemplates = [], isLoading } =
    useGetAllBaseTaskTemplatesQuery({
      searchString: searchTerm,
    });

  const baseTaskTemplatesWithOutBooks = baseTaskTemplates.filter(
    ({ task_category_type }) => task_category_type !== "BOOKS"
  );

  const options = [
    ...baseTaskTemplatesWithOutBooks,
    { title: "Books", uuid: "BOOKS" },
    { title: "General Help", uuid: "GENERAL_HELP" },
  ];

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value || null);
  });

  if (isLoading) {
    return <LoaderState />;
  }

  return (
    <MultiSelectFilter
      onSearchValueChange={handleSearch}
      onChange={(value) => {
        updateFilter("CHANNEL", value);
      }}
      options={options.map(({ title, uuid }) => ({
        value: uuid,
        label: title,
      }))}
      selected={values.CHANNEL}
    />
  );
};

const AgentList = ({ updateFilter, values }: ChannelListFilterProps) => {
  const {
    data: serviceTeam = [],
    isLoading,
    isSuccess,
  } = useGetServiceTeamQuery({}, {});
  const agents = [...serviceTeam, { name: NO_ASSIGNEE, uuid: NO_ASSGINEE_KEY }];

  return (
    <Async.Root
      isLoading={isLoading}
      isEmpty={serviceTeam.length === 0}
      isSuccess={isSuccess}
      customLoader={<LoaderState />}
    >
      <Async.Empty>No results found</Async.Empty>
      <Async.Success>
        <MultiSelectFilter
          onChange={(value) => {
            updateFilter("AGENT", value);
          }}
          options={agents.map(({ name, uuid }) => ({
            value: uuid,
            label: name,
          }))}
          selected={values.AGENT}
        />
      </Async.Success>
    </Async.Root>
  );
};

const CompanyList = ({ updateFilter, values }: ChannelListFilterProps) => {
  const [searchTerm, setSearchTerm] = React.useState<null | string>(null);
  const {
    data: groups,
    loadNext,
    isLoading,
    isSuccess,
  } = usePaginatedQuery<{
    company_groups?: CompanyGroup[];
  }>(useLazyGetAllGroupsQuery, "company_groups", {
    search_term: searchTerm,
  });

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value || null);
  });

  return (
    <Async.Root
      isLoading={isLoading}
      isEmpty={false}
      isSuccess={isSuccess}
      customLoader={<LoaderState />}
    >
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <MultiSelectFilter
          onSearchValueChange={handleSearch}
          hasMore={true}
          totalCount={groups?.company_groups?.length}
          loadNext={loadNext}
          onChange={(value) => updateFilter("COMPANY", value)}
          options={
            groups?.company_groups?.map((group) => ({
              value: group.uuid,
              label: group.name,
            })) || []
          }
          selected={values.COMPANY}
        />
      </Async.Success>
    </Async.Root>
  );
};

const FilterListItem = ({
  value,
  hideFilters,
  children,
}: {
  value: FilterTypes;
  hideFilters: FilterTypes[];
  children: React.ReactNode;
}) => {
  if (hideFilters?.includes(value)) {
    return null;
  }

  return <Filter.ListItem value={value}>{children}</Filter.ListItem>;
};

export const ChannelListFilter = ({
  updateFilter,
  values,
  toggleSortByUnread,
  hideFilters = [],
}: ChannelListFilterProps & {
  toggleSortByUnread: () => void;
  hideFilters?: FilterTypes[];
}) => {
  return (
    <Filter.Root
      defaultValue="CHANNEL"
      trigger={
        <Button customType="icon" size="small">
          <img src={FunnelIcon} alt="filter" />
        </Button>
      }
    >
      <Filter.Portal>
        <Filter.List>
          <FilterListItem hideFilters={hideFilters} value="CHANNEL">
            Channel
          </FilterListItem>
          <FilterListItem hideFilters={hideFilters} value="STATUS">
            Status
          </FilterListItem>
          <FilterListItem hideFilters={hideFilters} value="COMPANY">
            Company
          </FilterListItem>
          <FilterListItem hideFilters={hideFilters} value="AGENT">
            Agent
          </FilterListItem>
          <FilterListItem hideFilters={hideFilters} value="LAST_MESSAGE_DATE">
            Last message date
          </FilterListItem>
          <FilterListItem hideFilters={hideFilters} value="OTHERS">
            Others
          </FilterListItem>
        </Filter.List>
        <Filter.Body block value="CHANNEL">
          <ChannelList updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body block value="STATUS">
          <StatusList updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body block value="COMPANY">
          <CompanyList updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body block value="AGENT">
          <AgentList updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body block value="LAST_MESSAGE_DATE">
          <DateFilter values={values} updateFilter={updateFilter} />
        </Filter.Body>
        <Filter.Body block value="OTHERS">
          <Radio.Root
            onValueChange={(value: FilterValues["OTHERS"]) => {
              updateFilter("OTHERS", value);
              toggleSortByUnread();
            }}
            value={values.OTHERS}
          >
            <Radio.Content>
              <Radio.Item value="ALL_CHAT">All chat</Radio.Item>
              <Radio.Item value="UNREAD_CHAT">Unread chat</Radio.Item>
            </Radio.Content>
          </Radio.Root>
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
