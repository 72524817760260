import { Button } from "components/DesignSystem/Button/Button";
import { Notes } from "components/DesignSystem/Notes/Notes";
import { NotePencil } from "components/icons/NotePencil";
import { useModal } from "hooks/useModal";
import { ChatTicket } from "types/Models/chatTicket";

export const TicketNotes = ({ rowData }: { rowData: ChatTicket }) => {
  const notesModal = useModal();
  const ticketCount = rowData.internal_notes_count;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="t-flex t-gap-1 t-items-center">
        <Button customType="transparent" onClick={notesModal.open}>
          <NotePencil />
        </Button>
        {ticketCount > 0 && (
          <span className="t-text-subtext t-text-text-60">{ticketCount}</span>
        )}
      </div>
      {notesModal.isOpen && (
        <Notes
          isOpen={notesModal.isOpen}
          close={notesModal.close}
          objId={rowData.uuid}
          commentType="TICKET_COMMENT"
          entityId={rowData.entity?.uuid || ""}
          isPrivate
        />
      )}
    </div>
  );
};
