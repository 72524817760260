import { Button } from "components/DesignSystem/Button/Button";
import { useAppSelector } from "hooks/useAppSelector";
import { useToast } from "hooks/useToast";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import BooksPreOnboarding from "static/images/BooksPreOnboarding.svg";
import {
  useCreateProductOnboardingMutation,
  useGetOnboardingProductsQuery,
} from "store/apis/productOnboarding";
import { openOnboardingWidget } from "store/slices/onboardingWidget";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { BackendError } from "types/utils/error";
import { OnboardingHomeCard } from "components/OnboardingHomeCard/OnboardingHomeCard";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { LargeCard } from "components/home/Home";
import { useQuery, useUpdateQuery } from "hooks/useQuery";

export const BooksOnboarding = () => {
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast } = useToast();
  const currentGroup = useCurrentGroupContext();
  const query = useQuery();
  const isBooksOnboarding = query.get("books_onboarding");
  const { update } = useUpdateQuery();

  const [createBooksOnboarding, { isLoading }] =
    useCreateProductOnboardingMutation();

  const { data: products } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const platformOnboarding = products?.find(
    (p) => p.name === PRODUCT_ONBOARDING.BOOKS
  );

  const isOnboardingNotStarted = platformOnboarding?.status === "NOT_STARTED";

  const openBooksOnboarding = async () => {
    try {
      await createBooksOnboarding({
        groupId: currentGroup.uuid,
        productId: import.meta.env.VITE_APP_BOOKS_ONBOARDING_PRODUCT_ID!,
      }).unwrap();
      dispatch(openOnboardingWidget(PRODUCT_ONBOARDING.BOOKS));
    } catch (error: any) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  useEffect(() => {
    if (isBooksOnboarding) {
      dispatch(openOnboardingWidget(PRODUCT_ONBOARDING.BOOKS));
      update({ query: "books_onboarding", value: null });
    }
  }, [isBooksOnboarding]);

  return (
    <>
      {isOnboardingNotStarted ? (
        <div className="t-w-full t-min-h-60 t-bg-books-onboarding t-border t-rounded-lg t-border-purple-10 t-shadow-light-30 t-p-6 t-flex t-justify-between t-pr-[58px]">
          <div className="t-max-w-[540px] t-flex t-flex-col t-h-full t-gap-14">
            <div className="t-flex t-flex-col t-gap-4">
              <h5 className="t-text-h5 t-text-text-100">
                Introducing Inkle Books
              </h5>
              <span className="t-text-body t-text-text-60">
                Sync your banking, revenue and payroll data through native
                integrations, chat-based bookkeeping, rule-based categorisations
                & affordable bookkeeping plans that suit your needs.
              </span>
            </div>
            <div>
              <Button
                customType="primary"
                onClick={openBooksOnboarding}
                isLoading={isLoading}
                disabled={isLoading}
              >
                Get Started
              </Button>
            </div>
          </div>
          <img src={BooksPreOnboarding} alt="BooksPreOnboarding" />
        </div>
      ) : (
        <div className="t-w-[462px]">
          <LargeCard>
            <OnboardingHomeCard
              onClick={openBooksOnboarding}
              progress={platformOnboarding?.progress}
            />
          </LargeCard>
        </div>
      )}
    </>
  );
};
