import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";
import { Filter } from "components/icons/Filter";
import { MentionIcon } from "components/icons/MentionIcon";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ComponentProps, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearch,
  setSearchActiveState,
  setUnreadFilter,
  setShowMentionedMessages,
} from "store/slices/chatSearch";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { RootState } from "store/store";
import { useChatContext } from "stream-chat-react";
import { debounce } from "utils/debouncing";
import { ChannelListFilter } from "./ChannelListFilter";
import { Chip } from "components/DesignSystem/Chips/Chips";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { useChatFilterContext } from "contexts/ChatFilterContext";
import { AnimatePresence, motion } from "framer-motion";

export const ChannelSearch = ({
  hideFilters,
}: Pick<ComponentProps<typeof ChannelListFilter>, "hideFilters">) => {
  const dispatch = useDispatch();
  const isUnreadFilterActive = useSelector(
    (state: RootState) => state.chatSearch.unread
  );
  const { filters: values, setFilter: updateFilter } = useChatFilterContext();
  const { setActiveChannel } = useChatContext();
  const showMentionedMessages = useSelector(
    (state: RootState) => state.chatSearch.showMentionedMessages
  );
  const { isCustomer, isAdmin } = useRoleBasedView();
  const [showAppliedFilters, setShowAppliedFilters] = useState(false);

  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
    dispatch(setScrollToMessageId(undefined));
  });

  const toggleSortByUnread = () => {
    const enablingTheFilter = !isUnreadFilterActive;
    if (enablingTheFilter) {
      setActiveChannel(undefined);
    }
    dispatch(setUnreadFilter(!isUnreadFilterActive));
  };

  const handleMentionFilter = () => {
    dispatch(setShowMentionedMessages(!showMentionedMessages));
  };

  useEffect(() => {
    dispatch(setSearch(""));
    dispatch(setScrollToMessageId(""));
    return () => {
      dispatch(setShowMentionedMessages(false));
    };
  }, []);

  const totalAppliedFilters =
    values.CHANNEL.length +
    values.STATUS.length +
    values.COMPANY.length +
    values.AGENT.length +
    (values.START_DATE && values.END_DATE ? 1 : 0) +
    (values.OTHERS === "UNREAD_CHAT" ? 1 : 0);

  return (
    <div>
      <div className="t-flex t-w-full t-gap-3">
        <Search
          customSize="small"
          onChange={onChange}
          block
          placeholder="Search channel"
          onFocus={() => dispatch(setSearchActiveState(true))}
          onBlur={() => dispatch(setSearchActiveState(false))}
        />
        {isAdmin ? (
          <ChannelListFilter
            updateFilter={updateFilter}
            values={values}
            toggleSortByUnread={toggleSortByUnread}
            hideFilters={hideFilters}
          />
        ) : (
          <div className="t-flex t-gap-2">
            <ToolTip text="Sort by Unread">
              <span>
                <Button
                  size="small"
                  customType="icon"
                  onClick={toggleSortByUnread}
                  active={isUnreadFilterActive}
                >
                  <Filter />
                </Button>
              </span>
            </ToolTip>
          </div>
        )}
        {!isCustomer && (
          <div className="t-text-text-30">
            <Button
              size="small"
              customType={
                showMentionedMessages ? "icon-primary-outlined" : "icon"
              }
              onClick={handleMentionFilter}
              active={showMentionedMessages}
            >
              <MentionIcon />
            </Button>
          </div>
        )}
      </div>
      {isAdmin && (
        <AnimatePresence>
          <div className="t-flex t-gap-2 t-flex-wrap t-mt-2">
            {Boolean(showAppliedFilters) && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="t-flex t-gap-1 t-flex-wrap"
              >
                {values.CHANNEL.length > 0 && (
                  <Chip isActive onClose={() => updateFilter("CHANNEL", [])}>
                    Channel ({values.CHANNEL.length})
                  </Chip>
                )}

                {values.STATUS.length > 0 && (
                  <Chip isActive onClose={() => updateFilter("STATUS", [])}>
                    Status ({values.STATUS.length})
                  </Chip>
                )}

                {values.COMPANY.length > 0 && (
                  <Chip isActive onClose={() => updateFilter("COMPANY", [])}>
                    Company ({values.COMPANY.length})
                  </Chip>
                )}

                {values.AGENT.length > 0 && (
                  <Chip isActive onClose={() => updateFilter("AGENT", [])}>
                    Agent ({values.AGENT.length})
                  </Chip>
                )}

                {values.START_DATE && values.END_DATE && (
                  <Chip
                    isActive
                    onClose={() => {
                      updateFilter("START_DATE", "");
                      updateFilter("END_DATE", "");
                    }}
                  >
                    {dayjs(values.START_DATE).format(DD_MMM_YYYY)} to{" "}
                    {dayjs(values.END_DATE).format(DD_MMM_YYYY)}
                  </Chip>
                )}

                {values.OTHERS === "UNREAD_CHAT" && (
                  <Chip
                    isActive
                    onClose={() => {
                      updateFilter("OTHERS", "ALL_CHAT");
                      toggleSortByUnread();
                    }}
                  >
                    Unread
                  </Chip>
                )}
              </motion.div>
            )}

            {Boolean(totalAppliedFilters) && (
              <Button
                size="small"
                customType="link"
                onClick={() => setShowAppliedFilters((p) => !p)}
              >
                {showAppliedFilters
                  ? "Hide filters"
                  : `View filters (${totalAppliedFilters})`}
              </Button>
            )}
          </div>
        </AnimatePresence>
      )}
    </div>
  );
};
