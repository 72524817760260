import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFile from "static/images/UploadFile.svg";
import { useUploadMerchantCSVMutation } from "store/apis/vendors";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { AddManualVendors } from "./AddManualVendors";

type AddMechantProps = ModalProps & {
  isOpen: boolean;
  groupId: string;
  closeModal: () => void;
  season: string;
};

export const AddMerchant = ({
  isOpen,
  groupId,
  closeModal,
  season,
}: AddMechantProps) => {
  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const addVendorsManually = useModal();
  const [useCSV, setUseCSV] = useState<boolean>(false);

  const [uploadVendorsCSV, { isLoading: isUploading, data: uploadedCSVData }] =
    useUploadMerchantCSVMutation();

  const onDropRejected = () => {
    alertToast({ message: "Please upload a CSV file exclusively." });
  };

  const onDrop = async (files: File[]) => {
    setUseCSV(true);
    try {
      await uploadVendorsCSV({ entityId, csv: files[0] }).unwrap();
      addVendorsManually.open();
      closeModal();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop,
      maxSize: 25000000,
      accept: { "text/csv": [".csv"] },
      onDropRejected,
      multiple: false,
    });

  const openAddManualVendors = () => {
    setUseCSV(false);
    closeModal();
    addVendorsManually.open();
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={closeModal}>
        <Modal.Content size="large">
          <Modal.Header>
            <Modal.Title>Add Vendors</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-pb-10 t-flex t-gap-6 t-flex-col">
            <div className="t-flex t-flex-col t-cursor-pointer">
              <Label htmlFor="csv">
                Upload the vendors exported in{" "}
                <ConditionalLink
                  to="https://inkle-django-files.s3.ap-south-1.amazonaws.com/sample_vendor_upload_csv.csv"
                  className="t-text-purple t-mr-1 hover:!t-underline"
                >
                  .csv
                </ConditionalLink>
                format
              </Label>
              <div
                className={classNames(
                  "t-border-2 t-rounded-lg t-border-dashed t-border-neutral-20 t-px-6 t-py-8 t-text-body t-text-text-100 t-flex t-items-center t-justify-center t-flex-col t-gap-3 t-bg-surface-lighter-grey",
                  {
                    "!t-border-green !t-text-green-70": isDragAccept,
                    "!t-border-red-50 !t-text-red-50": isDragReject,
                  }
                )}
                {...getRootProps()}
                onClick={open}
              >
                <input {...getInputProps()} id="csv" />
                {isUploading ? (
                  <Loader />
                ) : (
                  <>
                    <img src={UploadFile} alt="Upload File" />
                    Click or drag and drop to upload.
                  </>
                )}
              </div>
            </div>
            <Divider>Or</Divider>
            <Button onClick={openAddManualVendors} size="large">
              Add vendors manually
            </Button>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
      {addVendorsManually.isOpen && (
        <AddManualVendors
          show={addVendorsManually.isOpen}
          closeModal={addVendorsManually.close}
          season={season}
          useCSV={useCSV}
          csvId={uploadedCSVData?.uuid}
        />
      )}
    </>
  );
};
