import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { Form, FormikValues } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useUpdateAutopayCreditsMutation } from "store/apis/refrral";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const AutoPayCreditModal = ({ isOpen, close }: ModalProps) => {
  const { successToast, alertToast } = useToast();
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const query = useQuery();
  const entityId = query.get("entity") || "";
  const currentEntity = entities.find(({ uuid }) => entityId === uuid);
  const defaultValue = currentEntity?.autopay_credits ? "YES" : "NO";

  const [updateCreditsAutopay, { isLoading: isUpdatingCreditAutopay }] =
    useUpdateAutopayCreditsMutation();

  const onSubmit = async (values: FormikValues) => {
    try {
      const autopayCredits = values.creditsAutopay === "YES" ? true : false;
      await updateCreditsAutopay({
        groupId,
        entityId,
        autopayCredits,
      }).unwrap();
      close();
      successToast({ message: "Settings updated" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };
  return (
    <Modal.FormikRoot
      initialValues={{
        creditsAutopay: defaultValue,
      }}
      onSubmit={onSubmit}
      open={isOpen}
      onOpenChange={close}
      enableReinitialize
    >
      {({ setFieldValue, submitForm }) => (
        <Modal.Content asChild>
          <Form>
            <Modal.Header>
              <Modal.Title>Manage Autopay</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-flex t-flex-col t-gap-5">
              <div className="t-text-subtitle">
                Inkle credits will be automatically used to pay for any fillings
                added by you or our support team
              </div>
              <RadioGroup.Root
                onValueChange={(value) => {
                  setFieldValue("creditsAutopay", value);
                }}
                className="t-flex t-flex-col t-gap-3 t-text-body"
                defaultValue={defaultValue}
              >
                <RadioGroup.Item value="YES">
                  Yes, enable Auto Pay
                </RadioGroup.Item>
                <RadioGroup.Item value="NO">
                  No, I will pay manually everytime
                </RadioGroup.Item>
              </RadioGroup.Root>
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                isLoading={isUpdatingCreditAutopay}
                disabled={isUpdatingCreditAutopay}
                onClick={submitForm}
              >
                Save
              </Button>
            </Modal.FooterButtonGroup>
          </Form>
        </Modal.Content>
      )}
    </Modal.FormikRoot>
  );
};
