import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransactionState {
  showAddModal: boolean;
  merchantsToMerge: string[];
  showEditModal: boolean;
}

const initialState: TransactionState = {
  showAddModal: false,
  merchantsToMerge: [],
  showEditModal: false,
};

export const vendors = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    openAddVendorModal: (state) => {
      state.showAddModal = true;
    },
    closeAddModal: (state) => {
      state.showAddModal = false;
    },
    setMerchantToMerge: (state, action: PayloadAction<string[]>) => {
      state.merchantsToMerge = action.payload;
    },
    openEditVendorModal: (state) => {
      state.showEditModal = true;
    },
    closeEditVendorModal: (state) => {
      state.showEditModal = false;
    },
  },
});

export const {
  openAddVendorModal,
  closeAddModal,
  setMerchantToMerge,
  openEditVendorModal,
  closeEditVendorModal,
} = vendors.actions;

export default vendors.reducer;
