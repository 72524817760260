import { Button } from "components/DesignSystem/Button/Button";
import { useModal } from "hooks/useModal";
import React from "react";
import { Form1099Fields } from "./Form1099Fields";

export const Form1099 = ({
  wFormMapId,
  form1099Type,
  season,
  isInprogress,
  entityId,
}: {
  wFormMapId: string;
  form1099Type: string;
  season: string;
  isInprogress?: boolean;
  entityId: string;
}) => {
  const form1099TemplateModal = useModal();

  return (
    <div>
      {isInprogress ? (
        <Button onClick={form1099TemplateModal.open} size="small">
          In Progress
        </Button>
      ) : (
        <Button
          customType="primary"
          onClick={form1099TemplateModal.open}
          size="small"
        >
          Start Filing
        </Button>
      )}
      {form1099TemplateModal.isOpen && (
        <Form1099Fields
          {...form1099TemplateModal}
          wFormMapId={wFormMapId}
          form1099Type={form1099Type}
          season={season}
          isInprogress={isInprogress}
          entityId={entityId}
        />
      )}
    </div>
  );
};
