import DashboardContainer from "components/dashboard/DashboardContainer";
import { TableUI } from "components/design/TableUI";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";

import ThreeDotsIcon from "../../static/images/ThreeDots.svg";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Button } from "components/DesignSystem/Button/Button";
import {
  ChangeEvent,
  forwardRef,
  Ref,
  useCallback,
  useMemo,
  useState,
} from "react";
import Modal from "components/DesignSystem/Modal/Modal";
import { SetReminder } from "components/SetReminder/SetReminder";
import { FormikForm } from "components/FormikForm/FormikForm";
import { setSearch } from "store/slices/chatSearch";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { Search } from "components/DesignSystem/Search/Search";
import { usePagination } from "hooks/usePagination";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import {
  ReminderSetting,
  ReminderSettingPayload,
  useGetAllRemindersQuery,
  useUpdateReminderSettingMutation,
  useGetUpcomingRemindersQuery,
  useUpdateReminderMutation,
  Reminder,
  ReminderPayload,
} from "store/apis/reminders";
import { SolidCheck } from "components/icons/SolidCheck";
import { SolidCross } from "components/icons/SolidCross";
import {
  EVERY_3_DAYS,
  EVERY_5_DAYS,
  EVERY_7_DAYS,
} from "constants/reminderFrequencies";
import { ONE_TIME } from "constants/billing";
import { Tag } from "components/DesignSystem/Tag/Tag";
import Toggle from "components/design/toggle";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { formatDate } from "utils/formatDate";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { useGetAllChannelMemberQuery } from "store/apis/chat";
import { Field, FieldProps } from "formik";
import { reminderSettingValidation } from "formValidations/reminderSettingValidation";
import { upcomingReminderValidation } from "formValidations/upcomingReminderValidation";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { EmptyCart } from "components/TPPayments/Illustration/EmptyCart";
import { produce } from "immer";
import { Link } from "components/DesignSystem/Link/Link";

const createColumn = createColumnHelper<ReminderSetting>();
const createReminderColumn = createColumnHelper<Reminder>();

const FREQUENCY_MAP = {
  [EVERY_3_DAYS]: "Every 3 days",
  [EVERY_5_DAYS]: "Every 5 days",
  [EVERY_7_DAYS]: "Every 7 days",
  [ONE_TIME]: "Send once",
};

const EditUpcomingReminder = forwardRef(
  (
    {
      reminder,
      onClose,
      onSubmit,
      isLoading,
      ...rest
    }: {
      reminder: Reminder;
      onClose: () => void;
      isLoading: boolean;
      onSubmit: (
        values: ReminderPayload["reminder_notification_details"]
      ) => void;
    },
    ref: Ref<HTMLFormElement>
  ) => {
    const channelId = reminder.reminder_setting.content_details.channel_id;
    const groupId = reminder.reminder_setting.company_group.uuid;

    const { data: members, isLoading: isMembersLoading } =
      useGetAllChannelMemberQuery(
        {
          groupId: groupId,
          channelId: channelId,
        },
        {
          skip: !channelId || !groupId,
        }
      );

    const initialValues = {
      message_text: reminder.reminder.message_text,
      skip_message: reminder.reminder.status === "SKIPPED",
      notification_channels: reminder.reminder_setting.notification_channel,
      subscribers: reminder.reminder_setting.subscribed_profiles.map(
        (s) => s.uuid
      ),
    };

    const memberOptions = members
      ?.filter(({ mobile }) => Boolean(mobile))
      .map(({ mobile, name, profile_id }) => ({
        label: name!,
        value: profile_id,
      }));

    return (
      <FormikForm
        ref={ref}
        initialValues={initialValues}
        // @ts-ignore
        onSubmit={onSubmit}
        validationSchema={upcomingReminderValidation}
        {...rest}
      >
        {({ values: valuesUntyped, setFieldValue }) => {
          const values = valuesUntyped as typeof initialValues;

          const selectedMembers =
            values.subscribers.length > 0 &&
            memberOptions?.filter(({ value }) =>
              values.subscribers.includes(value)
            );

          return (
            <>
              <Modal.Header>
                <Modal.Title>Edit Reminder</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-4">
                  <TextArea name="message_text" label="Message" />
                  <Field name="skip_message" type="checkbox">
                    {({ field }: FieldProps) => (
                      <Checkbox
                        {...field}
                        label="Skip this reminder"
                        onChange={field.onChange}
                      />
                    )}
                  </Field>
                  <div className="t-flex t-flex-col t-gap-3">
                    <label>Reminder channels</label>

                    <Checkbox
                      checked={values.notification_channels.includes("CHAT")}
                      disabled
                      label="Chat"
                    />

                    <Checkbox
                      name="SEND_TO_WHATSAPP"
                      onChange={(e) => {
                        const channels = new Set(values.notification_channels);

                        if (e.target.checked) {
                          channels.add("WHATSAPP");
                          return setFieldValue(
                            "notification_channels",
                            Array.from(channels)
                          );
                        }

                        channels.delete("WHATSAPP");
                        return setFieldValue(
                          "notification_channels",
                          Array.from(channels)
                        );
                      }}
                      checked={values.notification_channels.includes(
                        "WHATSAPP"
                      )}
                      label="WhatApp"
                    />
                  </div>

                  {values.notification_channels.includes("WHATSAPP") && (
                    <Combobox
                      withForm
                      isLoading={isMembersLoading}
                      isMulti
                      name="subscribers"
                      label="Send to"
                      options={memberOptions}
                      value={selectedMembers || null}
                      menuPortalTarget={document.body}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  customType="primary"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </>
          );
        }}
      </FormikForm>
    );
  }
);

const UpcomingReminders = () => {
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(
    null
  );
  const { data, isLoading, isSuccess } = useGetUpcomingRemindersQuery();
  const [updateReminder, { isLoading: isUpdating }] =
    useUpdateReminderMutation();

  const columns = useMemo(
    () => [
      createReminderColumn.accessor("reminder.message_text", {
        header: "Message",
        size: 30,
        cell: (cell) => <div className="t-truncate">{cell.getValue()}</div>,
      }),
      createReminderColumn.accessor("reminder.recurring_date", {
        header: "Remind on",
        size: 15,
        cell: (cell) => <div>{formatDate(cell.getValue())}</div>,
      }),
      createReminderColumn.accessor("reminder_setting.subscribed_profiles", {
        header: "Send To",
        size: 15,
        cell: (cell) => (
          <div className="t-flex t-gap-1 t-flex-wrap">
            {cell.getValue().map((p) => (
              <Tag tagType="gray" icon={false} key={p.uuid} size="small">
                {p.name}
              </Tag>
            ))}
          </div>
        ),
      }),
      createReminderColumn.accessor("reminder_setting.notification_channel", {
        header: "Channels",
        size: 20,
        cell: (cell) => (
          <div className="t-flex t-gap-2 t-flex-wrap">
            {cell.getValue().map((channel: string) => (
              <Tag key={channel} tagType="gray" icon={false} size="small">
                {channel}
              </Tag>
            ))}
          </div>
        ),
      }),
      createReminderColumn.accessor("reminder.status", {
        header: "Status",
        size: 10,
        cell: (cell) => <div>{cell.getValue()}</div>,
      }),
      createReminderColumn.accessor("reminder.uuid", {
        id: "actions",
        header: "Actions",
        size: 10,
        cell: (info) => (
          <div>
            <Dropdown.Root>
              <Dropdown.Trigger asChild>
                <Button customType="ghost_icon" size="small">
                  <img src={ThreeDotsIcon} alt="Three dots" />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  onSelect={() => setSelectedReminder(info.row.original)}
                >
                  Edit
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Root>
          </div>
        ),
      }),
    ],
    []
  );

  const handleUpdateReminder = async (
    values: ReminderPayload["reminder_notification_details"]
  ) => {
    if (!selectedReminder) return;

    try {
      await updateReminder({
        groupId: selectedReminder.reminder_setting.company_group.uuid,
        reminderId: selectedReminder.reminder.uuid,
        payload: {
          reminder_notification_details: values,
        },
      }).unwrap();
      setSelectedReminder(null);
    } catch (error) {
      console.error("Failed to update reminder:", error);
    }
  };

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
    data: data?.reminders || [],
  });

  return (
    <Async.Root
      isLoading={isLoading}
      isEmpty={data?.reminders.length === 0}
      isSuccess={isSuccess}
    >
      <Async.Empty>
        <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
          <EmptyCart />
        </div>
      </Async.Empty>
      <Async.Success>
        <div className="t-mt-4">
          <TableUI layout="fixed" table={table} />
          <Modal.Root
            open={selectedReminder !== null}
            onOpenChange={() => setSelectedReminder(null)}
          >
            <Modal.Content asChild>
              {selectedReminder && (
                <EditUpcomingReminder
                  reminder={selectedReminder}
                  onClose={() => setSelectedReminder(null)}
                  onSubmit={handleUpdateReminder}
                  isLoading={isUpdating}
                />
              )}
            </Modal.Content>
          </Modal.Root>
        </div>
      </Async.Success>
    </Async.Root>
  );
};

const AllReminders = () => {
  const [editReminder, setEditReminder] = useState<string | null>(null);
  const query = useQuery();
  const { update } = useUpdateQuery();
  const search = query.get("search");

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    update({
      query: "search",
      value: e.target.value,
    });
  };

  const { goToFirstPage, goToPrevPage, goToNextPage, goToLastPage, pageNum } =
    usePagination();

  const { data, isLoading, isSuccess } = useGetAllRemindersQuery({
    page_num: pageNum,
  });

  const currentReminder = data?.reminders?.find(
    (d) => d.reminder_setting_id === editReminder
  );

  const [updateReminderSetting, { isLoading: isUpdating }] =
    useUpdateReminderSettingMutation();

  const onSubmit = async (values: ReminderSettingPayload) => {
    try {
      if (!currentReminder) return;

      await updateReminderSetting({
        groupId: currentReminder.company_group.uuid,
        reminderId: currentReminder.reminder_setting_id,
        payload: values,
      }).unwrap();

      setEditReminder(null);
    } catch (error) {
      console.error("Failed to update reminder:", error);
    }
  };

  const initialValue: ReminderSettingPayload | {} = currentReminder
    ? {
        uuid: currentReminder?.reminder_setting_id || "",
        reminder_details: {
          frequency: currentReminder?.frequency,
          is_reminder_enabled: currentReminder?.is_reminder_enabled || false,
          notification_channels: currentReminder?.notification_channel || [],
          on_date: currentReminder.on_date,
          subscribers:
            currentReminder?.subscribed_profiles.map(
              (profile) => profile.uuid
            ) || [],
          group_id: currentReminder?.company_group.uuid || "",
        },
        content_details: {
          message_id: currentReminder?.content_details.message_id || "",
          message_text: currentReminder?.content_details.message_text || "",
          content_type: currentReminder?.content_details.content_type || "",
          channel_id: currentReminder?.content_details.channel_id || "",
        },
      }
    : {};

  const toggleReminder = useCallback(
    async (reminderId: string) => {
      const editingReminder = data?.reminders?.find(
        (d) => d.reminder_setting_id === reminderId
      );

      if (!editingReminder) return;

      const values: ReminderSettingPayload = {
        reminder_details: {
          frequency: editingReminder?.frequency,
          is_reminder_enabled: editingReminder?.is_reminder_enabled || false,
          notification_channels: editingReminder?.notification_channel || [],
          on_date: editingReminder.on_date,
          subscribers:
            editingReminder?.subscribed_profiles.map(
              (profile) => profile.uuid
            ) || [],
          group_id: editingReminder?.company_group.uuid || "",
        },
        content_details: {
          message_id: editingReminder?.content_details.message_id || "",
          message_text: editingReminder?.content_details.message_text || "",
          content_type: editingReminder?.content_details.content_type || "",
          channel_id: editingReminder?.content_details.channel_id || "",
        },
      };

      try {
        if (!editingReminder) return;

        const payload = produce(values as ReminderSettingPayload, (draft) => {
          draft.reminder_details.is_reminder_enabled =
            !draft.reminder_details.is_reminder_enabled;
        });

        await updateReminderSetting({
          groupId: editingReminder.company_group.uuid,
          reminderId: editingReminder.reminder_setting_id,
          payload,
        }).unwrap();

        setEditReminder(null);
      } catch (error) {
        console.error("Failed to update reminder:", error);
      }
    },
    [data?.reminders, updateReminderSetting]
  );

  const columns = useMemo(
    () => [
      createColumn.accessor("content_details.message_text", {
        header: "Message",
        size: 20,
        cell: (cell) => {
          const messageId = cell.row.original.content_details.message_id;
          const channelId = cell.row.original.content_details.channel_id;

          const link = `/admin/chat/${channelId}/${messageId}`;

          return (
            <Link to={link} className="t-break-all">
              {cell.getValue()}
            </Link>
          );
        },
      }),

      createColumn.accessor("company_group.uuid", {
        header: "Message",
        size: 10,
        cell: (cell) => {
          const link = `/admin/crm/${cell.getValue()}`;

          return (
            <Link to={link} className="t-break-all">
              {cell.row.original.company_group.name}
            </Link>
          );
        },
      }),

      createColumn.accessor("frequency", {
        header: "Frequency",
        size: 15,
        cell: (cell) => (
          <span className="t-flex t-flex-col t-gap-1 t-text-text-60">
            <span>{FREQUENCY_MAP[cell.getValue()]}</span>
            {cell.getValue() === "ONE_TIME" ? (
              <span className="t-text-text-30 t-text-body-sm">
                {formatDate(cell.row.original.on_date)}
              </span>
            ) : (
              <span className="t-text-text-30 t-text-body-sm">
                From {formatDate(cell.row.original.created_at)}
              </span>
            )}
          </span>
        ),
      }),

      createColumn.accessor("subscribed_profiles", {
        header: "Send To",
        size: 15,
        cell: (cell) => (
          <div className="t-flex t-gap-1 t-flex-wrap">
            {cell.getValue().map((p) => (
              <Tag tagType="gray" icon={false} key={p.uuid}>
                {p.name}
              </Tag>
            ))}
            {cell.getValue().length === 0 && "-"}
          </div>
        ),
      }),

      createColumn.accessor("notification_channel", {
        header: "Channels",
        size: 20,
        cell: (cell) => (
          <div className="t-flex t-gap-2 t-flex-wrap">
            {cell.getValue().map((channel: string) => (
              <Tag key={channel} tagType="gray" icon={false} size="small">
                {channel}
              </Tag>
            ))}
          </div>
        ),
      }),

      createColumn.accessor("is_reminder_enabled", {
        header: "Status",
        size: 10,
        cell: (cell) => (
          <div className="t-flex t-gap-2 t-flex-wrap">
            {cell.getValue() ? (
              <Tag tagType="green">Active</Tag>
            ) : (
              <Tag tagType="light_grey">Stopped</Tag>
            )}
          </div>
        ),
      }),

      createColumn.accessor("reminder_setting_id", {
        id: "Action",
        header: () => null,
        size: 10,
        cell: (info) => (
          <div>
            <Dropdown.Root>
              <Dropdown.Trigger asChild>
                <Button customType="ghost_icon" size="small">
                  <img src={ThreeDotsIcon} alt="Three dots" />
                </Button>
              </Dropdown.Trigger>
              <Dropdown.Content align="end">
                <Dropdown.Item
                  onSelect={() => setEditReminder(info.getValue())}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  onSelect={() =>
                    // @ts-ignore
                    info.table.options.meta?.toggleReminder?.(
                      info.row.original.reminder_setting_id
                    )
                  }
                >
                  {info.row.original.is_reminder_enabled
                    ? "Stop Reminder"
                    : "Enable Reminder"}
                </Dropdown.Item>
              </Dropdown.Content>
            </Dropdown.Root>
          </div>
        ),
      }),
    ],
    []
  );
  const {
    reminders = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 25,
  } = data || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
    data: reminders,
    meta: { toggleReminder },
  });

  return (
    <Async.Root
      isLoading={isLoading}
      isEmpty={reminders.length === 0}
      isSuccess={isSuccess}
    >
      <Async.Empty>
        <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
          <EmptyCart />
        </div>
      </Async.Empty>
      <Async.Success>
        <DashboardContainer.Header>
          <div className="t-flex t-flex-col t-gap-3 t-mb-5">
            <div className="t-flex t-gap-4 t-items-center t-justify-between">
              <div className="t-w-1/2">
                {/* <Search onChange={onSearch} placeholder="Search" block /> */}
              </div>
              <Pagination
                {...paginationData}
                goToFirstPage={goToFirstPage}
                goToPrevPage={goToPrevPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
              />
            </div>
          </div>
        </DashboardContainer.Header>
        <TableUI table={table} layout="fixed" />
        <Modal.Root
          open={editReminder !== null}
          onOpenChange={() => setEditReminder(null)}
        >
          <Modal.Content asChild useCustomOverlay>
            <FormikForm
              initialValues={initialValue}
              // @ts-ignore
              onSubmit={onSubmit}
              validationSchema={reminderSettingValidation}
            >
              {currentReminder && (
                <SetReminder
                  channelId={currentReminder?.content_details.channel_id}
                  isLoading={isUpdating}
                  groupId={currentReminder?.company_group.uuid}
                />
              )}
            </FormikForm>
          </Modal.Content>
        </Modal.Root>
      </Async.Success>
    </Async.Root>
  );
};

export const Reminders = () => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const isReminderSetting = pathname.includes("/reminder-settings");

  return (
    <div className=" t-p-5">
      <ToggleGroup value={isReminderSetting ? "REMINDERS" : "UPCOMING"}>
        <ToggleGroupItem value="UPCOMING" onClick={() => history.push(url)}>
          Upcoming
        </ToggleGroupItem>
        <ToggleGroupItem
          value="REMINDERS"
          onClick={() => history.push(`${url}/reminder-settings`)}
        >
          Reminders
        </ToggleGroupItem>
      </ToggleGroup>
      <Switch>
        <Route path={`${url}/reminder-settings`}>
          <AllReminders />
        </Route>
        <Route path={url}>
          <UpcomingReminders />
        </Route>
      </Switch>
    </div>
  );
};
