import { useMemo } from "react";
import { useAppSelector } from "./useAppSelector";
import { useCurrentGroupContext } from "./useCurrentGroupContext";
import { useCurrentEntityId } from "./useCurrentEntityId";
import { TxnAccountType } from "types/Models/books";

export const useTransactionsFilter = ({
  pageNum,
  searchTerm,
  sortCol = "DATE",
  sortedToDec = true,
  transactionIds = "",
  messageId = "",
  txnAccountType = "EXCLUDE_STRIPE",
  pageSize = null,
}: {
  pageNum: number;
  searchTerm?: string | null;
  sortCol?: "AMOUNT" | "DATE" | "VENDOR";
  sortedToDec?: boolean;
  transactionIds?: string;
  messageId?: string;
  txnAccountType?: TxnAccountType;
  pageSize?: string | null;
}) => {
  const {
    filters: {
      uncategorised,
      minAmount,
      maxAmount,
      excludedTransactions,
      ...restFilter
    },
  } = useAppSelector((state) => state.transactionFilter);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  return useMemo(
    () => ({
      groupId,
      entityId,
      page_num: pageNum,
      searchTerm,
      sortCol: sortCol,
      txnAccountType,
      sortOrder: sortedToDec ? ("DSC" as const) : ("ASC" as const),
      transactionIds,
      messageId,
      uncategorized: uncategorised,
      minAmount: ((minAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      maxAmount: ((maxAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      pageSize,
      txnStatus: excludedTransactions?.value
        ? ("EXCLUDED" as const)
        : undefined,
      ...restFilter,
    }),
    [
      entityId,
      groupId,
      maxAmount?.value,
      minAmount?.value,
      pageNum,
      pageSize,
      transactionIds,
      JSON.stringify(restFilter),
      searchTerm,
      sortCol,
      sortedToDec,
      uncategorised,
      txnAccountType,
      messageId,
      excludedTransactions,
    ]
  );
};
