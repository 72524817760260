import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import Slider from "components/DesignSystem/Slider/Slider";
import { SliderAccordion } from "components/DesignSystem/SliderAccordion/SliderAccordion";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { coaOptions } from "components/Transaction/TransactionColumn";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { itemTypesOptions } from "constants/productAndServices";
import {
  PRODUCT_SERVICE_ITEM_TYPE,
  PRODUCT_SERVICE_SOURCE_TYPE,
} from "dictionaries";
import { addProductAndServiceSchema } from "formValidations/productAndServiceSchema";
import { Form, Formik, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetProductAndServiceByIdQuery,
  useGetProductAndServicesQuery,
  useGetProductCategoriesQuery,
  useUpdateProductAndServiceMutation,
} from "store/apis/productAndServices";
import { ProductAndServices } from "types/Models/productAndServices";
import { BackendError } from "types/utils/error";
import { MyProducts as ProductAndServicesIcon } from "components/icons/LeftNav/Books/MyProducts";

const AnimationWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <AnimatePresence>
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.2,
        }}
        className="t-flex t-flex-col t-gap-4"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

const EditForm = ({ isStripeProduct }: { isStripeProduct: boolean }) => {
  const { data: categories = [], isLoading } = useGetProductCategoriesQuery();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: productAndService } = useGetProductAndServicesQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !entityId || !groupId,
    }
  );
  const { revenue_category_id } = productAndService || {};

  const { chartOfAccounts, ...coaAPIStates } = useChartOfAccounts({
    root_category_id: revenue_category_id,
  });

  const { values } = useFormikContext<{
    name: string;
    type: "PRODUCT" | "SERVICE";
    product_category: string;
    price: number;
    transaction_category_id: string;
    transaction_category_name: string;
  }>();

  const {
    type,
    transaction_category_id,
    product_category,
    transaction_category_name,
  } = values;

  const defaultType = itemTypesOptions.find((item) => item.value === type);

  const categoriesOptions = categories.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const defaultCategory = categoriesOptions.find(
    ({ value }) => value === product_category
  );

  return (
    <AnimationWrapper>
      <div className="t-flex t-gap-4 t-flex-col">
        <TextInput
          required
          name="name"
          placeholder="Enter product name"
          label="Name"
          disabled={isStripeProduct}
        />
        <div className="t-grid t-grid-flow-col t-grid-cols-2 t-gap-4">
          <Combobox
            required
            label="Type"
            options={itemTypesOptions}
            name="type"
            withForm
            placeholder="Select type"
            defaultValue={defaultType || null}
            isClearable={false}
            isDisabled={isStripeProduct}
          />
          <PriceInput
            required
            label="Price"
            name="price"
            disabled={isStripeProduct}
          />
        </div>
        <Combobox
          required
          label="Group"
          isDisabled={isLoading}
          isLoading={isLoading}
          options={categoriesOptions}
          name="product_category"
          creatable
          withForm
          placeholder="Select or create group"
          defaultValue={defaultCategory || null}
          isClearable={false}
          menuPortalTarget={document.body}
        />
        <Combobox
          label="Income Category"
          menuPlacement="top"
          isLoading={coaAPIStates.isLoading}
          isDisabled={coaAPIStates.isLoading}
          placeholder="Select income category"
          menuPortalTarget={document.body}
          withForm
          name="transaction_category_id"
          options={coaOptions(chartOfAccounts)}
          filterOption={(v, i) =>
            v.data.data?.toLocaleLowerCase().includes(i.toLocaleLowerCase()) ||
            false
          }
          isClearable={false}
          defaultValue={
            transaction_category_id
              ? {
                  label: transaction_category_name,
                  value: transaction_category_id,
                }
              : null
          }
        />
      </div>
    </AnimationWrapper>
  );
};

const DataConsole = ({
  productOrService,
}: {
  productOrService: ProductAndServices;
}) => {
  const {
    name,
    price,
    product_category_name,
    transaction_category_name,
    type,
  } = productOrService;

  return (
    <AnimationWrapper>
      <InfoItem label="Name">{name}</InfoItem>
      <div className="t-grid t-grid-flow-col t-grid-cols-2 t-gap-4">
        <InfoItem label="Type">{PRODUCT_SERVICE_ITEM_TYPE[type]}</InfoItem>
        <InfoItem label="Price">
          <AmountSuperScript amount={price} />
        </InfoItem>
      </div>
      <InfoItem label="Group">{product_category_name || "-"}</InfoItem>
      <InfoItem label="Income Category">
        {transaction_category_name || "-"}
      </InfoItem>
    </AnimationWrapper>
  );
};

export const ProductOrServiceSlider = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { productOrserviceId } = useParams<{ productOrserviceId: string }>();
  const { alertToast, successToast } = useToast();
  const [edit, setEdit] = useState(false);
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const {
    data: productOrService,
    isSuccess,
    isLoading,
  } = useGetProductAndServiceByIdQuery(
    {
      productId: productOrserviceId,
      entityId,
      groupId,
    },
    {
      skip: !productOrserviceId || !entityId || !groupId,
    }
  );

  const {
    name = "",
    price = 0,
    product_category_id = "",
    transaction_category_id = "",
    transaction_category_name = "",
    type = "PRODUCT",
    source,
    logo_url,
  } = productOrService || {};

  const isStripeProduct = source === "STRIPE";

  const [updateProductAndService, { isLoading: isUpdating }] =
    useUpdateProductAndServiceMutation();

  const onSubmit = async (value: {
    name: string;
    type: "PRODUCT" | "SERVICE";
    product_category: string;
    price: number;
    transaction_category_id: string;
    transaction_category_name: string;
  }) => {
    try {
      const { type } = await updateProductAndService({
        groupId,
        entityId,
        productId: productOrserviceId,
        payload: {
          name: value.name,
          price: value.price,
          product_category: value.product_category,
          transaction_category_id: value.transaction_category_id,
          type: value.type,
        },
      }).unwrap();
      successToast({ message: `${PRODUCT_SERVICE_ITEM_TYPE[type]} updated!` });
      setEdit(false);
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as {}
      );
    }
  };

  return (
    <Slider.Root open={true} onOpenChange={onClose}>
      <Slider.Content open={true} useCustomOverlay>
        <Formik
          initialValues={{
            name,
            type,
            product_category: product_category_id,
            price: price || 0,
            transaction_category_id,
            transaction_category_name,
          }}
          onSubmit={onSubmit}
          validateOnChange
          validationSchema={addProductAndServiceSchema}
          enableReinitialize
        >
          {({ submitForm }) => (
            <Form>
              <fieldset
                disabled={isUpdating}
                className="disabled:t-pointer-events-none"
              >
                <Slider.Header>
                  <Slider.Title role="heading">
                    {isLoading ? (
                      <div className="t-bg-neutral-10 t-rounded-md t-h-5 t-animate-pulse t-w-60"></div>
                    ) : (
                      <>{productOrService?.name}</>
                    )}
                  </Slider.Title>
                  <Slider.Close />
                </Slider.Header>
                <Async.Root
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isEmpty={!productOrService}
                >
                  <Async.Empty>
                    <></>
                  </Async.Empty>
                  <Async.Success>
                    <Slider.Body>
                      <SliderAccordion.Root
                        type="multiple"
                        defaultValue={["ABOUT"]}
                      >
                        <SliderAccordion.Item value="ABOUT">
                          <SliderAccordion.Trigger>
                            About
                          </SliderAccordion.Trigger>
                          <SliderAccordion.Content className="t-overflow-hidden">
                            <InfoItem label="Source">
                              <div className="t-flex t-gap-2 t-items-center t-mb-4">
                                {logo_url ? (
                                  <Avatar
                                    size="small"
                                    src={logo_url}
                                    alt={source!}
                                  />
                                ) : (
                                  <span
                                    className="t-rounded-full t-bg-white t-overflow-hidden t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6 t-text-neutral-40 t-border-neutral-0 t-border t-border-solid t-text-center"
                                    role="img"
                                  >
                                    <ProductAndServicesIcon />
                                  </span>
                                )}
                                {PRODUCT_SERVICE_SOURCE_TYPE[source!]}
                              </div>
                            </InfoItem>
                            {edit ? (
                              <EditForm isStripeProduct={isStripeProduct} />
                            ) : (
                              <DataConsole
                                productOrService={productOrService!}
                              />
                            )}
                          </SliderAccordion.Content>
                        </SliderAccordion.Item>
                      </SliderAccordion.Root>
                    </Slider.Body>
                  </Async.Success>
                </Async.Root>
                <Slider.Footer>
                  {edit ? (
                    <div className="t-flex t-gap-5">
                      <Button block onClick={() => setEdit(false)}>
                        Cancel
                      </Button>
                      <Button
                        block
                        customType="primary"
                        onClick={submitForm}
                        isLoading={isUpdating}
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <Button
                      block
                      customType="primary"
                      onClick={() => setEdit(true)}
                      disabled={isLoading}
                    >
                      Edit
                    </Button>
                  )}
                </Slider.Footer>
              </fieldset>
            </Form>
          )}
        </Formik>
      </Slider.Content>
    </Slider.Root>
  );
};
