import { CrmTableDropdown } from "components/CrmTableDropdown/CrmTableDropdown";
import Loader from "components/design/loader";
import { SearchBox } from "components/design/searchBox";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/Table";
import PaginatedItems from "components/pagination/paginate";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useMemo, useState } from "react";
import { useTable } from "react-table";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import {
  Profile,
  useApproveUserProfileMutation,
  useAssignSalesPersonMutation,
  useGetAllPreGroupProfilesQuery,
  useRejectUserProfileMutation,
} from "store/apis/salesCrmProfile";
import { debounce } from "utils/debouncing";
import AddCrmEntityTableModal from "./AddCrmEntityTableModal";
import NewGroupCreationModal from "./NewGroupEntityModal";
import { ApproveModal, RejectModal } from "./StatusModal";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { useGetAssignAgentsQuery } from "store/apis/agents";
import { agentsHandlers } from "store/__mockHandlers__/agents";
import { Agents } from "types/Models/agents";
import { MultiValue, SingleValue } from "react-select";
import {
  CellContext,
  createColumn,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { formatDate, formatTime } from "utils/formatDate";
import { SolidCross } from "components/icons/SolidCross";
import { SolidCheck } from "components/icons/SolidCheck";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Search } from "components/DesignSystem/Search/Search";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { usePagination } from "hooks/usePagination";
import { Cross } from "components/icons/Cross";
import { CheckOutline } from "components/icons/CheckOutline";
import ToolTip from "components/design/toolTip";

const SalesCrmProfiles = () => {
  const { alertToast, successToast } = useToast();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const searchTerm = query.get("search_term") || null;
  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [approveUserProfileId, setApproveUserProfileId] = useState("");
  const [showGroupCreationModal, setShowGroupCreationModal] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [userDataPayload, setUserDataPayLoad] = useState<any>();
  const [groupName, setGroupName] = useState<string>("");

  const {
    data: preGroupProfiles,
    isSuccess,
    isFetching,
  } = useGetAllPreGroupProfilesQuery({
    pageNumber,
    searchTerm,
  });

  const { profiles, total_pages } = preGroupProfiles || {};

  const { goToFirstPage, goToPrevPage, goToNextPage, goToLastPage } =
    usePagination({
      pageNumber,
      onPageNumChange: setPageNumber,
      totalPage: total_pages,
    });

  const [approveuserProfile, { isLoading: isApproveBtnLoading }] =
    useApproveUserProfileMutation();

  const [rejectUserProfile, { isLoading: isRejectBtnLoading }] =
    useRejectUserProfileMutation();

  const [assignSalesPerson, { isLoading: isSalesPersonLoading }] =
    useAssignSalesPersonMutation();

  const search = debounce((e: any) => {
    const { value } = e.target;
    if (value) {
      update({ query: "search_term", value: value });
    } else {
      update({ query: "search_term", value: null });
    }
  });

  const approveHandler = (profileId: string) => {
    setShowModal(true);
    setApproveUserProfileId(profileId);
  };

  const rejectHandler = (profileId: string) => {
    setShowRejectModal(true);
    setApproveUserProfileId(profileId);
  };

  const handleApprove = async () => {
    try {
      await approveuserProfile(approveUserProfileId).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowModal(false);
  };

  const rejectUser = async () => {
    try {
      await rejectUserProfile(approveUserProfileId).unwrap();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
    setShowRejectModal(false);
  };

  const newGroupCreationHandler = ({
    first_name,
    last_name,
    email,
    linkedin,
  }: {
    first_name: string;
    last_name: string;
    email: string;
    linkedin?: string;
  }) => {
    setUserDataPayLoad({
      first_name,
      last_name,
      email,
      linkedin,
    });
    setShowGroupCreationModal(true);
  };

  const SalesPersonDropdown = ({
    agent,
    profileId,
  }: {
    agent: Agents | null;
    profileId: string | undefined;
  }) => {
    const onAgentChange = async (
      values: SingleValue<OptionData> | MultiValue<OptionData>
    ) => {
      // implement api for assigning and then refetch
      if (values && !(values instanceof Array) && profileId) {
        const { value: agentProfileId } = values;
        try {
          await assignSalesPerson({
            profileId,
            agent_profile_id: agentProfileId,
          }).unwrap();
          successToast({ message: "Salesperson has been assigned" });
        } catch (error: any) {
          alertToast({ message: error?.data?.error?.message });
        }
      }
    };
    const { data: agentOptions = [] } = useGetAssignAgentsQuery({
      agentType: "SALES_EXECUTIVE",
    });
    const selectedSalesPerson = agentOptions.find(
      (agentOption) => agentOption.profile_id == agent?.profile_id
    );
    return (
      <>
        <Combobox
          size="small"
          components={{
            ClearIndicator: () => null,
            LoadingIndicator: () => <Loader size="small" />,
          }}
          menuPortalTarget={document.body}
          onChange={onAgentChange}
          options={agentOptions.map((agent) => ({
            label: agent.name,
            value: agent.profile_id,
          }))}
          value={
            selectedSalesPerson
              ? {
                  label: selectedSalesPerson.name,
                  value: selectedSalesPerson.profile_id,
                }
              : null
          }
          isLoading={isSalesPersonLoading}
        />
      </>
    );
  };
  const createColumn = createColumnHelper<Profile>();
  const columns = useMemo(
    () => [
      createColumn.accessor("first_name", {
        header: "Name",
        size: 14,
        cell: ({ row }: any) => (
          <ToolTip
            text={`${row.original?.first_name} ${row.original?.last_name}`}
          >
            <div className="t-truncate">
              {row.original?.first_name} {row.original?.last_name}
            </div>
          </ToolTip>
        ),
      }),
      createColumn.accessor("email", {
        header: "Email",
        size: 16,
        cell: (info) => (
          <ToolTip text={info.getValue()}>
            <div className="t-truncate">{info.getValue()}</div>
          </ToolTip>
        ),
      }),
      createColumn.accessor("is_email_verified", {
        header: () => <div className="t-text-center">Email OTP</div>,
        size: 8,
        cell: (info) => (
          <div className="t-flex t-justify-center">
            {info.getValue() ? (
              <span className="t-flex t-text-red-70">
                <SolidCheck size="24" />
              </span>
            ) : (
              <SolidCross size="24" />
            )}
          </div>
        ),
      }),
      createColumn.accessor("is_mobile_verified", {
        header: () => <div className="t-text-center">Mobile OTP</div>,
        size: 8,
        cell: (info) => (
          <div className="t-flex t-justify-center">
            {info.getValue() ? (
              <span className="t-flex t-text-red-70">
                <SolidCheck size="24" />
              </span>
            ) : (
              <SolidCross size="24" />
            )}
          </div>
        ),
      }),
      createColumn.accessor("salesperson", {
        header: "Sales person",
        size: 14,
        cell: (info: CellContext<Profile, Agents>) => {
          return (
            <SalesPersonDropdown
              agent={info.row.original.salesperson}
              profileId={info.row.original.uuid}
            />
          );
        },
      }),
      createColumn.accessor("is_restricted", {
        header: "Status",
        size: 11,
        cell: ({ row }: any) => {
          if (
            !row.original?.is_email_verified &&
            !row.original?.is_mobile_verified
          ) {
            return <div>-</div>;
          }
          if (row.original?.is_rejected) {
            return <Tag tagType="red">Rejected</Tag>;
          }
          if (row.original?.is_restricted) {
            return (
              <ConditionalToolTip
                condition={
                  !row.original?.salesperson &&
                  "Please assign a salesperson first"
                }
              >
                <div className="t-flex t-gap-2">
                  <Button
                    size="small"
                    customType="icon"
                    onClick={() => approveHandler(row.original?.uuid)}
                    disabled={!row.original.salesperson}
                  >
                    <CheckOutline />
                  </Button>
                  <Button
                    size="small"
                    customType="icon"
                    onClick={() => {
                      rejectHandler(row.original?.uuid);
                    }}
                    disabled={!row.original.salesperson}
                  >
                    <Cross color="currentColor" />
                  </Button>
                </div>
              </ConditionalToolTip>
            );
          }
          if (!row.original?.is_restricted && !row.original?.is_rejected) {
            return <Tag tagType="green">Approved</Tag>;
          }
        },
      }),
      createColumn.accessor("group", {
        header: "Group",
        size: 12,
        cell: ({ row }: any) => {
          const { first_name, last_name, email, linkedin } = row.original;
          return (
            <>
              {row.original?.group?.name ? (
                <div>{row.original?.group?.name}</div>
              ) : (
                <div>
                  {row.original?.is_rejected ? (
                    "-"
                  ) : (
                    <Button
                      customType="primary-outlined"
                      size="small"
                      onClick={() =>
                        newGroupCreationHandler({
                          first_name,
                          last_name,
                          email,
                          linkedin,
                        })
                      }
                    >
                      New group
                    </Button>
                  )}
                </div>
              )}
            </>
          );
        },
      }),
      createColumn.accessor("created_at", {
        header: "Time",
        size: 11,
        cell: (info) => (
          <div>
            <div className="t-text-text-60 t-text-body">
              {formatDate(info.getValue())}
            </div>
            <div className="t-text-body-sm t-text-text-30">
              {formatTime(info.getValue())}
            </div>
          </div>
        ),
      }),
      createColumn.accessor("uuid", {
        header: "",
        id: "threedots",
        size: 5,
        cell: ({ row }: any) => {
          const { uuid, name } = row.original;
          return <CrmTableDropdown actionOn="profile" uuid={uuid} />;
        },
      }),
    ],
    []
  );

  const data = useMemo(() => profiles || [], [profiles]);

  const table = useReactTable({
    data: data || [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });

  const paginationData = {
    totalPage: total_pages!,
    currentPage: preGroupProfiles?.current_page!,
    itemsPerPage: preGroupProfiles?.per_page!,
    totalItemCount: preGroupProfiles?.total_count!,
  };

  return (
    <>
      <div className="t-w-full t-pb-16">
        <div className="t-flex t-pl-0 t-pr-5 t-gap-5 t-justify-between">
          <div className="t-w-1/3">
            <Search
              defaultValue={searchTerm || ""}
              onChange={search}
              placeholder="Search user"
              block
            />
          </div>

          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>

        <div className="t-flex t-mb-3 t-justify-end"></div>

        {isFetching && !isSuccess ? (
          <div className="t-w-full">
            <Loader />
          </div>
        ) : (
          <>
            <ApproveModal
              showModal={showModal}
              setShowModal={setShowModal}
              approveUserProfileId={approveUserProfileId}
            />
            <RejectModal
              showRejectModal={showRejectModal}
              setShowRejectModal={setShowRejectModal}
              approveUserProfileId={approveUserProfileId}
            />
            <TableUI table={table} layout="fixed" />
            <NewGroupCreationModal
              showModal={showGroupCreationModal}
              setShowModal={setShowGroupCreationModal}
              setShowEntityModal={setShowEntityModal}
              setUserDataPayLoad={setUserDataPayLoad}
              userDataPayload={userDataPayload}
              setGroupName={setGroupName}
              fromProfiles
            />
            <AddCrmEntityTableModal
              setShowGroupModal={setShowGroupCreationModal}
              showModal={showEntityModal}
              setModalShow={setShowEntityModal}
              userDataPayload={userDataPayload}
              groupName={groupName}
            />

            <div className="t-flex t-p-4 t-justify-end">
              <Pagination
                {...paginationData}
                goToFirstPage={goToFirstPage}
                goToPrevPage={goToPrevPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SalesCrmProfiles;
