import * as qs from "qs";
import { emptyApi } from "./emptyApi";
import { Pagination } from "types/Models/pagination";
import { Group } from "types/Models/group";
import { ArrayElement } from "types/utils/ArrayElement";
import { makeFormData } from "utils/makeFormData";

export type BroadcastchannelResponse = {
  url: string;
  name: string;
  group_name: string;
  season: string;
  uuid: string;
  company_entity?: Pick<
    ArrayElement<Group["entities"]>,
    "uuid" | "name" | "country_code"
  >;
};

type Filter = {
  channel_types: string;
  task_status_ids: string;
  seasons: string;
  subscription_types: string;
  agent_ids: string;
  group_ids: string;
};

export type OpsCRMFilters = {
  page_num?: number;
  search_term?: string | null;
  crm_type: string | null;
  subscription_type?: string | null;
  agent_filter?: string | null;
  sales_person_filter?: string | null;
  us_state_uuids?: string | null;
  us_incorp_months?: string | null;
  tag_filter?: string | null;
  task_state_filter_uuids?: string | null;
  not_launched_task_filter?: string | null;
  active_subscribers?: string | null;
  base_task_template_id?: string | null;
  task_season?: string;
  sort_order?: string | null;
  sort_by?: string | null;
  generate_report?: boolean;
  archived?: boolean;
  service_team_id?: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    createChatBroadcast: build.mutation<
      void,
      {
        message: string;
        excludedChannels: string;
        filters: Filter;
        ops_crm_filters: string;
        task_tracker_filters: string;
        file?: File | null;
      }
    >({
      query: ({
        excludedChannels,
        message,
        filters,
        ops_crm_filters,
        task_tracker_filters,
        file,
      }) => {
        const query = qs.stringify(
          {
            ...filters,
            excluded_channels: excludedChannels,
            ops_crm_filters,
            task_tracker_filters,
          },
          { addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/messaging/channel/broadcast/${query}`,
          method: "post",
          body: makeFormData({ file, message }),
        };
      },
      invalidatesTags: ["ChatBroadcast"],
    }),
    getBroadcastChannels: build.query<
      Pagination & { channels: BroadcastchannelResponse[] },
      Filter & {
        search_term: string;
        page_num: number;
        ops_crm_filters: string;
        task_tracker_filters: string;
        file?: File | null;
      }
    >({
      query: ({ file, ...queryParams }) => {
        const query = qs.stringify(queryParams, { addQueryPrefix: true });

        return {
          url: `/api/inkle/messaging/channel/templates/broadcast/${query}`,
          body: makeFormData({ file }),
          method: "POST",
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.channels.map(
                ({ url }) => ({ type: "ChannelListQuery", id: "NO" } as const)
              ),
            ]
          : [],
    }),
    getChannels: build.query<
      { url: string; name: string; group_name: string; season: string }[],
      {
        searchTerm: string;
        taskTemplateIds: string;
        taskStatusIds: string;
      }
    >({
      query: ({ searchTerm, taskTemplateIds, taskStatusIds }) =>
        `/api/inkle/messaging/channel/templates/broadcast/?search_term=${searchTerm}&task_template_ids=${taskTemplateIds}&task_status_ids=${taskStatusIds}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ url }) => ({ type: "ChannelListQuery", id: "NO" } as const)
              ),
            ]
          : [],
    }),
  }),
});

export const {
  useCreateChatBroadcastMutation,
  useGetChannelsQuery,
  useLazyGetBroadcastChannelsQuery,
  useGetBroadcastChannelsQuery,
} = extendedApi;
