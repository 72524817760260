import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
import { useEffect, useState } from "react";

export const useFirebase = () => {
  const [isSupportedBrowser, setIsSupportedBrowser] = useState(false);
  const [messaging, setMessaging] = useState<any>(null);

  const firebaseConfig = {
    apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  };

  useEffect(() => {
    const browserSupport = async () => {
      try {
        const res = await isSupported();
        setIsSupportedBrowser(res);
        if (res) {
          console.log("Firebase supported");
        } else {
          console.log("Firebase not supported this browser");
        }
      } catch (error) {}
    };

    browserSupport();
  }, []);

  useEffect(() => {
    const getMessage = () => {
      const app = initializeApp(firebaseConfig);
      const message = getMessaging(app);
      setMessaging(message);
    };

    if (isSupportedBrowser) {
      getMessage();
    }
  }, [isSupportedBrowser]);

  return { messaging, isSupportedBrowser };
};
