import { CreditsCard } from "components/CreditsCard";
import { CreditsTable } from "components/CreditsTable/CreditsTable";
import Loader from "components/design/loader";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { DocumentPreviewModal } from "components/PreviewModal";
import { ReferralTable } from "components/Refer/ReferralTable";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useGetCreditsQuery } from "store/apis/refrral";

const CreditHistory = () => {
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const { uuid: groupId } = useCurrentGroupContext();

  const { isLoading, isSuccess, refetch } = useGetCreditsQuery(
    { groupId, pageNum: page },
    { skip: !groupId }
  );

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <div className="t-pb-16">
      <CreditsCard refetch={refetch} />

      <Tab.Root defaultValue="CREDITS">
        <Header
          v2
          title="History"
          bottom={
            <Tab.List>
              <Tab.Trigger value="CREDITS">Credits</Tab.Trigger>
              <Tab.Trigger value="REFERRAL">Referral</Tab.Trigger>
            </Tab.List>
          }
        />
        <div>
          <Tab.Content value="CREDITS">
            <CreditsTable />
          </Tab.Content>
          <Tab.Content value="REFERRAL">
            <ReferralTable />
          </Tab.Content>
        </div>
      </Tab.Root>

      <DocumentPreviewModal />
    </div>
  );
};

export default CreditHistory;
