export const RESOLVED = "Resolved";
export const UNRESOLVED = "Unresolved";
export const TICKETS_TAG = "TICKETS_TAG";
export const NOTE_CREATED_SUCCESSFULLY = "Note created successfully";
export const NOTE_UPDATED_SUCCESSFULLY = "Note updated successfully";
export const NOTE_DELETED_SUCCSSFULLY = "Note deleted successfully";

export const TICKET_DETAIlS_TAB = "details";
export const TICKET_CHAT_TAB = "conversation";

export const TICKET_TYPE_PRICED = "PRICED";
export const TICKET_TYPE_UNPRICED = "UNPRICED";
export const TICKET_TYPE_CHAT = "CHAT";
export const TICKET_TYPE_NONCHAT = "NONCHAT";

export const TICKET_TYPES = [
  {
    value: TICKET_TYPE_PRICED,
    label: "Priced",
  },
  {
    value: TICKET_TYPE_UNPRICED,
    label: "Unpriced",
  },
  {
    value: TICKET_TYPE_CHAT,
    label: "Chat",
  },
  {
    value: TICKET_TYPE_NONCHAT,
    label: "Non chat",
  },
];

export const TICKET_SORT_OPTIONS = {
  TICKET_TYPE_LATEST_SORT: {
    value: "LATEST",
    label: "Created on: Latest to Oldest",
  },
  TICKET_TYPE_OLDEST_SORT: {
    value: "OLDEST",
    label: "Created on: Oldest to Latest",
  },
  TICKET_TYPE_RESOLVED_LATEST_SORT: {
    value: "RESOLVED_LATEST",
    label: "Resolved on: Latest to Oldest",
  },
  TICKET_TYPE_RESOLVED_OLDEST_SORT: {
    value: "RESOLVED_OLDEST",
    label: "Resolved on: Oldest to Latest",
  },
};

export const PAYMENT_PAID = "Paid";

export const TICKET_PAYMENT = "TICKET_PAYMENT";
export const TICKET_UPDATED = "TICKET_UPDATED";
export const TICKET_CREATED = "TICKET_CREATED";
export const TICKET_RESOLVED = "TICKET_RESOLVED";
export const TICKET_PAID = "TICKET_PAID";

export const TICKET_ETD_UNSET = "TICKET_ETD_UNSET";
export const TICKET_ETD_TODAY_OR_PASSED = "TICKET_ETD_TODAY_OR_PASSED";
export const TICKET_ETD_APPROACHING = "TICKET_ETD_APPROACHING";
