import NoNotesAvailableIcon from "static/images/NoNotesFound.svg";

export const NoNoteAvailable = () => {
  return (
    <div className="t-h-96 t-w-full t-flex t-flex-col t-justify-center t-items-center">
      <img src={NoNotesAvailableIcon} alt="NoNotesAvailableIcon" />
      <span className="t-mt-6 t-text-subtitle t-text-text-30">
        No notes available
      </span>
    </div>
  );
};
