import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { useAddTicketETDMutation } from "store/apis/chatTicket";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { object, string } from "yup";

export const TicketEtdModal = ({
  isOpen,
  close,
  date,
  ticketId,
}: {
  isOpen: boolean;
  close: () => void;
  date: string;
  ticketId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [addTicketEtd, { isLoading: isAdding }] = useAddTicketETDMutation();

  const handleSubmit = async (values: { date: string; reason: string }) => {
    try {
      await addTicketEtd({
        reason: values.reason,
        etd_date: dayjs(values.date).format(YYYY_MM_DD),
        ticketId,
      }).unwrap();
      successToast({ message: "ETD added successfully" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Formik
          initialValues={{ date: date, reason: "" }}
          onSubmit={handleSubmit}
          validationSchema={object({
            reason: string().required("Reason is required"),
          })}
          validateOnChange
          validateOnMount
        >
          {({ isValid, values }) => (
            <Form className="all:unset">
              <Modal.Header>
                <Modal.Title>Reason for Update</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-space-y-4">
                <TextInput
                  label="Selected ETD"
                  block
                  placeholder="Select date"
                  name="date"
                  required
                  disabled
                  value={formatDate(values.date)}
                />
                <TextArea
                  required
                  name="reason"
                  rows={8}
                  placeholder="Enter your reason here"
                  onKeyDown={(e) => e.stopPropagation()}
                  label="Reason"
                />
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button type="button">Cancel</Button>
                </Modal.RawClose>
                <Button
                  customType="primary"
                  type="submit"
                  disabled={!isValid || isAdding}
                  isLoading={isAdding}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
