import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import {
  EVERY_3_DAYS,
  EVERY_5_DAYS,
  EVERY_7_DAYS,
  ONE_TIME,
} from "constants/reminderFrequencies";
import { useFormikContext, Field, FieldProps } from "formik";
import { useGetAllChannelMemberQuery } from "store/apis/chat";
import { ReminderSettingPayload } from "store/apis/reminders";

const frequencyOptions = [
  {
    label: "Every 3 days",
    value: EVERY_3_DAYS,
  },
  {
    label: "Every 5 days",
    value: EVERY_5_DAYS,
  },
  {
    label: "Every 7 days",
    value: EVERY_7_DAYS,
  },
  {
    label: "Send once",
    value: ONE_TIME,
  },
];

export const SetReminder = ({
  channelId,
  groupId,
  isLoading,
}: {
  channelId: string;
  isLoading: boolean;
  groupId: string;
}) => {
  const { values, setFieldValue } = useFormikContext<ReminderSettingPayload>();
  const { data: members, isLoading: isMembersLoading } =
    useGetAllChannelMemberQuery(
      {
        groupId: groupId,
        channelId: channelId,
      },
      {
        skip: !channelId || !groupId,
      }
    );

  const memberOptions = members
    ?.filter(({ mobile }) => Boolean(mobile))
    .map(({ mobile, name, profile_id }) => ({
      label: name!,
      value: profile_id,
    }));

  const selectedMembers =
    values.reminder_details.subscribers.length > 0 &&
    memberOptions?.filter(({ value }) =>
      values.reminder_details.subscribers.includes(value)
    );

  return (
    <>
      <Modal.Header>
        <Modal.Title>Reminder</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body className="t-flex t-gap-4 t-flex-col">
        <TextArea name="content_details.message_text" label="Message" />

        <Combobox
          menuPortalTarget={document.body}
          options={frequencyOptions}
          withForm
          name="reminder_details.frequency"
          label="Frequency"
          value={
            frequencyOptions.find(
              ({ value }) => value === values.reminder_details.frequency
            ) || null
          }
        />

        {values.reminder_details.frequency === ONE_TIME && (
          <Field name="reminder_details.on_date" type="one_time_date">
            {({ field }: FieldProps) => (
              <DateInput
                portalId={field.name}
                {...field}
                label="Send at"
                minDate={new Date()}
              />
            )}
          </Field>
        )}

        <div className="t-flex t-flex-col t-gap-3">
          <Label className="t-m-0">Reminder channels</Label>

          <Checkbox
            checked={values.reminder_details.notification_channels.includes(
              "CHAT"
            )}
            disabled
            label="Chat"
          />

          <Checkbox
            name="SEND_TO_WHATSAPP"
            onChange={(e) => {
              const channels = new Set(
                values.reminder_details.notification_channels
              );

              if (e.target.checked) {
                channels.add("WHATSAPP");
                return setFieldValue(
                  "reminder_details.notification_channels",
                  Array.from(channels)
                );
              }

              channels.delete("WHATSAPP");
              return setFieldValue(
                "reminder_details.notification_channels",
                Array.from(channels)
              );
            }}
            checked={values.reminder_details.notification_channels.includes(
              "WHATSAPP"
            )}
            label="WhatApp"
          />

          {values.reminder_details.notification_channels.includes(
            "WHATSAPP"
          ) && (
            <Combobox
              isLoading={isMembersLoading}
              isMulti
              withForm
              onChange={(data) => {
                if (data instanceof Array) {
                  setFieldValue(
                    "reminder_details.subscribers",
                    data.map(({ value }) => value)
                  );
                }
              }}
              menuPortalTarget={document.body}
              options={memberOptions}
              value={selectedMembers || null}
              name="reminder_details.subscribers"
              placeholder="Select"
              label="Send to"
            />
          )}
        </div>
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Modal.RawClose asChild>
          <Button>Back</Button>
        </Modal.RawClose>
        <Button
          customType="primary"
          isLoading={isLoading}
          disabled={isLoading}
          type="submit"
        >
          Save
        </Button>
      </Modal.FooterButtonGroup>
    </>
  );
};
