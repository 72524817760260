import { ReactNode } from "react";
import Star from "static/images/Star.svg";

export const PromoCardItem = ({ children }: { children: ReactNode }) => (
  <div className="t-flex t-items-center t-gap-2">
    <img src={Star} alt="Tax" className="t-h-8" />
    <p className="t-m-0 t-text-body">{children}</p>
  </div>
);

export const PromoCard = ({
  CTA,
  title,
  image,
  children,
}: {
  CTA: ReactNode;
  title: ReactNode;
  image: ReactNode;
  children: ReactNode;
}) => (
  <div className="t-rounded-lg t-flex t-p-4 t-gap-3.5 t-border t-border-solid t-border-neutral-10">
    <div className="t-flex t-h-full t-w-44 t-flex-col t-items-center t-justify-center t-gap-2 t-rounded-lg t-bg-surface-lighter-grey">
      {image}
    </div>
    <div className="t-flex t-flex-col t-gap-2.5">
      <p className="t-text-body-sm t-text-text-30 t-m-0">{title}</p>
      <div className="t-flex t-flex-col t-gap-4">{children}</div>
      {CTA}
    </div>
  </div>
);
