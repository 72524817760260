import { array, number, object, string } from "yup";

export const merchantAddSchema = object().shape({
  merchants: array().of(
    object().shape({
      name: string().required("Name is required"),
      email: string().test(
        "is-valid-email",
        "Should be a valid email",
        (value) => !value || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
      ),
      amount_paid_in_season: string().required("Year is required"),
      total_amount: number()
        .required("Amount is required")
        .notOneOf([0], "Amount should not be zero")
        .test(
          "is-positive-or-negative",
          "Amount is required",
          (value) => value !== 0
        ),
    })
  ),
});

export const merchantEditSchema = () =>
  object({
    name: string().required("Name is required"),
    email: string().email("Should be a valid email"),
  });

export const merchantEmailAddSchema = () =>
  object({
    email: string()
      .required("Email is required")
      .email("Should be a valid email"),
  });
