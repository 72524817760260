import classNames from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { DividerLine } from "components/icons/DividerLine";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { Trash } from "components/icons/Trash";
import { EmptyCart } from "components/TPPayments/Illustration/EmptyCart";
import { BILLING_CYCLE } from "dictionaries";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useHistory } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import WarningCircle from "static/images/WarningCircle.svg";
import {
  Cart as CartT,
  productsApi,
  useCheckoutCartMutation,
  useGetCartQuery,
  useRemoveProductFromCartMutation,
} from "store/apis/products";
import { refferalApis, useGetCreditsQuery } from "store/apis/refrral";
import { subscriptionApis } from "store/apis/subscriptions";
import { taskApis } from "store/apis/task";
import { ArrayElement } from "types/utils/ArrayElement";
import { BackendError } from "types/utils/error";
import { pluralize } from "utils/pluralize";

export const CartModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const columnHelper = createColumnHelper<ArrayElement<CartT["cart_items"]>>();
  const [
    removeCartItem,
    { isLoading: removingItemFromCart, originalArgs: removeItemArgs },
  ] = useRemoveProductFromCartMutation();

  const [checkoutCart, { isLoading: isCheckoutLoading, data: cartInvoice }] =
    useCheckoutCartMutation();

  const history = useHistory();
  const [isPayViaCredits, setIsPayViaCredits] = useState(false);
  const [showInsufficentError, setShowInsufficentError] = useState(false);

  const dispatch = useAppDispatch();
  const entityId = useCurrentEntityId();
  const { isAdmin } = useRoleBasedView();

  const {
    data: cart,
    isLoading: isCartLoading,
    isFetching: isCartRefreshing,
  } = useGetCartQuery(
    { groupId: groupId!, entityId },
    { skip: !groupId || !entityId }
  );

  const { data: creditsData } = useGetCreditsQuery(
    { groupId: groupId! },
    { skip: !groupId }
  );

  const { total_credits } = creditsData || {};

  const onPaymentSuccess = async () => {
    if (groupId) {
      dispatch(productsApi.util.invalidateTags([{ type: "CART" }]));
      dispatch(taskApis.util.invalidateTags([{ type: "Tasks" }]));
      dispatch(
        subscriptionApis.util.invalidateTags([{ type: "Subscriptions" }])
      );
      dispatch(refferalApis.util.invalidateTags([{ type: "CREDITS" }]));

      closePayment();
      onClose();
      history.push("/filings?check_for_tasks=true");
    }
  };

  const onDelete = async (id: string) => {
    if (groupId && cart) {
      try {
        await removeCartItem({
          groupId,
          cartItemId: id,
          entityId: cart.entity_id,
        }).unwrap();
        successToast({ message: "Item has been deleted!" });
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const columns = [
    columnHelper.accessor("product_details.product_name", {
      header: "Item",
      cell: (info) => {
        const {
          row: {
            original: {
              ra_state_name,
              tier_information,
              product_details: {
                season,
                has_balancing_payment,
                company_entity,
                state,
                product_name,
                slabs,
              },
            },
          },
        } = info;

        return (
          <div>
            <p className="t-m-0">{info.getValue()}</p>
            <p className="t-m-0 t-flex t-gap-1.5 t-items-center t-text-body-sm t-text-text-30">
              {company_entity && (
                <>
                  <ReactCountryFlag
                    countryCode={company_entity?.country_code}
                    svg
                  />
                  {company_entity?.name}{" "}
                </>
              )}
              {ra_state_name && <span>{ra_state_name}</span>}
              {tier_information && <span>{tier_information.range}</span>}
              {company_entity?.name && season && (
                <span className="t-text-neutral-20">
                  <DividerLine />
                </span>
              )}{" "}
              <span>{season}</span>
              {season && state && (
                <span className="t-text-neutral-20">
                  <DividerLine />
                </span>
              )}{" "}
              <span>{state?.name}</span>
            </p>
            {has_balancing_payment && (
              <p className="t-m-0 t-flex t-gap-1.5 t-items-center t-mt-1 t-text-body-sm">
                <span className="t-text-red t-flex">
                  <InfoFilledSmall />
                </span>
                <span>May have extra charges.</span>

                <Modal.Root>
                  <Modal.Trigger asChild>
                    {slabs && (
                      <button className="all:unset t-text-purple">
                        Learn more
                      </button>
                    )}
                  </Modal.Trigger>
                  <Modal.Content>
                    <Modal.Header>
                      <Modal.Title>{product_name}</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>

                    <Modal.Body>
                      {slabs && (
                        <div
                          className="t-text-body"
                          dangerouslySetInnerHTML={{
                            __html: slabs,
                          }}
                        />
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      <Modal.RawClose asChild>
                        <Button block>Go back to cart</Button>
                      </Modal.RawClose>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Root>
              </p>
            )}
          </div>
        );
      },
      size: 70,
    }),

    columnHelper.accessor("quantity", {
      header: "QTY",
      size: 10,
    }),

    columnHelper.accessor("total", {
      header: "price",
      cell: (info) => {
        const { discount, subtotal } = info.row.original;

        return (
          <div className="">
            <p
              className={classNames("t-m-0", {
                "t-line-through t-text-body-sm t-text-text-30":
                  discount && discount.discount_value > 0,
              })}
            >
              <AmountSuperScript amount={subtotal} />
              {info.row.original.subscription && (
                <span className="t-text-text-30">
                  /
                  {BILLING_CYCLE[info.row.original.subscription?.billing_cycle]}
                </span>
              )}
            </p>
            {Boolean(discount) && discount && discount.discount_value > 0 && (
              <p className="t-m-0">
                <AmountSuperScript amount={info.getValue()} />
                {info.row.original.subscription && (
                  <span className="t-text-text-30">
                    /
                    {
                      BILLING_CYCLE[
                        info.row.original.subscription?.billing_cycle
                      ]
                    }
                  </span>
                )}
              </p>
            )}
          </div>
        );
      },
      size: 10,
    }),

    columnHelper.display({
      header: "",
      id: "actions",
      cell: (info) => {
        return (
          <div className="t-flex t-justify-end">
            <Button
              isLoading={
                removingItemFromCart &&
                removeItemArgs?.cartItemId === info.row.original.uuid
              }
              disabled={removingItemFromCart}
              customType="ghost_icon"
              size="small"
              onClick={() => onDelete(info.row.original.uuid)}
            >
              <span className="t-text-text-30">
                <Trash stroke="1.5" />
              </span>
            </Button>
          </div>
        );
      },
      size: 15,
    }),
  ];

  const table = useReactTable({
    data: cart?.cart_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { minSize: 10 },
  });

  const { successToast, alertToast } = useToast();
  const {
    isOpen: isPaymentOpen,
    close: closePayment,
    open: openPayment,
  } = useModal();

  const onCheckout = async () => {
    if (groupId && cart?.uuid) {
      try {
        await checkoutCart({
          groupId,
          cartId: cart.uuid,
          entityId: cart.entity_id,
        }).unwrap();
        openPayment();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const handleCreditCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (cart && cart.order_summary.total <= total_credits!) {
      await checkoutCart({
        groupId: groupId!,
        cartId: cart.uuid,
        entityId: cart.entity_id,
      }).unwrap();
      setIsPayViaCredits(e.target.checked);
    } else {
      if (!e.target.checked) setShowInsufficentError(false);
      else setShowInsufficentError(true);
    }
  };

  return (
    <>
      <Modal.Root open={open} onOpenChange={onClose}>
        <Modal.Content size="xl">
          <Modal.Header>
            <Modal.Title>
              Your Cart{" "}
              {cart && cart.order_summary.items_count > 0 && (
                <>
                  ({pluralize(cart.order_summary.items_count, "item", "items")})
                </>
              )}
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="!t-pt-0 t-mt-5">
            {cart && cart?.cart_items.length === 0 && (
              <div className="t-flex t-justify-center t-items-center t-flex-col t-gap-3">
                <EmptyCart />
                <p className="t-text-text-30 t-text-body-sm">
                  Your cart is empty right now
                </p>
              </div>
            )}

            {isCartLoading && !cart && (
              <div className="t-flex t-justify-center t-items-center t-flex-col t-gap-3">
                <Loader />
              </div>
            )}

            {!isCartLoading && cart && cart.cart_items.length > 0 && (
              <div className="t-flex t-gap-6">
                <div className="t-w-8/12">
                  <Table.Container className="t-h-full t-w-full">
                    <Table.Content className="t-w-full">
                      <Table.Head>
                        {table.getHeaderGroups().map((headerGroup) => (
                          <Table.Row key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                              <Table.HeadCell
                                key={header.id}
                                className="t-text-subtext-sm t-uppercase t-py-2"
                                style={{ width: `${header.getSize()}%` }}
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </Table.HeadCell>
                            ))}
                          </Table.Row>
                        ))}
                      </Table.Head>
                      <Table.Body>
                        {table.getRowModel().rows.map((row) => (
                          <Table.Row key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                              <td
                                key={cell.id}
                                style={{ width: `${cell.column.getSize()}%` }}
                                className="t-py-4"
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table.Content>
                  </Table.Container>
                </div>

                <div className="t-p-4 t-w-4/12 t-bg-surface-lighter-grey t-border-solid t-border t-border-neutral-0 t-rounded t-h-72 t-flex t-flex-col t-sticky t-top-0">
                  <p className="t-pb-4 t-border-solid t-border-b t-border-neutral-0 t-border-0 t-text-subtext">
                    Order Summary
                  </p>
                  <p className="t-flex t-m-0 t-justify-between t-text-body">
                    <span>
                      Subtotal (
                      {pluralize(
                        cart.order_summary.items_count,
                        "item",
                        "items"
                      )}
                      )
                    </span>
                    <span>
                      <AmountSuperScript amount={cart.order_summary.subtotal} />
                    </span>
                  </p>

                  {Boolean(cart.order_summary.discount) &&
                    (cart.order_summary.discount?.discount_value || 0) > 0 && (
                      <p className="t-flex t-m-0 t-justify-between t-text-body t-mt-4">
                        <span>
                          Discount (
                          <span className="t-text-dark_green">
                            {cart.order_summary.discount?.coupon?.coupon_code}
                          </span>
                          )
                        </span>
                        <span className="t-text-dark_green">
                          -
                          <AmountSuperScript
                            amount={
                              cart.order_summary.discount?.discount_value || 0
                            }
                          />
                        </span>
                      </p>
                    )}

                  {isAdmin && (
                    <div className="t-mt-4">
                      <Checkbox
                        label={"Pay using Inkle credits"}
                        onChange={handleCreditCheckboxChange}
                      />
                      <div className="t-text-body-sm t-pl-6 t-flex t-flex-col t-gap-0.5">
                        <span className="t-text-neutral-40">
                          {total_credits?.toString()} credits available
                        </span>
                        {showInsufficentError && (
                          <span className="t-flex t-items-center t-gap-1">
                            <span className="t-text-red">
                              Insufficient credit balance{" "}
                            </span>
                            <img
                              src={WarningCircle}
                              alt="Warning circle"
                              className="t-h-3.5"
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <p className="t-flex t-m-0 t-justify-between t-text-subtext t-pt-4 t-border-solid t-border-0 t-border-t t-border-neutral-0 t-mt-auto">
                    <span>Total</span>
                    <span>
                      <AmountSuperScript amount={cart.order_summary.total} />
                    </span>
                  </p>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={onClose}>Back</Button>
              {isPayViaCredits && cart ? (
                <CheckoutModal
                  type="cart"
                  onCartPaid={onPaymentSuccess}
                  cart={{
                    ...cart,
                    cart_items: cart.cart_items.map((item) =>
                      item.subscription
                        ? {
                            ...item,
                            subscription: {
                              ...item.subscription,
                              selectedTierAmount: item.tier_information?.amount,
                              raStateName: item.ra_state_name,
                              cartItemId: item.uuid,
                            },
                          }
                        : item
                    ),
                  }}
                  cartInvoice={cartInvoice?.invoice}
                  title="Cart Payment"
                  onClose={closePayment}
                  open={isPaymentOpen}
                  onlyCTA={isPayViaCredits}
                  useCredits={isPayViaCredits}
                />
              ) : (
                <Button
                  onClick={onCheckout}
                  customType="primary"
                  isLoading={isCheckoutLoading}
                  disabled={
                    isCheckoutLoading ||
                    !cart ||
                    cart.cart_items.length === 0 ||
                    removingItemFromCart ||
                    isCartRefreshing ||
                    (showInsufficentError && isPayViaCredits)
                  }
                >
                  Checkout
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      {!isPayViaCredits && open && isPaymentOpen && cart && (
        <CheckoutModal
          type="cart"
          onCartPaid={onPaymentSuccess}
          cart={{
            ...cart,
            cart_items: cart.cart_items.map((item) =>
              item.subscription
                ? {
                    ...item,
                    subscription: {
                      ...item.subscription,
                      selectedTierAmount: item.tier_information?.amount,
                      raStateName: item.ra_state_name,
                      cartItemId: item.uuid,
                    },
                  }
                : item
            ),
          }}
          cartInvoice={cartInvoice?.invoice}
          title="Cart Payment"
          onClose={closePayment}
          open={isPaymentOpen}
        />
      )}
    </>
  );
};
