import { useAppSelector } from "hooks/useAppSelector";
import { useFilters } from "hooks/useFilter";
import { createContext, useContext } from "react";

export type FilterValues = {
  CHANNEL: string[];
  STATUS: string[];
  COMPANY: string[];
  AGENT: string[];
  START_DATE: string;
  END_DATE: string;
  SELECT_PERIOD: string;
  OTHERS: "ALL_CHAT" | "UNREAD_CHAT";
};

type ChatFilterContextProps = {
  filters: FilterValues;
  setFilter: <S extends keyof FilterValues>(
    name: S,
    newValue: FilterValues[S]
  ) => void;
};

export const ChatFilterContext = createContext<ChatFilterContextProps>({
  filters: {
    CHANNEL: [],
    STATUS: [],
    COMPANY: [],
    AGENT: [],
    START_DATE: "",
    END_DATE: "",
    SELECT_PERIOD: "",
    OTHERS: "ALL_CHAT",
  },
  setFilter: () => {},
});

export const ChatFilterContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const unread = useAppSelector((state) => state.chatSearch.unread);
  const { values, updateFilter } = useFilters<FilterValues>({
    initialValue: {
      CHANNEL: [],
      STATUS: [],
      COMPANY: [],
      AGENT: [],
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "",
      OTHERS: unread ? "UNREAD_CHAT" : "ALL_CHAT",
    },
  });

  return (
    <ChatFilterContext.Provider
      value={{ filters: values, setFilter: updateFilter }}
    >
      {children}
    </ChatFilterContext.Provider>
  );
};

export const useChatFilterContext = () => {
  const context = useContext(ChatFilterContext);
  if (!context) {
    throw new Error(
      "useChatFilterContext must be used within a ChatFilterContextProvider"
    );
  }

  return context;
};

export const withChatFilterContext = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  return function WithChatFilterContext(props: P) {
    return (
      <ChatFilterContextProvider>
        <Component {...props} />
      </ChatFilterContextProvider>
    );
  };
};
