import { Transaction, TxnCategories } from "types/Models/books";
import { emptyApi } from "./emptyApi";
import {
  transactionFilterValuesToParams,
  TransactionQuery,
} from "./transactions";
import { parse } from "qs";
import {
  categoryFilterValuesToParams,
  GeneralLedgerParams,
} from "./generalLedger";
import {
  journalEntryFilterValuesToParams,
  JournalEntryParams,
} from "./journalEntry";
import {
  interactiveReportFilterValuesToParams,
  InteractiveNewReportParams,
} from "./reports";
import { JournalEntry } from "types/Models/journalEntry";
import { addGroupNotes } from "apis/serviceTeamGroups";
import qs from "qs";

export type Note = {
  uuid: string;
  description: string;
  created_at: string;
  updated_at: string;
  created_by: {
    name: string;
    uuid: string;
    profile_url: string;
  };
  parent_object_id: string;
  comment_type:
    | "TRANSACTION_COMMENT"
    | "TRANSACTION_CATEGORY_COMMENT"
    | "LEDGER_ENTRY_COMMENT"
    | "TICKET_COMMENT";
  tagged_user_profiles: {
    name: string;
    uuid: string;
    profile_url: string;
  }[];
};

export type NoteResponse = {
  comments: Note[];
  ledger_entry?: Pick<JournalEntry, "uuid" | "entry_name" | "date">;
  transaction?: Pick<Transaction, "uuid" | "amount" | "date" | "merchant"> & {
    category_id?: string;
  };
  category?: Pick<
    TxnCategories,
    "uuid" | "opening_balance" | "identifier" | "name"
  >;
};

const notesApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    groupNotes: build.query<
      {
        notes: any[];
      },
      {
        groupId: string;
        page_num: number;
      }
    >({
      query: ({ groupId, page_num }) => ({
        url: `/api/inkle/notes/company/group/${groupId}/`,
        params: {
          page: page_num,
        },
      }),
      providesTags: (result) => (result ? ["NOTES"] : []),
    }),

    addGroupNotesMeeting: build.mutation<
      any,
      {
        groupId: string;
        payload: {
          description: string;
          last_contact_date?: string;
          next_contact_date?: string;
        };
      }
    >({
      query: ({ groupId, payload }) => ({
        url: `/api/inkle/notes/company/group/${groupId}/meeting/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["NOTES"],
    }),

    addGroupNotes: build.mutation<
      any,
      {
        groupId: string;
        payload: {
          description: string;
        };
      }
    >({
      query: ({ groupId, payload }) => ({
        url: `/api/inkle/notes/company/group/${groupId}/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["NOTES"],
    }),

    getNotes: build.query<
      NoteResponse[],
      {
        entityId: string;
        types: (Note["comment_type"] | "REPORTS_COMMENT")[];
        transactionFilters?: TransactionQuery;
        generalLedgerFilters?: GeneralLedgerParams;
        journalEntryFilters?: JournalEntryParams;
        reportFilters?: InteractiveNewReportParams;
        search?: string;
      }
    >({
      query: ({
        entityId,
        types,
        transactionFilters,
        generalLedgerFilters,
        journalEntryFilters,
        reportFilters,
        search,
      }) => ({
        url: `/api/inkle/notes/entity/${entityId}/comments/`,
        params: {
          comment_types: types.join(","),
          comment_search_term: search,
          ...parse(
            transactionFilters
              ? transactionFilterValuesToParams(transactionFilters)
              : "",
            {
              ignoreQueryPrefix: true,
            }
          ),
          ...parse(
            generalLedgerFilters
              ? categoryFilterValuesToParams(generalLedgerFilters)
              : "",
            {
              ignoreQueryPrefix: true,
            }
          ),
          ...parse(
            journalEntryFilters
              ? journalEntryFilterValuesToParams(journalEntryFilters)
              : "",
            {
              ignoreQueryPrefix: true,
            }
          ),
          ...parse(
            reportFilters
              ? interactiveReportFilterValuesToParams(reportFilters)
              : "",
            {
              ignoreQueryPrefix: true,
            }
          ),
        },
      }),
      providesTags: (result) =>
        result ? ["NOTES", "BOOKS_TRANSACTIONS", "JOURNAL_ENTRIES"] : [],
    }),

    getNote: build.query<
      NoteResponse,
      { parentObjId: string; entityId: string; is_private?: boolean }
    >({
      query: ({ entityId, parentObjId, is_private }) => {
        let queryUrl = qs.stringify(
          {
            is_private: is_private,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return `/api/inkle/notes/entity/${entityId}/parent_object/${parentObjId}/comment/${queryUrl}`;
      },
      providesTags: (result) => ["NOTES"],
    }),

    createNotes: build.mutation<
      NoteResponse[],
      {
        parentObjId: string;
        entityId: string;
        payload: {
          description?: string;
          mentioned_stream_user_ids?: string[];
          comment_type: Note["comment_type"];
          is_private?: boolean;
        };
      }
    >({
      query: ({ entityId, parentObjId, payload }) => ({
        url: `/api/inkle/notes/entity/${entityId}/parent_object/${parentObjId}/comment/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: (result) =>
        result ? ["NOTES", "BOOKS_SINGLE_TRANSACTION", "ChatTickets"] : [],
    }),
  }),
});

export const {
  useCreateNotesMutation,
  useGetNotesQuery,
  useGetNoteQuery,
  useGroupNotesQuery,
  useAddGroupNotesMeetingMutation,
  useAddGroupNotesMutation,
} = notesApis;
