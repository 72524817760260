import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { coaOptions } from "components/Transaction/TransactionColumn";
import { itemTypesOptions } from "constants/productAndServices";
import { PRODUCT_SERVICE_ITEM_TYPE } from "dictionaries";
import { Form, Formik } from "formik";
import { addProductAndServiceSchema } from "formValidations/productAndServiceSchema";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  useAddProductAndServiceMutation,
  useGetProductAndServicesQuery,
  useGetProductCategoriesQuery,
} from "store/apis/productAndServices";
import { ProductAndServices } from "types/Models/productAndServices";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const AddProductModal = ({
  onSuccess,
  close,
  name,
}: ModalProps & {
  name?: string;
  onSuccess?: ({ item }: { item: ProductAndServices }) => void;
}) => {
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: categories = [], isLoading } = useGetProductCategoriesQuery();
  const { data: productAndSerivce } = useGetProductAndServicesQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !entityId || !groupId,
    }
  );
  const { revenue_category_id } = productAndSerivce || {};

  const { chartOfAccounts, ...coaAPIStates } = useChartOfAccounts({
    root_category_id: revenue_category_id,
  });

  const [addProductAndService, { isLoading: isAdding }] =
    useAddProductAndServiceMutation();

  const categoriesOptions = categories.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));

  const onSubmit = async (values: {
    name: string;
    type: "PRODUCT" | "SERVICE";
    product_category: string;
    price: number;
    transaction_category_id: null | string;
  }) => {
    try {
      const { type, ...rest } = await addProductAndService({
        groupId,
        entityId,
        payload: values,
      }).unwrap();
      onSuccess?.({ item: { type, ...rest } });
      successToast({ message: `${PRODUCT_SERVICE_ITEM_TYPE[type]} added` });
      close();
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  return (
    <Formik
      initialValues={{
        name: name || "",
        type: "PRODUCT",
        product_category: "",
        price: 0,
        transaction_category_id: null,
      }}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={addProductAndServiceSchema}
    >
      {({ submitForm }) => (
        <Form>
          <fieldset disabled={isAdding}>
            <Modal.Header>
              <Modal.Title>Add Product</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-flex t-gap-4 t-flex-col">
              <TextInput
                required
                name="name"
                placeholder="Enter product name"
                label="Name"
              />
              <Combobox
                required
                label="Type"
                options={itemTypesOptions}
                name="type"
                withForm
                placeholder="Select type"
                isClearable={false}
              />
              <Combobox
                required
                label="Group"
                isDisabled={isLoading}
                isLoading={isLoading}
                options={categoriesOptions}
                name="product_category"
                creatable
                withForm
                placeholder="Select or create group"
                isClearable={false}
                menuPortalTarget={document.body}
              />
              <PriceInput required label="Price" name="price" />
              <Combobox
                label="Income Category"
                menuPlacement="top"
                isLoading={coaAPIStates.isLoading}
                isDisabled={coaAPIStates.isLoading}
                placeholder="Select income category"
                menuPortalTarget={document.body}
                withForm
                name="transaction_category_id"
                options={coaOptions(chartOfAccounts)}
                filterOption={(v, i) =>
                  v.data.data
                    ?.toLocaleLowerCase()
                    .includes(i.toLocaleLowerCase()) || false
                }
                isClearable={false}
              />
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Modal.RawClose asChild disabled={isAdding}>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                isLoading={isAdding}
                disabled={isAdding}
                onClick={submitForm}
              >
                Save
              </Button>
            </Modal.FooterButtonGroup>
          </fieldset>
        </Form>
      )}
    </Formik>
  );
};
