import classNames from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Badge } from "components/design/badge";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Discount } from "components/Discount";
import Headset from "components/icons/Headset";
import { PencilWithPaper } from "components/icons/PencilWithPaper";
import { SmallPriceLedger } from "components/icons/SmallPriceLedger";
import { Price } from "components/Price";
import { TaskAssignee } from "components/taskAssignee";
import { FilingTags } from "components/tasks/taskForForeignCA/FilingTags";
import {
  EstimdatedTimeColumn,
  PreparerColumn,
  ServiceTeamColumn,
} from "components/tasks/taskForForeignCA/ForeignCATask";
import { EditPriceAndDescription } from "components/tasks/taskPriceLedger/EditPriceAndDescription";
import { TaskPriceLedger } from "components/tasks/taskPriceLedger/TaskPriceLedger";
import { TASK_AMENDMENT_COMPLETED } from "constants/chatType";
import { PERCENT } from "constants/stripeCouponTypes";
import { SUBMITTED } from "constants/task";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/useAppSelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Check from "static/images/DoubleGreenTick.svg";
import { useGetCreditsQuery } from "store/apis/refrral";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { useGetTaskTagsQuery } from "store/apis/task";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { setShowPricingModal } from "store/slices/task";
import {
  PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
  PRO_MONTHLY_BOOKKEEPING,
} from "types/Models/services";
import { BackendError } from "types/utils/error";
import { formatDate, formatDateToYear } from "utils/formatDate";
import { truncateHtml } from "utils/truncateHtml";
import { stateUpdate } from "../../apis/stateUpdate";
import { TaskDetailLabelValue } from "./TaskDetailLabelValue";
import WarningCircle from "static/images/WarningCircle.svg";
import { formatNumber } from "utils/formatNumber";
import { Label } from "components/DesignSystem/TextInput/TextInput";

export default function PaymentEngine({
  pricingData,
  pricingAmount,
  next,
  title,
  groupId,
  task,
  isArchived,
  mainId,
  updateAssignee,
  refetchTask,
  isFromServices,
  onClose,
}: {
  pricingData: any;
  pricingAmount: any;
  next?: any;
  title: any;
  groupId: any;
  task: any;
  isArchived: any;
  mainId: any;
  updateAssignee: any;
  refetchTask: any;
  isFromServices: any;
  onClose: (val: string) => void;
}) {
  const { alertToast } = useToast();
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [isSubsCheckoutOpen, setIsSubsCheckoutOpen] = useState(false);
  const dispatch = useDispatch();
  const { isAdmin, isCpaInkleServiceAdmin, isCustomer, isCpa, isForeignCA } =
    useRoleBasedView();
  const [showPriceEditModal, setShowPriceEditModal] = useState(false);
  const [showPriceLedger, setShowPriceLedger] = useState(false);
  const { taskId } = useParams<{ taskId: string }>();
  const { data: tags = [] } = useGetTaskTagsQuery();
  const { showPricingModal, isPricingStep } = useAppSelector(
    (state) => state.taskSlice
  );

  const { data: serviceTeam = [] } = useGetServiceTeamQuery(
    { accessible_teams: null },
    {
      skip: isCustomer,
    }
  );

  const taskStateType =
    task?.state?.type === SUBMITTED ||
    task?.state?.type === TASK_AMENDMENT_COMPLETED
      ? "FILED ON"
      : "DEADLINE";

  const [showMore, setShowMore] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(false);
  const maxContentToShow = 8;
  const { truncatedHtml } = truncateHtml(pricingData, maxContentToShow);
  const contentToShow = showMore ? pricingData : truncatedHtml;
  const taskDeadline = dayjs(task?.deadline).endOf("day");
  const today = dayjs().endOf("day");
  const formattedDate = formatDate(task?.deadline);
  const daysOverdue = taskDeadline.diff(today, "day");
  const daysLeft = today.diff(taskDeadline, "day");
  const isSameDay = taskDeadline.isSame(today, "day");
  let overDueOrDayLeft;
  const [isPayViaCredits, setIsPayViaCredits] = useState(false);
  const [showInsufficentError, setShowInsufficentError] = useState(false);

  if (taskDeadline.isAfter(today) || isSameDay) {
    overDueOrDayLeft = `${daysLeft * -1} days left`;
  } else {
    overDueOrDayLeft = `${daysOverdue * -1} days overdue`;
  }

  useEffect(() => {
    const { showMoreBtn } = truncateHtml(pricingData, maxContentToShow);
    setShowMoreBtn(showMoreBtn);
  }, [pricingData, showMoreBtn]);

  const updateState = async () => {
    try {
      const id = next;
      if (next) {
        await stateUpdate({ taskId, id });
        window.location.reload();
      }
    } catch (e: any) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const openPaymentModal = async () => {
    try {
      if (task && task.entity) {
        dispatch(setUsableCredit(true));
        dispatch(setPaymentTitle(title));
        dispatch(setShowPricingModal(false));
        setPaymentModalOpen(true);
      }
    } catch (error: any) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const openPaymentSuccessModal = () => {
    updateState();
    setPaymentModalOpen(false);
  };

  const onSubscribe = () => {
    dispatch(setPaymentTitle(title));
    setIsSubsCheckoutOpen(true);
    setShowSubscriptionModal(false);
  };

  const closeSubsCheckout = () => {
    setIsSubsCheckoutOpen(false);
    dispatch(setShowPricingModal(true));
  };

  const onSubscribeSuccessModal = () => {
    updateState();
    setIsSubsCheckoutOpen(false);
  };

  const isPaid = task?.payment_status === "PAID";
  const isSubscriptionRequired = task?.subscription !== null;
  const {
    subscription_name,
    amount,
    description,
    uuid: subscriptionId,
    billing_cycle,
    stripe_coupon = {},
  } = task?.subscription || {};

  const { data: creditsData } = useGetCreditsQuery(
    { groupId: groupId! },
    { skip: !groupId }
  );

  const { total_credits } = creditsData || {};

  const coupon = task?.coupon;

  const discountOff =
    coupon?.discount_type === PERCENT
      ? `${coupon?.discount_rate}%`
      : `$${coupon?.discount_rate}`;

  let finalPrice = coupon?.discounted_price || pricingAmount;
  const payBtnText = isPaid ? "Successfully paid" : `Pay $${finalPrice}`;

  if (isSubscriptionRequired) {
    finalPrice = amount;
  }

  const openPriceLedger = () => {
    dispatch(setShowPricingModal(false));
    setShowPriceLedger(true);
  };

  const closePriceLedger = () => {
    dispatch(setShowPricingModal(true));
    setShowPriceLedger(false);
  };

  const openPriceEditModal = () => {
    dispatch(setShowPricingModal(false));
    setShowPriceEditModal(true);
  };

  const closePriceEditModal = () => {
    dispatch(setShowPricingModal(true));
    setShowPriceEditModal(false);
  };

  const openSubscriptionModal = () => {
    dispatch(setShowPricingModal(false));
    setShowSubscriptionModal(true);
  };

  const closeSubscriptionModal = () => {
    dispatch(setShowPricingModal(true));
    setShowSubscriptionModal(false);
  };

  const closePaymentModal = () => {
    dispatch(setShowPricingModal(true));
    setPaymentModalOpen(false);
  };

  const closeTaskDetailModal = () => {
    dispatch(setShowPricingModal(false));
    onClose?.("");
  };

  const TaskPriceLog = isAdmin ? (
    <div className="t-flex t-gap-4">
      <Button
        customType="transparent"
        onClick={openPriceEditModal}
        disabled={!mainId}
      >
        <PencilWithPaper />
      </Button>
      <Button customType="transparent" onClick={openPriceLedger}>
        <div className="t-text-neutral">
          <SmallPriceLedger />
        </div>
      </Button>
    </div>
  ) : (
    <></>
  );
  return (
    <>
      <Modal.Root open={showPricingModal} onOpenChange={closeTaskDetailModal}>
        <Modal.Portal>
          <Modal.Overlay />
          <Modal.Content size="regular">
            <Modal.Header>
              <Modal.Title>
                {isFromServices ? "Service" : "Filing"} details
              </Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="!t-overflow-x-hidden">
              <div className="t-flex t-flex-col t-gap-4">
                {task?.base_task_key &&
                  [
                    PRO_MONTHLY_BOOKKEEPING,
                    PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
                  ].includes(task.base_task_key) && (
                    <TaskDetailLabelValue
                      label="Accounting Method"
                      value={
                        <div className="t-text-subtext t-text-text-100">
                          {task?.base_task_key == PRO_MONTHLY_BOOKKEEPING
                            ? "Accrual"
                            : "Cash"}
                        </div>
                      }
                    />
                  )}
                {task?.from_date &&
                  task?.base_task_key == PRO_MONTHLY_BOOKKEEPING && (
                    <TaskDetailLabelValue
                      label="Month"
                      value={
                        <div className="t-text-subtext t-text-text-100">
                          {task.base_task_key == PRO_MONTHLY_BOOKKEEPING &&
                          task?.from_date
                            ? dayjs(task?.from_date).format("MMMM")
                            : "-"}
                        </div>
                      }
                    />
                  )}
                {task?.season && (
                  <TaskDetailLabelValue
                    label="Season"
                    value={
                      <div className="t-text-subtext t-text-text-100">
                        {task.base_task_key == PRO_MONTHLY_BOOKKEEPING &&
                        task?.from_date
                          ? formatDateToYear(task?.from_date)
                          : task?.season || "-"}
                      </div>
                    }
                  />
                )}
                {/* deadline  */}
                {task?.deadline &&
                  !task?.state?.is_final &&
                  !isFromServices && (
                    <TaskDetailLabelValue
                      label="Deadline"
                      value={
                        <div className="t-flex t-flex-col t-items-end">
                          <div className="t-text-subtext t-text-text-100">
                            {formattedDate}
                          </div>
                          <div className="t-text-body-sm t-text-red">
                            {overDueOrDayLeft}
                          </div>
                        </div>
                      }
                    />
                  )}
                {task?.state_data?.updated_at && task?.state?.is_final && (
                  <TaskDetailLabelValue
                    label={taskStateType}
                    value={
                      <ToolTip text="Completed">
                        <div className="t-flex t-items-center">
                          <img src={Check} alt="check" />
                          <div className="t-ml-1 t-text-body-sm t-text-dark_green">
                            {formatDate(task?.state_data?.updated_at)}
                          </div>
                        </div>
                      </ToolTip>
                    }
                  />
                )}
                {/* assignee  */}
                {isCpaInkleServiceAdmin && (
                  <TaskDetailLabelValue
                    label="Assignee"
                    value={
                      <TaskAssignee
                        members={serviceTeam}
                        selectedId={task?.assigned_to?.uuid}
                        taskId={task?.uuid}
                        updateTaskWithUser={updateAssignee}
                        size="small"
                      />
                    }
                  />
                )}
                {/* tags  */}
                {(isCpa || (isCustomer && Boolean(task?.tags))) && (
                  <TaskDetailLabelValue
                    label="Tags"
                    value={
                      <div className="t-flex t-w-1/2 t-justify-end">
                        <FilingTags
                          taskId={task.uuid}
                          currentTags={task?.tags}
                          tags={tags}
                        />
                      </div>
                    }
                  />
                )}
                {isCpa && task?.assigned_agent?.name && (
                  <TaskDetailLabelValue
                    label="Agent"
                    value={
                      <div className="t-flex t-items-center t-gap-1">
                        <Headset />
                        <div className="t-text-body-sm t-text-text-100">
                          {task?.assigned_agent?.name}
                        </div>
                      </div>
                    }
                  />
                )}
                {isPaid && (
                  <TaskDetailLabelValue
                    label="Payment status"
                    value={
                      <div className="t-text-body-sm t-text-dark_green-70 t-px-2 t-py-0.5 t-rounded t-bg-dark_green-10">
                        Paid
                      </div>
                    }
                  />
                )}
                {isAdmin && (
                  <TaskDetailLabelValue
                    label="Service Team"
                    value={
                      <div className="t-flex t-justify-end t-pr-2">
                        <ServiceTeamColumn taskData={task} />
                      </div>
                    }
                  />
                )}
                {isCpa && (
                  <TaskDetailLabelValue
                    label="Preparer"
                    value={
                      <div className="t-flex t-justify-end t-pr-2">
                        <PreparerColumn taskData={task} />
                      </div>
                    }
                  />
                )}
                {isCpa && (
                  <TaskDetailLabelValue
                    label="Estimated time of delivery"
                    value={
                      <div className="t-flex t-justify-end t-pr-2">
                        <EstimdatedTimeColumn taskData={task} />
                      </div>
                    }
                  />
                )}
              </div>
              <div
                className={classNames(
                  "taxing t-px-0 t-pt-4 t-mt-4 t-border t-border-solid t-border-l-0 t-border-r-0 t-border-b-0 t-border-neutral-10",
                  {
                    disabledForm: isArchived,
                  }
                )}
              >
                <h6 className="t-text-body t-text-text-30">Description</h6>

                <div
                  className="t-text-body-sm"
                  dangerouslySetInnerHTML={{
                    __html: contentToShow,
                  }}
                />

                {showMoreBtn && (
                  <Button
                    customType="link"
                    onClick={() => setShowMore(!showMore)}
                  >
                    {showMore ? "Show less" : "Show more"}
                  </Button>
                )}
                <div className="t-mb-8 t-mt-8 t-flex t-w-full t-rounded-lg t-bg-surface-lighter-grey t-p-3">
                  <div
                    className={classNames(
                      "t-border-transparent t-border-2 t-border-solid t-border-[transparent] t-text-text-100",
                      {
                        "!t-border-r-neutral-10 t-w-2/3": Boolean(
                          task?.government_fee
                        ),
                        "t-w-full": !Boolean(task?.government_fee),
                      }
                    )}
                  >
                    <div className="t-flex t-gap-2 t-flex-wrap t-items-center">
                      <div className="t-text-body-sm t-text-text-30">
                        Inkle Service Fee{" "}
                      </div>
                      <div className="t-rounded-sm t-bg-neutral-10 t-px-2 t-py-0.5 t-text-overline t-text-text-30 t-w-fit !t-tracking-wide">
                        {isPaid ? "Paid" : "Pay by Credit Card"}
                      </div>
                    </div>
                    {coupon && (
                      <div className="t-mt-2 t-flex t-items-center">
                        <span className="t-mr-2 t-text-body t-text-text-30">
                          {coupon.coupon_code}
                        </span>
                        <Badge color="light-green">
                          <span className="t-text-caption t-font-medium">
                            {discountOff} off
                          </span>
                        </Badge>
                      </div>
                    )}
                    <div className="t-mt-2 t-text-subtext">
                      {isSubscriptionRequired ? (
                        stripe_coupon?.discounted_amount ||
                        stripe_coupon?.discounted_amount === 0 ? (
                          <div>
                            <Price
                              size="medium"
                              cycle={billing_cycle}
                              amount={stripe_coupon.discounted_amount}
                            />
                            <div className="t-mt-2">
                              <Discount
                                coupon={stripe_coupon}
                                amount={finalPrice}
                              />
                            </div>
                            {TaskPriceLog}
                          </div>
                        ) : (
                          <>
                            <Price
                              size="medium"
                              cycle={billing_cycle}
                              amount={finalPrice}
                            />
                            {TaskPriceLog}
                          </>
                        )
                      ) : isNaN(finalPrice) ? (
                        <div className="t-flex t-gap-4 t-items-center">
                          {finalPrice}
                          {TaskPriceLog}
                        </div>
                      ) : (
                        <div className="t-flex t-gap-4 t-items-center">
                          <>{`$${finalPrice}`}</>
                          {TaskPriceLog}
                        </div>
                      )}
                    </div>
                  </div>
                  {Boolean(task?.government_fee) && (
                    <div className="t-ml-8 t-w-1/2">
                      <div className="t-flex t-gap-2 t-flex-wrap t-items-center">
                        <div className="t-text-body-sm t-text-text-30">
                          Government Fee
                        </div>
                        <div className="t-rounded-sm t-bg-neutral-10 t-px-2 t-py-0.5 t-text-overline t-text-text-100 t-w-fit !t-tracking-wide">
                          Pay by ACH
                        </div>
                      </div>
                      <div className="t-mt-2 t-text-subtext">
                        ${task.government_fee}
                      </div>
                    </div>
                  )}
                </div>
                {isAdmin && (
                  <div className="t-grid t-grid-cols-[min-content_1fr] t-gap-1">
                    <Checkbox
                      name="pay_using_inkle_credits_task"
                      onChange={(e) => {
                        if (finalPrice <= total_credits!) {
                          setIsPayViaCredits(Boolean(e.target.checked!));
                        } else {
                          if (!e.target.checked) setShowInsufficentError(false);
                          else setShowInsufficentError(true);
                        }
                      }}
                    />
                    <label
                      htmlFor="pay_using_inkle_credits_task"
                      className="t-cursor-pointer t-select-none t-text-body t-text-neutral-100"
                    >
                      Pay using Inkle credits
                    </label>
                    <div className="t-text-body-sm t-flex t-flex-col t-gap-0.5 t-col-start-2">
                      <span className="t-text-neutral-40">
                        {formatNumber(total_credits || 0)} credits available
                      </span>
                      {showInsufficentError && (
                        <span className="t-flex t-items-center t-gap-1">
                          <span className="t-text-red">
                            Insufficient credit balance
                          </span>
                          <img
                            src={WarningCircle}
                            alt="Warning circle"
                            className="t-h-3.5"
                          />
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              {task?.is_prepayment_required ? (
                <div className="t-flex t-justify-end">
                  {isSubscriptionRequired && !isPaid ? (
                    <Button
                      customType="primary"
                      onClick={openSubscriptionModal}
                    >
                      Subscribe now
                    </Button>
                  ) : (
                    <>
                      {isPayViaCredits ? (
                        <CheckoutModal
                          payerUserType={isForeignCA ? "FCA" : "CUSTOMER"}
                          type="task"
                          task={task}
                          onInvoicePaid={openPaymentSuccessModal}
                          title="Cart Payment"
                          onClose={closePaymentModal}
                          open={isPaymentModalOpen}
                          onlyCTA={isPayViaCredits}
                          useCredits={isPayViaCredits}
                        />
                      ) : (
                        <div className="t-flex t-gap-4">
                          <Button
                            customType="primary"
                            disabled={isPaid}
                            onClick={openPaymentModal}
                          >
                            {payBtnText}
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="t-flex t-justify-end">
                  <Button
                    customType="primary"
                    size="regular"
                    onClick={updateState}
                    disabled={!isPricingStep || !next}
                  >
                    Next
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </Modal.Content>
        </Modal.Portal>
      </Modal.Root>
      <Modal.Root
        open={showSubscriptionModal}
        onOpenChange={closeSubscriptionModal}
      >
        <Modal.Content>
          <Modal.Body>
            <div className="subscription-plan-modal-header t-mr-5 t-w-full">
              <div className="t-flex t-w-full t-items-center t-justify-between">
                <div className="d-flex flex-column gap-3 col">
                  <span className="subscription-plan-name">
                    {subscription_name}
                  </span>
                  <Price
                    cycle={billing_cycle}
                    amount={
                      stripe_coupon.discounted_amount ||
                      stripe_coupon.discounted_amount === 0
                        ? stripe_coupon.discounted_amount
                        : amount
                    }
                  />
                  <Button
                    size="regular"
                    customType="primary"
                    onClick={() => onSubscribe()}
                  >
                    Subscribe
                  </Button>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>

      {isPaymentModalOpen && (
        <CheckoutModal
          payerUserType={isForeignCA ? "FCA" : "CUSTOMER"}
          type="task"
          task={task}
          open={isPaymentModalOpen}
          onInvoicePaid={openPaymentSuccessModal}
          onClose={closePaymentModal}
        />
      )}

      {isSubsCheckoutOpen && task.subscription && (
        <CheckoutModal
          entityId={task.entity.uuid}
          type="subscription"
          open={isSubsCheckoutOpen}
          onClose={closeSubsCheckout}
          onSubscribed={onSubscribeSuccessModal}
          subscription={task.subscription}
        />
      )}
      <TaskPriceLedger
        show={showPriceLedger}
        closeModal={closePriceLedger}
        taskChangeLogs={task.task_change_logs}
        finalPrice={finalPrice}
      />
      <EditPriceAndDescription
        show={showPriceEditModal}
        closeModal={closePriceEditModal}
        finalPrice={isNaN(finalPrice) ? "" : finalPrice}
      />
    </>
  );
}
