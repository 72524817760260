import { useFlows } from "@frigade/react";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Modal from "components/DesignSystem/Modal/Modal";
import React, { useEffect, useState } from "react";
import { openLink } from "utils/openLink";

const ProductUpdatesModal = () => {
  const FLOW_ID = import.meta.env.VITE_APP_FRIGADE_FLOW_ID || "";

  const [openModal, setOpenModal] = useState(false);

  const {
    markFlowCompleted,
    markFlowStarted,
    getFlowData,
    getFlowSteps,
    markStepStarted,
    markStepCompleted,
    getFlowStatus,
    getFlow,
    isLoading,
  } = useFlows();

  const currentState = getFlowStatus(FLOW_ID);
  const flowSteps = getFlowSteps(FLOW_ID);

  useEffect(() => {
    if (currentState === "NOT_STARTED_FLOW" && !isLoading) {
      markFlowStarted(FLOW_ID);
      setOpenModal(true);
      markStepStarted(FLOW_ID, flowSteps[0].id);
    }
  }, [currentState, isLoading]);

  const handleClose = () => {
    setOpenModal(false);
    markFlowCompleted(FLOW_ID);
    markStepCompleted(FLOW_ID, flowSteps[0].id);
  };

  const handleCheckClick = () => {
    openLink(flowSteps[0].urlTarget, "_blank");
  };

  return (
    <Modal.Root open={openModal} onOpenChange={handleClose}>
      <Modal.Content size="regular">
        <Modal.Body className="!t-p-0">
          {flowSteps.map((e) => (
            <div className="t-flex t-flex-col" key={e.id}>
              <img className="t-w-full" src={e.imageUri || ""} alt="test" />
              <div className="t-flex t-justify-center	t-flex-col t-p-6 t-gap-3">
                <div className="t-text-center t-text-h4">{e.title}</div>
                <div className="t-text-center">{e.subtitle}</div>
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer className="t-flex t-items-center t-gap-3 t-justify-end">
          <Button customType="text" onClick={handleClose}>
            Close
          </Button>
          <Button customType="primary" onClick={handleCheckClick}>
            Check out now
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ProductUpdatesModal;
