import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { ComponentProps, forwardRef, Ref } from "react";

type FormikComponentProps = ComponentProps<typeof Formik>;

export const FormikForm = forwardRef(
  <Values extends FormikValues>(
    {
      children,
      onSubmit,
      initialValues,
      validationSchema,
      enableReinitialize = false,
      ...props
    }: {
      children:
        | ((props: FormikProps<Values>) => React.ReactNode)
        | React.ReactNode;
      onSubmit: (
        values: Values,
        formikHelpers?: FormikHelpers<Values>
      ) => void | Promise<any>;
      initialValues: Values;
      validationSchema?: FormikComponentProps["validationSchema"];
      enableReinitialize?: FormikComponentProps["enableReinitialize"];
    },
    ref: Ref<HTMLFormElement>
  ) => {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={enableReinitialize}
        onSubmit={onSubmit}
      >
        {(...formikProps) => (
          // @ts-ignore
          <Form ref={ref} {...props}>
            {typeof children === "function"
              ? children(...formikProps)
              : children}
          </Form>
        )}
      </Formik>
    );
  }
);
