import Quill from "quill";
import { forwardRef, useRef, useLayoutEffect, useEffect, Ref } from "react";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";

const QuillEditor = ({
  readOnly,
  defaultValue,
  onTextChange,
  parentRef,
  onSelectionChange,
  theme = "snow",
  initReadOnly,
}: {
  readOnly?: boolean;
  defaultValue?: any;
  onTextChange?: (...args: any[]) => void;
  onSelectionChange?: (...args: any[]) => void;
  parentRef: React.MutableRefObject<undefined | Quill | null>;
  theme?: "snow" | "bubble";
  initReadOnly?: boolean;
}) => {
  const containerRef = useRef(null);
  const defaultValueRef = useRef(defaultValue);
  const onTextChangeRef = useRef(onTextChange);
  const onSelectionChangeRef = useRef(onSelectionChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    onSelectionChangeRef.current = onSelectionChange;
  });

  useEffect(() => {
    // @ts-ignore
    parentRef?.current?.disable(readOnly);
  }, [parentRef, readOnly]);

  useEffect(() => {
    const container = containerRef.current as HTMLElement | null;
    const editorContainer = container?.appendChild(
      container?.ownerDocument.createElement("div")
    );

    if (editorContainer) {
      const quill = new Quill(editorContainer, {
        theme: theme,
        readOnly: initReadOnly,
      });

      parentRef.current = quill;

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (delta) => {
        onTextChangeRef.current?.(delta);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });
    }

    return () => {
      parentRef.current = null;
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [parentRef]);

  return <div ref={containerRef}></div>;
};

export default QuillEditor;
