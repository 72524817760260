import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { Badge, TaskType } from "components/Task/Badge";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import { Preview } from "components/PreviewModal";
import { Cross } from "components/icons/Cross";
import { PlusIcon } from "components/icons/PlusIcon";
import { NOT_STARTED } from "constants/taskStatus";
import dayjs from "dayjs";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useGetSliderDataQuery,
  useUpdateGroupDataMutation,
} from "store/apis/salesCrm";
import { FileIcon } from "utils/fileTypeIcon";
import { openLink } from "utils/openLink";
import { DynamicStar } from "components/DesignSystem/Star/DynamicStar";
import { useMarkCompanyAsVipMutation } from "store/apis/crm";

type Tags = {
  uuid: string;
  tag_category: number;
  title: string;
  color_hex: string;
  bg_color_hex: string;
};

const LastQuoteContent = ({
  lastQuoteSent,
  groupId,
}: {
  lastQuoteSent?: { uuid: string; name: string };
  groupId: string;
}) => {
  const { isOpen, close, open } = useModal();
  return (
    <div className="t-flex t-justify-between t-h-8 t-items-center">
      <div className="t-text-overline t-text-text-30">QUOTE</div>
      {lastQuoteSent ? (
        <Button customType="ghost_icon" onClick={open}>
          <FileIcon fileType="PDF" width="32px" height="32px" />
        </Button>
      ) : (
        <ConditionalLink target="_blank" to={`crm/${groupId}/quotes`}>
          <Button customType="icon">
            <PlusIcon color="#41395C" />
          </Button>
        </ConditionalLink>
      )}
      <Preview
        showModal={isOpen}
        closeModal={close}
        groupId={groupId}
        previewId={lastQuoteSent?.uuid}
      />
    </div>
  );
};

const ReferrerContent = ({
  email,
  coupon_code,
  referer_text,
}: {
  email?: string;
  coupon_code?: string;
  referer_text?: string;
}) => {
  const attribuionExists = email || coupon_code || referer_text;
  return (
    <div className="t-flex t-justify-between t-h-8 t-items-center">
      <div className="t-text-overline t-text-text-30">REFERRER</div>
      <div className="t-text-body-sm">
        {attribuionExists && email ? (
          <span>{email}</span>
        ) : (
          <span>{coupon_code || referer_text || "-"}</span>
        )}
      </div>
    </div>
  );
};

const TagsContent = ({ tags }: { tags?: Tags[] }) => {
  return (
    <div className="t-flex t-justify-between t-min-h-8 t-items-center">
      <div className="t-text-overline t-text-text-30">TAGS</div>
      <div className="t-flex t-gap-2 t-flex-wrap t-justify-end t-w-3/5">
        {tags ? (
          tags?.map(({ title, color_hex, bg_color_hex, uuid }) => (
            <div
              key={uuid}
              className="t-rounded-lg t-px-1"
              style={{
                color: color_hex,
                backgroundColor: bg_color_hex,
              }}
            >
              #{title}
            </div>
          ))
        ) : (
          <>-</>
        )}
      </div>
    </div>
  );
};

const Content = ({
  name,
  status,
  isBadge,
  type,
  filingUuid,
  isBank,
}: {
  name: string;
  status: any;
  isBadge?: boolean;
  type?: TaskType;
  filingUuid?: string;
  isBank?: boolean;
}) => {
  return (
    <div className="t-flex t-justify-between t-h-8 t-items-center">
      <div
        className={classNames({
          "t-text-text-100 t-text-body-sm": filingUuid || isBank,
          "t-text-text-30 t-text-overline": !filingUuid && !isBank,
        })}
      >
        {filingUuid ? (
          <ConditionalLink
            to={`/filings/${filingUuid}`}
            target="_blank"
            className="all:unset hover:t-text-purple hover:t-cursor-pointer hover:!t-underline"
          >
            {name}
          </ConditionalLink>
        ) : (
          name
        )}
      </div>
      {status ? (
        !isBadge ? (
          <div className="t-text-body-sm">{status}</div>
        ) : (
          <Badge type={type || "ACTION_REQUIRED"}>{status}</Badge>
        )
      ) : (
        <>-</>
      )}
    </div>
  );
};

export const OPSSlider = ({
  groupId,
  setOpenSlider,
}: {
  groupId: string;
  setOpenSlider: (newState: React.SetStateAction<boolean>) => void;
}) => {
  const { pathname } = useLocation();
  const [payWall, setPayWall] = useState<boolean | null>(null);
  const [booksPayWall, setBooksPayWall] = useState<boolean | null>(null);
  const { alertToast, successToast } = useToast();

  const { data } = useGetSliderDataQuery({
    groupId,
    base_task_template_id: import.meta.env.VITE_APP_BASE_TASK_TEMPLATE_ID_1120,
  });

  const [updateGroup, { isLoading, originalArgs }] =
    useUpdateGroupDataMutation();
  const [markGroupAsVip] = useMarkCompanyAsVipMutation();

  useEffect(() => {
    if (payWall !== null) {
      const updatePayWall = async () => {
        try {
          const resp = await updateGroup({
            payLoad: { is_subscription_required: payWall },
            groupId: groupId,
          });
          setPayWall(null);
        } catch (err: any) {
          alertToast({ message: err?.data?.error?.message });
        }
      };
      updatePayWall();
    }
  }, [payWall]);

  useEffect(() => {
    if (booksPayWall !== null) {
      const updateBooksPayWall = async () => {
        try {
          await updateGroup({
            payLoad: { is_books_subscription_required: booksPayWall },
            groupId: groupId,
          });
          setBooksPayWall(null);
        } catch (err: any) {
          alertToast({ message: err?.data?.error?.message });
        }
      };
      updateBooksPayWall();
    }
  }, [booksPayWall]);

  const {
    name,
    assigned_agent,
    books_software = [],
    connected_banks = [],
    is_subscription_required,
    is_books_subscription_required,
    last_called,
    last_quote_sent,
    ongoing_tasks = [],
    ongoing_tasks_count = 0,
    subscription_name,
    referer,
    coupon_code,
    referer_text,
    tags = [],
    task: task1120,
    uuid,
    pending_dues_allowed,
    is_vip,
  } = data || {};

  const markCompanyGroupAsVip = async () => {
    try {
      await markGroupAsVip({ groupId }).unwrap();
      successToast({
        message: "Customer marked with a star",
      });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const onDebtWallChange = async (checked: boolean) => {
    try {
      await updateGroup({
        payLoad: { pending_dues_allowed: !checked },
        groupId: groupId,
      });
    } catch (err: any) {
      alertToast({ message: err?.data?.error?.message });
    }
  };

  let taskStatus = "Not Created";
  if (task1120?.current_state_name) {
    taskStatus = task1120.current_state_name;
  }
  if (task1120?.uuid) {
    taskStatus = NOT_STARTED;
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="t-h-full t-flex t-flex-col t-relative"
    >
      <div className="t-flex t-justify-between t-px-6 t-py-4 t-drop-shadow-drawer t-sticky t-top-0 t-bg-surface t-justify-self-start t-items-center">
        <div className="t-text-subtitle t-flex t-items-center t-gap-2">
          <div>{name}</div>
          <div
            className={classNames("-t-mt-1", {
              "t-cursor-pointer": !is_vip,
            })}
            onClick={() => !is_vip && markCompanyGroupAsVip()}
          >
            <DynamicStar isActive={is_vip!} />
          </div>
        </div>
        <Button
          customType="icon"
          size="small"
          onClick={() => setOpenSlider(false)}
        >
          <Cross color="currentColor" />
        </Button>
      </div>
      <div className="t-px-8 t-pt-4 t-pb-32 t-flex t-flex-col t-gap-5 t-overflow-auto t-min-h-[550px]">
        <div className="t-flex t-flex-col t-gap-3">
          <TagsContent tags={tags} />
          <Content
            name="AGENT"
            status={assigned_agent?.name}
            type="FILING_IN_PROGRESS"
            isBadge
          />
          <Content name="TASK 1120" status={taskStatus} isBadge />
          <ReferrerContent
            email={referer?.email}
            referer_text={referer_text}
            coupon_code={coupon_code}
          />
          <LastQuoteContent groupId={groupId} lastQuoteSent={last_quote_sent} />
          <Content name="SOFTWARE" status={books_software} />
          <Content
            name="LAST CALLED"
            status={
              last_called?.date
                ? dayjs(last_called?.date)?.format("DD-MMM-YYYY")
                : "-"
            }
          />
          <Content name="SUBSCRIPTION" status={subscription_name} />
          <div className="t-flex t-justify-between t-h-8 t-items-center">
            <div className="t-text-overline t-text-text-30">TAX PAY WALL</div>
            <div className="t-flex t-items-center t-gap-1">
              <Switch
                disabled={isLoading}
                checked={Boolean(is_subscription_required)}
                name="paywallSwitch"
                onCheckedChange={() => {
                  setPayWall(!is_subscription_required);
                }}
              />
            </div>
          </div>

          <div className="t-flex t-justify-between t-h-8 t-items-center">
            <div className="t-text-overline t-text-text-30">BOOKS PAY WALL</div>
            <div className="t-flex t-items-center t-gap-1">
              <Switch
                disabled={isLoading}
                checked={Boolean(is_books_subscription_required)}
                name="booksPaywallSwitch"
                onCheckedChange={() => {
                  setBooksPayWall(!is_books_subscription_required);
                }}
              />
            </div>
          </div>

          <div className="t-flex t-justify-between t-h-8 t-items-center">
            <div className="t-text-overline t-text-text-30">DEBT WALL</div>
            <div className="t-flex t-items-center t-gap-1">
              <Switch
                disabled={isLoading}
                checked={!pending_dues_allowed}
                name="paywallSwitch"
                onCheckedChange={onDebtWallChange}
              />
            </div>
          </div>
        </div>

        <Divider />
        <div className="t-flex t-flex-col t-gap-3">
          <div className="t-text-overline t-text-text-30">FILINGS</div>
          {ongoing_tasks_count > 0 ? (
            <>
              {ongoing_tasks?.map(
                ({
                  name,
                  current_state: { name: currentStateName, type },
                  uuid,
                }) => (
                  <Content
                    key={uuid}
                    name={name}
                    status={currentStateName || "Not Started"}
                    type={type}
                    isBadge
                    filingUuid={uuid}
                  />
                )
              )}
            </>
          ) : (
            <Badge
              className="!t-max-w-56 !t-line-clamp-none"
              type="ACTION_REQUIRED"
            >
              No filings
            </Badge>
          )}
        </div>
        <Divider />
        <div className="t-flex t-flex-col t-gap-3">
          <div className="t-text-overline t-text-text-30">BANKS</div>
          {connected_banks?.length > 0 ? (
            <>
              {connected_banks?.map((bank) => (
                <Content
                  key={bank.uuid}
                  name={bank?.bank_brand?.name}
                  status={bank?.in_error_state ? "Disconnected" : "Connected"}
                  type={bank?.in_error_state ? "ACTION_REQUIRED" : "SUBMITTED"}
                  isBadge
                  isBank
                />
              ))}
            </>
          ) : (
            <Badge
              className="!t-max-w-56 !t-line-clamp-none"
              type="ACTION_REQUIRED"
            >
              No bank accounts connected
            </Badge>
          )}
        </div>
      </div>
      <div className="t-w-full t-bg-surface t-shadow-tasks-slider-bottom t-px-3 t-py-4 t-justify-self-end t-sticky t-bottom-0">
        <Button
          customType="primary"
          onClick={() => openLink(`${pathname}/${uuid}`)}
          block
        >
          Go to company group
        </Button>
      </div>
    </div>
  );
};
