import { DeleteModal } from "components/design/deleteModal";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  useDeleteVendorMutation,
  useEditVendorsMutation,
} from "store/apis/vendors";

export const MerchantTableDropdown = ({
  editMerchant,
  openMarkAsVendor,
  merchantId,
  groupId,
  isManualMerchant,
  isVendor,
  showTransactions,
}: {
  editMerchant: () => void;
  openMarkAsVendor: () => void;
  merchantId: string;
  groupId: string;
  isManualMerchant: boolean;
  isVendor: boolean;
  showTransactions: () => void;
}) => {
  const { isCustomer } = useRoleBasedView();
  const [deleteMerchant, { isLoading: isDeleting }] = useDeleteVendorMutation();
  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    isOpen: showDeleteModal,
  } = useModal();
  const { alertToast, successToast } = useToast();
  const {
    isOpen: notRequiredModalOpen,
    close: closeNotRequiredModal,
    open: openNotRequiredModal,
  } = useModal();

  const [editVendor, { isLoading: isEditing }] = useEditVendorsMutation();

  const markVendorFormNotRequired = async () => {
    try {
      await editVendor({
        groupId,
        uuid: merchantId,
        payload: { merchant_type: "NOT_REQUIRED" },
      }).unwrap();
      closeNotRequiredModal();
      successToast({ message: "Vendor has been updated" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const deleteVendor = async () => {
    try {
      await deleteMerchant({ merchantId, groupId }).unwrap();
      successToast({ message: "Vendor deleted successfully" });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <div>
            <Button type="button" customType="ghost_icon">
              <img src={ThreeDots} alt="Three dots" />
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content align="end">
            <Dropdown.Item onClick={editMerchant}>Edit</Dropdown.Item>
            {!isVendor && (
              <Dropdown.Item onClick={openMarkAsVendor}>
                Form required
              </Dropdown.Item>
            )}
            {isVendor && (
              <Dropdown.Item onClick={openNotRequiredModal}>
                Form not required
              </Dropdown.Item>
            )}
            {!isCustomer && isManualMerchant && (
              <Dropdown.Item onClick={openDeleteModal}>
                Delete vendor
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={showTransactions}>
              Show transactions
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <DeleteModal
        show={showDeleteModal}
        closeModal={closeDeleteModal}
        onClick={deleteVendor}
        text="Delete vendor"
        body={
          "Are you sure you want to delete this vendor? This action can not be undone."
        }
        isLoading={isDeleting}
      />

      <Modal.Root
        open={notRequiredModalOpen}
        onOpenChange={closeNotRequiredModal}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>W form not required?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Are you sure you don't want W form for this vendor?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Modal.RawClose asChild>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                isLoading={isEditing}
                disabled={isEditing}
                onClick={markVendorFormNotRequired}
              >
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
