import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import React, { Suspense, useRef, useState } from "react";
import {
  Note,
  useCreateNotesMutation,
  useGetNoteQuery,
} from "store/apis/notes";
import { ModalProps } from "types/utils/modal";
import { NotesContent } from "./NotesContent";
import Async from "../AsyncComponents/Async";
import { NoNoteAvailable } from "./NoNoteAvailable";
import { BackendError } from "types/utils/error";
import Quill from "quill";
import Delta from "quill-delta";

type NotesProps = ModalProps & {
  objId: string;
  entityId: string;
  commentType: string;
  isPrivate?: boolean;
};

const QuillEditor = React.lazy(
  () => import("components/QuillEditor/QuillEditor")
);

export const Notes = ({
  close,
  isOpen,
  objId,
  entityId,
  commentType,
  isPrivate,
}: NotesProps) => {
  const { successToast, alertToast } = useToast();
  const quillRef = useRef<Quill | null>(null);
  const [createTicketNote, { isLoading: isAdding }] = useCreateNotesMutation();
  const { data, isLoading, isSuccess } = useGetNoteQuery(
    {
      entityId,
      parentObjId: objId,
      is_private: isPrivate,
    },
    {
      skip: !entityId || !objId,
    }
  );
  const [notesPayload, setNotesPayload] = useState<Delta | {}>({});

  const handleSubmit = async () => {
    try {
      await createTicketNote({
        entityId,
        parentObjId: objId,
        payload: {
          description: JSON.stringify(notesPayload),
          comment_type: commentType as Note["comment_type"],
          is_private: isPrivate,
        },
      }).unwrap();
      successToast({
        message: "Note added successfully!",
      });
      setNotesPayload({});
      quillRef?.current?.setContents(new (Quill.import("delta"))());
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const isEmpty = data?.comments?.length == 0;

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>Notes</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Async.Root
            isEmpty={isEmpty}
            isLoading={isLoading}
            isSuccess={isSuccess}
          >
            <Async.Empty>
              <NoNoteAvailable />
            </Async.Empty>
            <Async.Success>
              <NotesContent notes={data?.comments || []} />
            </Async.Success>
          </Async.Root>
        </Modal.Body>
        <Modal.Footer className="t-space-y-3">
          <Suspense fallback={<Loader size="small" />}>
            <QuillEditor
              parentRef={quillRef}
              onTextChange={(update) =>
                setNotesPayload(quillRef?.current?.getContents() || {})
              }
            />
          </Suspense>
          <div className="t-flex t-w-full t-justify-end t-gap-3 t-items-center">
            <Button
              customType="primary"
              isLoading={isAdding}
              disabled={
                isAdding || Object.entries(notesPayload || {}).length === 0
              }
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
