import { fileAndFolder } from "./../../types/Models/documents";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { MerchantCSVResponse, Vendor } from "types/Models/vendors";
import { FileObject } from "types/Models/fileObject";
import { makeFormData } from "utils/makeFormData";

type PayloadData = {
  groupId: string;
  page_num?: number;
  type?: string | null;
  search_term?: string | null;
  season: string;
  entityId: string;
  min_amount?: number;
  max_amount?: number;
  refresh_merchants?: boolean | null;
  sort_order?: string;
};

export const vendorsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getVendors: build.query<Vendor[], PayloadData>({
      query: ({
        groupId,
        entityId,
        search_term,
        type,
        page_num,
        season,
        min_amount,
        max_amount,
        refresh_merchants,
        sort_order,
      }) => {
        let queryUrl = qs.stringify(
          {
            search_term,
            merchant_type: type,
            page_num,
            season,
            min_amount,
            max_amount,
            refresh_merchants,
            sort_order,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchant/${queryUrl}`,
        };
      },
      providesTags: ["VENDORS"],
    }),
    addVendors: build.mutation<
      Vendor,
      {
        groupId: string;
        payload: {
          name: string;
          email?: string;
          total_amount?: number;
          amount_paid_in_season?: string;
        };
        entityId: string;
      }
    >({
      query: ({ groupId, payload, entityId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/create-merchant/`,
          method: "post",
          body: payload,
        };
      },
      invalidatesTags: ["VENDORS", "TRANSACTION_MERCHANTS"],
    }),
    editVendors: build.mutation<
      Vendor,
      {
        groupId: string;
        uuid: string;
        payload: {
          name?: string;
          email?: string;
          merchant_type?: "CPA_REVIEWED" | "NOT_REQUIRED" | "SYSTEM_REVIEWED";
          season?: string;
          amount_paid_in_season?: string;
        };
      }
    >({
      query: ({ groupId, uuid, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/merchant/${uuid}/update/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["VENDORS", "Merchants"],
    }),
    deleteVendor: build.mutation<
      Vendor,
      {
        groupId: string;
        merchantId: string;
      }
    >({
      query: ({ groupId, merchantId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/merchant/${merchantId}/delete/`,
          method: "delete",
        };
      },
      invalidatesTags: ["VENDORS"],
    }),
    uploadForm1099File: build.mutation<
      FileObject,
      {
        file?: File;
        fileId?: string;
        merchantId: string;
        groupId: string;
        entityId: string;
        form1099Document: string;
      }
    >({
      query: ({
        file,
        fileId,
        merchantId,
        groupId,
        entityId,
        form1099Document,
      }) => {
        const formData = new FormData();
        formData.append("form_1099_document_type", form1099Document);

        if (file) {
          formData.append("file", file);
        }
        if (fileId) {
          formData.append("file_id", fileId);
        }

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchant/${merchantId}/file_upload/`,
          method: "post",
          body: formData,
        };
      },
      invalidatesTags: ["VENDORS"],
    }),
    mergeMerchants: build.mutation<
      any,
      {
        parent_merchant_id: string;
        child_merchant_ids: string[];
        group_id: string;
        entity_id: string;
      }
    >({
      query: ({
        child_merchant_ids,
        parent_merchant_id,
        group_id,
        entity_id,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${group_id}/entity/${entity_id}/merge/merchant/`,
          method: "post",
          body: { child_merchant_ids, parent_merchant_id },
        };
      },
      invalidatesTags: ["VENDORS"],
    }),

    uploadMerchantCSV: build.mutation<
      fileAndFolder,
      { entityId: string; csv: File }
    >({
      query: ({ entityId, csv }) => {
        const payload = makeFormData({
          csv: csv,
        });
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/upload_merchant_csv/`,
          method: "POST",
          body: payload,
        };
      },
    }),

    getParsedCSV: build.query<
      MerchantCSVResponse[],
      { entityId: string; csvId: string }
    >({
      query: ({ entityId, csvId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/csv/${csvId}/merchant_csv_response/`,
        };
      },
    }),

    bulkMerchantUpload: build.mutation<
      void,
      { entityId: string; merchants: MerchantCSVResponse[] }
    >({
      query: ({ entityId, merchants }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/bulk_merchant_upload/`,
          method: "POST",
          body: { merchant_datas: merchants },
        };
      },
      invalidatesTags: ["VENDORS", "TRANSACTION_MERCHANTS"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVendorsQuery,
  useAddVendorsMutation,
  useEditVendorsMutation,
  useDeleteVendorMutation,
  useUploadForm1099FileMutation,
  useMergeMerchantsMutation,
  useLazyGetVendorsQuery,
  useUploadMerchantCSVMutation,
  useGetParsedCSVQuery,
  useBulkMerchantUploadMutation,
} = vendorsApi;
