import { CommentThread, ActiveComment } from "components/AddComment/AddComment";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { CrossIcon } from "components/icons/CrossIcon";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useEffect, useRef } from "react";
import { GeneralLedgerParams } from "store/apis/generalLedger";
import { JournalEntryParams } from "store/apis/journalEntry";
import { Note, NoteResponse, useGetNotesQuery } from "store/apis/notes";
import { InteractiveNewReportParams } from "store/apis/reports";
import { TransactionQuery } from "store/apis/transactions";

export const CommentsSlider = ({
  onClose,
  commentTypes,
  transactionFilters,
  generalLedgerFilters,
  journalEntryFilters,
  reportFilters,
  activeComment,
  setActiveComment,
}: {
  onClose?: () => void;
  commentTypes: (Note["comment_type"] | "REPORTS_COMMENT")[];
  transactionFilters?: TransactionQuery;
  generalLedgerFilters?: GeneralLedgerParams;
  journalEntryFilters?: JournalEntryParams;
  reportFilters?: InteractiveNewReportParams;
  activeComment: string | null;
  setActiveComment: (commentId: NoteResponse) => void;
}) => {
  const entityId = useCurrentEntityId();

  const {
    data: notes,
    isLoading,
    isSuccess,
  } = useGetNotesQuery(
    {
      entityId,
      types: commentTypes,
      transactionFilters,
      generalLedgerFilters,
      journalEntryFilters,
      reportFilters,
    },
    {
      skip: !entityId,
    }
  );

  const ref = useRef<Record<string, HTMLElement>>({});

  useEffect(() => {
    if (activeComment && ref.current[activeComment] && ref) {
      ref.current[activeComment].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeComment]);

  return (
    <ActiveComment
      activeComment={activeComment}
      setActiveComment={setActiveComment}
    >
      <div>
        <div className="t-w-full t-flex t-gap-4 t-sticky t-top-0 t-pt-4 t-bg-white t-pb-4 t-items-center t-justify-between t-z-header">
          <p className="t-text-subtitle t-m-0">Comments</p>
          {onClose && (
            <Button customType="ghost_icon" size="small" onClick={onClose}>
              <CrossIcon />
            </Button>
          )}
        </div>

        <Async.Root
          isLoading={isLoading}
          isSuccess={isSuccess}
          isEmpty={notes?.length === 0}
        >
          <Async.Empty>
            <div className="t-flex-1 t-text-neutral-30 t-flex t-h-full t-justify-center t-items-center">
              Add comments to see here!
            </div>
          </Async.Empty>
          <Async.Success>
            <div className="t-w-full t-flex t-flex-col t-gap-3 t-pb-10">
              {notes?.map((note, index) => {
                const id =
                  note.transaction?.uuid ||
                  note.category?.uuid ||
                  note.ledger_entry?.uuid ||
                  note.comments[0]?.uuid;

                return (
                  <CommentThread
                    ref={(r) => {
                      if (id && r) {
                        ref.current[id] = r;
                      }
                    }}
                    key={index}
                    notes={note}
                    type="TOGETHER"
                  />
                );
              })}
            </div>
          </Async.Success>
        </Async.Root>
      </div>
    </ActiveComment>
  );
};
