import Loader from "components/design/loader";
import { Children, createContext, ReactNode, useContext } from "react";

export type Rootprops = {
  children: ReactNode;
  isLoading: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  loaderType?: "primary" | "secondary";
  customLoader?: ReactNode;
  renderOnLoad?: ReactNode;
};

const AsyncContext = createContext<Omit<Rootprops, "children">>({
  isLoading: false,
  isSuccess: false,
  isEmpty: false,
  loaderType: "primary",
});

const LoaderScreen = ({
  customLoader,
  loaderType,
}: Pick<Rootprops, "customLoader" | "loaderType">) => {
  if (customLoader) {
    return customLoader;
  }

  if (loaderType === "secondary") {
    return (
      <div className="t-h-4 t-w-4">
        <Loader customType={loaderType} />
      </div>
    );
  }

  if (loaderType === "primary") {
    return <Loader customType={loaderType} />;
  }

  return null;
};

const Root = ({
  children,
  isLoading,
  isSuccess,
  isEmpty,
  loaderType = "primary",
  renderOnLoad,
  customLoader,
}: Rootprops) => {
  const Success = Children.toArray(children).filter(
    ({ type: { name } }: any) => name === "Success"
  )?.[0];

  if (import.meta.env.DEV && !Success) {
    throw new Error("Success Screen Not Found!");
  }

  return (
    <AsyncContext.Provider
      value={{ isLoading, isSuccess, isEmpty, loaderType }}
    >
      <>
        {isLoading ? (
          renderOnLoad || (
            <LoaderScreen customLoader={customLoader} loaderType={loaderType} />
          )
        ) : (
          <>{children}</>
        )}
      </>
    </AsyncContext.Provider>
  );
};

const Success = ({ children }: { children: ReactNode }) => {
  const { isSuccess, isEmpty } = useContext(AsyncContext);

  if (isSuccess && !isEmpty) return <>{children}</>;
  return null;
};

const Empty = ({ children }: { children: ReactNode }) => {
  const { isSuccess, isEmpty } = useContext(AsyncContext);

  if (isSuccess && isEmpty) return <>{children}</>;
  return null;
};

const Async = { Root, Success, Empty };

export default Async;
