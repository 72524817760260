import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { FieldBuilder } from "components/Entity/EntityFormEngine/FieldBuilder";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import {
  useGetForm1099TemplateQuery,
  useGetForm1099TypesQuery,
  useSubmitForm1099TemplateMutation,
  useUpdateForm1099TemplateMutation,
} from "store/apis/Form1099";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import isEqual from "lodash/isEqual";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import Loader from "components/design/loader";
import { vendorsApi } from "store/apis/vendors";
import { useDispatch } from "react-redux";

const MISC_FORM_TEMPLATE_TYPE = import.meta.env
  .VITE_APP_FORM_1099_TEMPLATE_TYPE_MISC;

export const Form1099Fields = ({
  isOpen,
  close,
  wFormMapId,
  form1099Type,
  season,
  isInprogress,
  entityId,
}: {
  isOpen: boolean;
  close: () => void;
  wFormMapId: string;
  form1099Type: string;
  season: string;
  isInprogress?: boolean;
  entityId: string;
}) => {
  const { successToast, alertToast } = useToast();
  const dispatch = useDispatch();
  const [formTemplateId, setFormTemplateId] = useState<string | null>();
  const [
    updateForm1099Template,
    { isLoading: isUpdating, isSuccess: isUpdated },
  ] = useUpdateForm1099TemplateMutation();
  const [submitForm1099Template, { isLoading: isSubmittingForm }] =
    useSubmitForm1099TemplateMutation();

  const {
    data: form1099Types,
    isLoading: isGettingForm1099Types,
    isSuccess: gettingForm1099TypesSuccess,
  } = useGetForm1099TypesQuery(
    { entityId, season },
    { skip: !entityId || !season }
  );

  const initialFormTemplateId =
    form1099Types?.find((form) => form.form_key === form1099Type)?.uuid ||
    form1099Types?.find((form) => form.form_key === MISC_FORM_TEMPLATE_TYPE)
      ?.uuid;

  const {
    data: form1099Template,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetForm1099TemplateQuery(
    {
      entityId,
      formTemplateId: (formTemplateId || initialFormTemplateId) as string,
      parentObjectId: wFormMapId,
    },
    {
      skip:
        !(formTemplateId || initialFormTemplateId) || !wFormMapId || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );

  // Backend saves the selection of form type on a GET request, so can't rely on auto invalidation
  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(vendorsApi.util.invalidateTags([{ type: "VENDORS" }]));
    }
  }, [isFetching]);

  const initialValues = form1099Template?.sections.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.name] = field.value;
    });
    return acc;
  }, {} as Record<string, EntityDataAttribute["value"] | undefined>);

  const onChange = debounce(
    async ({
      name,
      value,
      fieldId,
    }: {
      name: string;
      value: EntityDataAttribute["value"];
      fieldId: string;
    }) => {
      const currnetValue = initialValues?.[name];

      const isSameValue = isEqual(
        JSON.stringify(value),
        JSON.stringify(currnetValue)
      );

      if (isSameValue) {
        return;
      }

      try {
        await updateForm1099Template({
          entityId,
          value,
          fieldId,
        }).unwrap();

        successToast({ message: "Form updated successfully" });
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  );

  const handleSubmit = async () => {
    try {
      await submitForm1099Template({
        formId: form1099Template?.uuid as string,
      }).unwrap();
      successToast({ message: "Form submitted successfully" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const handleFormTypeChange = (
    option: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (option instanceof Array) {
      return null;
    }
    setFormTemplateId(option?.value ?? null);
  };

  const formTemplateOptions =
    form1099Types?.map((form) => ({
      label: form.name,
      value: form.uuid,
    })) ?? [];

  const allRequiredFieldsFilled = form1099Template?.sections.every((section) =>
    section.fields.every((field) => {
      if (field.is_required) {
        const value = field.value?.toString().trim();

        return Boolean(value);
      }
      return true;
    })
  );

  return (
    <>
      <Async.Root
        isEmpty={false}
        isLoading={isGettingForm1099Types || !gettingForm1099TypesSuccess}
        isSuccess={gettingForm1099TypesSuccess}
        renderOnLoad={
          <Modal.Root open={isOpen} onOpenChange={close}>
            <Modal.Content size="xl">
              <Modal.Header>
                <Modal.Title>Form 1099 Template</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Loader />
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>
        }
      >
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <Modal.Root open={isOpen} onOpenChange={close}>
            <Formik initialValues={{ initialValues }} onSubmit={handleSubmit}>
              {({ submitForm }) => (
                <Form className="t-m-0">
                  <Modal.Content size="xl">
                    <Modal.Header>
                      <div>
                        <Modal.Title>Form 1099 Template</Modal.Title>
                        {isUpdated && (
                          <Modal.Subtitle>
                            <span className="t-flex t-items-center t-gap-2 t-transition-all t-text-body">
                              <img src={DoubleGreenTick} alt="Saved" />
                              Changes Autosaved
                            </span>
                          </Modal.Subtitle>
                        )}
                        {isUpdating && (
                          <Modal.Subtitle>
                            <div className="t-flex t-items-center t-gap-2 t-transition-all t-text-body t-h-4 t-w-4 t-mt-[9px]">
                              <Loader size="small" customType="secondary" />
                              Saving
                            </div>
                          </Modal.Subtitle>
                        )}
                      </div>

                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body>
                      <Combobox
                        name="formType"
                        label="Form Type"
                        isMulti={false}
                        size="small"
                        isDisabled={isInprogress}
                        isLoading={isGettingForm1099Types}
                        menuPortalTarget={document.body}
                        options={formTemplateOptions}
                        onChange={handleFormTypeChange}
                        value={
                          formTemplateOptions.find(
                            (option) =>
                              option.value === formTemplateId ||
                              option.value === initialFormTemplateId
                          ) ?? null
                        }
                      />

                      <Async.Root
                        isEmpty={false}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                      >
                        <Async.Empty>
                          <></>
                        </Async.Empty>
                        <Async.Success>
                          <div className="t-flex t-flex-col t-gap-6 t-mt-6 t-w-full">
                            {form1099Template?.sections.map((section) => (
                              <div key={section.uuid}>
                                <div className="t-text-subtitle t-mb-2">
                                  {section.name}
                                </div>
                                <div className="t-border t-border-solid t-border-neutral-0 t-w-full t-rounded-lg t-p-4">
                                  <div className="t-flex t-flex-col t-gap-6 t-w-full">
                                    {section.fields.map((field) => {
                                      return (
                                        <FieldBuilder
                                          field={{
                                            ...field,
                                            disabled:
                                              isInprogress || field.disabled,
                                          }}
                                          onChange={(val) =>
                                            onChange({
                                              ...val,
                                              fieldId: field.uuid,
                                            })
                                          }
                                          key={field.uuid}
                                        />
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Async.Success>
                      </Async.Root>
                    </Modal.Body>
                    <Modal.FooterButtonGroup>
                      <Modal.RawClose asChild>
                        <Button type="button">Cancel</Button>
                      </Modal.RawClose>
                      <Button
                        customType="primary"
                        onClick={submitForm}
                        isLoading={isSubmittingForm}
                        disabled={
                          isUpdating ||
                          isFetching ||
                          !allRequiredFieldsFilled ||
                          isSubmittingForm ||
                          isInprogress
                        }
                      >
                        Save
                      </Button>
                    </Modal.FooterButtonGroup>
                  </Modal.Content>
                </Form>
              )}
            </Formik>
          </Modal.Root>
        </Async.Success>
      </Async.Root>
    </>
  );
};
