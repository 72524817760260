import { Loader } from "components/DesignSystem/Loader/Loader";
import { InvoicePreivew } from "components/InvoicePreivew/InvoicePreivew";
import { usePageTitle } from "hooks/usePageTitle";
import { useParams } from "react-router-dom";
import ErrorIllustrations from "static/images/ErrorIllustration.svg";
import { useGetPublicInvoiceQuery } from "store/apis/invoices";

export const PublicInvoiceView = () => {
  usePageTitle("Invoice");
  const { token } = useParams<{ token: string }>();
  const {
    data: invoice,
    isError,
    isLoading,
  } = useGetPublicInvoiceQuery({ token });

  if (isError) {
    return (
      <div className="t-flex t-flex-col t-items-center t-justify-center t-h-screen">
        <img src={ErrorIllustrations} alt="error" />
        <div className="t-flex t-flex-col t-gap-2 t-text-center">
          <div className="t-text-subtitle">Uh oh! Something went wrong.</div>
          <div className="t-text-body t-text-text-30">
            The invoice link might be wrong or expired.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="t-w-full t-h-full t-pt-6 t-px-60 t-overflow-y-auto">
      {isLoading ? (
        <div className="t-flex t-justify-center t-items-center t-h-80">
          <Loader />
        </div>
      ) : (
        <InvoicePreivew invoice={invoice} scale="scaled" />
      )}
    </div>
  );
};
