import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { LoadingIcon } from "components/icons/LoadingIcon";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { Form, Formik, FormikHelpers } from "formik";
import { useToast } from "hooks/useToast";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetPricingTiersQuery } from "store/apis/billing";
import { useGetAllCouponsQuery } from "store/apis/salesCrm";
import {
  SalesQuoteRequest,
  useGenerateSalesQuotesMutation,
  useLazyGetCalculatedTieredPriceQuery,
  useLazyGetSheetLinkQuery,
} from "store/apis/salesQuotes";
import { currency } from "utils/Currency";

dayjs.extend(localeData);

const initialQuoteRequest = {
  tax_quote: {
    2023: {
      company_name: "",
      entity_name: "",
      coupon: "",
      filing_data: {
        "1099": false,
        dft: false,
        "7004": false,
        fbar: false,
        cft: false,
        "1120": false,
        "5472": false,
        "926": false,
      },
      subsidiary_count: null,
    },
  },
  books_quote: {},
};

export const SalesQuotes = () => {
  const { alertToast } = useToast();
  const [has2024Tax, sethas2024Tax] = useState(false);
  const [has2024Books, sethas2024Books] = useState(false);

  const [generateQuote] = useGenerateSalesQuotesMutation();
  const [getSheetLink, { data: sheetLink }] = useLazyGetSheetLinkQuery();
  const [generatingSheet, setGeneratingSheet] = useState(false);
  const { data: coupons } = useGetAllCouponsQuery();

  const [
    getNewPricing2023,
    { data: pricingInfo2023, isLoading: isPricingLoading2023 },
  ] = useLazyGetCalculatedTieredPriceQuery();

  const [
    getNewPricing2024,
    { data: pricingInfo2024, isLoading: isPricingLoading2024 },
  ] = useLazyGetCalculatedTieredPriceQuery();

  const dispatch = useDispatch();

  const pollForSheetLink = async (
    id: string,
    resolve: (link: string) => void,
    reject: () => void,
    totalSeconds: number = 0
  ) => {
    const { sheet_link, status } = await getSheetLink({ linkId: id }).unwrap();

    if (status === "FAILED") {
      reject();
      return;
    }

    if (totalSeconds === 3 * 60 * 1000) {
      reject();
      return;
    }

    if (sheet_link) {
      resolve(sheet_link);
      return;
    }

    setTimeout(
      () => pollForSheetLink(id, resolve, reject, totalSeconds + 10000),
      10000
    );
  };

  const { data: pricingTiers } = useGetPricingTiersQuery();

  const onSubmitHandler = async (values: SalesQuoteRequest) => {
    setGeneratingSheet(true);
    const commonValues = {
      company_name: values.tax_quote[2023]?.company_name,
      entity_name: values.tax_quote[2023]?.entity_name,
      coupon: values.tax_quote[2023]?.coupon || undefined,
    };

    const updatedValues: SalesQuoteRequest = {
      tax_quote: {
        "2023": {
          ...values.tax_quote[2023],
          ...commonValues,
          subsidiary_count: values.tax_quote[2023].subsidiary_count
            ? Number(values.tax_quote[2023].subsidiary_count)
            : null,
        },
        "2024": values.tax_quote[2024]
          ? {
              ...values.tax_quote[2024],
              ...commonValues,
              subsidiary_count: values.tax_quote[2024].subsidiary_count
                ? Number(values.tax_quote[2024].subsidiary_count)
                : null,
            }
          : undefined,
      },
      books_quote: {
        "2023": values.books_quote[2023]
          ? {
              ...values.books_quote[2023],
              ...commonValues,
              discounted_pro_plan_total: values.books_quote[2023]
                .discounted_pro_plan_total
                ? Number(values.books_quote[2023].discounted_pro_plan_total)
                : null,
              historical_cleanup_start_month:
                values.books_quote[2023].historical_cleanup_start_month || null,
              pro_start_month: values.books_quote[2023].pro_start_month || null,
            }
          : undefined,
        "2024": values.books_quote[2024]
          ? {
              ...values.books_quote[2024],
              ...commonValues,
              discounted_pro_plan_total: values.books_quote[2024]
                .discounted_pro_plan_total
                ? Number(values.books_quote[2024].discounted_pro_plan_total)
                : null,
              historical_cleanup_start_month:
                values.books_quote[2024].historical_cleanup_start_month || null,
              pro_start_month: values.books_quote[2024].pro_start_month || null,
            }
          : undefined,
      },
    };

    try {
      const { uuid } = await generateQuote(updatedValues).unwrap();
      await new Promise<string>((resolve, reject) =>
        pollForSheetLink(uuid, resolve, reject)
      );
    } catch (error) {
      alertToast({
        message: "Failed to generate the quote, please contact support",
        title: "Failed",
      });
    }
    setGeneratingSheet(false);
  };

  const add2024Books = (
    values: SalesQuoteRequest,
    setValues: FormikHelpers<SalesQuoteRequest>["setValues"]
  ) => {
    sethas2024Books(true);
    const books2023 = values.books_quote[2023];

    const updatedValues = {
      ...values,
      books_quote: {
        ...values.books_quote,
        2024: {
          ...books2023!,
          historical_cleanup_start_month: "JAN",
          pro_start_month: dayjs().format("MMM").toLocaleUpperCase(),
        },
      },
    };

    setValues(updatedValues);
  };

  const remove2024Books = (
    values: SalesQuoteRequest,
    setValues: FormikHelpers<SalesQuoteRequest>["setValues"]
  ) => {
    sethas2024Books(false);
    const updatedValues = {
      ...values,
      books_quote: {
        ...values.books_quote,
        2024: undefined,
      },
    };
    setValues(updatedValues);
  };

  const remove2024Tax = (
    values: SalesQuoteRequest,
    setValues: FormikHelpers<SalesQuoteRequest>["setValues"]
  ) => {
    sethas2024Tax(false);
    const updatedValues = {
      ...values,
      tax_quote: {
        ...values.tax_quote,
        2024: undefined,
      },
    };
    setValues(updatedValues);
  };

  const add2024Tax = (
    values: SalesQuoteRequest,
    setValues: FormikHelpers<SalesQuoteRequest>["setValues"]
  ) => {
    sethas2024Tax(true);
    const updatedValues = {
      ...values,
      tax_quote: {
        ...values.tax_quote,
        2024: {
          ...values.tax_quote[2023],
        },
      },
    };
    setValues(updatedValues);
  };

  const onChange = (
    e: ChangeEvent<HTMLSelectElement>,
    values: SalesQuoteRequest
  ) => {
    const name = e.target.name;
    const value = e.target.value;
    const currentNameKey = name.split(".").at(-1) || "";

    if (
      name === "books_quote.2023.pro_start_month" ||
      name === "books_quote.2023.historical_cleanup_start_month" ||
      name === "books_quote.2023.tier_id"
    ) {
      getNewPricing2023({
        pro_start_month: values.books_quote[2023]?.pro_start_month,
        historical_cleanup_start_month:
          values.books_quote[2023]?.historical_cleanup_start_month,
        tier_id: values.books_quote[2023]?.tier_id,
        [currentNameKey]: value,
      });
    }

    if (
      name === "books_quote.2024.pro_start_month" ||
      name === "books_quote.2024.historical_cleanup_start_month" ||
      name === "books_quote.2024.tier_id"
    ) {
      getNewPricing2024({
        pro_start_month: values.books_quote[2024]?.pro_start_month,
        historical_cleanup_start_month:
          values.books_quote[2024]?.historical_cleanup_start_month,
        tier_id: values.books_quote[2024]?.tier_id,
        [currentNameKey]: value,
      });
    }
  };

  return (
    <div className="t-flex t-w-full t-flex-col">
      <Header title="Sales quotes generator" />
      <div className="t-p-5">
        <Formik initialValues={initialQuoteRequest} onSubmit={onSubmitHandler}>
          {({ values, setValues }) => (
            <Form className="t-w-full t-space-y-4 t-m-0 md:t-w-3/5 t-mb-36">
              <p className="t-m-0">Company details</p>
              <TextInput
                name="tax_quote.2023.company_name"
                label="Company group name"
              />
              <TextInput
                name="tax_quote.2023.entity_name"
                label="Entity name"
              />

              <SelectDropDown name="tax_quote.2023.coupon" label="Coupon">
                <option value="">Select coupon</option>
                {coupons?.map((coupon) => (
                  <option value={coupon.coupon_code} key={coupon.coupon_code}>
                    {coupon.coupon_code}
                  </option>
                ))}
              </SelectDropDown>
              <hr />
              <p className="t-m-0 !t-mt-8">2023 Tax Quote</p>
              <TextInput
                name="tax_quote.2023.subsidiary_count"
                label="No. of Subsidiary"
              />

              <SwitchField
                label="Form 1099"
                name="tax_quote.2023.filing_data.[1099]"
              />
              <SwitchField
                label="Delaware Franchise Tax & Annual Report"
                name="tax_quote.2023.filing_data.dft"
              />
              <SwitchField
                label="Form 7004"
                name="tax_quote.2023.filing_data.[7004]"
              />
              <SwitchField
                label="FBAR"
                name="tax_quote.2023.filing_data.fbar"
              />
              <SwitchField
                label="California Franchise Tax"
                name="tax_quote.2023.filing_data.cft"
              />
              <SwitchField
                label="Form 1120"
                name="tax_quote.2023.filing_data.[1120]"
              />
              <SwitchField
                label="Form 5472"
                name="tax_quote.2023.filing_data.[5472]"
              />
              <SwitchField
                label="Form 926"
                name="tax_quote.2023.filing_data.[926]"
              />
              <SwitchField
                label="Beneficial Ownership Information Reporting"
                name="tax_quote.2023.filing_data.boi"
              />

              <hr />

              <div className="t-flex t-gap-3 t-items-center">
                <p className="t-m-0">2024 Tax Quote</p>
                <Button
                  size="small"
                  type="button"
                  onClick={
                    has2024Tax
                      ? // @ts-ignore
                        remove2024Tax.bind(this, values, setValues)
                      : // @ts-ignore
                        add2024Tax.bind(this, values, setValues)
                  }
                >
                  {has2024Tax ? "Remove" : "Add"}
                </Button>
              </div>

              {has2024Tax && (
                <>
                  <TextInput
                    name="tax_quote.2024.subsidiary_count"
                    label="No. of Subsidiary"
                  />

                  <SwitchField
                    name="tax_quote.2024.filing_data.[1099]"
                    label="Form 1099"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.dft"
                    label="Delaware Franchise Tax & Annual Report"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.[7004]"
                    label="Form 7004"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.fbar"
                    label="FBAR"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.cft"
                    label="California Franchise Tax"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.[1120]"
                    label="Form 1120"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.[5472]"
                    label="Form 5472"
                  />
                  <SwitchField
                    name="tax_quote.2024.filing_data.[926]"
                    label="Form 926"
                  />
                  <SwitchField
                    label="Beneficial Ownership Information Reporting"
                    name="tax_quote.2024.filing_data.boi"
                  />
                </>
              )}
              <hr />

              <p className="t-m-0">2023 Books Quote</p>

              <SelectDropDown
                onChange={(e) => onChange(e, values)}
                name="books_quote.2023.tier_id"
                label="Pricing tier"
              >
                <option value="">Select pricing tier</option>
                {pricingTiers?.map((tier) => (
                  <option value={tier.uuid} key={tier.uuid}>
                    {tier.range}
                  </option>
                ))}
              </SelectDropDown>

              <SelectDropDown
                onChange={(e) => onChange(e, values)}
                name="books_quote.2023.historical_cleanup_start_month"
                label="Historical clean up starts at"
              >
                <option value="">Select month</option>
                {dayjs.monthsShort().map((m) => (
                  <option value={m.toUpperCase()} key={m}>
                    {m}
                  </option>
                ))}
              </SelectDropDown>

              <SelectDropDown
                onChange={(e) => onChange(e, values)}
                name="books_quote.2023.pro_start_month"
                label="Pro starts at (Ends at Dec)"
              >
                <option value="">Select month</option>
                {dayjs.monthsShort().map((m) => (
                  <option value={m.toUpperCase()} key={m}>
                    {m}
                  </option>
                ))}
              </SelectDropDown>

              <TextInput
                label={
                  <>
                    Historical price{" "}
                    {Boolean(pricingInfo2023?.pricing) && (
                      <span>
                        (Calculated price{" "}
                        {currency({ amount: pricingInfo2023?.pricing! })})
                      </span>
                    )}
                  </>
                }
                name="books_quote.2023.discounted_pro_plan_total"
              />

              <hr />

              <div className="t-flex t-gap-3 t-items-center">
                <p className="t-m-0">2024 Books Quote</p>
                <Button
                  type="button"
                  size="small"
                  onClick={
                    has2024Books
                      ? // @ts-ignore
                        remove2024Books.bind(this, values, setValues)
                      : // @ts-ignore
                        add2024Books.bind(this, values, setValues)
                  }
                >
                  {has2024Books ? "Remove" : "Add"}
                </Button>
              </div>

              {has2024Books && (
                <>
                  <SelectDropDown
                    name="books_quote.2024.tier_id"
                    label="Pricing tier"
                    onChange={(e) => onChange(e, values)}
                  >
                    <option value="">Select pricing tier</option>
                    {pricingTiers?.map((tier) => (
                      <option value={tier.uuid} key={tier.uuid}>
                        {tier.range}
                      </option>
                    ))}
                  </SelectDropDown>

                  <SelectDropDown
                    name="books_quote.2024.historical_cleanup_start_month"
                    label="Historical clean up starts at"
                    onChange={(e) => onChange(e, values)}
                  >
                    <option value="">Select month</option>
                    {dayjs.monthsShort().map((m) => (
                      <option value={m.toUpperCase()} key={m}>
                        {m}
                      </option>
                    ))}
                  </SelectDropDown>

                  <SelectDropDown
                    name="books_quote.2024.pro_start_month"
                    label="Pro starts at (Ends at Dec)"
                    onChange={(e) => onChange(e, values)}
                  >
                    <option value="">Select month</option>
                    {dayjs.monthsShort().map((m) => (
                      <option value={m.toUpperCase()} key={m}>
                        {m}
                      </option>
                    ))}
                  </SelectDropDown>

                  <TextInput
                    label={
                      <>
                        Historical price{" "}
                        {Boolean(pricingInfo2024?.pricing) && (
                          <span>
                            (Calculated price{" "}
                            {currency({ amount: pricingInfo2024?.pricing! })})
                          </span>
                        )}
                      </>
                    }
                    name="books_quote.2024.discounted_pro_plan_total"
                  />
                </>
              )}

              <div className="t-flex t-gap-5 t-items-center md:t-p-0 t-flex-wrap md:t-static md:t-shadow-none t-fixed t-bottom-0 t-p-5 t-w-full t-right-0 t-shadow-2xl t-bg-white">
                <Button customType="primary">Generate quote</Button>
                {generatingSheet ? (
                  <span className="t-inline-flex t-items-center t-gap-3">
                    {" "}
                    <span className="t-animate-spin t-inline-flex t-justify-center t-items-center">
                      <LoadingIcon />
                    </span>{" "}
                    <span>Generating sheet...</span>
                  </span>
                ) : (
                  <ConditionalLink to={sheetLink?.sheet_link || undefined}>
                    {sheetLink?.sheet_link ? "Go to Sheet" : ""}
                  </ConditionalLink>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
