import * as ToastPrimitive from "@radix-ui/react-toast";
import classNames from "classnames";
import { Cross } from "components/icons/Cross";
import { Error } from "components/icons/Error";
import { InfoFilled } from "components/icons/InfoFilled";
import { LoadingIcon } from "components/icons/LoadingIcon";
import { SolidCheck } from "components/icons/SolidCheck";
import { Warning } from "components/icons/Warning";
import { motion } from "framer-motion";
import { createContext, ReactNode, useContext } from "react";
import { Button } from "../Button/Button";

const ToastContext = createContext({
  size: "regular",
});

const TOAST_ROOT_TYPE_CLASSES = {
  /* @tw */
  DEFAULT: "t-border-blue t-bg-blue-10",
  /* @tw */
  SUCCESS: "t-border-green t-bg-green-10",
  /* @tw */
  WARNING: "t-border-yellow t-bg-yellow-10",
  /* @tw */
  ERROR: "t-border-red t-bg-red-10",
};

const TOAST_TYPE_ICONS = {
  default: <InfoFilled />,
  success: <SolidCheck />,
  warning: <Warning />,
  error: <Error />,
  loading: (
    <span className="t-flex t-origin-center t-animate-spin">
      <LoadingIcon />
    </span>
  ),
};

export type ToastCustomType =
  | "default"
  | "success"
  | "warning"
  | "error"
  | "loading";
export type ToastSizeType = "small" | "regular" | "large";

type RootProps = {
  customType?: ToastCustomType;
  children: ReactNode;
  icon?: boolean;
  size?: ToastSizeType;
} & ToastPrimitive.ToastProps;

type ActionProps = {
  onAction: () => void;
  children: ReactNode;
} & ToastPrimitive.ToastActionProps;

const Title = (props: ToastPrimitive.ToastTitleProps) => {
  return (
    <ToastPrimitive.Title
      {...props}
      className="all:unset t-text-subtitle-sm group-data-[type=default]:t-text-blue-100 group-data-[type=loading]:t-text-blue-100 group-data-[type=error]:t-text-red-100 group-data-[type=warning]:t-text-yellow-100 group-data-[type=success]:t-text-green-100 t-row-start-1 t-col-start-2 t-items-center t-flex"
    />
  );
};

const Description = (props: ToastPrimitive.ToastDescriptionProps) => {
  return (
    <ToastPrimitive.Description
      {...props}
      className="t-text-body-sm t-text-text-60 t-row-start-2 t-col-start-2"
    />
  );
};

const Viewport = (props: ToastPrimitive.ToastViewportProps) => {
  return <ToastPrimitive.Viewport {...props} className="t-list-none" />;
};

const Root = ({
  customType = "error",
  size = "small",
  icon = true,
  children,
  ...props
}: RootProps) => {
  return (
    <ToastContext.Provider value={{ size }}>
      <ToastPrimitive.Root
        {...props}
        onClick={(e) => {
          e.stopPropagation();
        }}
        data-type={customType}
      >
        <motion.li
          layout
          initial={{ x: 360 }}
          animate={{
            x: 0,
            transition: {
              opacity: {
                ease: "easeIn",
              },
            },
          }}
          exit={{
            opacity: 0,
            x: 360,
            transition: {
              opacity: {
                ease: "easeOut",
              },
            },
          }}
          transition={{
            type: "spring",
            mass: 1,
            damping: 30,
            stiffness: 200,
            duration: 0.1,
          }}
          style={{ WebkitTapHighlightColor: "transparent", width: "360px" }}
          className={classNames(
            "all:unset t-group t-mr-8 t-flex t-min-w-[17.5rem] t-max-w-[20.5rem] t-items-center t-justify-between t-gap-2 t-rounded t-border-[0.5px] t-border-solid t-text-subtitle-sm",
            {
              [TOAST_ROOT_TYPE_CLASSES.DEFAULT]:
                customType === "default" || customType === "loading",
              [TOAST_ROOT_TYPE_CLASSES.SUCCESS]: customType === "success",
              [TOAST_ROOT_TYPE_CLASSES.WARNING]: customType === "warning",
              [TOAST_ROOT_TYPE_CLASSES.ERROR]: customType === "error",
              "t-p-3": size === "small",
              "t-p-4": size !== "small",
            }
          )}
        >
          <div className="t-flex t-w-full t-items-center t-gap-2">
            <div
              className={classNames(
                "t-flex t-w-full t-justify-between t-gap-2",
                {
                  "t-flex-row t-items-center ":
                    size === "small" || size === "regular",
                }
              )}
            >
              <div className="t-grid t-gap-1 t-w-full t-relative t-grid-cols-[min-content_1fr_min-content_min-content]">
                <div className="t-self-center t-mr-1">
                  {TOAST_TYPE_ICONS[customType]}
                </div>
                {children}
              </div>
            </div>
          </div>
        </motion.li>
      </ToastPrimitive.Root>
    </ToastContext.Provider>
  );
};

const Close = ({ onClose }: { onClose: (v: boolean) => void }) => {
  return (
    <ToastPrimitive.Close asChild>
      <div className="t-row-start-1 t-col-start-4">
        <Button
          size="small"
          customType="ghost_icon"
          onClick={() => onClose(false)}
        >
          <Cross />
        </Button>
      </div>
    </ToastPrimitive.Close>
  );
};

const Action = ({ onAction, children, ...props }: ActionProps) => {
  const size = useContext(ToastContext).size;
  const buttonType = size === "large" ? "secondary" : "ghost";

  return (
    <ToastPrimitive.Action {...props} asChild>
      <div
        className={classNames("t-self-start t-row-span-2", {
          "t-mr-auto t-col-start-2 t-row-start-3": size === "large",
          "t-col-start-3 t-row-start-1": size !== "large",
        })}
      >
        <Button size="small" customType={buttonType} onClick={onAction}>
          {children}
        </Button>
      </div>
    </ToastPrimitive.Action>
  );
};

const Provider = ({
  duration = 3000,
  children,
  ...props
}: ToastPrimitive.ToastProviderProps) => {
  return (
    <ToastPrimitive.Provider {...props} duration={duration}>
      {children}
      <Toast.Viewport />
    </ToastPrimitive.Provider>
  );
};

const Toast = {
  ...ToastPrimitive,
  Title: Title,
  Viewport: Viewport,
  Root: Root,
  Close: Close,
  Description: Description,
  Action: Action,
  Provider: Provider,
};

export default Toast;
