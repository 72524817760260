import { array, object, string } from "yup";

export const reminderSettingValidation = () =>
  object({
    reminder_details: object({
      frequency: string().required(),
      is_reminder_enabled: string().required(),
      notification_channels: array().required(),
      on_date: string().when("frequency", {
        is: "ONE_TIME",
        then: string().nullable().required("Date is required"),
        otherwise: string().nullable().notRequired(),
      }),
      subscribers: array().when("notification_channels", {
        is: (val: string[] | undefined) => val?.includes("WHATSAPP"),
        then: array().length(1, "Send to is required").required(),
        otherwise: array().notRequired(),
      }),
    }),
    content_details: object({
      message_text: string().required(),
    }),
  });
