import { loadStripe } from "@stripe/stripe-js";
import { RestrictedRoute } from "RestrictedRoute";
import { AppLayout } from "components/AppLayout/AppLayout";
import { DeadlineAlarm } from "components/DeadlineAlarm/DeadlineAlarm";
import { Entities } from "components/Entity/Entity";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
  LeftBarTag,
} from "components/Sidebar/LeftBar";
import { TaskList } from "components/Task/TaskList";
import { TopBar } from "components/TopBar/TopBar";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { Home as TaxHomePage } from "components/home/Home";
import { Deadline } from "components/icons/LeftNav/Deadline";
import { Filings as FilingsIcon } from "components/icons/LeftNav/Filings";
import { Home } from "components/icons/LeftNav/Home";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { RegistrationsIcon } from "components/icons/LeftNav/RegistrationsIcon";
import { InkleTaxLogo } from "components/icons/Logos/InkleTaxLogo";
import { InkleTaxSymbol } from "components/icons/Logos/InkleTaxSymbol";
import AddTaskEntityModal from "components/tasks/modal/AddTaskEntityModal";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { ForeignCATask } from "components/tasks/taskForForeignCA/ForeignCATask";
import { TaxAndCompliance } from "components/taxCompliance/TaxAndCompliance";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAnalytics } from "hooks/useAnalytics";
import { useAuth } from "hooks/useAuth";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { AdminNavbar } from "pages/AdminDashboard";
import { PracticeNavbar } from "pages/PracticeDashboard";
import { Registrations } from "pages/Registrations/Registrations";
import { useContext, useEffect } from "react";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { UpgradeTaxPlan } from "./UpgradeTaxPlan";
import { useCurrentAppContext } from "contexts/CurrentAppContext";

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLIC_KEY!);

const Navbar = () => {
  const { type } = useAuth();

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        {type === FOREIGN_CA && (
          <div className="t-m-2">
            <GroupSelect />
          </div>
        )}
        <LeftBarItem exact to="/" icon={Home}>
          Home
        </LeftBarItem>
        <LeftBarItem to="/filings" icon={FilingsIcon}>
          Filings
        </LeftBarItem>
        <LeftBarItem to="/deadlines" icon={Deadline}>
          Deadline Alerts
        </LeftBarItem>
        <LeftBarItem
          to="/registrations"
          icon={RegistrationsIcon}
          suffix={<LeftBarTag type="blue">Beta</LeftBarTag>}
        >
          Registrations
        </LeftBarItem>
        <LeftBarItem to="/entities" icon={MyEntities}>
          My Entities
        </LeftBarItem>
      </div>

      <div>
        <LeftBarSeparator />
        <NavAcrossApps />
      </div>
    </div>
  );
};

export const TaxDashboard = () => {
  const { isCustomer, isForeignCA } = useRoleBasedView();

  const { path, url } = useRouteMatch();
  const { authtoken } = useContext(authContext);

  const Nav = isCustomer ? Navbar : isForeignCA ? PracticeNavbar : AdminNavbar;

  const baseLink = isCustomer ? url : isForeignCA ? "/practice" : "/admin";

  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: "Tax" },
    });
  }, []);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <LeftBarLogo
              url={baseLink}
              logo={InkleTaxLogo}
              symbol={InkleTaxSymbol}
            />
          }
          showCollapse
        >
          {<Nav />}
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={<LeftBarLogo logo={InkleTaxLogo} symbol={InkleTaxSymbol} />}
          nav={
            <LeftBar>
              <Nav />
            </LeftBar>
          }
        />
      }
      banner={<DashboardBanner />}
    >
      <Switch>
        <RestrictedRoute
          exact
          path={`${path}/tax`}
          component={TaxAndCompliance}
        />
        <RestrictedRoute
          exact
          path={`${path}/registrations`}
          component={Registrations}
        />
        <RestrictedRoute
          exact
          path={`${path}/deadlines`}
          component={DeadlineAlarm}
        />
        <RestrictedRoute
          exact
          path={`${path}/filings/addtask`}
          render={() => <TemplateMenu to="/filings" />}
        />
        <RestrictedRoute
          exact
          path={`${path}/filings/addtask/:templateId`}
          render={() => <AddTaskEntityModal to="/filings" />}
        />
        <RestrictedRoute exact path={`${path}/tasks/addtask`}>
          <Redirect to={`${path}/filings/addtask`} />
        </RestrictedRoute>
        <RestrictedRoute
          path={`${path}/filings/:taskId`}
          component={FilingDetails}
        />
        <RestrictedRoute
          exact
          path={`${path}/tasks/:taskId`}
          render={({
            match: {
              params: { taskId },
            },
          }) => <Redirect to={`${path}/filings/${taskId}`} />}
        />

        <RestrictedRoute
          exact
          path={`${path}/tasks/addtask/:templateId`}
          render={({
            match: {
              params: { templateId },
            },
          }) => <Redirect to={`/filings/addtask/${templateId}`} />}
        />
        <RestrictedRoute
          path={`${path}/filings`}
          component={authtoken?.is_any_service_user ? ForeignCATask : TaskList}
        />
        <RestrictedRoute exact path="/tasks">
          <Redirect to={`${path}/filings`} />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/entities`} component={Entities} />
        <RestrictedRoute exact path={path} component={TaxHomePage} />

        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
