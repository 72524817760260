import { Search } from "components/DesignSystem/Search/Search";
import { ProductBody } from "components/product/ProductBody";
import {
  PRODUCT_BOOKS,
  PRODUCT_INTRAGROUP,
  PRODUCT_MAILROOM,
  PRODUCT_SALESTAX,
  PRODUCT_TAX,
} from "constants/billing";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import SmallTax from "static/images/SmallTax.svg";
import SmallBooks from "static/images/SmallBooks.svg";
import SmalMailroom from "static/images/SmallMailroom.svg";
import SmallSalesTax from "static/images/SmallSalesTax.svg";
import SmallIntragroup from "static/images/SmallIntragroup.svg";
import { ProductChip } from "components/product/ProductChip";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { AddProductToCart } from "components/AddProductToCart/AddProductToCart";
import { CartModal } from "components/CartModal/CartModal";
import { PayDebt } from "components/PayDebt/PayDebt";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { parse, stringify } from "qs";
import { Button } from "components/DesignSystem/Button/Button";
import { PlusIcon } from "components/icons/PlusIcon";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import {
  CartTemplate,
  useGetCartTemplatesQuery,
  usePushToCartFromTemplateMutation,
} from "store/apis/products";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { useState } from "react";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { DividerLine } from "components/icons/DividerLine";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { BILLING_CYCLE } from "dictionaries";
import ReactCountryFlag from "react-country-flag";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type productQueryType =
  | typeof PRODUCT_BOOKS
  | typeof PRODUCT_TAX
  | typeof PRODUCT_INTRAGROUP
  | typeof PRODUCT_SALESTAX
  | typeof PRODUCT_MAILROOM;

const TemplateItems = ({
  templateItems,
}: {
  templateItems: CartTemplate["items"];
}) => {
  const columnHelper = createColumnHelper<CartTemplate["items"][0]>();

  const columns = [
    columnHelper.accessor("product_details.product_name", {
      header: "Item",
      cell: (info) => {
        const {
          row: {
            original: {
              ra_state_name,
              tier_information,
              product_details: {
                season,
                has_balancing_payment,
                company_entity,
                state,
                product_name,
                slabs,
              },
            },
          },
        } = info;

        return (
          <div>
            <p className="t-m-0">{info.getValue()}</p>
            <p className="t-m-0 t-flex t-gap-1.5 t-items-center t-text-body-sm t-text-text-30">
              {company_entity && (
                <>
                  <ReactCountryFlag
                    countryCode={company_entity?.country_code}
                    svg
                  />
                  {company_entity?.name}{" "}
                </>
              )}
              {ra_state_name && <span>{ra_state_name}</span>}
              {tier_information && <span>{tier_information.range}</span>}
              {company_entity?.name && season && (
                <span className="t-text-neutral-20">
                  <DividerLine />
                </span>
              )}{" "}
              <span>{season}</span>
              {season && state && (
                <span className="t-text-neutral-20">
                  <DividerLine />
                </span>
              )}{" "}
              <span>{state?.name}</span>
            </p>
            {has_balancing_payment && (
              <p className="t-m-0 t-flex t-gap-1.5 t-items-center t-mt-1 t-text-body-sm">
                <span className="t-text-red t-flex">
                  <InfoFilledSmall />
                </span>
                <span>May have extra charges.</span>

                <Modal.Root>
                  <Modal.Trigger asChild>
                    {slabs && (
                      <button className="all:unset t-text-purple">
                        Learn more
                      </button>
                    )}
                  </Modal.Trigger>
                  <Modal.Content>
                    <Modal.Header>
                      <Modal.Title>{product_name}</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>

                    <Modal.Body>
                      {slabs && (
                        <div
                          className="t-text-body"
                          dangerouslySetInnerHTML={{
                            __html: slabs,
                          }}
                        />
                      )}
                    </Modal.Body>

                    <Modal.Footer>
                      <Modal.RawClose asChild>
                        <Button block>Go back to cart</Button>
                      </Modal.RawClose>
                    </Modal.Footer>
                  </Modal.Content>
                </Modal.Root>
              </p>
            )}
          </div>
        );
      },
      size: 70,
    }),

    columnHelper.accessor("quantity", {
      header: "QTY",
      size: 10,
    }),

    columnHelper.accessor("total", {
      header: "price",
      cell: (info) => {
        const { discount, subtotal } = info.row.original;

        return (
          <div className="">
            <p
              className={classNames("t-m-0", {
                "t-line-through t-text-body-sm t-text-text-30":
                  discount && discount.discount_value > 0,
              })}
            >
              <AmountSuperScript amount={subtotal} />
              {info.row.original.subscription && (
                <span className="t-text-text-30">
                  /
                  {BILLING_CYCLE[info.row.original.subscription?.billing_cycle]}
                </span>
              )}
            </p>
            {Boolean(discount) && discount && discount.discount_value > 0 && (
              <p className="t-m-0">
                <AmountSuperScript amount={info.getValue()} />
                {info.row.original.subscription && (
                  <span className="t-text-text-30">
                    /
                    {
                      BILLING_CYCLE[
                        info.row.original.subscription?.billing_cycle
                      ]
                    }
                  </span>
                )}
              </p>
            )}
          </div>
        );
      },
      size: 10,
    }),
  ];

  const table = useReactTable({
    columns,
    data: templateItems,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { minSize: 10 },
  });

  return <TableUI table={table} />;
};

export const Product = () => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const isBooksActive = query.get(PRODUCT_BOOKS);
  const isTaxActive = query.get(PRODUCT_TAX);
  const isMailroomActive = query.get(PRODUCT_MAILROOM);
  const isSalesTaxActive = query.get(PRODUCT_SALESTAX);
  const isIntragroupActive = query.get(PRODUCT_INTRAGROUP);
  const search = query.get("search") || "";
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const groupId = useCurrentGroupContext()?.uuid;
  const entityId = useCurrentEntityId();
  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>();
  const { isAdmin } = useRoleBasedView();

  const { data: cartTemplates } = useGetCartTemplatesQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const [addToCartFromTemplate, { isLoading: isAddingToCart, originalArgs }] =
    usePushToCartFromTemplateMutation();

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "search", value: e.target.value || null });
  };

  const updateProductType = (
    query: productQueryType,
    value: boolean | null
  ) => {
    update({ query, value: value || null });
  };

  const { successToast, alertToast } = useToast();

  const confirmAddToCart = async () => {
    if (!currentTemplateId) {
      return;
    }

    try {
      await addToCartFromTemplate({
        groupId: groupId!,
        entityId: entityId!,
        templateId: currentTemplateId,
      }).unwrap();
      setCurrentTemplateId(null);
      successToast({ title: "Template added to cart" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <div>
      <div className="t-flex t-gap-4 t-justify-between">
        <div className="t-w-1/2 ">
          <Search
            block
            onChange={onSearch}
            defaultValue={search}
            placeholder="Search..."
          />
        </div>
        {isAdmin && (
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <Button size="small">
                <span className="t-flex t-gap-1">
                  <PlusIcon />
                  <span>Bulk add to cart</span>
                </span>
              </Button>
            </Dropdown.Trigger>
            <Dropdown.Content sideOffset={10} align="end">
              {cartTemplates?.map((template) => (
                <Dropdown.Item
                  key={template.uuid}
                  onSelect={() => setCurrentTemplateId(template.uuid)}
                >
                  {template.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Content>
          </Dropdown.Root>
        )}
      </div>
      <div className="t-flex t-gap-2 t-items-center t-wrap t-mt-4 t-mb-10">
        <ProductChip
          onClick={() => updateProductType(PRODUCT_BOOKS, true)}
          isActive={Boolean(isBooksActive)}
          onClose={() => updateProductType(PRODUCT_BOOKS, null)}
          icon={<img src={SmallBooks} alt="SmallBooks" />}
        >
          Books
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_TAX, true)}
          isActive={Boolean(isTaxActive)}
          onClose={() => updateProductType(PRODUCT_TAX, null)}
          icon={<img src={SmallTax} alt="SmallTax" />}
        >
          Tax
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_MAILROOM, true)}
          isActive={Boolean(isMailroomActive)}
          onClose={() => updateProductType(PRODUCT_MAILROOM, null)}
          icon={<img src={SmalMailroom} alt="SmalMailroom" />}
        >
          Mailroom
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_SALESTAX, true)}
          isActive={Boolean(isSalesTaxActive)}
          onClose={() => updateProductType(PRODUCT_SALESTAX, null)}
          icon={<img src={SmallSalesTax} alt="SmallSalesTax" />}
        >
          Sales Tax
        </ProductChip>
        <ProductChip
          onClick={() => updateProductType(PRODUCT_INTRAGROUP, true)}
          isActive={Boolean(isIntragroupActive)}
          onClose={() => updateProductType(PRODUCT_INTRAGROUP, null)}
          icon={<img src={SmallIntragroup} alt="SmallIntragroup" />}
        >
          Intragroup
        </ProductChip>
      </div>

      <ProductBody />
      <Switch>
        <Route exact path={`${path}/add-to-cart/:productId`}>
          <AddProductToCart
            onClose={() =>
              history.push(link(url, { moreQuery: parsedSearch as {} }))
            }
          />
        </Route>
        <Route path={`${path}/cart`}>
          <CartModal
            open
            onClose={() =>
              history.push(link(url, { moreQuery: parsedSearch as {} }))
            }
          />
        </Route>
        <Route exact path={`${path}/payment-pending`}>
          <PayDebt
            isOpen={true}
            onClose={() =>
              history.push(link(url, { moreQuery: parsedSearch as {} }))
            }
          />
        </Route>
      </Switch>
      <Modal.Root
        open={Boolean(currentTemplateId)}
        onOpenChange={() => setCurrentTemplateId(null)}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Add template to cart</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <TemplateItems
              templateItems={
                cartTemplates?.find(
                  (template) => template.uuid === currentTemplateId
                )?.items || []
              }
            />
          </Modal.Body>

          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              onClick={confirmAddToCart}
              isLoading={isAddingToCart}
              disabled={isAddingToCart}
            >
              Add to cart
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
