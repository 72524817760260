import classNames from "classnames";
import { CallHistory } from "components/chat/CallHistory";
import { Chat } from "components/chat/Chat";
import Toggle from "components/design/toggle";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { DocumentPreviewModal } from "components/PreviewModal";
import { SlackIntegration } from "components/SlackIntegration";
import { ANNOUNCEMENTS_CHANNEL } from "constants/chatType";
import { RANDOM_DATE_FOR_CHANNELS_LAST_MESSAGE } from "constants/date";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { ComponentProps, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { RootState } from "store/store";
import { useChatContext } from "stream-chat-react";
import BroadcastIcon from "components/icons/Broadcast";
import Modal from "components/DesignSystem/Modal/Modal";
import { BroadcastMessage } from "components/BroadcastMessage/BroadcastMessage";
import { useModal } from "hooks/useModal";
import { ChatSettings } from "components/ChatSettings/ChatSettings";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { withChatFilterContext } from "contexts/ChatFilterContext";
import { useStreamFilterQuery } from "hooks/useStreamFilterQuery";
import { Reminders } from "./Chat/Reminders";

const ChatUI = (props: ComponentProps<typeof Chat> & { path: string }) => {
  const { setActiveChannel, client, channel } = useChatContext();
  const { channelId, messageId } = useParams<{
    channelId?: string;
    messageId?: string;
  }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const filterQuery = useStreamFilterQuery();

  const onChannelSelect = (newChannelId: string, messageId?: string) => {
    let newPath = `${props.path}/${newChannelId}`;

    if (messageId) {
      newPath = `${newPath}/${messageId}`;
    }

    if (search) {
      newPath = `${newPath}${search}`;
    }

    history.push(newPath);
    return;
  };

  useEffect(() => {
    if (channelId) {
      const channelData = client.channel("messaging", channelId, {});
      setActiveChannel(channelData);
    }
  }, [channelId, client]);

  useEffect(() => {
    if (messageId && channel?.id === channelId) {
      dispatch(setScrollToMessageId(messageId));
    }
  }, [channel?.id, channelId, messageId]);

  const filters = {
    ...filterQuery,
    ...props.filters,
  };

  return (
    <Chat
      channelId={channelId}
      onChannelSelect={onChannelSelect}
      {...props}
      filters={filters}
    />
  );
};

const ChatHome = ({ path }: { path: string }) => {
  const { client } = useChatContext();

  const { isAdmin, isCpa, isCustomer, isUserTypeForeignCA, isForeignCA } =
    useRoleBasedView();
  const chatSearchValue = useSelector(
    (state: RootState) => state.chatSearch.value
  );

  const isFCA = isUserTypeForeignCA || isForeignCA;

  const filterEmptyChatForAdmin =
    (isAdmin || isCpa || isFCA) && !chatSearchValue
      ? {
          last_non_broadcast_message_at: {
            $gte: RANDOM_DATE_FOR_CHANNELS_LAST_MESSAGE,
          },
        }
      : {};

  return (
    <ChatUI
      path={path}
      filters={{
        type: "messaging",
        ...(isCustomer
          ? {}
          : {
              custom_type: {
                $nin: [ANNOUNCEMENTS_CHANNEL],
              },
            }),

        members: { $in: [client.userID!] },
        ...filterEmptyChatForAdmin,
      }}
    />
  );
};

const MyMessage = ({ path }: { path: string }) => {
  const { authtoken } = useContext(authContext);
  const { client, setActiveChannel } = useChatContext();
  const { isAdmin, isCpa, isUserTypeForeignCA, isForeignCA } =
    useRoleBasedView();
  const chatSearchValue = useSelector(
    (state: RootState) => state.chatSearch.value
  );

  const isFCA = isUserTypeForeignCA || isForeignCA;

  useEffect(() => {
    setActiveChannel();
  }, []);

  return (
    <ChatUI
      path={path}
      filters={Object.assign(
        {
          type: "messaging",
          assigned_agent: authtoken.uuid,
          members: { $in: [client.userID!] },
        },
        (isAdmin || isCpa || isFCA) && !chatSearchValue
          ? {
              last_non_broadcast_message_at: {
                $gte: RANDOM_DATE_FOR_CHANNELS_LAST_MESSAGE,
              },
            }
          : {}
      )}
    />
  );
};

const Broadcast = ({ path }: { path: string }) => {
  const { client, setActiveChannel } = useChatContext();

  useEffect(() => {
    setActiveChannel();
  }, []);

  return (
    <ChatUI
      path={path}
      filters={{
        type: "messaging",
        custom_type: ANNOUNCEMENTS_CHANNEL,
        members: { $in: [client.userID!] },
      }}
      broadcast
      hideTabBar
    />
  );
};

const BaseChat = () => {
  usePageTitle("Chat");
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { isCustomer, isAdmin, isCpa, isUserTypeForeignCA, isForeignCA } =
    useRoleBasedView();

  const isFCA = isUserTypeForeignCA || isForeignCA;

  const { authtoken } = useContext(authContext);
  const [currentTask, setCurrentTask] = useState({ other_channel_url: "" });
  const [checked, setChecked] = useState(true);
  const [isPrivateChannel, setPrivateChannel] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [showPublicPrivateToggle, setShowPublicPrivateToggle] = useState(true);
  const { client } = useChatContext();
  const dispatch = useDispatch();
  const chatSearchValue = useSelector(
    (state: RootState) => state.chatSearch.value
  );
  const ACTIVE = "ACTIVE";
  const isSubscriptionInactive =
    authtoken.platform_subscription?.subscription_status !== ACTIVE;
  const { goBack } = useHistory();

  const {
    open: openBroadcast,
    close: closeBroadcast,
    isOpen: isBroadcastOpen,
  } = useModal();

  const setChatType = (toggleValue: boolean) => {
    setChecked(toggleValue);
    setPrivateChannel(toggleValue);
  };

  useEffect(() => {
    if (pathname.includes("callhistory")) {
      setShowPublicPrivateToggle(false);
    } else {
      setShowPublicPrivateToggle(true);
    }
  }, [pathname]);

  const filterEmptyChatForAdmin =
    (isAdmin || isCpa || isFCA) && !chatSearchValue
      ? {
          last_non_broadcast_message_at: {
            $gte: RANDOM_DATE_FOR_CHANNELS_LAST_MESSAGE,
          },
        }
      : {};

  const isChatHome = [
    "unreplied",
    "mymessages",
    "broadcast",
    "callhistory",
    "settings",
    "reminders",
  ].every((v) => !pathname.includes(v));

  const isMyChat = matchPath(pathname, {
    path: [
      `${path}/mymessages/`,
      `${path}/mymessages/:channelId/`,
      `${path}/mymessages/:channelId/:messageId`,
    ],
    strict: false,
    exact: false,
  });

  const isUnreplied = matchPath(pathname, {
    path: [
      `${path}/unreplied/`,
      `${path}/unreplied/:channelId/`,
      `${path}/unreplied/:channelId/:messageId`,
    ],
    strict: false,
    exact: false,
  });

  const isCallHistory = matchPath(pathname, {
    path: [`${path}/callhistory`],
    strict: false,
    exact: false,
  });

  const isBroadcast = matchPath(pathname, {
    path: [
      `${path}/broadcast/`,
      `${path}/broadcast/:channelId/`,
      `${path}/broadcast/:channelId/:messageId`,
    ],
    strict: false,
    exact: false,
  });

  const isSettings = matchPath(pathname, {
    path: [`${path}/settings`],
    strict: false,
    exact: false,
  });

  const isReminder = matchPath(pathname, {
    path: [`${path}/reminders`],
    strict: false,
    exact: false,
  });

  const pathsToMatch = [
    path,
    `${path}/mymessages`,
    `${path}/unreplied`,
    `${path}/broadcast`,
    `${path}/callhistory`,
    `${path}/broadcast`,
    `${path}/settings`,
  ];

  const isChannelSelected = !pathsToMatch.some((checkPath) =>
    matchPath(checkPath, pathname)
  );

  return (
    <div className="t-flex t-h-full t-w-full t-flex-col">
      <div className="t-shrink-0 t-flex-grow-0 t-basis-auto">
        <Header
          v2
          title={
            <div className="t-ml-8 t-flex t-gap-1 t-items-center">
              {isChannelSelected && (
                <div className="t-block md:t-hidden">
                  <Button size="small" customType="ghost_icon" onClick={goBack}>
                    <ArrowLeft size="16px" />
                  </Button>
                </div>
              )}
              <span className="t-block">Chat</span>
            </div>
          }
          right={
            <div className="t-flex t-mr-10 t-gap-2">
              {!authtoken.is_any_service_user && (
                <SlackIntegration hideOnceAdded floating reverse={undefined} />
              )}
              <span className="t-flex t-flex-row t-items-center t-gap-2">
                {isAdmin && (
                  <div>
                    <Button onClick={openBroadcast}>
                      <div className="t-flex t-gap-1">
                        <BroadcastIcon /> <span>Broadcast</span>
                      </div>
                    </Button>
                    <Modal.Root
                      open={isBroadcastOpen}
                      onOpenChange={closeBroadcast}
                    >
                      <Modal.Content size="large" useCustomOverlay>
                        <BroadcastMessage onClose={closeBroadcast} />
                      </Modal.Content>
                    </Modal.Root>
                  </div>
                )}
              </span>
            </div>
          }
          bottom={
            <Tab.Root value="MATCH" className="md:t-w-auto t-w-full">
              <div className="chatHeader t-flex t-items-center t-justify-between t-gap-8 t-p-0 t-ml-10">
                <Tab.List>
                  {isCpa && (
                    <Tab.NavTrigger
                      to={`${url}/unreplied`}
                      value={isUnreplied ? "MATCH" : "UNMATCH"}
                    >
                      Unreplied
                    </Tab.NavTrigger>
                  )}
                  {isCpa && (
                    <Tab.NavTrigger
                      to={url}
                      value={isChatHome ? "MATCH" : "UNMATCH"}
                    >
                      <div className="t-min-w-max">All Chats</div>
                    </Tab.NavTrigger>
                  )}
                  {isAdmin && (
                    <>
                      <Tab.NavTrigger
                        to={`${url}/mymessages`}
                        value={isMyChat ? "MATCH" : "UNMATCH"}
                      >
                        <div className="t-min-w-max">My Chats</div>
                      </Tab.NavTrigger>
                      <Tab.NavTrigger
                        to={`${url}/callhistory`}
                        value={isCallHistory ? "MATCH" : "UNMATCH"}
                      >
                        <div className="t-min-w-max">Call Log</div>
                      </Tab.NavTrigger>
                      <Tab.NavTrigger
                        to={`${url}/broadcast`}
                        value={isBroadcast ? "MATCH" : "UNMATCH"}
                      >
                        Announcements
                      </Tab.NavTrigger>
                      <Tab.NavTrigger
                        to={`${url}/settings`}
                        value={isSettings ? "MATCH" : "UNMATCH"}
                      >
                        Settings
                      </Tab.NavTrigger>
                      <Tab.NavTrigger
                        to={`${url}/reminders`}
                        value={isReminder ? "MATCH" : "UNMATCH"}
                      >
                        Reminders
                      </Tab.NavTrigger>
                    </>
                  )}
                </Tab.List>
                {authtoken.is_any_service_user &&
                  currentTask?.other_channel_url &&
                  showPublicPrivateToggle && (
                    <div
                      className={classNames("t-block", {
                        "sm:t-hidden": !showToggle,
                        "sm:t-block": showToggle,
                      })}
                    >
                      <span className="t-flex t-w-[150px] t-items-center t-justify-between t-font-medium t-leading-[18px]">
                        <span
                          className={classNames({
                            "t-text-purple": !checked,
                          })}
                        >
                          Public
                        </span>
                        <Toggle
                          setToggleValue={setChatType}
                          checked={checked}
                        ></Toggle>
                        <span
                          className={classNames({ "t-text-purple": checked })}
                        >
                          Private
                        </span>
                      </span>
                    </div>
                  )}
              </div>
            </Tab.Root>
          }
        />
      </div>

      <Switch>
        <Route
          exact
          path={[
            `${path}/mymessages/`,
            `${path}/mymessages/:channelId/`,
            `${path}/mymessages/:channelId/:messageId`,
          ]}
        >
          <MyMessage path={`${url}/mymessages`} />
        </Route>
        <Route exact path={`${path}/callhistory/`}>
          <CallHistory />
        </Route>
        <Route
          exact
          path={[
            `${path}/unreplied/`,
            `${path}/unreplied/:channelId/`,
            `${path}/unreplied/:channelId/:messageId`,
          ]}
        >
          <ChatUI
            path={`${url}/unreplied`}
            filters={{
              type: "messaging",
              needs_reply: true,
              members: { $in: [client.userID!] },
              ...filterEmptyChatForAdmin,
            }}
            unreplied
          />
        </Route>
        <Route
          exact
          path={[
            `${path}/broadcast/`,
            `${path}/broadcast/:channelId/`,
            `${path}/broadcast/:channelId/:messageId`,
          ]}
        >
          <Broadcast path={`${url}/broadcast`} />
        </Route>
        <Route exact path={`${path}/settings/`}>
          <ChatSettings />
        </Route>
        <Route path={`${path}/reminders/`}>
          <Reminders />
        </Route>
        <Route
          exact
          path={[path, `${path}/:channelId/`, `${path}/:channelId/:messageId`]}
        >
          <ChatHome path={url} />
        </Route>
      </Switch>
      <DocumentPreviewModal />
    </div>
  );
};

export const ChatPage = withChatFilterContext(BaseChat);
