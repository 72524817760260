import { ReactNode } from "react";
import { Info } from "components/icons/Info";
import classNames from "classnames";
import { Button } from "../Button/Button";
import { Cross } from "components/icons/Cross";

type BannerPropsType = {
  type: "success" | "error" | "warning" | "info" | "primary" | "neutral";
  title: string;
  description: string;
  onClose?: () => void;
  cta?: ReactNode;
};

export const Banner = ({
  type,
  cta,
  title,
  onClose,
  description,
}: BannerPropsType) => {
  return (
    <div
      className={classNames(
        "t-flex t-p-3 t-border t-border-solid t-rounded-lg t-gap-2 t-items-center",
        {
          "t-bg-red-0 t-border-red-10": type === "error",
          "t-border-green-20 t-bg-green-10": type === "success",
          "t-border-orange-10 t-bg-orange-0": type === "warning",
          "t-bg-blue-0 t-border-blue-10": type === "info",
          "t-border-purple-10 t-bg-purple-0": type === "primary",
          "t-border-neutral-0 t-bg-surface-lighter-grey": type === "neutral",
        }
      )}
    >
      <span
        className={classNames("t-flex t-shrink-0 t-self-start", {
          "t-text-red": type === "error",
          "t-text-green": type === "success",
          "t-text-orange": type === "warning",
          "t-text-blue": type === "info",
          "t-text-purple": type === "primary",
          "t-text-text-60": type === "neutral",
        })}
      >
        <Info color="currentColor" stroke="1.5" size="20" />
      </span>
      <div className="t-space-y-2">
        <p className="t-m-0 t-text-subtitle-sm t-text-text-60">{title}</p>
        <p className="t-text-body-sm t-text-text-60">{description}</p>
      </div>
      {cta && <div className="t-ml-auto t-mr-2">{cta}</div>}
      {onClose && (
        <div
          className={classNames({
            "t-ml-auto": !cta,
          })}
        >
          <Button customType="ghost_icon" size="small" onClick={onClose}>
            <Cross color="currentColor" />
          </Button>
        </div>
      )}
    </div>
  );
};
