import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import {
  BALANCE_SHEET,
  INCOME_STATEMENT,
  SAVED_REPORTS,
} from "constants/bookkeeping";
import dayjs from "dayjs";
import { REPORT_FILE_TYPE } from "dictionaries";
import { Form, Formik, FormikValues, useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ReportsType } from "pages/Books/Reports/Reports";
import { useUpdateMonthSummaryMutation } from "store/apis/financialClosing";
import {
  useGetAllClosingSeasonQuery,
  useGetSeasonMonthsQuery,
} from "store/apis/reports";
import { BackendError } from "types/utils/error";
import { object, string } from "yup";

const MonthSelector = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const {
    values: { season },
  } = useFormikContext<{ season: string }>();

  const { data: months } = useGetSeasonMonthsQuery(
    { entityId, groupId, seasonSummaryId: season },
    { skip: !season || !entityId || !groupId }
  );

  if (!season) {
    return null;
  }

  return (
    <Combobox
      required
      name="month"
      label="Month"
      withForm
      placeholder="Please select month"
      options={months?.map(({ date, uuid, end_date }) => ({
        value: uuid,
        label: (
          <>
            {dayjs(date).format("MMMM YYYY")}
            {end_date && <> - {dayjs(end_date).format("MMMM YYYY")}</>}
          </>
        ),
      }))}
      block
      menuPortalTarget={document.getElementById("root")}
    />
  );
};

export const AddReportToClosingModal = ({
  open,
  onClose,
  reportType,
  documentId,
}: {
  open: boolean;
  onClose: () => void;
  reportType: ReportsType;
  documentId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [updateMonthSummary] = useUpdateMonthSummaryMutation();

  const { data: seasons } = useGetAllClosingSeasonQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const onSubmit = async (values: FormikValues) => {
    try {
      const formData = new FormData();
      formData.append(
        REPORT_FILE_TYPE[
          reportType as Exclude<ReportsType, typeof SAVED_REPORTS>
        ],
        documentId!
      );
      formData.append("uuid", values.month);
      await updateMonthSummary({
        payload: formData,
        groupId,
        entityId,
      }).unwrap();
      onClose();
      successToast({ message: "Report added!" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Add to Monthly Closing</Modal.Title>
          <Modal.Close />
        </Modal.Header>

        <Formik
          initialValues={{ season: "", month: "" }}
          onSubmit={onSubmit}
          validationSchema={object({
            season: string().required("Please select a season"),
            month: string().required("Please select a month"),
          })}
          validateOnChange
        >
          {({ isSubmitting, isValid }) => (
            <Form className="t-m-0 t-w-full">
              <Modal.Body className="t-flex t-gap-6 t-flex-col">
                <Combobox
                  required
                  name="season"
                  label="Season"
                  withForm
                  placeholder="Please select season"
                  options={seasons?.map(({ name, uuid }) => ({
                    value: uuid,
                    label: name,
                  }))}
                  block
                  menuPortalTarget={document.getElementById("root")}
                />
                <MonthSelector />
                <div className="t-flex t-flex-col">
                  <Label>Associated Reports</Label>
                  {reportType === BALANCE_SHEET && (
                    <Tag tagType="yellow">Balance Sheet</Tag>
                  )}
                  {reportType === INCOME_STATEMENT && (
                    <Tag tagType="orange">Income Statement</Tag>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="t-flex t-gap-3 t-justify-end t-items-center">
                <Button type="reset" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  customType="primary"
                  disabled={!isValid || isSubmitting}
                  isLoading={isSubmitting}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
