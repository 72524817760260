import { AppLayout } from "components/AppLayout/AppLayout";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { StartFinancialClosingModal } from "components/FinancialClosing/StartFinancialClosingModal";
import { GroupSelectorWithDefaultGroup } from "components/GroupSelector/GroupSelectorWithDefaultGroup";
import { Accounting } from "components/icons/LeftNav/Books/Accounting";
import { BookkeepingServices } from "components/icons/LeftNav/Books/BookkeepingServices";
import { Contacts as ContactsIcon } from "components/icons/LeftNav/Books/Contacts";
import { DataSources as DataSourcesIcon } from "components/icons/LeftNav/Books/DataSources";
import { FixedAsset as FixedAssetIcon } from "components/icons/LeftNav/Books/FixedAsset";
import { Invoicing } from "components/icons/LeftNav/Books/Invoicing";
import { MonthlyClosing } from "components/icons/LeftNav/Books/MonthlyClosing";
import { MyProducts as ProductAndServicesIcon } from "components/icons/LeftNav/Books/MyProducts";
import { Reports as ReportsIcon } from "components/icons/LeftNav/Books/Reports";
import { RevenueMetrics as RevenueMetricsIcon } from "components/icons/LeftNav/Books/RevenueMetrics";
import { Transactions as TransactionsIcon } from "components/icons/LeftNav/Books/Transactions";
import { Chat } from "components/icons/LeftNav/Chat";
import { Documents as DocumentsIcon } from "components/icons/LeftNav/Documents";
import { Home } from "components/icons/LeftNav/Home";
import { Transfers } from "components/icons/LeftNav/Intragroup/Transfers";
import { Ticket } from "components/icons/LeftNav/Ticket";
import { InkleBooksLogo } from "components/icons/Logos/InkleBooksLogo";
import { InkleBooksSymbol } from "components/icons/Logos/InkleBooksSymbol";
import { ReconciliationTxn } from "components/Reconciliation/ReconciliationTxn";
import { ReportViewWithId } from "components/Reports/ReportViewById";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { TopBar } from "components/TopBar/TopBar";
import { Upgrade } from "components/TopBar/Upgrade";
import InitiateTransferModal from "components/TPPayments/TransferModal/InitiateTransferModal";
import { BOOKS_PLUS_PLAN } from "constants/addons";
import {
  ACTION_ITEMS_CLICKED_FROM_SIDEBAR,
  CHAT_CLICKED_FROM_SIDEBAR,
  CLICKED_INVOICE_ON_LEFT_NAV,
  DOCUMENTS_CLICKED_FROM_SIDEBAR,
} from "constants/analyticsEvents";
import { MONTHLY } from "constants/billingCycles";
import { FOREIGN_CA } from "constants/onBoarding";
import { BOOKS } from "constants/taskCategoryType";
import { useAnalytics } from "hooks/useAnalytics";
import { useAuth } from "hooks/useAuth";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { Invoices } from "pages/TPPayments/Invoices";
import { parse, stringify } from "qs";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { EntitySelector } from "../../components/EntitySelector/EntitySelector";
import { BooksHome } from "./BooksDashboard/BooksHome";
import { InvoiceCustomerInfo } from "./Customers/InvoiceCustomerInfo";
import { CustomersAndVendors } from "./CustomersAndVendors/CustomersAndVendors";
import { DataSources } from "./DataSources/DataSources";
import { FinancialClosing } from "./FinancialClosing/FinancialClosing";
import { FixedAsset } from "./FixedAsset/FixedAsset";
import { GeneralLedgerApp } from "./GeneralLedger/GeneralLedger";
import { InvoicesHome } from "./Invoicing/InvoicesHome";
import { MyProducts } from "./MyProducts/MyProducts";
import { Reports } from "./Reports/Reports";
import { RevenueMetrics } from "./RevenueMetrics/RevenueMetrics";
import { Services } from "./Services/Services";
import { Transactions } from "./Transactions/Transactions";
import React, { useEffect } from "react";
import Loader from "components/design/loader";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import { useTransactionApisPrefetch } from "store/apis/transactions";
import { useTransactionsFilter } from "hooks/useTransactionsFilter";
import Toast from "components/DesignSystem/Toast/Toast";
import { useReportsPrefetch } from "store/apis/reports";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";
import { useBankConnectionsPrefetch } from "store/apis/bankConnections";
import { useBooksConnectionsPrefetch } from "store/apis/booksConnections";

const BooksNav = () => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { type } = useAuth();
  const { trackEvent } = useAnalytics();

  const { isCpa, isForeignCA, isCustomer } = useRoleBasedView();
  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        <LeftBarItem exact to={search} icon={Home}>
          Home
        </LeftBarItem>
        <LeftBarItem to={`/transactions${search}`} icon={TransactionsIcon}>
          Transactions
        </LeftBarItem>
        <LeftBarItem to={`/data-sources${search}`} icon={DataSourcesIcon}>
          Data Sources
        </LeftBarItem>
        <LeftBarItem to={`/revenue-metrics${search}`} icon={RevenueMetricsIcon}>
          Revenue Metrics
        </LeftBarItem>
        <LeftBarItem to={`/invoicing${search}`} icon={Invoicing}>
          <div onClick={() => trackEvent(CLICKED_INVOICE_ON_LEFT_NAV)}>
            Invoicing
          </div>
        </LeftBarItem>
        <LeftBarItem to={`/customers-and-vendors${search}`} icon={ContactsIcon}>
          Vendors & Customers
        </LeftBarItem>
        <LeftBarItem to={`/my-products${search}`} icon={ProductAndServicesIcon}>
          My Products
        </LeftBarItem>
        {(isCpa || isForeignCA) && (
          <LeftBarItem to={`/transfers${search}`} icon={Transfers}>
            Transfers
          </LeftBarItem>
        )}

        <div className="t-py-3">
          <LeftBarSeparator name="Accounting" />
        </div>

        <LeftBarItem
          to={`/live-financials${
            search ? `${search}&` : "?"
          }reportType=BALANCE_SHEET`}
          icon={ReportsIcon}
        >
          Live Financials
        </LeftBarItem>
        <LeftBarItem to={`/monthly-closing${search}`} icon={MonthlyClosing}>
          Monthly Closing
        </LeftBarItem>
        <LeftBarItem to={`/services${search}`} icon={BookkeepingServices}>
          Bookkeeping Services
        </LeftBarItem>
        <LeftBarItem
          to={`/categories${search}&hideZero=${true}`}
          icon={Accounting}
        >
          Categories
        </LeftBarItem>
        <LeftBarItem to={`/fixed-assets${search}`} icon={FixedAssetIcon}>
          Fixed Assets
        </LeftBarItem>

        {(isCpa || isForeignCA) && (
          <>
            <div className="t-py-3">
              <LeftBarSeparator />
            </div>
            <button
              className="all:unset t-w-full"
              onClick={() => trackEvent(ACTION_ITEMS_CLICKED_FROM_SIDEBAR)}
            >
              <LeftBarItem to="/tickets" icon={Ticket}>
                Tickets
              </LeftBarItem>
            </button>
            <button
              className="all:unset t-w-full"
              onClick={() => trackEvent(CHAT_CLICKED_FROM_SIDEBAR)}
            >
              <LeftBarItem to="/chat/unreplied" icon={Chat}>
                Chat
              </LeftBarItem>
            </button>
          </>
        )}
      </div>

      {isCustomer && (
        <div>
          <div className="t-py-3">
            <LeftBarSeparator />
          </div>
          <button
            className="all:unset t-w-full"
            onClick={() => trackEvent(CHAT_CLICKED_FROM_SIDEBAR)}
          >
            <ChatLeftNav to={`/chat${search}`} />
          </button>
          <button
            className="all:unset t-w-full"
            onClick={() => trackEvent(ACTION_ITEMS_CLICKED_FROM_SIDEBAR)}
          >
            <LeftBarItem to={`/action-items${search}`} icon={Ticket}>
              Action Items
            </LeftBarItem>
          </button>
          <button
            className="all:unset t-w-full"
            onClick={() => trackEvent(DOCUMENTS_CLICKED_FROM_SIDEBAR)}
          >
            <LeftBarItem to={`/documents${search}`} icon={DocumentsIcon}>
              Documents
            </LeftBarItem>
          </button>
        </div>
      )}
    </div>
  );
};

export const BooksDashboard = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { isCpa, isCustomer, isForeignCA } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { upgrade } = useUpgradePlan();
  const history = useHistory();

  const allFilters = useTransactionsFilter({
    searchTerm: null,
    pageNum: 1,
  });

  const prefetchTransactions = useTransactionApisPrefetch(
    "getAllTransactionsData"
  );
  const prefetchLedgerBanks = useBankConnectionsPrefetch("getEntityBanks");
  const prefetchLiveReports = useReportsPrefetch("newInteractiveReport");
  const prefetchConnections = useBooksConnectionsPrefetch("getAllConnections");

  useEffect(() => {
    if (entityId) {
      prefetchTransactions(allFilters);
      prefetchLiveReports({
        groupId,
        entityId,
        reportType: "BALANCE_SHEET",
        accountingMethod: "CASH",
        startDate: dayjs().startOf("year").format(YYYY_MM_DD),
        endDate: dayjs().format(YYYY_MM_DD),
        // @ts-ignore
        showNonZeroAccounts: true,
        comparison_report_type: "TOTALS",
      });
      prefetchLedgerBanks({
        groupId,
        entityId,
      });
      prefetchConnections({
        groupId,
        entityId,
      });
    }
  }, [allFilters, entityId]);

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const { data: bookkeepingSubscriptionPlans = [] } = useGetSubscriptionsQuery(
    {
      subscription_types: BOOKS_PLUS_PLAN,
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const activeBooksPlusPlan = bookkeepingSubscriptionPlans?.find(
    ({ group_subscription = [] }) => group_subscription?.length > 0
  );

  const booksMonthlyPlusPlan = bookkeepingSubscriptionPlans?.find(
    (ele) => ele.billing_cycle === MONTHLY
  );

  const onUpgrade = () => {
    upgrade({ addon: booksMonthlyPlusPlan! });
    history.push(`/settings/billing?entity=${entityId}`);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: isCustomer ? "Books" : "Books Admin" },
    });
  }, [isCustomer]);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          useInternalLink
          showCollapse
          logo={
            <>
              <LeftBarLogo logo={InkleBooksLogo} symbol={InkleBooksSymbol} />
              {(isCpa || isForeignCA) && (
                <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                  <GroupSelectorWithDefaultGroup
                    onGroupChange={({ isLoading }) => setIsLoading(isLoading)}
                  />
                  <EntitySelector />
                </div>
              )}
            </>
          }
        >
          <BooksNav />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={
            isCustomer && (
              <>
                {Boolean(activeBooksPlusPlan) ? undefined : (
                  <Upgrade onUpgrade={onUpgrade} />
                )}
              </>
            )
          }
          logo={
            <>
              <LeftBarLogo logo={InkleBooksLogo} symbol={InkleBooksSymbol} />

              <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                {(isForeignCA || isCpa) && (
                  <GroupSelectorWithDefaultGroup
                    onGroupChange={({ isLoading }) => setIsLoading(isLoading)}
                  />
                )}
                <EntitySelector right={0} size="small" />
              </div>
            </>
          }
          nav={
            <LeftBar logo={<></>}>
              <BooksNav />
            </LeftBar>
          }
        >
          {isCustomer && (
            <EntitySelector
              toolTipPlacement="bottom"
              right={0}
              disableNonUsEntities
              showGoToEntity
              size="small"
            />
          )}
        </TopBar>
      }
      banner={<DashboardBanner />}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Switch>
          <Route
            path={`${path}/transactions/reconciliation/start/:bankAccountId`}
          >
            {(props) => (
              <Redirect
                to={`${path}/data-sources/reconciliation/start/${props.match?.params.bankAccountId}${search}`}
              />
            )}
          </Route>
          <Redirect
            from={`${path}/transactions/reconciliation`}
            to={`${path}/data-sources/reconciliation${search}`}
          />
          <Redirect
            from={`${path}/connections`}
            to={`${path}/data-sources${search}`}
          />
          <RestrictedRoute path={`${path}/transactions`}>
            <Transactions />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/services/addtask`}>
            <TemplateMenu
              to={`/books/services${search}`}
              taskCategoryType={BOOKS}
            />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/services/:taskId`}>
            <FilingDetails
              isFromServices
              parentRoute="/books/services"
              addEntityInUrl
            />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/services`}>
            <Services />
          </RestrictedRoute>
          <RestrictedRoute
            exact
            path={`${path}/reconciliation/:reconcilationId`}
          >
            <ReconciliationTxn />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/monthly-closing`}>
            <FinancialClosing />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/merchants`}>
            <Redirect to={`${url}/customers-and-vendors${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/invoicing`}>
            <InvoicesHome />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/categories`}>
            <GeneralLedgerApp />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/data-sources`}>
            <DataSources />
          </RestrictedRoute>
          <RestrictedRoute
            path={`${path}/customers-and-vendors/customers/:customerId`}
          >
            <InvoiceCustomerInfo
              breadcrumbs={[
                {
                  name: "Customers",
                  link: `${url}/customers-and-vendors/customers${search}`,
                },
              ]}
            />
          </RestrictedRoute>
          <RestrictedRoute
            path={`${path}/customers/:customerId`}
            render={({ match }) => {
              return (
                <Redirect
                  to={`/books/customers-and-vendors/customers/${match.params.customerId}${search}`}
                />
              );
            }}
          />
          <RestrictedRoute path={`${path}/customers`}>
            <Redirect to={`${url}/customers-and-vendors/customers${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/customers-and-vendors`}>
            <CustomersAndVendors />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/vendors`}>
            <Redirect to={`${url}/customers-and-vendors${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/my-products`}>
            <MyProducts />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/products-and-services`}>
            <Redirect to={`${url}/my-products${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/revenue-metrics`}>
            <RevenueMetrics />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/live-financial/:reportId`}>
            <ReportViewWithId />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/live-financials`}>
            <Reports />
          </RestrictedRoute>
          <Route path={`${path}/fixed-assets`}>
            <FixedAsset />
          </Route>
          <Redirect
            from={`${path}/accounting`}
            to={`${path}/categories${search}`}
          />
          <Redirect
            from={`${path}/reports`}
            to={`${path}/live-financials${search}`}
          />{" "}
          <Redirect from={`${path}/metrics`} to={`${path}${search}`} />
          <Redirect
            from={`${path}/report`}
            to={`${path}/live-financial${search}`}
          />
          <Redirect
            from={`${path}/report/:reportId`}
            to={`${path}/live-financial/:reportId${search}`}
          />
          {(isCpa || isForeignCA) && (
            <RestrictedRoute path={`${path}/transfers/transfer/`}>
              <InitiateTransferModal to={`${path}/transfers/`} />
            </RestrictedRoute>
          )}
          {(isCpa || isForeignCA) && (
            <RestrictedRoute path={`${path}/transfers/`}>
              <Invoices />
            </RestrictedRoute>
          )}
          <RestrictedRoute exact path={`${path}/dashboard`}>
            <Redirect to={path} />
          </RestrictedRoute>
          {isCustomer && (
            <RestrictedRoute exact path={`${path}/tickets`}>
              <Redirect to={`${path}/action-items`} />
            </RestrictedRoute>
          )}
          <RestrictedRoute exact path={`${path}/financial-closing`}>
            <Redirect to={`${path}/monthly-closing`} />
          </RestrictedRoute>
          <RestrictedRoute exact path={path} component={BooksHome} />
          <RoutesAccrossApps />
        </Switch>
      )}
      <StartFinancialClosingModal />
    </AppLayout>
  );
};
