import { stringify } from "qs";
import { ComplianceDetail } from "types/Models/calendar";
import { Task } from "types/Models/task";
import { emptyApi } from "./emptyApi";
import { Product } from "./products";

type GetTaskListUserProps = {
  showFinished?: boolean;
  showArchive?: boolean;
  showOngoing?: boolean;
  searchTerm?: string | null;
  seasons?: string | null;
  taskCategoryType?: "TAX,RND" | "BOOKS" | "INTRA_GROUP" | "SALES_TAX";
  baseTaskKey?: "PRO_MONTHLY_BOOKKEEPING";
};

export type TaskAlarm = {
  alarm_info: (ComplianceDetail & { base_task_template_info: Product }) | null;
  task_info:
    | (Task & {
        subRows?: Task[];
        toggleAllRowsExpanded?: boolean;
      })
    | null;
  deadline_date: String;
  data_type: "TASK" | "ALARM";
  uuid: string;
};

type GetTaskListCPAProps = GetTaskListUserProps & {
  groupId: string;
  include_alarms?: boolean;
};

export const taskAlarmApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskListForUser: build.query<
      {
        tasks?: Task[];
        ongoing_count?: number;
        count?: number;
        completed_count?: number;
        archived_count?: number;
      },
      GetTaskListUserProps
    >({
      query: ({
        showFinished,
        showArchive,
        showOngoing,
        searchTerm,
        seasons,
        taskCategoryType,
        baseTaskKey,
      }) => {
        let queryUrl = stringify(
          {
            show_finished: showFinished,
            show_archived: showArchive,
            ongoing: showOngoing,
            search_term: searchTerm,
            seasons,
            task_category_type: taskCategoryType,
            base_task_key: baseTaskKey,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tasks/company/${queryUrl}`,
        };
      },
      providesTags: (_result, _error) => [{ type: "TaskList" }],
    }),

    getTaskListForServiceTeam: build.query<
      {
        tasks: Task[];
        ongoing_count?: number;
        count?: number;
        completed_count?: number;
        archived_count?: number;
      },
      GetTaskListCPAProps
    >({
      query: ({
        showFinished,
        showArchive,
        showOngoing,
        groupId,
        searchTerm,
        seasons,
        taskCategoryType,
      }) => {
        let queryUrl = stringify(
          {
            show_finished: showFinished,
            show_archived: showArchive,
            ongoing: showOngoing,
            search_term: searchTerm,
            seasons,
            task_category_type: taskCategoryType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tasks/company_group/${groupId}/${queryUrl}`,
        };
      },
      providesTags: (_result, _error) => [{ type: "TaskList" }],
    }),

    updateTaskArchival: build.mutation<any, { taskId: string }>({
      query: ({ taskId }) => {
        return {
          url: `/api/inkle/tasks/${taskId}/archive/toggle/`,
          method: "PUT",
        };
      },
      invalidatesTags: (_result, _error) => {
        return [{ type: "TaskList" }];
      },
    }),

    taskYearWiseCounts: build.query<
      { task__season: string; count: number }[],
      GetTaskListCPAProps
    >({
      query: ({
        showFinished,
        showArchive,
        showOngoing,
        groupId,
        searchTerm,
        include_alarms,
        taskCategoryType,
      }) => {
        let queryUrl = stringify(
          {
            show_finished: showFinished,
            show_archived: showArchive,
            ongoing: showOngoing,
            search_term: searchTerm,
            include_alarms,
            task_category_type: taskCategoryType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/tasks/group/${groupId}/count/${queryUrl}`,
        };
      },
      providesTags: (_result, _error) => {
        return [{ type: "TaskList" }];
      },
    }),
    getTaskStates: build.query<{ name: string; uuid: string }[], void>({
      query: () => {
        return {
          url: "/api/inkle/tasks/get_all_task_states/",
        };
      },
    }),

    getTaskAlarm: build.query<
      TaskAlarm[],
      GetTaskListUserProps & { groupId: string }
    >({
      query: ({
        showFinished,
        showArchive,
        showOngoing,
        searchTerm,
        seasons,
        taskCategoryType,
        groupId,
      }) => {
        let queryUrl = stringify(
          {
            show_finished: showFinished,
            show_archived: showArchive,
            ongoing: showOngoing,
            search_term: searchTerm,
            seasons,
            task_category_type: taskCategoryType,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/tasks/group/${groupId}/tasks_alarms/${queryUrl}`,
        };
      },
      providesTags: ["TASK_ALARM"],
    }),
    getTaskAlarmById: build.query<
      TaskAlarm,
      { taskAlarmId: string; groupId: string; data_type: "TASK" | "ALARM" }
    >({
      query: ({ taskAlarmId, groupId, data_type }) =>
        `api/inkle/tasks/group/${groupId}/tasks_alarms/${taskAlarmId}/?data_type=${data_type}`,
      providesTags: (result) =>
        result ? [{ type: "TASK_ALARM", id: result.uuid } as const] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaskListForUserQuery,
  useGetTaskListForServiceTeamQuery,
  useUpdateTaskArchivalMutation,
  useTaskYearWiseCountsQuery,
  useGetTaskStatesQuery,
  useGetTaskAlarmQuery,
  useGetTaskAlarmByIdQuery,
} = taskAlarmApis;
